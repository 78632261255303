<div *ngIf="visible">
    <div
        class="pointer-events-none absolute h-0 w-0 border-r-[20px] border-t-[20px] border-r-transparent border-t-yellow-100"
        [ngStyle]="{ left: selectedLoopStartIRN * getZoomFactor() + 'px' }"
    ></div>
    <div
        class="pointer-events-none absolute h-0 w-0 border-l-[20px] border-t-[20px] border-l-transparent border-t-cyan-100"
        [ngStyle]="{ left: selectedLoopEndIRN * getZoomFactor() - 20 + 'px' }"
        *ngIf="selectedLoopEndIRN !== 0"
    ></div>
    <div
        class="absolute h-[20px] opacity-60"
        [ngStyle]="{
            left: startPos * getZoomFactor() + 'px',
            width: width * getZoomFactor() + 'px',
            backgroundColor: background
        }"
        (click)="onClickOverlay($event)"
        *ngIf="selectedLoopEndIRN !== 0"
    ></div>
</div>
