import { Injectable, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { FileManagerService } from './file-manager.service'
import { AppState, Logger, PlatformService, Soundfont, doWithLock } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { SetSoundfontAction } from '../../store/actions/settings-state.actions'
import { ModalService } from '@tekbox-coco/midiative-components'
import { timer } from 'rxjs'
import { StorageService } from '../storage/storage.service'

/**
 * SoundfontManagerService is used to handle on device soundfonts
 */
@Injectable({
    providedIn: 'root',
})
export class SoundfontManagerService {
    private readonly logger = Logger.createLogger('SoundfontManagerService')

    private soundfontsRoot = 'Soundfonts'
    private loadedInstruments: string[] = []

    private allSoundfonts: Soundfont[] = [
        {
            name: 'FatBoy',
            url: 'https://sf.midiative.com/FatBoy/',
        },
        {
            name: 'FluidR3_GM',
            url: 'https://sf.midiative.com/FluidR3_GM/',
        },
        {
            name: 'MusyngKite',
            url: 'https://sf.midiative.com/MusyngKite/',
        },
    ]

    selectSounfontTimeout: any

    constructor(
        private http: HttpClient,
        private fileManagerService: FileManagerService,
        private store: Store<AppState>,
        private modalService: ModalService,
        private pl: PlatformService,
        private storage: StorageService
    ) {
        store.select('settingsState').subscribe((state) => {
            // if no soundfont is selected wait a few seconds for settings store to initialize
            // if still no soundfont is selected select the first soundfont automatically
            if (state.soundFont.name === 'none') {
                this.logger.debug('no soundfont selected start timer...')
                this.selectSounfontTimeout = setTimeout(() => {
                    this.store.dispatch(new SetSoundfontAction(this.allSoundfonts[0]))
                    timer(200).subscribe(async () => {
                        await this.storage.saveSettings()
                        this.logger.info('sound font changed')
                    })
                }, 3000)
            } else {
                this.logger.debug(`Soundfont selected ${state.soundFont.name}`)
                // clear timer
                clearTimeout(this.selectSounfontTimeout)

                //  reloaded instruments on soundfont switch
                const orgi = this.loadedInstruments
                this.loadedInstruments = []
                this.loadInstruments(orgi, state.soundFont)
            }
        })
    }

    async listSoundfonts(): Promise<Soundfont[]> {
        return this.allSoundfonts
    }

    async soundfontExists(baseUrl: string, sfName: string): Promise<boolean> {
        const sfPath = `${this.soundfontsRoot}/${sfName}`
        return await this.fileManagerService.exists(sfPath)
    }

    /**
     * try to load soundfont file from disk
     * @param sfName
     * @param instrument
     * @returns
     */
    private async loadSoundfontFileFromDisk(sfName: string, instrument: string) {
        this.logger.debug('loadSoundfontFileFromDisk', sfName, instrument)
        // check file exists
        try {
            const filesExists = await this.fileManagerService.stat(`${this.soundfontsRoot}/${sfName}/${instrument}.js`)
            if (filesExists) {
                // load file
                const data = await this.fileManagerService.readFile(filesExists.uri)
                return data
            }
        } catch (e) {
            // does not exists ...
        }
        return null
    }

    private async saveSoundfontFileToDisk(sfName: string, name: string, data: string) {
        // create soundfont root dir if not exist
        if (!(await this.fileManagerService.exists(this.soundfontsRoot))) {
            await this.fileManagerService.mkdir(this.soundfontsRoot)
        }

        // create soundfont dir if not exist
        const sfPath = `${this.soundfontsRoot}/${sfName}`
        if (!(await this.fileManagerService.exists(sfPath))) {
            await this.fileManagerService.mkdir(sfPath)
        }

        const filePath = `${sfPath}/${name}.js`
        await this.fileManagerService.saveFile(filePath, [data], 'application/json')
    }

    /**
     * Handles single soundfont file download
     * @param baseUrl
     * @param sfPath
     * @param name
     * @private
     */
    private async downloadInstrument(baseUrl: string, name: string): Promise<string> {
        const url = `${baseUrl}/${name}-mp3.js`
        this.logger.debug(`Download ${url}`)
        const data = await this.http.get(url, { responseType: 'text' }).toPromise()
        return data
    }

    private addSoundfontScriptTag(data: string) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.text = data
        document.body.appendChild(script)
    }

    /**
     * register instruments in WebAudio
     * @param instruments
     */
    private registerWebAudio(instruments: string[]) {
        this.logger.debug('register instruments in WebAudio')
        const root = (window as any).MIDI
        root.WebAudio.connect({
            instruments: instruments,
            format: 'mp3',
            __api: 'webaudio',
        })
    }

    /**
     * Custom loadInstruments for local soundfont files.
     * Loads soundfont files from disk and injects them into window object
     * @param soundFont
     * @param instruments
     * @param originalLoadPlugin
     * @param originalArgs
     */
    async loadInstruments(
        _instruments: string[],
        soundFont: Soundfont,
        originalLoadPlugin?: any,
        originalArgs?: any
    ): Promise<void> {
        // load each instruments only once
        await doWithLock('loadInstruments', async () => {
            const instruments = [...new Set(_instruments)].filter((i) => this.loadedInstruments.indexOf(i) === -1)
            this.logger.debug(`Load instruments`, instruments)
            try {
                for (const instrument of instruments) {
                    let data = ''
                    if (this.pl.isNative()) {
                        const cached = await this.loadSoundfontFileFromDisk(soundFont.name, instrument)
                        if (cached != null) {
                            await this.addSoundfontScriptTag(cached)
                            this.logger.debug(`Instrument '${instrument}' loaded from cache`)
                            this.loadedInstruments.push(instrument)
                            continue
                        }
                    }
                    this.logger.debug(`Download instrument ${instrument} from ${soundFont.url}`)
                    data = await this.downloadInstrument(soundFont.url, instrument)
                    await this.addSoundfontScriptTag(data)

                    if (data === '') {
                        throw new Error(`Error loading ${instrument} ${soundFont.url}`)
                    }

                    this.logger.debug(`Instrument '${instrument}' loaded`)
                    this.loadedInstruments.push(instrument)
                    if (this.pl.isNative()) {
                        this.logger.debug(`Saved instrument ${instrument} to disk`)
                        this.saveSoundfontFileToDisk(soundFont.name, instrument, data)
                    }
                }
                this.registerWebAudio(instruments)
            } catch (e) {
                this.logger.warn('Error loading soudfont', e)

                // we must add all instruments to trigger reloading on soundfont switch
                instruments.forEach((e) => this.loadedInstruments.push(e))
            }
        })
    }

    async deleteAll(): Promise<boolean> {
        return await this.fileManagerService.rmdir(this.soundfontsRoot)
    }
}
