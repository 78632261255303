import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { Capacitor } from '@capacitor/core'

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    constructor(private platform: Platform) {}

    isNative() {
        return Capacitor.isNativePlatform()
    }

    isIOS() {
        return Capacitor.isNativePlatform() && this.platform.is('ios')
    }

    isAndroid() {
        return Capacitor.isNativePlatform() && this.platform.is('android')
    }
}
