import { DefaultAction, AppState, PlatformService } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { StorageService } from '../../services/storage/storage.service'
import { AdService } from '../../services/ad.service'
import { ToastService } from '../../services/toast.service'
import { Router } from '@angular/router'
import { NavigateToHomeAction } from '../navigate/NavigateToHomeAction'
import { Share } from '@capacitor/share'
import { MidiService } from '../../services/midi.service'
import { Logger, ProjectStateModel, initialProjectState } from '@tekbox-coco/midiative-commons'
import { FileExporterService } from '../../services/file-manager/file-exporter.service'

import { ActionController } from '../actions.decorator'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'DownloadProjectAction',
})
export class DownloadProjectAction extends DefaultAction {
    private readonly logger = Logger.createLogger('DownloadProjectAction')

    private projectName: string = ''
    private projectState: ProjectStateModel = initialProjectState

    constructor(
        private storageService: StorageService,
        private store: Store<AppState>,
        private toastService: ToastService,
        private midiService: MidiService,
        private platformService: PlatformService,
        private fileExporterService: FileExporterService,
        private adService: AdService,
        private translateService: TranslateService
    ) {
        super()
        this.icon.next('outbox')
        this.label.next('PROJECT.MENU.DOWNLOAD_PROJECT')

        this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectState = value
            },
            error: (e) => {
                console.error(e)
            },
        })
    }

    async onClick() {
        this.logger.debug('export project', this.projectState)
        const result = await this.fileExporterService.saveProject(this.projectState, this.projectState.name)

        if (result.successful && result.data) {
            const message = this.translateService.instant('STORAGE.DOWNLOAD_PROJECT', {
                value: this.projectState.name,
            })
            this.toastService.info(message)
        } else {
            this.toastService.warn(`STORAGE.EXPORT_ERROR`)
        }

        // Show ads on export
        await this.adService.showInterstitial()
    }
}
