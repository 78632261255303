import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface MenuItemColor {
    borderColor?: string
    textColor?: string
    backgroundColor?: string
}

export interface MenuItem {
    label?: string
    icon?: string
    callback?: (args?: any) => any
    borderRadius?: string
    disabled?: boolean
    width?: string
    height?: string
    vertical?: boolean
    colors?: MenuItemColor
    routerLink?: string[]
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent {
    @Input() items: MenuItem[]
    @Input() visible: boolean
    @Input() drawer: boolean
    @Input() emptyMessage: string
    @Input() drawerOpen: boolean

    @Output() onBurgerClick: EventEmitter<any> = new EventEmitter<any>()

    clickedMenuButton() {
        this.onBurgerClick.emit()
    }

    onClickOutside($event: MouseEvent) {
        this.onBurgerClick.emit()
    }
}
