import { Logger } from '@tekbox-coco/midiative-commons'
import { BehaviorSubject, Observable, Subject, Subscription, interval } from 'rxjs'

export const TICK_EVENT_IDENTIFIER = 'TickEvent'

export class TickEmitter {
    private static instance: TickEmitter
    private readonly tickEvent: Event
    private intervalBuffer: Subscription
    private readonly logger = Logger.createLogger('TickEmitter')

    private ticking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private millisecondsPerQuarterBeat: number

    private constructor() {
        this.tickEvent = new Event(TICK_EVENT_IDENTIFIER)
        this.millisecondsPerQuarterBeat = 0
    }

    public static getInstance(): TickEmitter {
        if (!TickEmitter.instance) {
            TickEmitter.instance = new TickEmitter()
        }

        return TickEmitter.instance
    }

    public startTick() {
        if (!this.ticking.value) {
            document.dispatchEvent(this.tickEvent)
            this.intervalBuffer = interval(this.millisecondsPerQuarterBeat).subscribe({
                next: (res) => {
                    document.dispatchEvent(this.tickEvent)
                },
                error: (error) => {
                    this.logger.error(error.messages)
                },
            })
            this.ticking.next(true)
        }
    }

    public stopTick() {
        this.ticking.next(false)
        if (this.intervalBuffer !== undefined){
            this.intervalBuffer.unsubscribe()
        }
    }

    public setBPM(bpm: number) {
        // TODO: Check this
        this.millisecondsPerQuarterBeat = Math.round(60000 / bpm)
    }

    isTicking() {
        return this.ticking
    }
}
