import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header.component'
import { ButtonModule } from '../button/button.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    imports: [CommonModule, ButtonModule, TranslateModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
})
export class HeaderModule {}
