import { HistoryActionTypes, HistoryStateActions } from './history.actions'
import { HistoryItem, HistoryStateModel, initialHistoryState } from './history.model'
import { Action, ActionReducer } from '@ngrx/store'
import {
    AddNoteAction,
    AddNotesAction,
    ClearNotesAction,
    DeleteNoteAction,
    DeleteNotesAction,
    OctaveDownNotesAction,
    OctaveUpNotesAction,
    SetPatternLengthIRNAction,
    UpdateNoteAction,
    UpdateNotesAction,
    UpsertNoteAction,
    UpsertNotesAction,
} from '../../actions/piano-roll-state.actions'
import {
    AddPatternAction,
    AddTrackAction,
    CutPatternAction,
    DeletePatternAction,
    DeletePatternMappingAction,
    DeleteTrackAction,
    HideLoopSelectorAction,
    SetLoopSelectorEndIRNAction,
    SetLoopSelectorStartIRNAction,
    UpdatePatternAction,
    UpdateTrackAction,
} from '../../actions/project-state.actions'
import {
    AddDrumSequencerNoteAction,
    AddDrumSequencerNotesAction,
    ClearDrumSequencerNotesAction,
    DeleteDrumSequencerNoteAction,
    DeleteDrumSequencerNotesAction,
    UpdateDrumSequencerNoteAction,
    UpdateDrumSequencerNotesAction,
    UpsertDrumSequencerNoteAction,
    UpsertDrumSequencerNotesAction,
} from '../../actions/drum-sequencer-state.actions'
import { Logger, AppState } from '@tekbox-coco/midiative-commons'

const logger = Logger.createLogger('historyMetaReducer')

export function historyMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    // The actual history object that keeps the history
    let history: HistoryStateModel = initialHistoryState

    // meta reducer function
    return (state: AppState, action: HistoryStateActions) => {
        switch (action.type) {
            case HistoryActionTypes.ClearHistory:
                history.past = []
                history.future = []
                return reducer(state, action)

            case HistoryActionTypes.Undo:
                if (history.past.length === 0) {
                    // no undo if last there is no past
                    return reducer(state, action)
                } else {
                    const lastUndoableHistoryItem = history.past.filter((hi) => isUndoRedoPossible(hi.relatedAction))[0]
                    if (!lastUndoableHistoryItem) {
                        // no undo
                        return reducer(state, action)
                    }
                    const lastUndoableHistoryItemIndex = history.past.indexOf(lastUndoableHistoryItem)

                    // keep everything before history item to undo
                    const newPast = history.past.slice(lastUndoableHistoryItemIndex + 1)

                    if (newPast.length === 0) {
                        // TODO: check out why we need this
                        return reducer(state, action)
                    }

                    // begin with a new base state for the present
                    let newBaseState = reducer(newPast[0].currentState, newPast[0].relatedAction)

                    // apply all changes after the undo
                    for (let i = lastUndoableHistoryItemIndex - 1; i >= 0; i--) {
                        newPast.unshift({
                            currentState: newBaseState,
                            relatedAction: history.past[i].relatedAction,
                        })
                        newBaseState = reducer(newBaseState, history.past[i].relatedAction)
                    }

                    const presentHI = {
                        currentState: newBaseState,
                        relatedAction: null,
                    }

                    logger.info('UNDO: ', lastUndoableHistoryItem.relatedAction)
                    history = {
                        past: newPast,
                        present: presentHI,
                        future: [
                            {
                                currentState: null,
                                relatedAction: lastUndoableHistoryItem.relatedAction,
                            },
                            ...history.future,
                        ],
                    }

                    return reducer(presentHI.currentState, action)
                }

            case HistoryActionTypes.Redo:
                // No redo if future is empty
                if (history.future.length === 0) {
                    return reducer(state, action)
                } else if (isUndoRedoPossible(history.future[0].relatedAction)) {
                    const next = {
                        currentState: reducer(state, history.future[0].relatedAction),
                        relatedAction: null,
                    }
                    const newFuture = history.future.slice(1)
                    // find next possible action in future and
                    // apply action to current state
                    logger.info('REDO: ', history.future[0].relatedAction)
                    history = {
                        past: [
                            {
                                currentState: state,
                                relatedAction: history.future[0].relatedAction,
                            },
                            ...history.past,
                        ],
                        present: next,
                        future: newFuture,
                    }
                    return reducer(next.currentState, action)
                } else {
                    logger.error('FUCK SHIT - GAR NIX GUT')
                    return reducer(null, null)
                }

            default:
                const newPresent: HistoryItem = {
                    currentState: reducer(state, action),
                    relatedAction: null,
                }

                history.present.relatedAction = action

                // TODO: LIMIT History

                history = {
                    past: [history.present, ...history.past],
                    present: newPresent,
                    // clear future if new action is undoable
                    future: isUndoRedoPossible(action) ? [] : history.future,
                }
                return newPresent.currentState
        }
    }
}

function isUndoRedoPossible(action: Action): boolean {
    return (
        action instanceof AddNoteAction ||
        action instanceof AddNotesAction ||
        action instanceof DeleteNoteAction ||
        action instanceof DeleteNotesAction ||
        action instanceof UpdateNoteAction ||
        action instanceof UpdateNotesAction ||
        action instanceof UpsertNoteAction ||
        action instanceof UpsertNotesAction ||
        action instanceof ClearNotesAction ||
        action instanceof OctaveUpNotesAction ||
        action instanceof OctaveDownNotesAction ||
        // Sequencer
        action instanceof AddDrumSequencerNoteAction ||
        action instanceof AddDrumSequencerNotesAction ||
        action instanceof DeleteDrumSequencerNoteAction ||
        action instanceof DeleteDrumSequencerNotesAction ||
        action instanceof UpdateDrumSequencerNoteAction ||
        action instanceof UpdateDrumSequencerNotesAction ||
        action instanceof UpsertDrumSequencerNoteAction ||
        action instanceof UpsertDrumSequencerNotesAction ||
        action instanceof ClearDrumSequencerNotesAction ||
        // TODO: experimental
        action instanceof AddPatternAction ||
        action instanceof DeletePatternMappingAction ||
        action instanceof DeletePatternAction ||
        action instanceof SetPatternLengthIRNAction ||
        action instanceof SetLoopSelectorStartIRNAction ||
        action instanceof SetLoopSelectorEndIRNAction ||
        action instanceof HideLoopSelectorAction ||
        action instanceof UpdateTrackAction ||
        action instanceof AddTrackAction ||
        action instanceof DeleteTrackAction ||
        action instanceof CutPatternAction
    )
}
