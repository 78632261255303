import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Logger } from '@tekbox-coco/midiative-commons'
import { Subscription, interval } from 'rxjs'

@Component({
    selector: 'app-tap-tempo',
    templateUrl: './tap-tempo.component.html',
})
export class TapTempoComponent implements OnInit {
    private lastTap: number
    private autoReset: number = 3 //seconds
    private idleTime: number = 0
    private defaultText: string = 'Tap here to get BPM'
    private tapTimer: number
    private count: number = 0
    private idleInterval: Subscription

    private readonly logger = Logger.createLogger('tap-tempo.component')
    @Output()
    setBpm: EventEmitter<string> = new EventEmitter()
    bpm: string
    ngOnInit(): void {
        this.bpm = this.defaultText
    }

    // GetBpm
    getBpm() {
        this.tapTimer = Date.now()

        if (this.count === 0) {
            this.lastTap = this.tapTimer
            this.count = 1
            this.idleInterval = interval(1000)
                .subscribe({
                    next: (res) => this.idleIncrement(),
                    error: (error) => this.logger.error(error.messages)
                })

        } else if (this.count === 20) {
            this.lastTap = this.tapTimer
            this.count = 1
            this.idleTime = 0
        } else {
            this.bpm = ((60000 * this.count) / (this.tapTimer - this.lastTap)).toFixed(2)
            this.count++
            this.idleTime = 0
        }
    }

    idleIncrement() {
        this.idleTime++
        if (this.idleTime >= this.autoReset) {
            this.setBpm.emit(this.bpm)
            this.bpm = this.defaultText
            this.idleInterval.unsubscribe()
            this.count = 0
            this.idleTime = 0
        }
    }
}
