<div class="no-noscrollbar flex h-full overflow-scroll" #menu>
    <div class="flex h-[100vh] w-full flex-row flex-wrap place-content-center items-center justify-center">
        <div class="m-4 grid w-full grid-cols-3 gap-4">
            <div *ngFor="let item of renderedMenuItems">
                <app-action-button
                    [mode]="item.options && item.options.mode ? item.options.mode : 'default'"
                    [actionName]="item"
                    (onClick)="onClickActionButton($event)"
                    [innerClasses]="['h-full', 'flex', 'flex-col', 'items-center', 'justify-center']"
                    [labelClasses]="['pt-1']"
                    [iconClasses]="['mat-icon-40', 'text-white']"
                >
                </app-action-button>
            </div>
        </div>
    </div>
</div>
