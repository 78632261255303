import { ModuleWithProviders, NgModule } from '@angular/core'
import { createActionProvidersProviders } from './actions.provider'

// import actions
import './player/FollowCursorToggleAction'
import './player/TogglePlayPauseAction'
import './ui/ToggleSnapToGridAction'
import './player/GoToTrackStartAction'
import './player/GoToPreviousBeatAction'
import './player/GoToNextBeatAction'
import './player/GoToTrackEndAction'
import './ui/ZoomProjectOutAction'
import './ui/ZoomProjectInAction'
import './history/RedoAction'
import './history/UndoAction'
import './project/AddTrackToProjectAction'
import './ui/ZoomPianoRollOutAction'
import './ui/ZoomPianoRollInAction'
import './piano-roll/ResolutionSelectionAction'
import './hud/AdditionalActionsButtonAction'
import './project/ProjectToolSelectionButtonAction'
import './app/CloseEditorAction'
import './app/CloseOverlayAction'
import './piano-roll/PianoRollClearNotesAction'
import './piano-roll/PianoRollOctaveDownAction'
import './navigate/NavigateToSettingsAction'
import './navigate/NavigateToHomeAction'
import './import/OpenProjectFileAction'
import './import/OpenMidiFileAction'
import './piano-roll/PianoRollSaveChangesAction'
import './piano-roll/PianoRollCopyNotesAction'
import './piano-roll/PianoRollPasteNotesAction'
import './navigate/NavigateToProjectSettingsAction'
import './project/ProjectSaveChangesAction'
import './project/ProjectDeleteAction'
import './project/DownloadProjectAction'
import './project/ProjectShareAction'
import './project/DownloadMIDIAction'
import './project/ProjectShareMidiAction'
import './project/ProjectCloseAction'
import './project/DownloadAction'
import './project/OpenFileAction'
import './project/ShareAction'
import './piano-roll/PianoRollToolSelectionButtonAction'
import './app/CloseAppAction'
import './app/SpacerAction'
import './project/track-pattern/DeleteTrackPatternAction'
import './project/track-pattern/TrackPatternStartCopyAction'
import './project/track-pattern/TrackPatternStartCloneAction '
import './project/track-pattern/TrackPatternDeselectAction '
import './project/track-pattern/SelectPatternColorAction'
import './project/track-actions/DeleteTrackAction'
import './project/track-actions/SoloTrackAction'
import './project/track-actions/MuteTrackAction'
import './navigate/NavigateToNewProjectAction'
import './navigate/NavigateToLoadProjectAction'
import './navigate/NavigateToLicensesAction'
import './navigate/NavigateBackAction'
import './piano-roll/PianoRollOctaveUpAction'
import './piano-roll/PianoRollSetChordAction'
import './piano-roll/PianoRollSetMagicChordAction'
import './piano-roll/PianoRollToggleScrollAction'
import './piano-roll/PianoRollAddBarAction'
import './piano-roll/PianoRollRemoveBarAction'
import './piano-roll/PianoRollDisplayInversionLevelAction'
import './piano-roll/PianoRollIncreaseInversionLevelAction'
import './piano-roll/PianoRollDecreaseInversionLevelAction'
import './piano-roll/PianoRollDeselectAllNotesAction'
import './mixer/OpenMixerAction'
import './mixer/CloseMixerAction'
import './shortcut-overlay/OpenShortcutOverlay'
import './testing/TestAction'

@NgModule({})
export class ActionsModule {
    public static forRoot(): ModuleWithProviders<ActionsModule> {
        const actionControllerProviders = createActionProvidersProviders()
        return {
            ngModule: ActionsModule,
            providers: [...actionControllerProviders],
        }
    }
}
