import { MidiFileBuffer } from './MidiFileBuffer'
import { MetaEventTypes } from '../models/enums/MetaEventTypes'
import { EventType } from '../models/enums/EventType'
import { Logger } from '../../logger/Logger'

const logger = Logger.createLogger('MidiParser')

export interface MidiEventX {
    type: number
    name: string
    deltaTime: number
    data: Record<string, any>
}

export interface MidiFileChunk {
    type: string
    length: number
    raw?: ArrayBuffer
}

export interface MidiFileHeader extends MidiFileChunk {
    format: number
    numOfTracks: number
    timeDiv: number
}

export interface MidiFileTrack extends MidiFileChunk {
    events: MidiEventX[]
}

export class DefaultMidiChunk implements MidiFileChunk {
    constructor(public type: string, public length: number, public raw: ArrayBuffer) {}
}

export class MidiFile {
    constructor(public header: MidiFileHeader, public tracks: MidiFileTrack[]) {}
}

export class MidiParser {
    parse(buffer: ArrayBuffer): MidiFile {
        const midiBuffer = new MidiFileBuffer(buffer)
        const header = this.parseHeader(midiBuffer)
        const tracks: MidiFileTrack[] = []

        for (let i = 0; i < header.numOfTracks; i++) {
            tracks.push(this.readChunk(midiBuffer))
        }

        return new MidiFile(header, tracks)
    }

    readChunk(midiBuffer: MidiFileBuffer): MidiFileTrack {
        const startPos = midiBuffer.seek(new TextEncoder().encode('MTrk'))
        // move cursor to start pos
        logger.debug(
            '=================================================================================================',
            midiBuffer
        )
        midiBuffer.readBytes(startPos)
        // read for bytes to move the cursor

        midiBuffer.readString(4)
        const length = midiBuffer.readUint32()
        const data = midiBuffer.readBytes(length)
        logger.debug('chunk starts at', startPos, 'length', length, midiBuffer.eof, data)
        return this.parseTrack(data)
    }

    parseHeader(midiBuffer: MidiFileBuffer): MidiFileHeader {
        const startPos = midiBuffer.seek(new TextEncoder().encode('MThd'))
        // move cursor to start pos
        midiBuffer.readBytes(startPos)
        logger.debug('------------------------------------------')
        logger.debug('header starts at', startPos, midiBuffer)

        // read for bytes to move the cursor
        midiBuffer.readString(4)
        const length = midiBuffer.readUint32()
        const format = midiBuffer.readInt16()
        const numOfTracks = midiBuffer.readInt16()
        const timeDiv = midiBuffer.readInt16()

        return {
            type: 'MThd',
            format,
            numOfTracks,
            timeDiv,
            length,
        }
    }

    private parseTrack(arrayBuffer: ArrayBuffer): MidiFileTrack {
        logger.debug('> parseTrack:', arrayBuffer)
        const unknownEvents = []
        const buffer = new MidiFileBuffer(arrayBuffer)
        const events = []

        while (!buffer.eof) {
            try {
                const deltaTime = buffer.readVarLength()
                const eventType = buffer.readUint8()

                if (eventType >= 128 && eventType <= 239) {
                    events.push(this.parseChannelEvent(deltaTime, eventType, buffer))
                } else if (eventType === EventType.MetaEvent) {
                    events.push(this.parseMetaEvent(deltaTime, buffer))
                } else if (eventType === EventType.SystemExclusiveEvents || eventType == EventType.EscapeSequences) {
                    events.push(this.parseSysExEvents(deltaTime, eventType, buffer))
                } else {
                    events.push(this.parseMidiControllerMessage(deltaTime, eventType, buffer))
                }
            } catch (e) {
                logger.debug('e', e)
                throw e
            }
        }
        logger.debug('unknownEvents', [...new Set(unknownEvents)])

        return {
            type: 'MThd',
            length,
            events: events,
        }
    }

    private getEnumValue(key: number, enu: object) {
        const keys = Object.keys(enu)
        const entries = keys
            .filter((k, i) => i + 1 > keys.length / 2)
            .map((key: any) => {
                // logger.debug('map', key)
                return {
                    label: key,
                    value: enu[key],
                }
            })
            .reduce((a: any, b: any) => {
                // logger.debug('set', a, b)
                return a.set(b.value, b.label)
                // debugger
            }, new Map<number, string>())
        // debugger
        return entries.get(key)
    }

    private getEventName(key: number) {
        return this.getEnumValue(key, EventType)
    }

    private parseMidiControllerMessage(deltaTime: number, eventType: number, buffer: MidiFileBuffer): MidiEventX {
        const name = this.getEventName(eventType)
        let data = buffer.readUint8()
        logger.debug('parseMidiControllerMessage', {
            eventType,
            name: name,
            rawValue: eventType,
            value: data,
        })
        if (!name) {
            logger.debug(EventType.DataEntry)
            // debugger

            throw Error(`${eventType} ${eventType.toString(16)} not found`)
        }
        return {
            type: eventType,
            name: this.getEventName(eventType),
            deltaTime,
            data: { value: data },
        }
    }

    private parseChannelEvent(deltaTime: number, eventType: number, buffer: MidiFileBuffer): MidiEventX {
        const [type, channel] = eventType
            .toString(16)
            .split('')
            .map((e) => parseInt(e, 16))
        let data = {}
        logger.debug('[ChannelEvent]', { deltaTime, channel })
        if (type === EventType.NoteOn) {
            const note = buffer.readUint8()
            const velocity = buffer.readUint8()
            logger.debug('> NoteOn', { note, velocity })
            data = { note, velocity }
        } else if (type === EventType.NoteOff) {
            const note = buffer.readUint8()
            const velocity = buffer.readUint8()
            logger.debug('> NoteOff', { note, velocity })
            data = { note, velocity }
        } else if (type === EventType.Controller) {
            const controller = buffer.readUint8()
            const value = buffer.readUint8()
            logger.debug('> Controller', { controller, value })
            data = { controller, value }
        } else if (type === EventType.ChannelAftertouch) {
            const pressure = buffer.readUint8()
            logger.debug('> ChannelAftertouch', { pressure })
            data = { pressure }
        } else if (type === EventType.ProgramChange) {
            const program = buffer.readUint8()
            logger.debug('> ProgramChange', { program })
            data = { program }
        } else if (type === EventType.PitchBend) {
            const lsb = buffer.readUint8()
            const msb = buffer.readUint8()
            logger.debug('> PitchBend', { lsb, msb })
            data = { lsb, msb }
        } else {
            logger.debug('> Event not handled', type)
            debugger
            throw new Error(`> Event not handled${type} ${type.toString(16)}`)
        }
        return {
            type,
            deltaTime,
            name: this.getEventName(type),
            data,
        }
    }

    private parseSysExEvents(deltaTime: number, eventType: number, buffer): MidiEventX {
        const len = buffer.readVarLength()
        const data = buffer.readBytes(len)

        logger.debug('sysExEvent', { eventType, len, data })
        return {
            type: eventType,
            name: 'sys' + this.getEventName(eventType),
            deltaTime,
            data: {
                value: data,
            },
        }
    }

    private parseMetaEvent(deltaTime: number, buffer: MidiFileBuffer): MidiEventX {
        const type = buffer.readUint8()
        let data = {}
        logger.debug('[MetaEvent]')
        if (type === MetaEventTypes.TimeSignature) {
            // skip second time signature key
            buffer.readUint8()

            const numerator = buffer.readUint8()
            const denominator = buffer.readUint8()
            const clocks = buffer.readUint8()
            const notes = buffer.readUint8()
            logger.debug('> TimeSignature', {
                numerator,
                denominator,
                clocks,
                notes,
            })
            data = { numerator, denominator, clocks, notes }
        } else if (type === MetaEventTypes.SetTempo) {
            // skip second tempo key
            buffer.readUint8()

            const tempo = buffer.readUint24()
            logger.debug('> SetTempo', tempo)
            data = { tempo }
        } else if (type === MetaEventTypes.EndOfTrack) {
            data = buffer.readUint8()
            logger.debug('> EndOfTrack', data)
        } else if (type === MetaEventTypes.TrackName) {
            const length = buffer.readVarLength()
            const dataRaw = buffer.readString(length)
            logger.debug('> TrackName', data)
            data = { name: dataRaw }
        }
        // else if(type ===MetaEventTypes.SequenceNumber) {}
        // else if(type ===MetaEventTypes.TextEvent) {}
        // else if(type ===MetaEventTypes.CopyrightNotice) {}
        else if (type === MetaEventTypes.MIDIChannelPrefix) {
            // skip second key
            buffer.readUint8()
            const prefix = buffer.readUint8()
            logger.debug('> MIDIChannelPrefix', prefix)
        } else if (type === MetaEventTypes.MIDIPort) {
            // skip second key
            buffer.readUint8()
            const port = buffer.readUint8()
            logger.debug('> MIDIPort', port)
        } else if (type === MetaEventTypes.KeySignature) {
            // skip second key
            buffer.readUint8()
            const sf = buffer.readUint8()
            const mi = buffer.readUint8()
            logger.debug('> KeySignature', { sf, mi })
        } else if (type === MetaEventTypes.SequencerSpecific) {
            const length = buffer.readVarLength()
            const marker = buffer.readString(length)
            logger.debug('> SequencerSpecific', data)
        } else if (type === MetaEventTypes.Marker) {
            const length = buffer.readVarLength()
            const marker = buffer.readString(length)
            logger.debug('> Marker', data)
            data = { marker }
        } else {
            logger.debug('> MetaEvent not handled', type)
            debugger
            throw new Error(`> MetaEvent not handled ${type} ${type.toString(16)}`)
        }

        return {
            type,
            name: this.getEnumValue(type, MetaEventTypes),
            deltaTime,
            data,
        }
    }
}
