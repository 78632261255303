<div class="tutorial-wrapper" (click)="onClickOutside($event)" *ngIf="isOpen">
    <div class="absolute z-[5000] m-10" [ngStyle]="calculateButtonStyle()">
        <app-button
            id="skip-button"
            label="TUTORIAL.GENERAL.SKIP_TUTORIALS"
            [innerClasses]="['bg-[white]', 'text-[black]', 'rounded-lg']"
            (onClick)="skipTutorial()"
        ></app-button>
    </div>
    <div class="tutorial" (click)="onClickInside($event)">
        <ng-container *ngFor="let step of tutorialSteps; let i = index">
            <div *ngIf="stepCounter === i" class="tutorial__content-default">
                <div class="tutorial__overlay" [ngStyle]="calculateOverlayStyle(step)"></div>
                <div
                    [id]="'tutorial-overlay-' + i"
                    class="tutorial__text-area select-none"
                    #overlayElement
                    [ngStyle]="calculateTextAreaStyle(step)"
                >
                    {{ step.text | translate }}
                </div>
            </div>
        </ng-container>
    </div>
</div>
