import { BackgroundCss, CanvasBackgroundGenerator } from './BackgroundLayerGenerator'
import { CellConfig, GridConfig, GridGenerator } from './GridGenerator'

export class TrackBackgroundGenerator implements CanvasBackgroundGenerator {
    private readonly barWidth: number
    private readonly noCells: number

    constructor(barWidth: number, noCells: number) {
        this.barWidth = barWidth
        this.noCells = noCells
    }

    async build(): Promise<HTMLCanvasElement> {
        const config: GridConfig = {
            cellWidth: this.barWidth / this.noCells,
            cellHeight: 150,
            opacity: 0.1,
            cellConfigFactory: (x: number, y: number, gridConfig: GridConfig) => {
                const cellConfig: CellConfig = {
                    backgroundColor: '#3f3d3d',
                    opacity: 0.5,
                    borderLeft: {
                        thickness: 1,
                        color: '#fff',
                    },
                    borderRight: {
                        thickness: 1,
                        color: '#fff',
                    },
                }
                return cellConfig
            },
        }

        const gridGenerator = new GridGenerator(config)
        const gridBackground = await gridGenerator.generateCanvas(this.noCells, 1)
        return gridBackground
    }
    /**
     * Generates background css containing images as data url
     * @returns background css
     */
    async generateBackground(): Promise<BackgroundCss> {
        const bg = await this.build()
        return {
            backgroundImage: `url(${bg.toDataURL()})`,
            backgroundRepeat: 'repeat-x repeat-y',
            backgroundBlendMode: 'normal',
        }
    }
}
