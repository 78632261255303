import { EventType } from '../enums/EventType'

export class MidiEvent {
    deltaTime: number
    eventType: EventType

    constructor(deltaTime: number, eventType: EventType) {
        this.deltaTime = deltaTime
        this.eventType = eventType
    }

    toHex() {
        return 'shit-happened'
    }
}
