import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Logger } from '../logger/Logger'
import { AppState } from '../models/app-state.model'
import { ProjectStateModel, Track } from '../models/project-state.model'

@Injectable({
    providedIn: 'root',
})
export class ProjectInfoService {
    private readonly logger = Logger.createLogger('ProjectInfoService')
    private projectState: ProjectStateModel

    constructor(private store: Store<AppState>) {
        this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectState = value
            },
            error: (e) => this.logger.error(e),
        })
    }

    /**
     * resolve track index from channel number
     * @param channel channel to resolve
     * @returns index of track in project
     */
    public getTrackIndexFromChannel(channel: number): number {
        if (this.projectState) {
            return Object.values(this.projectState.tracks.entities)
                .map((e) => e.channel)
                .indexOf(channel)
        }
        return -1
    }

    /**
     * Resolve track from index
     * @param index track index to resolve
     * @returns track
     */
    public getTrackForIndex(index: number): Track {
        if (this.projectState) {
            return Object.values(this.projectState.tracks.entities)[index]
        }
        return undefined
    }
}
