import { NgModule } from '@angular/core'
import { MenuComponent } from './menu.component'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { TileMenuModule } from '../tile-menu/tile-menu.module'
import { ButtonModule } from '../button/button.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, TileMenuModule, ButtonModule, TranslateModule],
    declarations: [MenuComponent],
    exports: [MenuComponent],
})
export class MenuModule {}
