<div
    *ngIf="mixerService.isOpen"
    class="noscrollbar absolute bottom-0 left-0 right-0 top-0 flex flex-col overflow-scroll bg-black-95 text-white"
>
    <app-header class="fixed z-header w-full" [heading]="'COMMON.MIXER'" [leftAction]="'CloseMixerAction'"></app-header>
    <div class="mt-[40px]">
        <div
            *ngFor="let track of mixerService.tracks"
            class="m-4 flex select-none flex-row place-content-between content-start rounded-lg border border-black-100 bg-black-80 p-4"
        >
            <div class="w-full">
                <div>
                    <h2>{{ 'COMMON.CHANNEL' | translate }}: {{ track.channel }}</h2>
                    <h2 class="flex flex-row items-center">
                        {{ 'COMMON.INSTRUMENT' | translate }}:
                        <ion-select
                            [value]="track.instrument"
                            interface="action-sheet"
                            (ionChange)="changedInstrument($event.detail.value)"
                        >
                            <ion-select-option *ngFor="let i of instruments" [value]="i">{{ i }}</ion-select-option>
                        </ion-select>
                    </h2>
                </div>
                <ion-range
                    minlength="0"
                    max="127"
                    step="1"
                    [value]="track.volume"
                    (ionKnobMoveEnd)="changedVolume(track.id, $event.detail.value)"
                >
                </ion-range>
            </div>
            <div class="flex flex-col place-content-center gap-4">
                <div
                    class="flex h-[50px] w-[50px] cursor-pointer place-content-evenly items-center rounded-2xl border text-center"
                    [ngClass]="{
                        'bg-mdv-active': track.mute,
                        'bg-mdv-inactive': !track.mute
                    }"
                    (click)="clickedMute(track.id)"
                >
                    M
                </div>
                <div
                    class="flex h-[50px] w-[50px] cursor-pointer place-content-evenly items-center rounded-2xl border text-center"
                    [ngClass]="{
                        'bg-mdv-active-secondary': track.solo,
                        'bg-mdv-inactive': !track.solo
                    }"
                    (click)="clickedSolo(track.id)"
                >
                    S
                </div>
            </div>
        </div>
    </div>
</div>
