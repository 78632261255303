import { MetaEvent } from '../MetaEvent'
import { MetaEventTypes } from '../../enums/MetaEventTypes'
import { Hexecutioner } from '../../../utils/Hexecutioner'

export class SetTempo extends MetaEvent {
    constructor(deltaTime: number, protected num: number) {
        super(deltaTime)
        this.metaType = MetaEventTypes.SetTempo
        const render = (x) => Hexecutioner.numberToHex(x, 6)
        this.data = `${render(this.num)}`
    }

    toString(): string {
        return `[MetaEvent] SetTempo tempo: ${this.num}`
    }
}
