import { Injectable } from '@angular/core'
import {
    DefaultAction,
    PianoRollStateModel,
    Logger,
    UiStateModel,
    DisplayNote,
    MAX_INVERSION_LEVEL,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'
import { Store } from '@ngrx/store'
import { PianoRollChordToolSetInversionLevelAction, SetPrClipboardAction } from '../../store/actions/ui-state.actions'
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollDecreaseInversionLevelAction',
})
export class PianoRollDecreaseInversionLevelAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollDecreaseInversionLevelAction')
    private uiState: UiStateModel

    constructor(private store: Store<AppState>) {
        super()

        this.icon.next('remove')

        this.subscriptions.push(
            this.store.select('uiState').subscribe({
                next: async (value) => {
                    this.uiState = value
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    onClick() {
        if (this.uiState.prChordToolInversionLevel > -MAX_INVERSION_LEVEL) {
            this.store.dispatch(
                new PianoRollChordToolSetInversionLevelAction(this.uiState.prChordToolInversionLevel - 1)
            )
        }
    }
}
