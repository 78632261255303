import { FileManger } from '../file-manager.service'
import { FileInfo, StatResult } from '@capacitor/filesystem/dist/esm/definitions'

export interface FileManagerResult {
    data?: any
    error?: any
    successful: boolean
}

export interface FileManagerOpenFileResult {
    data?: ArrayBuffer
    fileName?: string
    canceled: boolean
}

export interface PlatformFileManager extends FileManger {
    platform: string
}

export abstract class AbstractPlatformFileManager implements PlatformFileManager {
    platform: string

    // save file to app data
    abstract saveFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>

    // save file to downloads directory
    abstract exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>
    abstract cacheFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>

    abstract readFile(path: string): Promise<string>

    abstract openFile(): Promise<FileManagerOpenFileResult>

    abstract exists(path: string): Promise<boolean>

    abstract mkdir(path: string): Promise<boolean>

    abstract stat(path: string): Promise<StatResult>

    abstract rmdir(path: string): Promise<boolean>
    abstract listDir(path: string): Promise<FileInfo[]>
}
