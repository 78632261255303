/**
 * Returns the closest power of 2 to the given value.
 * The value will be less or equal the given number.
 * Returns 1 for negative values.
 * @param n
 */
export function closestPowerOfTwo(n: number): number {
    if (n < 0) {
        return 1
    }
    return 1 << (31 - Math.clz32(n))
}

/**
 * Returns a boolean if a given value is a power of 2.
 * Returns false for negative values.
 * @param n
 */
export function isPowerOfTwo(n: number): boolean {
    if (n < 0) {
        return false
    }
    return Math.log2(n) % 1 === 0
}
