import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'
import { Injectable } from '@angular/core'
import { AppState } from '@capacitor/app'
import { Store } from '@ngrx/store'
import { ResetPrSelectedNotesAction } from '../../store/actions/ui-state.actions'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollDeselectAllNotesAction',
})
export class PianoRollDeselectAllNotesAction extends DefaultAction {
    constructor(private store: Store<AppState>) {
        super()
        this.icon.next('deselect')
        this.label.next('PIANO_ROLL.COMMON.DESELECT_ALL_NOTES')
    }

    onClick() {
        this.store.dispatch(new ResetPrSelectedNotesAction())
    }
}
