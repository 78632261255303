import {
    canvasNotesAdapter,
    GlobalPatternMappingDescription,
    initialPianoRollState,
    MAX_GRID_RESOLUTION,
    Note,
    Pattern,
    PianoRollStateModel,
    Track,
    TrackNote,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { PianoRollStateActions, PianoRollStateActionTypes } from '../actions/piano-roll-state.actions'
import { createSelector } from '@ngrx/store'
import { listPatternMappings, selectPatterns, selectTracks } from './project-state.reducer'

export function pianoRollStateReducer(
    state: PianoRollStateModel = initialPianoRollState,
    action: PianoRollStateActions
) {
    switch (action.type) {
        // General
        case PianoRollStateActionTypes.LoadPattern: {
            return {
                ...state,
                patternId: action.payload.id,
                resolution: action.payload.lastResolution,
                patternLengthIRN: action.payload.lengthIRN,
                // convert to display note
                notes: canvasNotesAdapter.setAll(
                    action.payload.notes.map((e) => ({
                        ...e,
                        selected: false,
                    })),
                    state.notes
                ),
            }
        }
        // Background
        case PianoRollStateActionTypes.SetRows: {
            return { ...state, rows: action.payload }
        }
        case PianoRollStateActionTypes.SetInstrument: {
            return { ...state, instrument: action.payload }
        }
        case PianoRollStateActionTypes.SetChannel: {
            return { ...state, channel: action.payload }
        }
        case PianoRollStateActionTypes.SetName: {
            return { ...state, name: action.payload }
        }
        case PianoRollStateActionTypes.SetPatternId: {
            return { ...state, patternId: action.payload }
        }
        case PianoRollStateActionTypes.SetPatternLengthIRN: {
            return {
                ...state,
                // minimal length = 1 bar = MAX_GRID_RESOLUTION
                patternLengthIRN: action.payload >= MAX_GRID_RESOLUTION ? action.payload : MAX_GRID_RESOLUTION,
            }
        }
        case PianoRollStateActionTypes.SetResolution: {
            return { ...state, resolution: action.payload }
        }

        // Notes
        case PianoRollStateActionTypes.SetNotes: {
            return {
                ...state,
                notes: canvasNotesAdapter.setAll(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.ClearNotes:
            return {
                ...state,
                notes: canvasNotesAdapter.removeAll(state.notes),
            }
        case PianoRollStateActionTypes.AddNote: {
            return {
                ...state,
                notes: canvasNotesAdapter.addOne(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.AddNotes: {
            return {
                ...state,
                notes: canvasNotesAdapter.addMany(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.UpsertNote: {
            return {
                ...state,
                notes: canvasNotesAdapter.upsertOne(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.UpsertNotes: {
            return {
                ...state,
                notes: canvasNotesAdapter.upsertMany(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.UpdateNote: {
            return {
                ...state,
                notes: canvasNotesAdapter.updateOne(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.UpdateNotes: {
            return {
                ...state,
                notes: canvasNotesAdapter.updateMany(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.DeleteNote: {
            return {
                ...state,
                notes: canvasNotesAdapter.removeOne(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.DeleteNotes: {
            return {
                ...state,
                notes: canvasNotesAdapter.removeMany(action.payload, state.notes),
            }
        }
        case PianoRollStateActionTypes.OctaveUpNotes: {
            const updates = Object.values(state.notes.ids).map((id) => {
                const oldKey = Object.values(state.notes.entities).filter((e) => e.id === id)[0].key
                const newKey = oldKey + 12
                return {
                    id,
                    changes: { key: newKey > 118 ? oldKey : newKey },
                }
            })
            return {
                ...state,
                notes: canvasNotesAdapter.updateMany(updates, state.notes),
            }
        }
        case PianoRollStateActionTypes.OctaveDownNotes: {
            const updates = Object.values(state.notes.ids).map((id) => {
                const oldKey = Object.values(state.notes.entities).filter((e) => e.id === id)[0].key
                const newKey = oldKey - 12
                return {
                    id,
                    changes: { key: newKey < 12 ? oldKey : newKey },
                }
            })
            return {
                ...state,
                notes: canvasNotesAdapter.updateMany(updates, state.notes),
            }
        }
        default: {
            return state
        }
    }
}

const selectNotes = (state: AppState) => Object.values(state.pianoRollState.notes.entities)
export const selectNotes2 = (state: AppState) => state.pianoRollState.notes
export const selectPianoRollChannel = (state: AppState) => state.pianoRollState.channel
export const selectPianoRollTrackNotes = createSelector(
    selectNotes,
    selectPianoRollChannel,
    (notes: Note[], channel: number): Record<number, TrackNote[]> => {
        return notes
            .map((note) => {
                return {
                    ...note,
                    channel,
                }
            })
            .reduce((a, b) => {
                if (!a[b.startIRN]) {
                    a[b.startIRN] = []
                }
                a[b.startIRN].push(b)
                return a
            }, {})
    }
)

export const { selectAll: selectAllPRNotes } = canvasNotesAdapter.getSelectors(selectNotes2)

export const selectPatternLengthIRN = (state: AppState) => state.pianoRollState.patternLengthIRN
export const selectResolution = (state: AppState) => state.pianoRollState.resolution

/**
 * Select notes from other patterns to show in background of piano roll
 */
export const prPatternMappingId = (state: AppState) => state.uiState.prPatternMappingId

export const notesInRangeSelector = createSelector(
    selectTracks,
    selectPatterns,
    prPatternMappingId,
    listPatternMappings,
    (tracks: Track[], patterns: Record<string, Pattern>, prPatternMappingId: string, listPatternMappings): any => {
        if (prPatternMappingId === '') {
            return []
        }

        const mapping: GlobalPatternMappingDescription = Object.values(listPatternMappings)
            .map((e) => e.mappings)
            .reduce((a, b) => a.concat(b), [])
            .reduce(
                (a, b) => {
                    if (a.mappingId === prPatternMappingId) {
                        return a
                    }
                    return b
                    //
                },
                { mappingId: '', trackId: '', start: 0, length: 0 }
            )

        return (
            tracks
                // resolve pattern notes
                .map((track: Track) => {
                    return {
                        ...track,
                        patterns: Object.values(track.patterns).map((patternMapping) => {
                            if (
                                patternMapping &&
                                patterns[patternMapping.patternId] &&
                                patternMapping.id !== mapping.mappingId
                            ) {
                                return {
                                    ...patternMapping,
                                    // add notes to mapping object
                                    notes: patterns[patternMapping.patternId].notes
                                        // map notes to correct startIRN
                                        .map((note) => {
                                            return {
                                                ...note,
                                                startIRN: note.startIRN + patternMapping.position,
                                                channel: track.channel,
                                                velocity: track.volume !== undefined ? track.volume : 255,
                                            }
                                        }) as TrackNote[],
                                }
                            } else {
                                return {
                                    ...patternMapping,
                                    // add notes to mapping object
                                    notes: [] as TrackNote[],
                                }
                            }
                        }),
                    }
                })
                // map tracks to notes
                .map((track) => {
                    return track.patterns
                        .reduce((a, b) => {
                            return a.concat(b.notes)
                        }, [])
                        .map((e) => {
                            return {
                                ...e,
                                trackId: track.id,
                                // TODO: default color
                                trackColor: track.color || 'red',
                            }
                        })
                })
                // reduce tracks to big notes array
                .reduce((a, b) => a.concat(b), [])
                // sort array
                .sort((a: Note, b: Note) => a.startIRN - b.startIRN)

                .filter(
                    (note) =>
                        (note.startIRN >= mapping.start &&
                            note.startIRN >= mapping.start &&
                            note.startIRN <= mapping.start + mapping.length) ||
                        note.startIRN + note.lengthIRN <= mapping.start + mapping.length
                )
                .map((note) => {
                    // remove offset from note
                    if (note.startIRN + note.lengthIRN > mapping.start + mapping.length) {
                        note.lengthIRN -= note.startIRN + note.lengthIRN - (mapping.start + mapping.length)
                    }

                    note.startIRN -= mapping.start
                    return note
                })
        )
    }
)
