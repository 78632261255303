import { ChordType } from '../music-theory/definitions'
import { DisplayNote } from './piano-roll-state.model'

export const DEFAULT_MAX_HEADER_WIDTH = 150

export enum PrToolEnum {
    NONE = 'NONE',
    PENCIL = 'PENCIL',
    CURSOR = 'CURSOR',
    CHORD = 'CHORD',
    AUTO_CHORD = 'AUTO_CHORD',
    DRAW = 'DRAW',
    LASSO = 'LASSO',
    CUT = 'CUT',
}

export enum ProjectToolEnum {
    PENCIL = 'PENCIL',
    CURSOR = 'CURSOR',
    CUT = 'CUT',
}

export interface UiStateModel {
    // Global Player
    followCursor: boolean
    snapToGrid: boolean

    // PianoRoll
    prDrawerVisible: boolean
    prDrawerOpen: boolean
    prToolbarVisible: boolean
    prToolSelectionOpen: boolean

    prMenuLoadPatternVisible: boolean
    prModalSavePatternVisible: boolean
    prModalUnsavedPatternVisible: boolean
    prScrollEnabled: boolean

    prBarRenderWidth: number // The width of a bar in the project in pixel
    prCellRenderHeight: number

    prSelectedTool: PrToolEnum
    prSelectedChordType: ChordType

    prSelectedNotes: string[]
    prClipboard: DisplayNote[]

    prNoteDeltaX: number
    prNoteDeltaY: number
    prNoteDeltaResizeX: number

    // refer to pattern mapping if pr is opened from mapping. This can be empty!
    prPatternMappingId: string

    // inversion level for chord tool
    prChordToolInversionLevel: number

    // Project
    copyPatternId: string
    clonePatternId: string
    projectDrawerVisible: boolean
    projectDrawerOpen: boolean
    projectToolbarVisible: boolean
    projectToolSelectionOpen: boolean
    projectSelectedPatternMapping: string

    projectBarRenderWidth: number // The width of a bar in the project in pixel

    projectMenuSelectPatternOpen: boolean
    projectModalUnsavedProjectVisible: boolean

    // Home
    homeModalDeleteProjectVisible: boolean

    projectSelectedTool: ProjectToolEnum
}

export const initialUIState: UiStateModel = {
    // Global Player
    followCursor: true,
    // PianoRoll
    prDrawerVisible: false,
    prDrawerOpen: false,
    prToolbarVisible: false,
    prToolSelectionOpen: false,
    prScrollEnabled: true,

    prMenuLoadPatternVisible: false,
    prModalSavePatternVisible: false,
    prModalUnsavedPatternVisible: false,

    prBarRenderWidth: 200,
    prCellRenderHeight: 30,
    prSelectedTool: PrToolEnum.PENCIL,
    prSelectedChordType: ChordType.MAJOR,
    prSelectedNotes: [],
    prClipboard: [],
    prPatternMappingId: '',

    prNoteDeltaX: 0,
    prNoteDeltaY: 0,
    prNoteDeltaResizeX: 0,

    prChordToolInversionLevel: 0,

    // Project
    copyPatternId: '',
    clonePatternId: '',
    projectDrawerVisible: false,
    projectDrawerOpen: false,
    projectToolbarVisible: false,
    projectToolSelectionOpen: false,

    projectBarRenderWidth: 100,

    projectMenuSelectPatternOpen: false,
    projectModalUnsavedProjectVisible: false,

    projectSelectedPatternMapping: '',

    // Home
    homeModalDeleteProjectVisible: false,

    snapToGrid: true,

    projectSelectedTool: ProjectToolEnum.PENCIL,
}
