import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    ActionTask,
    DefaultAction,
    UiStateModel,
    initialUIState,
    Logger,
    GlobalPatternMapping,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { listPatternMappings } from '../../../store/reducers/project-state.reducer'
import { ToastService } from '../../../services/toast.service'
import { DeletePatternAction, DeletePatternMappingAction } from '../../../store/actions/project-state.actions'
import { TrackPatternAction } from './TrackPatternAction'
import { StartClonePatternAction, StartCopyPatternAction } from '../../../store/actions/ui-state.actions'
import { ActionController } from '../../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'TrackPatternStartCloneAction',
})
export class TrackPatternStartCloneAction extends TrackPatternAction {
    constructor(store: Store<AppState>, private toast: ToastService) {
        super(store)

        this.icon.next('content_copy')
        this.label.next('Copy')
    }

    protected onUiStateUpdate(state: UiStateModel) {
        if (state.clonePatternId === '') {
            this.icon.next('content_copy')
        } else {
            this.icon.next('cancel')
        }
    }

    async onClick() {
        const copyInProgress = this.uiState.clonePatternId != ''

        if (copyInProgress) {
            this.store.dispatch(new StartClonePatternAction(''))
        } else {
            const mappingId = this.uiState.projectSelectedPatternMapping
            if (mappingId === '') {
                this.toast.warn('No pattern selected!')
                return
            }
            const patternId = this.getPatterIdForMapping(mappingId)
            if (!patternId) {
                this.toast.warn('Pattern mapping could not resolved')
                return
            }
            this.store.dispatch(new StartClonePatternAction(patternId))
        }
    }
}
