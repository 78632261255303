import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import {
    ROUTE_DEBUG,
    ROUTE_HOME,
    ROUTE_LOAD_PROJECT,
    ROUTE_NEW_PROJECT,
    ROUTE_PROJECT,
    ROUTE_SETTINGS,
    ROUTE_LICENSES,
} from '@tekbox-coco/midiative-commons'

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: ROUTE_HOME,
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: ROUTE_SETTINGS,
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    },
    {
        path: ROUTE_NEW_PROJECT,
        loadChildren: () => import('./pages/new-project/new-project.module').then((m) => m.NewProjectPageModule),
    },
    {
        path: ROUTE_PROJECT,
        loadChildren: () => import('./pages/project/project.module').then((m) => m.ProjectPageModule),
    },
    {
        path: ROUTE_LOAD_PROJECT,
        loadChildren: () => import('./pages/load-project/load-project.module').then((m) => m.LoadProjectPageModule),
    },
    {
        path: ROUTE_LICENSES,
        loadChildren: () => import('./pages/license/license.module').then((m) => m.LicensePageModule),
    },
    {
        path: ROUTE_DEBUG,
        loadChildren: () => import('./pages/debug/debug.module').then((m) => m.DebugPageModule),
    },
    { path: '**', redirectTo: '/' },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
