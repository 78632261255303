import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import {
    ActionTask,
    createRouterLink,
    Logger,
    ROUTE_LOAD_PROJECT,
    ROUTE_NEW_PROJECT,
} from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToLoadProjectAction',
})
export class NavigateToLoadProjectAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToLoadProjectAction')

    constructor(private router: Router) {
        super()
        this.label.next('HOME.LOAD_PROJECT')
        this.icon.next('folder_open')
        this.domID.next('load-project-action')
    }

    onClick() {
        this.navigate(ROUTE_LOAD_PROJECT)
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
