export class Hexecutioner {
    static numberToHex(num: number, noDigits?: number) {
        if (!noDigits) {
            return num.toString(16)
        } else {
            return '0'.repeat(noDigits - num.toString(16).length) + num.toString(16)
        }
    }

    static numberToVariableMidiHex(num: number, noDigits: number) {
        const RADIX = 7
        const MASK = 2 ** RADIX - 1

        const octetify = (n: number) => {
            if (n >= 2147483648) {
                throw new Error('Variable Length Quantity not supported for numbers >= 2147483648')
            }

            const octets: any = []
            for (let i = n; i != 0; i >>>= RADIX) {
                octets.push((i & MASK) + (octets.empty ? 0 : MASK + 1))
            }

            /**
             * All bytes except the last have bit 7 set, and the last byte has bit 7 clear. If the number
             * is between 0 and 127, it is thus represented exactly as one byte.
             * http://www.music.mcgill.ca/~ich/classes/mumt306/StandardMIDIfileformat.html#BM1_1
             */

            const numAsBinary = n.toString(2)
            const lastByteBinary = numAsBinary.substring(numAsBinary.length - 7)
            let lastByteDecimal = parseInt(lastByteBinary, 2)
            if (lastByteDecimal === 255) {
                lastByteDecimal = parseInt('11111110', 2)
            }
            octets[0] = lastByteDecimal
            octets.reverse()
            return octets
        }

        // return result
        if (num <= 127) {
            return num.toString(16).padStart(noDigits, '0')
        }

        return octetify(num)
            .map((e) => e.toString(16).padStart(2, '0'))
            .join('')
            .padStart(noDigits, '0')
    }

    static stringToHex(str) {
        let result = ''
        for (let i = 0; i < str.length; i++) {
            result += str.charCodeAt(i).toString(16)
        }
        return result
    }
}
