import { Injectable } from '@angular/core'
import { ActionController } from '../actions.decorator'
import { Store } from '@ngrx/store'
import { DefaultAction, Logger, AppState } from '@tekbox-coco/midiative-commons'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollSetMagicChordAction',
})
export class PianoRollSetMagicChordAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollSetMagicChordAction')
    public advancedMode = false

    constructor(protected store: Store<AppState>) {
        super()
        this.icon.next('auto_fix_normal')
    }

    onClick(options: any): any {
        if (!this.advancedMode) {
            this.buttonClasses.next(['bg-cyan-50'])
            this.icon.next('auto_fix_high')
        } else {
            this.buttonClasses.next([])
            this.icon.next('auto_fix_normal')
        }
        this.advancedMode = !this.advancedMode
    }
}
