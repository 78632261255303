import { InjectableProvider } from '@angular/core'

export interface Class<T> extends Function {
    new (...args: any[]): T
}

export interface ActionDecoratorOptions {
    name: string
}

export const annotatedActionControllers: { target: Class<unknown>; options: ActionDecoratorOptions }[] = []

export function ActionController(options: ActionDecoratorOptions & InjectableProvider): any {
    return (target: any) => {
        annotatedActionControllers.push({ options, target })
    }
}
