/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
import { Logger } from '../logger/Logger'
import { Soundfont } from '../models/settings-state.model'

export interface MidiJSLoadPluginOptions {
    soundfontUrl?: string
    instrument?: string // or the instrument code 1 (aka the default)
    instruments?: string[] // or multiple instruments
    onsuccess?: () => void
    _soundfont: Soundfont
}

export interface IMidiJS {
    loadPlugin(options: MidiJSLoadPluginOptions)

    noteOn(channel: number, note: number, velocity: number, delay?: number)

    chordOn(channel: number, notes: number[], velocity: number, delay?: number)

    noteOff(channel: number, note: number, delay: number)

    chordOff(channel: number, notes: number[], delay: number)

    setInstrument(channel: number, instrument: number)
}

export class MidiJSWrapper implements IMidiJS {
    private readonly logger = Logger.createLogger('MidiJS')
    chordOff(channel: number, notes: number[], delay: number) {
        this.logger.debug('chordOff', { channel, notes, delay })
        this.midi.chordOff(channel, notes, delay)
    }

    chordOn(channel: number, notes: number[], velocity: number, delay: number) {
        this.logger.debug('chordOn', { channel, notes, velocity, delay })
        this.midi.chordOn(channel, notes, velocity, delay)
    }

    loadPlugin(options: MidiJSLoadPluginOptions) {
        this.logger.debug('loadPlugin', options)
        this.midi.loadPlugin(options)
    }

    noteOff(channel: number, note: number, delay: number) {
        this.logger.debug('noteOff', { channel, note, delay })
        this.midi.noteOff(channel, note, delay)
    }

    noteOn(channel: number, note: number, velocity: number, delay: number) {
        this.logger.debug('noteOn', { channel, note, velocity, delay })
        this.midi.noteOn(channel, note, velocity, delay)
    }

    setInstrument(channel: number, instrument: number) {
        this.logger.debug('setInstrument', { channel, instrument })
        this.midi.setInstrument(channel, instrument)
    }

    protected get midi(): IMidiJS {
        return (window as any).MIDI
    }
}
