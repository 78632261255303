<div
    *ngIf="shortcutOverlayService.isOpen"
    class="noscrollbar absolute bottom-0 left-0 right-0 top-0 flex flex-col overflow-scroll bg-black-95 text-white"
>
    <div class="min-w-[40px] fixed right-0 top-0">
        <app-button
            icon="close"
            [iconClasses]="['text-white']"
            [innerClasses]="['justify-center', 'rounded-bl-[20px]', 'p-[7.5px]', 'border-r-0', 'border-t-0']"
            (onClick)="shortcutOverlayService.closeOverlay()"
        ></app-button>
    </div>

    <div class="w-[100%] grid grid-cols-7">
        <div class="col-span-2"></div>
        <div class="col-span-4">
            <div class="md:!text-[30px] p-4">Shortcuts</div>
            <div *ngFor="let shortcut of shortcuts; let i = index">
                <div class="rounded-bl-[10px] grid grid-cols-8 rounded-lg p-4">
                    <div class="md:!text-[20px] col-span-4 text-left">
                        {{ shortcut.description | translate }}
                    </div>
                    <div class="md:!text-[20px]h col-span-4 flex flex-row flex-row items-center text-left">
                        <ng-container *ngFor="let button of getButtonLabel(shortcut.keys); let isLast = last">
                            <div
                                class="py-[8px] px-[12px] mx-4 inline-block cursor-pointer rounded-md border-2 border-2 border-gray-300 border-gray-300 bg-gray-200 text-center font-bold text-gray-800 no-underline"
                            >
                                {{ button }}
                            </div>
                            <div *ngIf="!isLast">+</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
