import { Injectable } from '@angular/core'
import { ProjectStateModel } from '@tekbox-coco/midiative-commons'
import { FileManagerService } from './file-manager.service'
import { FileManagerResult } from './platforms/PlatformFileManager'

/**
 * FileExporterService to export data to various formats
 */
@Injectable({
    providedIn: 'root',
})
export class FileExporterService {
    constructor(private fileManger: FileManagerService) {}

    /**
     * Export project file
     * @param project
     * @param name
     */
    public async saveProject(project: ProjectStateModel, name: string): Promise<FileManagerResult> {
        const data = JSON.stringify(project, null, 4)
        return await this.fileManger.exportFile(`${name}.mdv`, [data], 'application/mdv')
    }

    /**
     * Export project file
     * @param project
     * @param name
     */
    public async shareProject(project: ProjectStateModel, name: string): Promise<FileManagerResult> {
        const data = JSON.stringify(project, null, 4)
        return await this.fileManger.cacheFile(`${name}.mdv`, [data], 'application/mdv')
    }

    /**
     * Export midi file
     * @param project
     * @param name
     */

    public async saveMidiFile(hexContentString: string, name: string): Promise<FileManagerResult> {
        const binary = []
        for (let i = 0; i < hexContentString.length / 2; i++) {
            const h = hexContentString.substr(i * 2, 2)
            binary[i] = parseInt(h, 16)
        }

        const byteArray = new Uint8Array(binary)
        return await this.fileManger.exportFile(`${name}.midi`, [byteArray], 'application/octet-stream')
    }
    /**
     * Export midi file
     * @param project
     * @param name
     */

    public async shareMidiFile(hexContentString: string, name: string): Promise<FileManagerResult> {
        const binary = []
        for (let i = 0; i < hexContentString.length / 2; i++) {
            const h = hexContentString.substr(i * 2, 2)
            binary[i] = parseInt(h, 16)
        }

        const byteArray = new Uint8Array(binary)
        return await this.fileManger.cacheFile(`${name}.midi`, [byteArray], 'application/octet-stream')
    }
}
