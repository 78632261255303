import { Injectable } from '@angular/core'
import { DefaultAction, RESOLUTIONS, AppState } from '@tekbox-coco/midiative-commons'
import { BeatResolutionEnum } from '@tekbox-coco/midiative-commons'
import { SetResolutionAction } from '../../store/actions/piano-roll-state.actions'
import { SelectDialogService } from '@tekbox-coco/midiative-components'
import { Store } from '@ngrx/store'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ResolutionSelectionAction',
})
export class ResolutionSelectionAction extends DefaultAction {
    private static readonly allResolutions = RESOLUTIONS

    private static readonly defaultResolution = RESOLUTIONS[0]

    private resolution: BeatResolutionEnum = ResolutionSelectionAction.defaultResolution.value

    constructor(private selectDialogService: SelectDialogService, private store: Store<AppState>) {
        super()
        this.label.next('')
        this.domID.next('piano-roll_resolution-selection')

        this.store.select('pianoRollState').subscribe({
            next: async (value) => {
                const element = ResolutionSelectionAction.allResolutions.filter((e) => e.value === value.resolution)
                this.resolution = value.resolution
                this.label.next(String(element[0].label.replace('Triplet', 'T')))
            },
            error: (e) => console.error(e),
        })
    }

    async onClick() {
        const resolution = await this.selectDialogService.showSelect({
            items: ResolutionSelectionAction.allResolutions,
            selected: this.resolution,
        })

        this.store.dispatch(new SetResolutionAction(resolution.value))
    }
}
