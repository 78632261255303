import { BehaviorSubject, Subscription } from 'rxjs'
import { ActionTask } from '../ActionTask'
import { Injectable } from '@angular/core'

@Injectable()
export abstract class DefaultAction<T = any> implements ActionTask<T> {
    protected subscriptions: Subscription[] = []
    private _icon: BehaviorSubject<string> = new BehaviorSubject<string>('')
    private _label: BehaviorSubject<string> = new BehaviorSubject<string>('')
    private _emit: BehaviorSubject<string> = new BehaviorSubject<string>('')
    private _buttonClasses: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
    private _iconClasses: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
    private _labelClasses: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
    private _visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
    private _domID: BehaviorSubject<string> = new BehaviorSubject<string>('')

    public abstract onClick(options?: T)

    /**
     * Called when action consumer is initialized
     * @param options payload
     */
    public onInitActionConsumer(options?: T) {}

    public get icon(): BehaviorSubject<string> {
        return this._icon
    }

    public get label(): BehaviorSubject<string> {
        return this._label
    }

    public get emit(): BehaviorSubject<string> {
        return this._emit
    }

    public get buttonClasses(): BehaviorSubject<string[]> {
        return this._buttonClasses
    }

    public get iconClasses(): BehaviorSubject<string[]> {
        return this._iconClasses
    }

    public get labelClasses(): BehaviorSubject<string[]> {
        return this._labelClasses
    }
    public get visible(): BehaviorSubject<boolean> {
        return this._visible
    }
    public get domID(): BehaviorSubject<string> {
        return this._domID
    }

    /**
     * Unsubscribe on service destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach((e) => e.unsubscribe())
    }
}
