/**
 * FeatureFlags allow you to enable/disable features by setting a flag in the environment.
 *
 * Add this to main.ts to set features at app boot
 * featureFlagsController.setFlags(environment.features)
 *
 * Check if flag is enabled:
 * featureFlagsController.enabled("my.feature")
 */
class FeatureFlags {
    private flags: Map<string, boolean> = new Map()

    setFlags(flags: Record<string, boolean>) {
        this.flags = new Map()
        for (const k of Object.keys(flags)) {
            this.flags.set(k, flags[k])
        }
    }

    getFlags(): Record<string, boolean> {
        const result: Record<string, boolean> = {}
        for (const k of this.flags.keys()) {
            result[k] = this.flags.get(k)
        }
        return result
    }

    enabled(key: string): boolean {
        if (this.flags.has(key)) {
            return this.flags.get(key)
        }
        return false
    }
}

export const featureFlagsController = new FeatureFlags()
