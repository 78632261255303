import { Injectable } from '@angular/core'
import { HammerGestureConfig } from '@angular/platform-browser'
import * as Hammer from 'hammerjs'

/**
 * @hidden
 * This class overrides the default Angular gesture config.
 */
@Injectable()
export class MyHammerGestureConfig extends HammerGestureConfig {
    overrides = <any>{
        press: { time: '250' }, // override default settings
        tap: { taps: 2 }, // override default settings,
        pan: { direction: Hammer.DIRECTION_ALL },
    }
}
