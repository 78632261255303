import { Injectable } from '@angular/core'
import {
    ActionTask,
    ProjectToolEnum,
    PrToolEnum,
    ToolSelectionAction,
    UiStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { ToolSelectionMenuService } from '@tekbox-coco/midiative-components'
import { SetPrSelectedToolAction } from '../../store/actions/ui-state.actions'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollToolSelectionButtonAction',
})
export class PianoRollToolSelectionButtonAction extends ToolSelectionAction implements ActionTask {
    public readonly toolSelectionConfig = [
        {
            tool: PrToolEnum.CHORD,
            label: 'chords',
            matIconCode: 'playlist_play',
            domID: 'piano-roll_tool-selection-menu_chord',
        },
        {
            tool: PrToolEnum.PENCIL,
            label: 'insert',
            matIconCode: 'create',
            domID: 'piano-roll_tool-selection-menu_pencil',
        },
        {
            tool: PrToolEnum.LASSO,
            label: 'select',
            matIconCode: 'highlight_alt',
            domID: 'piano-roll_tool-selection-menu_lasso',
        },
        {
            tool: PrToolEnum.CUT,
            label: 'cut',
            matIconCode: 'content_cut',
            domID: 'piano-roll_tool-selection-menu_cut',
        },
        {
            tool: PrToolEnum.DRAW,
            label: 'draw',
            matIconCode: 'auto_fix_normal',
            domID: 'piano-roll_tool-selection-menu_draw',
        },
        {
            tool: PrToolEnum.AUTO_CHORD,
            label: 'auto chord',
            matIconCode: 'auto_fix_high',
            domID: 'piano-roll_tool-selection-menu_auto-chord',
        },
    ]
    private prSelectedTool: PrToolEnum

    constructor(private store: Store<AppState>, private toolSelectionMenuService: ToolSelectionMenuService) {
        super()

        this.buttonClasses.next(['bg-yellow-50'])
        this.domID.next('piano-roll_tool-selection')

        this.subscriptions.push(
            this.store.select('uiState').subscribe({
                next: (value: UiStateModel) => {
                    this.prSelectedTool = value.prSelectedTool
                    let icon = this.toolSelectionConfig.filter((t) => t.tool === this.prSelectedTool)[0]

                    this.icon.next(icon.matIconCode)
                },
            })
        )
    }

    async onClick() {
        // do noting
        const result = await this.toolSelectionMenuService.requestToolSelectionMenu(this.toolSelectionConfig, {
            tool: this.prSelectedTool,
            label: '?',
            matIconCode: '??',
        })
        if (result) {
            this.store.dispatch(new SetPrSelectedToolAction(result.tool as PrToolEnum))
        }
    }
}
