import { MAX_GRID_RESOLUTION, SMALLEST_DISPLAYABLE_IRN } from './piano-roll-util'
import { Logger } from '../logger/Logger'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'

const logger = Logger.createLogger('rendering.util.ts')

/**
 * Converts irn to px
 * @param valueInIRN
 * @param timeSignature
 * @param barRenderWidthPX
 */
export function irnToPx(valueInIRN: number, timeSignature: number, barRenderWidthPX: number): number {
    return barRenderWidthPX * (valueInIRN / MAX_GRID_RESOLUTION) * (1 / timeSignature)
}

/**
 * Converts px to irn
 * @param valueInPX
 * @param timeSignature
 * @param barRenderWidthPX
 */
export function pxToIrn(valueInPX: number, timeSignature: number, barRenderWidthPX: number): number {
    return ((valueInPX * timeSignature) / barRenderWidthPX) * MAX_GRID_RESOLUTION
}

/**
 * returns a boolean that depicts if the inserted time signature is irrational
 */
export function timeSignatureIrrational(resolution: number, timeSignature: number): boolean {
    const cellsPerBar = resolution * timeSignature
    return cellsPerBar % 1 !== 0
}

/**
 * Rounds the input irn to the closest bar with the current time signature.
 * @param inputIRN
 * @param timeSignature
 */
export function quantizeIRNToBar(inputIRN: number, timeSignature: number): number {
    return Math.round(inputIRN / MAX_GRID_RESOLUTION) * MAX_GRID_RESOLUTION * timeSignature
}

/**
 * Rounds the input irn to the closest beat with the current time signature.
 * @param inputIRN
 * @param divider
 * @param timeSignature
 */
export function quantizeIRNToBeat(inputIRN: number, divider: number, timeSignature): number {
    let beatLengthIRN
    if (timeSignatureIrrational(divider, timeSignature)) {
        beatLengthIRN = (MAX_GRID_RESOLUTION * timeSignature) / divider
    } else {
        beatLengthIRN = MAX_GRID_RESOLUTION / divider
    }

    return Math.round(inputIRN / beatLengthIRN) * beatLengthIRN
}

/**
 * Floors the input irn to the closest beat with the current time signature.
 * There will always be two, so it will take the lower one in this case.
 * If both are below zero the function will return 0.
 * If the time signature is irrational, the current divider will be used.
 * @param inputIRN
 * @param divider
 * @param timeSignature
 */
export function quantizeIRNToLowerBeat(inputIRN: number, divider: number, timeSignature: number): number {
    let beatLengthIRN
    if (timeSignatureIrrational(divider, timeSignature)) {
        beatLengthIRN = (MAX_GRID_RESOLUTION * timeSignature) / divider
    } else {
        beatLengthIRN = MAX_GRID_RESOLUTION / divider
    }
    const quantizedIRN = Math.floor(inputIRN / beatLengthIRN) * beatLengthIRN
    return quantizedIRN < 0 ? 0 : quantizedIRN
}

/**
 * Ceils the input irn to the closest beat with the current time signature.
 * There will always be two, so it will take the higher one in this case.
 * If both are below or zero, it will return the first beat IRN in the current time signature.
 * If the time signature is irrational, the current divider will be used.
 * @param inputIRN
 * @param divider
 * @param timeSignature
 */
export function quantizeIRNToHigherBeat(inputIRN: number, divider: number, timeSignature: number): number {
    let beatLengthIRN
    if (timeSignatureIrrational(divider, timeSignature)) {
        beatLengthIRN = (MAX_GRID_RESOLUTION * timeSignature) / divider
    } else {
        beatLengthIRN = MAX_GRID_RESOLUTION / divider
    }

    const quantizedIRN = Math.ceil(inputIRN / beatLengthIRN) * beatLengthIRN

    // return higher beat if quantized value is the same as input value
    if (quantizedIRN === inputIRN) {
        return quantizedIRN + beatLengthIRN
    }

    // return first beat if quantized value is below 0
    if (quantizedIRN <= 0) {
        return beatLengthIRN
    } else {
        return quantizedIRN
    }
}

/**
 * returns the closest beat that has no negative length relative to the start irn
 * @param startIRN
 * @param lengthIRN
 * @param divider
 * @param timeSignature
 */
export function quantizeIRNLength(startIRN: number, lengthIRN: number, divider: number, timeSignature: number): number {
    const correctedLength = lengthIRN < SMALLEST_DISPLAYABLE_IRN ? SMALLEST_DISPLAYABLE_IRN : lengthIRN
    const noteEndIRN = startIRN + correctedLength
    const lowerBeat = quantizeIRNToLowerBeat(noteEndIRN, divider, timeSignature)
    const higherBeat = quantizeIRNToHigherBeat(noteEndIRN, divider, timeSignature)

    // find beat that is closer to the note end but still bigger than the start
    const distLow = Math.abs(noteEndIRN - lowerBeat)
    const distHigh = Math.abs(noteEndIRN - higherBeat)
    if (distHigh > distLow) {
        if (lowerBeat - startIRN > 0) {
            return lowerBeat - startIRN
        } else if (higherBeat - startIRN > 0) {
            return higherBeat - startIRN
        } else {
            // return next beat relative to the start IRN if the user tries to set the end IRN lower as the start IRN.
            return quantizeIRNToHigherBeat(startIRN, divider, timeSignature) - startIRN
        }
    } else {
        if (higherBeat - startIRN > 0) {
            return higherBeat - startIRN
        } else {
            logger.error(
                'This should not happen. quantizeIRNToHigherBeat should always return something that is bigger than the start. Create a dev ticket if you see this error.'
            )
        }
    }
}

/**
 * calculates the time signature from the numerator and denominator.
 * @param numerator
 * @param denominator
 */
export function calcTimeSignature(numerator: number, denominator: number): number {
    return numerator / denominator
}

/**
 * calculates the length of a bar with the current time signature in pixel
 * @param timeSignature
 * @param barRenderWidthPX
 */
export function calcBarLengthPX(timeSignature: number, barRenderWidthPX: number): number {
    return timeSignature * barRenderWidthPX
}

/**
 * calculates the length of a bar with the current time signature in IRN
 * @param timeSignature
 */
export function calcBarLengthIRN(timeSignature: number): number {
    return MAX_GRID_RESOLUTION * timeSignature
}

/**
 * calculates the zoom factor by the width of a bar in pixels
 * @param barWidthXTimeSignaturePX
 */
export function calcZoomFactor(barWidthXTimeSignaturePX: number): number {
    return barWidthXTimeSignaturePX / MAX_GRID_RESOLUTION
}

/**
 * calculates the transform factor of a pattern to scale it
 * @param barRenderWidthPX
 */
export function calcTransformFactor(barRenderWidthPX: number): number {
    return barRenderWidthPX / MAX_GRID_RESOLUTION
}

/**
 * calculates the scaling factor of in the current time signature
 * @param timeSignature
 */
export function calcScalingFactor(timeSignature: number): number {
    return 1 / timeSignature
}

/**
 * This function calculates how many bars have to be added to include the targetIRN compared to the lengthIRN.
 * E.g. I want to add a note to a pattern, the end of the note exceeds the pattern length, how many bars do I
 * have to add to include it?
 * @param targetIRN
 * @param lengthIRN
 * @param timeSignatureNumerator
 * @param timeSignatureDenominator
 */
export function calcMissingBars(
    targetIRN: number,
    lengthIRN: number,
    timeSignatureNumerator: number,
    timeSignatureDenominator: number
): number {
    const barLengthIRN = calcBarLengthIRN(calcTimeSignature(timeSignatureNumerator, timeSignatureDenominator))
    const difference = targetIRN - lengthIRN

    let barsToAdd = 0
    for (let i = 0; i < 16; i++) {
        if (barLengthIRN * i >= difference) {
            barsToAdd = i
            break
        }
    }
    return barsToAdd
}

/**
 * returns the correct amount of cells needed per bar related to the current resolution and the current time signature
 * @param resolution
 * @param timeSignatureNumerator
 * @param timeSignatureDenominator
 */
export function getCellsPerBar(
    resolution: BeatResolutionEnum,
    timeSignatureNumerator: number,
    timeSignatureDenominator: number
): number {
    const timeSignature = timeSignatureNumerator / timeSignatureDenominator
    const cellsPerBar = resolution * timeSignature

    if (cellsPerBar % 1 !== 0) {
        return resolution
    } else {
        return cellsPerBar
    }
}
