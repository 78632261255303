import { Component, Input } from '@angular/core'
import { HudInfoPanelConfig } from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-info-panel',
    templateUrl: './info-panel.component.html',
})
export class InfoPanelComponent {
    @Input()
    config: HudInfoPanelConfig
}
