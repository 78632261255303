import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { OctaveUpNotesAction } from '../../store/actions/piano-roll-state.actions'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollOctaveUpAction',
})
export class PianoRollOctaveUpAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.label.next('PIANO_ROLL.MENU.OCTAVE_UP')
        this.icon.next('keyboard_arrow_up')
    }

    onClick() {
        this.store.dispatch(new OctaveUpNotesAction())
    }
}
