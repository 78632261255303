import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'TestAction',
})
export class TestAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.icon.next('info')
        this.label.next('Test')
    }

    onClick() {
        console.log('Action Executed')
    }
}
