import { Injectable } from '@angular/core'
import { ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { ToastOptions } from '@ionic/core/dist/types/components/toast/toast-interface'
import { timer } from 'rxjs'

export interface ToastServiceOptions extends ToastOptions {
    // label options are used to interpolate values in the label string
    // `The value is {{value}}` => {labelOptions: {value: 123}} => `The value is 123`
    labelOptions?: {}
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private static toastDefaults: ToastServiceOptions = {
        position: 'bottom',
        animated: true,
        duration: 1500,
    }
    private messages: ToastServiceOptions[] = []
    private messageQueueRunning: boolean
    constructor(private toastController: ToastController, private translateService: TranslateService) {}

    /**
     * Show toast in error color. Color can NOT be overwritten using options
     * @param message message text
     * @param options ToastServiceOptions
     */
    public error(message: string, options: ToastServiceOptions = {}) {
        this.send(message, {
            ...options,
            color: 'danger',
        })
    }

    /**
     * Show toast in warning color. Color can NOT be overwritten using options
     * @param message message text
     * @param options ToastServiceOptions
     */
    public warn(message: string, options: ToastServiceOptions = {}) {
        this.send(message, {
            ...options,
            color: 'warning',
        })
    }

    /**
     * Show toast in success color. Color can NOT be overwritten using options
     * @param message message text
     * @param options ToastServiceOptions
     */
    public success(message: string, options: ToastServiceOptions = {}) {
        this.send(message, {
            ...options,
            color: 'success',
        })
    }

    /**
     * Show toast in info color, color can be overwritten using options
     * @param message message text
     * @param options ToastServiceOptions
     */
    public info(message: string, options: ToastServiceOptions = {}) {
        this.send(message, {
            ...options,
            color: 'dark',
        })
    }

    /**
     * Add message to queue
     * @param toastMsg message
     * @param options ToastServiceOptions
     * @private
     */
    private send(toastMsg: string, options: ToastServiceOptions = {}): void {
        this.messages.push({
            ...ToastService.toastDefaults,
            ...options,
            message: this.translateService.instant(toastMsg, options.labelOptions || {}),
        })
        this.runQueue()
    }

    /**
     * Starts queue if not already running
     * @private
     */
    private async runQueue() {
        if (!this.messageQueueRunning) {
            await this.handleToastQueue()
        }
    }

    /**
     * Check queue for messages, if messages present process them
     * @private
     */
    private async handleToastQueue() {
        this.messageQueueRunning = true
        const nextMessage = this.messages.shift()

        const message = await this.toastController.create(nextMessage)
        await message.present()
        timer(nextMessage.duration).subscribe((result) => {
            /**
             * Better would be prevent having several same messages in queue in send()
             */
            if (this.messages.length > 0) {
                while (this.messages.length > 1) {
                    this.messages.shift()
                }
                this.handleToastQueue()
            } else {
                this.messageQueueRunning = false
            }
        })
    }
}
