import {
    AppState,
    BeatResolutionEnum,
    calculateBPMFromMicroseconds,
    Color,
    createProjectRouterLink,
    cutTrackIntoPatterns,
    DefaultAction,
    getRandomId,
    getUnixTimeStampByDate,
    initialLoopSelectorState,
    Instruments,
    KeyType,
    Logger,
    MAX_GRID_RESOLUTION,
    Note,
    Pattern,
    PatternType,
    ProjectStateModel,
    ScaleType,
    Track,
} from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    ClearProjectAction,
    LoadProjectAction,
    ProjectStateActionTypes,
    SetBPMAction,
    SetNameAction,
    SetTimeSignatureDenominatorAction,
    SetTimeSignatureNumeratorAction,
} from '../../store/actions/project-state.actions'
import { FileManagerService } from '../../services/file-manager/file-manager.service'
import { Router } from '@angular/router'
import { ToastService } from '../../services/toast.service'
import {
    AnyChannelEvent,
    AnyEvent,
    AnyMetaEvent,
    NoteOffEvent,
    NoteOnEvent,
    ProgramChangeEvent,
    read,
    SetTempoEvent,
    TextEvent,
    TimeSignatureEvent,
} from 'midifile-ts'
import { ActionController } from '../actions.decorator'
import { ColorService } from '../../services/color.service'
import { projectStateReducer } from '../../store/reducers/project-state.reducer'
import { SpinnerService } from '@tekbox-coco/midiative-components'
import { debug } from 'ng-packagr/lib/utils/log'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'OpenMidiFileAction',
})
export class OpenMidiFileAction extends DefaultAction {
    private readonly logger = Logger.createLogger('OpenMidiFileAction')

    constructor(
        private fileManagerService: FileManagerService,
        private router: Router,
        private toastService: ToastService,
        private store: Store<AppState>,
        private colorService: ColorService,
        private spinnerService: SpinnerService
    ) {
        super()
        this.label.next('HOME.OPEN_MIDI_FILE')
        this.icon.next('drive_folder_upload')
    }

    private readonly beatResolutionConst = 96

    private stringToBuffer(input: string): ArrayBuffer {
        var buf = new ArrayBuffer(input.length * 2) // 2 bytes for each char
        var bufView = new Uint8Array(buf)
        for (var i = 0, strLen = input.length; i < strLen; i++) {
            bufView[i] = input.charCodeAt(i)
        }
        return buf
    }

    private getSubevent<T>(events: AnyEvent[], type: string): T | null {
        const res = events.filter((e) => 'subtype' in e).filter((e: AnyMetaEvent) => e.subtype === type)
        if (res.length > 0) {
            return res[0] as T
        }
        return null
    }

    private findFirstChannelEvent(events: AnyEvent[]): AnyChannelEvent | null {
        const res = events.filter((e) => 'channel' in e)
        if (res.length > 0) {
            return res[0] as AnyChannelEvent
        }
        return null
    }
    private findFirstMetaEvent(events: AnyEvent[]): AnyMetaEvent | null {
        const res = events.filter((e) => 'subtype' in e)
        if (res.length > 0) {
            return res[0] as AnyMetaEvent
        }
        return null
    }

    public loadMidiFromArrayBuffer(fileData: ArrayBuffer, fileName: string) {
        this.spinnerService.showing = true
        // read midi file to object structure
        const midiData = read(fileData)
        const { header, tracks } = midiData

        if (header.formatType != 1) {
            throw new Error('Only type 1 is supported. Type was ' + header.formatType)
        }

        // in formatType 1 the first track contains only meta information
        const metaTrack = tracks.shift()

        // get time signature
        const tempoEvent = this.getSubevent<SetTempoEvent>(metaTrack, 'setTempo')

        let projectBPM = 120
        if (tempoEvent !== null) {
            projectBPM = calculateBPMFromMicroseconds(tempoEvent.microsecondsPerBeat)
            this.logger.info('Tempo found. Using BPM from file. BPM read: ' + projectBPM)
        } else {
            this.logger.info('No tempo found. Using default BPM')
        }

        const timeSignatureEvent = this.getSubevent<TimeSignatureEvent>(metaTrack, 'timeSignature')
        let projectTimeSignatureNumerator = 4
        let projectTimeSignatureDenominator = 4
        if (timeSignatureEvent !== null) {
            projectTimeSignatureNumerator = timeSignatureEvent.numerator
            projectTimeSignatureDenominator = timeSignatureEvent.denominator
            this.logger.info(
                `Time Signature read from file: ${projectTimeSignatureNumerator}/${projectTimeSignatureDenominator}`
            )
        } else {
            this.logger.info('No Time Signature found in file. Using default 4/4.')
        }

        this.store.dispatch(new ClearProjectAction())
        this.store.dispatch(new SetNameAction(fileName))
        this.store.dispatch(new SetBPMAction(projectBPM))
        this.store.dispatch(new SetTimeSignatureNumeratorAction(projectTimeSignatureNumerator))
        this.store.dispatch(new SetTimeSignatureDenominatorAction(projectTimeSignatureDenominator))

        const tracksAndPatterns = tracks.map((trackData, trackIndex) => {
            const trackName = this.getSubevent<TextEvent>(trackData, 'text')
            const programChange = this.getSubevent<ProgramChangeEvent>(trackData, 'programChange')
            const firstChannelEvent = this.findFirstChannelEvent(trackData)
            const firstMetaEvent = this.findFirstMetaEvent(trackData)

            let instrument =
                programChange != null
                    ? Object.values(Instruments)[programChange.value]
                    : Instruments.ACOUSTIC_GRAND_PIANO

            const channel = firstChannelEvent != null ? firstChannelEvent.channel : 0
            if (channel === 9) {
                instrument = Instruments.STEEL_DRUMS
            }

            const pattern: Pattern = {
                id: getRandomId(),
                lengthIRN: 0, // will be set below in here
                notes: [],
                color: Color.RED, // color does not matter here, will be overridden in a later stage of this function
                lastResolution: BeatResolutionEnum.r1_4,
                type: PatternType.PianoRollPattern,
            }

            const mappingId = getRandomId()
            const track: Track = {
                id: getRandomId(),
                channel: channel,
                instrument,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    [mappingId]: {
                        id: mappingId,
                        patternId: pattern.id,
                        position: 0,
                        startAt: 0,
                        length: pattern.lengthIRN,
                    },
                },
            }
            let absoluteTime = 0
            const withAbsoluteTime = trackData
                // add absTime to the note object
                .map((e) => {
                    // calculate absolute time. absTime is the sum of delta times
                    absoluteTime = absoluteTime + e.deltaTime
                    return {
                        ...e,
                        absoluteTime: absoluteTime,
                    }
                })
                // transform absolute time to 96 ticks per quarter bar
                .map((e) => {
                    const trackScalingFactor = this.beatResolutionConst / header.ticksPerBeat
                    return {
                        ...e,
                        absoluteTime: e.absoluteTime * trackScalingFactor,
                    }
                }) as (AnyEvent & { absoluteTime: number })[]
            // get all channel events
            const channelEvents = withAbsoluteTime.filter((e) => e.type === 'channel') as (AnyChannelEvent & {
                absoluteTime: number
            })[]
            // get all note events (on/off)
            const noteEvents = channelEvents.filter((e) => e.subtype === 'noteOn' || e.subtype === 'noteOff') as ((
                | NoteOffEvent
                | NoteOnEvent
            ) & { absoluteTime: number })[]
            // group by noteNumber to have an array with on, off sequence
            const byNoteNumbers: Record<
                string,
                ((NoteOffEvent & { absoluteTime: number }) | (NoteOnEvent & { absoluteTime: number }))[]
            > = noteEvents.reduce((notesMap, note) => {
                if (!notesMap[note.noteNumber]) {
                    notesMap[note.noteNumber] = []
                }
                notesMap[note.noteNumber].push(note)
                return notesMap
            }, {})
            // iterate over all note keys and extract midiative notes
            for (const noteNumber of Object.keys(byNoteNumbers)) {
                const noteSeq = byNoteNumbers[noteNumber]
                for (let i = 0; i < noteSeq.length; i = i + 2) {
                    const noteStart = noteSeq[i] as NoteOnEvent & { absoluteTime: number }
                    const noteEnd = noteSeq[i + 1] as NoteOffEvent & { absoluteTime: number }
                    const length = noteEnd.absoluteTime - noteStart.absoluteTime
                    const note: Note = {
                        id: getRandomId(),
                        key: noteStart.noteNumber,
                        startIRN: noteStart.absoluteTime,
                        velocity: noteStart.velocity,
                        lengthIRN: length,
                    }
                    pattern.notes.push(note)
                }
            }

            pattern.lengthIRN = Math.ceil(absoluteTime / MAX_GRID_RESOLUTION) * MAX_GRID_RESOLUTION
            track.patterns[mappingId].length = pattern.lengthIRN
            return { track, pattern }
            // this.store.dispatch(new AddPatternAction(pattern))
            // this.store.dispatch(new AddTrackAction(track))
        })

        let project: ProjectStateModel = {
            id: getRandomId(),
            name: fileName,
            demo: false,
            bpm: projectBPM,
            timeSignatureNumerator: projectTimeSignatureNumerator,
            timeSignatureDenominator: projectTimeSignatureDenominator,
            key: KeyType.C,
            scale: ScaleType.IONIAN,
            loopSelector: initialLoopSelectorState,
            schemaVersion: 0,
            created: getUnixTimeStampByDate(new Date()),
            lastEdit: getUnixTimeStampByDate(new Date()),
            tracks: {
                ids: tracksAndPatterns.map((e) => e.track.id),
                entities: tracksAndPatterns.reduce((a, b) => {
                    return {
                        ...a,
                        [b.track.id]: b.track,
                    }
                }, {}),
            },
            patterns: {
                ids: tracksAndPatterns.map((e) => e.pattern.id),
                entities: tracksAndPatterns.reduce((a, b) => {
                    return {
                        ...a,
                        [b.pattern.id]: b.pattern,
                    }
                }, {}),
            },
        }

        for (const track of Object.values(project.tracks.entities)) {
            for (const mapping of Object.values(track.patterns)) {
                const pattern = project.patterns.entities[mapping.patternId]
                const result = cutTrackIntoPatterns(pattern, 4 * MAX_GRID_RESOLUTION)

                // set color for imported track patterns
                result.patterns = result.patterns.map((p) => ({
                    ...p,
                    color: this.colorService.getTrackColorForImportedTrackPatterns(track.channel),
                }))

                const formObject = (a, b) => {
                    return {
                        ...a,
                        [b.id]: b,
                    }
                }
                track.patterns = result.trackPatternMappings.reduce(formObject, {})
                project.patterns.entities = {
                    ...project.patterns.entities,
                    ...result.patterns.reduce(formObject, {}),
                }
                result.patterns.forEach((e) => {
                    project.patterns.ids = [...project.patterns.ids.filter((e) => e != mapping.id), e.id] as string[]
                })

                console.log({ track, mapping, pattern, result })
            }

            // const result = cutTrackIntoPatterns(pattern, MAX_GRID_RESOLUTION)
        }

        console.log('Project', project)
        // todo: check if this makes any sense to remove the meta tracks
        // clean empty tracks
        const emptyTracks = Object.values(project.tracks.entities)
            .filter((t) => Object.values(t.patterns).length === 0)
            .map((t) => t.id)

        emptyTracks.forEach((tId) => {
            project = projectStateReducer(project, { type: ProjectStateActionTypes.DeleteTrack, payload: tId })
        })

        this.store.dispatch(new LoadProjectAction(project))

        // this.store.dispatch(new SetBPMAction(this.bpm))
        // this.store.dispatch(new SetKeyAction(this.key))
        // this.store.dispatch(new SetScaleAction(this.scale))]

        // const projectId = getRandomId()
        // this.store.dispatch(new SetProjectIdAction(projectId))
        this.router.navigate(createProjectRouterLink(project.id)).catch((err) => this.logger.error(err))
    }

    async onClick() {
        await this.fileManagerService
            .openFile({ types: ['audio/midi'] })
            .then(async (openFileResult) => {
                this.spinnerService.showing = true
                this.loadMidiFromArrayBuffer(openFileResult.data, openFileResult.fileName)
                this.spinnerService.showing = false
            })
            .catch((e) => {
                this.toastService.error(`STORAGE.IMPORT_ERROR`, { labelOptions: { value: e } })
                this.logger.info(e)
                this.spinnerService.showing = false
                return
            })
    }
}
