import { Action } from '@ngrx/store'
import { Instruments, Note, Pattern } from '@tekbox-coco/midiative-commons'
import { Update } from '@ngrx/entity'

export enum DrumSequencerStateActionTypes {
    LoadDrumSequencerPattern = '[DrumSequencer] LoadDrumSequencerPattern',
    SetDrumSequencerPatternId = '[DrumSequencer] SetDrumSequencerPatternId',
    SetDrumsSequencerInstrument = '[DrumSequencer] SetDrumsSequencerInstrument',
    SetDrumSequencerChannel = '[DrumSequencer] SetDrumsSequencerChannel',
    // Notes
    SetDrumSequencerNotes = '[DrumSequencer] SetDrumSequencerNotes',
    ClearDrumSequencerNotes = '[DrumSequencer] ClearDrumSequencerNotes',
    AddDrumSequencerNote = '[DrumSequencer] AddDrumSequencerNote',
    AddDrumSequencerNotes = '[DrumSequencer] AddDrumSequencerNotes',
    UpsertDrumSequencerNote = '[DrumSequencer] UpsertDrumSequencerNote',
    UpsertDrumSequencerNotes = '[DrumSequencer] UpsertDrumSequencerNotes',
    UpdateDrumSequencerNote = '[DrumSequencer] UpdateDrumSequencerNote',
    UpdateDrumSequencerNotes = '[DrumSequencer] UpdateDrumSequencerNotes',
    DeleteDrumSequencerNote = '[DrumSequencer] DeleteDrumSequencerNote',
    DeleteDrumSequencerNotes = '[DrumSequencer] DeleteDrumSequencerNotes',
    SetDrumSequencerBars = '[DrumSequencer] SetDrumSequencerBars',
}

export class LoadDrumSequencerPatternAction implements Action {
    readonly type = DrumSequencerStateActionTypes.LoadDrumSequencerPattern

    constructor(public payload: Pattern) {}
}

export class SetDrumSequencerPatternIdAction implements Action {
    readonly type = DrumSequencerStateActionTypes.SetDrumSequencerPatternId

    constructor(public payload: string) {}
}

export class SetDrumsSequencerInstrumentAction implements Action {
    readonly type = DrumSequencerStateActionTypes.SetDrumsSequencerInstrument

    constructor(public payload: Instruments) {}
}

export class SetDrumSequencerChannelAction implements Action {
    readonly type = DrumSequencerStateActionTypes.SetDrumSequencerChannel

    constructor(public payload: number) {}
}

// Notes
export class SetDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.SetDrumSequencerNotes

    constructor(public payload: Note[]) {}
}

export class ClearDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.ClearDrumSequencerNotes
}

export class AddDrumSequencerNoteAction implements Action {
    readonly type = DrumSequencerStateActionTypes.AddDrumSequencerNote

    constructor(public payload: Note) {}
}

export class AddDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.AddDrumSequencerNotes

    constructor(public payload: Note[]) {}
}

export class UpsertDrumSequencerNoteAction implements Action {
    readonly type = DrumSequencerStateActionTypes.UpsertDrumSequencerNote

    constructor(public payload: Note) {}
}

export class UpsertDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.UpsertDrumSequencerNotes

    constructor(public payload: Note[]) {}
}

export class UpdateDrumSequencerNoteAction implements Action {
    readonly type = DrumSequencerStateActionTypes.UpdateDrumSequencerNote

    constructor(public payload: Update<Note>) {}
}

export class UpdateDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.UpdateDrumSequencerNotes

    constructor(public payload: Update<Note>[]) {}
}

export class DeleteDrumSequencerNoteAction implements Action {
    readonly type = DrumSequencerStateActionTypes.DeleteDrumSequencerNote

    constructor(public payload: string) {}
}

export class DeleteDrumSequencerNotesAction implements Action {
    readonly type = DrumSequencerStateActionTypes.DeleteDrumSequencerNotes

    constructor(public payload: string[]) {}
}

export type DrumSequencerActions =
    | LoadDrumSequencerPatternAction
    | SetDrumSequencerPatternIdAction
    | SetDrumsSequencerInstrumentAction
    | SetDrumSequencerChannelAction
    | SetDrumSequencerNotesAction
    | ClearDrumSequencerNotesAction
    | AddDrumSequencerNoteAction
    | AddDrumSequencerNotesAction
    | UpsertDrumSequencerNoteAction
    | UpsertDrumSequencerNotesAction
    | UpdateDrumSequencerNoteAction
    | UpdateDrumSequencerNotesAction
    | DeleteDrumSequencerNoteAction
    | DeleteDrumSequencerNotesAction
