import { Injectable } from '@angular/core'
import {
    calcTimeSignature,
    DefaultAction,
    Logger,
    MAX_GRID_RESOLUTION,
    PianoRollStateModel,
    ProjectStateModel,
    TrackPatternMapping,
    UiStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import {
    ClearNotesAction,
    OctaveDownNotesAction,
    SetPatternLengthIRNAction,
} from '../../store/actions/piano-roll-state.actions'

import { ActionController } from '../actions.decorator'
import { UpdateAllMappingsForPatternAction, UpdatePatternAction } from '../../store/actions/project-state.actions'

/**
 * Action to add a bar of a pattern.
 * If mapping is passed as payload all linked patterns are updated
 */
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollAddBarAction',
})
export class PianoRollAddBarAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollAddBarAction')

    private pianoRollState: PianoRollStateModel
    private projectState: ProjectStateModel

    constructor(private store: Store<AppState>) {
        super()
        this.label.next('PIANO_ROLL.ADD_BAR')
        this.domID.next('piano-roll_add-bar')
        this.icon.next('add')

        this.subscriptions.push(
            this.store.select('pianoRollState').subscribe({
                next: async (value) => {
                    this.pianoRollState = value
                },
                error: (e) => this.logger.error(e),
            }),

            this.store.select('projectState').subscribe({
                next: async (value) => {
                    this.projectState = value
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    onClick(payload: TrackPatternMapping | undefined) {
        this.addBar(1, payload)
    }

    private addBar(noBars: number, mapping?: TrackPatternMapping): void {
        const patternLength =
            this.pianoRollState.patternLengthIRN +
            noBars *
                (MAX_GRID_RESOLUTION *
                    calcTimeSignature(
                        this.projectState.timeSignatureNumerator,
                        this.projectState.timeSignatureDenominator
                    ))
        // update piano roll pattern
        this.store.dispatch(new SetPatternLengthIRNAction(patternLength))

        // update project pattern
        this.store.dispatch(
            new UpdatePatternAction({
                id: this.pianoRollState.patternId,
                changes: {
                    lengthIRN: patternLength,
                },
            })
        )

        // update all linked pattern mappings
        if (mapping) {
            this.store.dispatch(
                new UpdateAllMappingsForPatternAction(mapping.patternId, {
                    ...mapping,
                    length: patternLength,
                })
            )
        }
    }
}
