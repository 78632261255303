import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { SetProjectBarRenderWidthAction } from '../../store/actions/ui-state.actions'
import {
    createRouterLink,
    Logger,
    MigrationHandler,
    PROJECT_DEFAULT_ZOOM_STEP,
    ProjectStateModel,
    ROUTE_PROJECT,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { UndoAction as StoreUndoAction } from '../../store/meta/history/history.actions'
import { LoadProjectAction } from '../../store/actions/project-state.actions'
import { FileManagerService } from '../../services/file-manager/file-manager.service'
import { Router } from '@angular/router'
import { ToastService } from '../../services/toast.service'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'OpenProjectFileAction',
})
export class OpenProjectFileAction extends DefaultAction {
    private readonly logger = Logger.createLogger('OpenProjectFileAction')

    constructor(
        private fileManagerService: FileManagerService,
        private router: Router,
        private toastService: ToastService,
        private store: Store<AppState>
    ) {
        super()
        this.label.next('HOME.OPEN_PROJECT_FILE')
        this.icon.next('drive_folder_upload')
    }

    async onClick() {
        await this.fileManagerService
            .openFile({ types: ['application/octet-stream'] })
            .then(async (openFileResult) => {
                const decoder = new TextDecoder()
                this.logger.debug('openProjectFile', 'file', decoder.decode(openFileResult.data))
                const project: ProjectStateModel = JSON.parse(decoder.decode(openFileResult.data))
                this.logger.debug('openProjectFile', 'project json', project)
                if (MigrationHandler.validate(project)) {
                    this.store.dispatch(new LoadProjectAction(project))
                    await this.router.navigate(createRouterLink(ROUTE_PROJECT)).catch((e) => this.logger.error(e))
                } else {
                    this.logger.error('Project file corrupted, could not verify file consistency')
                    this.toastService.error('Project file is broken and could not be loaded')
                }
            })
            .catch((e) => {
                this.toastService.error(`STORAGE.IMPORT_ERROR`, { labelOptions: { value: e } })
                this.logger.info(e)
                return
            })
    }
}
