import {
    DefaultAction,
    AppState,
    PROJECT_MIN_BAR_RENDER_WIDTH,
    PR_MIN_BAR_RENDER_WIDTH,
} from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { SetPianoRollBarRenderWidthAction, SetProjectBarRenderWidthAction } from '../../store/actions/ui-state.actions'
import { PR_DEFAULT_ZOOM_STEP, PROJECT_DEFAULT_ZOOM_STEP } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ZoomPianoRollOutAction',
})
export class ZoomPianoRollOutAction extends DefaultAction {
    private prBarRenderWidth: number = 0

    constructor(private store: Store<AppState>) {
        super()
        this.icon.next('zoom_out')

        this.store.select('uiState').subscribe({
            next: (value) => {
                this.prBarRenderWidth = value.prBarRenderWidth

                if (this.prBarRenderWidth <= PR_MIN_BAR_RENDER_WIDTH) {
                    this.buttonClasses.next(['bg-gray-900'])
                    this.iconClasses.next(['text-gray-700'])
                } else {
                    this.buttonClasses.next([])
                    this.iconClasses.next([])
                }
            },
        })
    }

    onClick() {
        this.store.dispatch(new SetPianoRollBarRenderWidthAction(this.prBarRenderWidth - PR_DEFAULT_ZOOM_STEP))
    }
}
