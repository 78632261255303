import { Injectable, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { selectProjectNotes, selectSongEnd } from '../store/reducers/project-state.reducer'
import {
    EndOfTrack,
    HeaderChunk,
    Instruments,
    Logger,
    MidiFileType,
    NoteOff,
    NoteOn,
    ProgramChange,
    ProjectStateModel,
    SetTempo,
    TimeSignature,
    Track,
    TrackChunk,
    TrackName,
    TrackNote,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { CalculationService } from './calculation.service'

@Injectable({
    providedIn: 'root',
})
export class MidiService implements OnDestroy {
    private readonly logger = Logger.createLogger('MidiService')
    songNotes: Record<string, TrackNote[]>
    projectState: ProjectStateModel
    songLength: number
    private readonly metaEventEnd = new EndOfTrack(0)
    private songNotesSubscription: Subscription
    private projectStateSubscription: Subscription
    private songLengthSubscription: Subscription

    constructor(private store: Store<AppState>, private calculationService: CalculationService) {
        this.songNotesSubscription = this.store.select(selectProjectNotes).subscribe({
            next: (value) => (this.songNotes = value),
            error: (e) => this.logger.error(e),
        })
        this.projectStateSubscription = this.store.select('projectState').subscribe({
            next: (value) => (this.projectState = value),
            error: (e) => this.logger.error(e),
        })
        this.songLengthSubscription = this.store.select(selectSongEnd).subscribe({
            next: (value) => (this.songLength = value),
            error: (e) => this.logger.error(e),
        })
    }

    ngOnDestroy(): void {
        this.songNotesSubscription.unsubscribe()
        this.projectStateSubscription.unsubscribe()
        this.songLengthSubscription.unsubscribe()
    }

    public exportMidi() {
        // length + 1 for tempTrack
        const headerChunk = new HeaderChunk(MidiFileType.MULTI, this.getTracks().length + 1, 96)

        const timeSignatureEvent = new TimeSignature(0, 4, 2, 24, 8)
        const microSeconds = this.calculationService.getMicrosecondsPerBar()
        // tempo track
        const setTempoEvent = new SetTempo(0, microSeconds)

        const tempoTrackChunk = new TrackChunk([timeSignatureEvent, setTempoEvent, this.metaEventEnd])

        const trackChunkHex = this.generateTrackChunks()
            .map((tc) => tc.toHex())
            .reduce((a, b) => a + b)
        return headerChunk.toHex() + tempoTrackChunk.toHex() + trackChunkHex
    }

    private getTracks(): Track[] {
        return Object.values(this.projectState.tracks.entities)
    }

    private generateTrackChunks(): TrackChunk[] {
        return this.getTracks().map((track) => {
            // Set Name
            const trackNameMetaEvent = new TrackName(0, track.instrument)
            // Set Channel
            const instrID = Object.values(Instruments).indexOf(track.instrument)
            const programChangeEvent = new ProgramChange(0, track.channel, instrID)

            const notes = this.generateAbsoluteNoteOnOffEvents(track.channel)
                // calculate delta time relative to last element
                .map((trackNote, index, array) => {
                    const lastElement = index > 0 ? array[index - 1] : undefined
                    // calculates the time difference to the last note, 0 if it is the same timeIndex
                    const delta = lastElement ? trackNote.deltaTime - lastElement.deltaTime : trackNote.deltaTime

                    // we need to do this to pass the calculated value in a new reference,
                    // cause we will mess up the calculation if we manipulate the objects.
                    // Javascript passes simple data types by value, Objects by reference.
                    // We also cannot clone the object easily since we need to also clone
                    // the toHex() function.
                    if (trackNote instanceof NoteOn) {
                        return new NoteOn(delta, trackNote.channel, trackNote.param1, trackNote.param2)
                    } else if (trackNote instanceof NoteOff) {
                        return new NoteOff(delta, trackNote.channel, trackNote.param1, trackNote.param2)
                    } else {
                        this.logger.error('critical note parsing error. skipping note.')
                    }
                })

            return new TrackChunk([trackNameMetaEvent, programChangeEvent, ...notes, this.metaEventEnd])
        })
    }

    private generateAbsoluteNoteOnOffEvents(channel: number) {
        return (
            Object.values(this.songNotes)
                .reduce((a, b) => a.concat(b), [])
                // filter for current channel
                .filter((n) => n.channel === channel)
                // generate absolute note on and note off objects
                .map((trackNote, index, array) => {
                    return [
                        new NoteOn(trackNote.startIRN, trackNote.channel, trackNote.key, trackNote.velocity),
                        new NoteOff(trackNote.startIRN + trackNote.lengthIRN, trackNote.channel, trackNote.key, 0),
                    ]
                })
                .reduce((a, b) => a.concat(b), [])
                // sort for delta time
                .sort((a, b) => {
                    // if delta times equal sort by NoteType
                    // NoteOn > NoteOff
                    if (a.deltaTime === b.deltaTime) {
                        if (a instanceof NoteOn && b instanceof NoteOff) {
                            return +1
                        } else if (a instanceof NoteOff && b instanceof NoteOn) {
                            return -1
                        }
                    }

                    // sort by delta time
                    return a.deltaTime - b.deltaTime
                })
        )
    }
}
