import { MidiEvent } from './MidiEvent'
import { EventType } from '../enums/EventType'
import { Hexecutioner } from '../../utils/Hexecutioner'

export abstract class ChannelEvent extends MidiEvent {
    channel: number
    param1: number
    param2: number

    constructor(deltaTime: number, eventType: EventType, channel: number, param1: number, param2?: number) {
        super(deltaTime, eventType)
        this.channel = channel
        this.param1 = param1
        this.param2 = param2
    }

    toHex(): string {
        let deltaTime = Hexecutioner.numberToVariableMidiHex(this.deltaTime, 2)
        let type = Hexecutioner.numberToHex(this.eventType)
        let channel = Hexecutioner.numberToHex(this.channel)
        let p1 = Hexecutioner.numberToHex(this.param1, 2)
        let p2 = this.param2 != undefined ? Hexecutioner.numberToHex(this.param2, 2) : ''
        return `${deltaTime}${type}${channel}${p1}${p2}`
    }
}
