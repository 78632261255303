import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { featureFlagsController } from './feature-flags'

/**
 * Directive to show/hide blocks depending on feature flag
 */
@Directive({ selector: '[featureFlag]' })
export class FeatureFlagDirective implements OnInit {
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    flagName: string

    @Input() set featureFlag(flagName: string) {
        this.flagName = flagName
    }

    ngOnInit() {
        if (featureFlagsController.enabled(this.flagName)) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        } else {
            this.viewContainer.clear()
        }
    }
}
