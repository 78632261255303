import { ChordType } from './definitions'

export function getChordAbbreviation(chord: ChordType): string {
    switch (chord) {
        // MAJOR
        case ChordType.MAJOR:
            return 'M'
        case ChordType.MAJOR_FULL:
            return 'M.e'
        case ChordType.MAJOR_7:
            return 'M⁷'
        case ChordType.MAJOR_9:
            return 'M⁹'
        case ChordType.MAJOR_11:
            return 'M¹¹'
        case ChordType.MAJOR_13:
            return 'M¹³'
        // MINOR
        case ChordType.MINOR:
            return 'm'
        case ChordType.MINOR_FULL:
            return 'm.e'
        case ChordType.MINOR_7:
            return 'm⁷'
        case ChordType.MINOR_9:
            return 'm⁹'
        case ChordType.MINOR_11:
            return 'm¹¹'
        case ChordType.MINOR_13:
            return 'm¹³'
        // OTHER
        case ChordType.FIVE:
            return '5'
        case ChordType.SEVEN:
            return '7'
        case ChordType.AUG:
            return 'aug'
        case ChordType.DIM:
            return 'dim'
        case ChordType.SUS_2:
            return 's.2'
        case ChordType.SUS_4:
            return 's.4'
        default:
            return ''
    }
}
