import { Action } from '@ngrx/store'
import { PrToolEnum, ProjectToolEnum, ChordType, DisplayNote } from '@tekbox-coco/midiative-commons'

export enum UIStateActionTypes {
    SetFollowCursor = '[UI] SetFollowCursor',
    SetPrDrawerVisible = '[UI] SetPrDrawerVisible',
    SetPrDrawerOpen = '[UI] SetPrDrawerOpen',
    SetPrToolbarVisible = '[UI] SetPrToolbarVisible',
    SetPrToolSelectionOpen = '[UI] SetPrToolSelectionOpen',

    SetPrMenuLoadPatternVisible = '[UI] SetPrMenuLoadPatternVisible',
    SetPrModalSavePatternVisible = '[UI] SetPrModalSavePatternVisible',
    SetPrModalUnsavedPatternVisible = '[UI] SetPrModalUnsavedPatternVisible',
    SetPreScrollEnabled = '[UI] SetPreScrollEnabled',

    SetPrClipboard = '[UI] SetPrClipboard',

    SetPrSelectedTool = '[UI] SetPrSelectedTool',
    SetPrSelectedChordType = '[UI] SetPrSelectedChordType',

    AddPrSelectedNote = '[UI] AddPrSelectedNote',
    RemovePrSelectedNote = '[UI] RemovePrSelectedNote',
    ResetPrSelectedNotes = '[UI] ResetPrSelectedNotes',
    PrNoteUpdateDeltas = '[UI] PrNoteUpdateDeltas',
    SetPrPatternMappingId = '[UI] SetPrPatternMappingId',
    PianoRollChordToolSetInversionLevel = '[UI] PianoRollChordToolSetInversionLevel',

    SetProjectDrawerVisible = '[UI] SetProjectDrawerVisible',
    SetProjectDrawerOpen = '[UI] SetProjectDrawerOpen',
    SetProjectToolbarVisible = '[UI] SetProjectToolbarVisible',
    SetProjectToolSelectionOpen = '[UI] SetProjectToolSelectionOpen',
    SetProjectSelectedPatternMapping = '[UI] SetProjectSelectedPatternMapping',

    SetProjectMenuSelectPatternOpen = '[UI] SetProjectMenuSelectPatternOpen',
    SetProjectModalUnsavedProjectVisible = '[UI] SetProjectModalUnsavedProjectVisible',

    SetProjectBarRenderWidth = '[UI] SetProjectBarRenderWidth',

    SetHomeModalDeleteProjectVisible = '[UI] SetHomeModalDeleteProjectVisible',

    SetPianoRollBarRenderWidth = '[UI] SetPianoRollBarRenderWidth',

    SetSnapToGrid = '[UI] SetSnapToGrid',

    StartCopyPattern = '[UI] StartCopyPattern',
    StartClonePattern = '[UI] StartClonePattern',

    SetProjectSelectedTool = '[UI] SetProjectSelectedTool',

    Reset = '[UI] Reset',
}

export class SetFollowCursorAction implements Action {
    readonly type = UIStateActionTypes.SetFollowCursor

    constructor(public payload: boolean) {}
}

export class SetPrDrawerVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetPrDrawerVisible

    constructor(public payload: boolean) {}
}

export class SetPrDrawerOpenAction implements Action {
    readonly type = UIStateActionTypes.SetPrDrawerOpen

    constructor(public payload: boolean) {}
}

export class SetPrToolbarVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetPrToolbarVisible

    constructor(public payload: boolean) {}
}

export class SetPrToolSelectionOpenAction implements Action {
    readonly type = UIStateActionTypes.SetPrToolSelectionOpen

    constructor(public payload: boolean) {}
}

export class SetPrMenuLoadPatternVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetPrMenuLoadPatternVisible

    constructor(public payload: boolean) {}
}

export class SetPrModalSavePatternVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetPrModalSavePatternVisible

    constructor(public payload: boolean) {}
}

export class SetPrModalUnsavedPatternVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetPrModalUnsavedPatternVisible

    constructor(public payload: boolean) {}
}

export class SetPrSelectedToolAction implements Action {
    readonly type = UIStateActionTypes.SetPrSelectedTool

    constructor(public payload: PrToolEnum) {}
}

export class SetPrSelectedChordTypeAction implements Action {
    readonly type = UIStateActionTypes.SetPrSelectedChordType

    constructor(public payload: ChordType) {}
}

export class SetPrPatternMappingIdAction implements Action {
    readonly type = UIStateActionTypes.SetPrPatternMappingId

    constructor(public payload: string) {}
}

export class AddPrSelectedNoteAction implements Action {
    readonly type = UIStateActionTypes.AddPrSelectedNote

    constructor(public payload: string) {}
}
export class RemovePrSelectedNoteAction implements Action {
    readonly type = UIStateActionTypes.RemovePrSelectedNote

    constructor(public payload: string) {}
}
export class ResetPrSelectedNotesAction implements Action {
    readonly type = UIStateActionTypes.ResetPrSelectedNotes

    constructor() {}
}
export class PrNoteUpdateDeltasAction implements Action {
    readonly type = UIStateActionTypes.PrNoteUpdateDeltas

    constructor(public delta: { x: number; y: number; resizeX: number }) {}
}
export class SetPreScrollEnabledAction implements Action {
    readonly type = UIStateActionTypes.SetPreScrollEnabled

    constructor(public enabled: boolean) {}
}

export class SetPrClipboardAction implements Action {
    readonly type = UIStateActionTypes.SetPrClipboard

    constructor(public payload: DisplayNote[]) {}
}

export class PianoRollChordToolSetInversionLevelAction implements Action {
    readonly type = UIStateActionTypes.PianoRollChordToolSetInversionLevel

    constructor(public level: number) {}
}
export class SetProjectDrawerVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetProjectDrawerVisible

    constructor(public payload: boolean) {}
}

export class SetProjectDrawerOpenAction implements Action {
    readonly type = UIStateActionTypes.SetProjectDrawerOpen

    constructor(public payload: boolean) {}
}

export class SetProjectToolbarVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetProjectToolbarVisible

    constructor(public payload: boolean) {}
}

export class SetProjectToolSelectionOpenAction implements Action {
    readonly type = UIStateActionTypes.SetProjectToolSelectionOpen

    constructor(public payload: boolean) {}
}

export class SetProjectMenuSelectPatternOpenAction implements Action {
    readonly type = UIStateActionTypes.SetProjectMenuSelectPatternOpen

    constructor(public payload: boolean) {}
}

export class SetProjectModalUnsavedProjectVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetProjectModalUnsavedProjectVisible

    constructor(public payload: boolean) {}
}

export class SetProjectSelectedPatternMappingAction implements Action {
    readonly type = UIStateActionTypes.SetProjectSelectedPatternMapping

    constructor(public payload: string) {}
}

export class SetHomeModalDeleteProjectVisibleAction implements Action {
    readonly type = UIStateActionTypes.SetHomeModalDeleteProjectVisible

    constructor(public payload: boolean) {}
}

export class SetProjectBarRenderWidthAction implements Action {
    readonly type = UIStateActionTypes.SetProjectBarRenderWidth

    constructor(public payload: number) {}
}

export class SetPianoRollBarRenderWidthAction implements Action {
    readonly type = UIStateActionTypes.SetPianoRollBarRenderWidth

    constructor(public payload: number) {}
}

export class SetSnapToGridAction implements Action {
    readonly type = UIStateActionTypes.SetSnapToGrid

    constructor(public payload: boolean) {}
}

export class StartCopyPatternAction implements Action {
    readonly type = UIStateActionTypes.StartCopyPattern

    constructor(public payload: string) {}
}

export class StartClonePatternAction implements Action {
    readonly type = UIStateActionTypes.StartClonePattern

    constructor(public payload: string) {}
}

export class SetProjectSelectedToolAction implements Action {
    readonly type = UIStateActionTypes.SetProjectSelectedTool

    constructor(public payload: ProjectToolEnum) {}
}
export class UiResetAction implements Action {
    readonly type = UIStateActionTypes.Reset

    constructor() {}
}

export type UiStateActions =
    | SetFollowCursorAction
    | SetPrDrawerVisibleAction
    | SetPrDrawerOpenAction
    | SetPrToolbarVisibleAction
    | SetPrToolSelectionOpenAction
    | SetPrMenuLoadPatternVisibleAction
    | SetPrModalSavePatternVisibleAction
    | SetPrModalUnsavedPatternVisibleAction
    | SetPrSelectedToolAction
    | SetPrSelectedChordTypeAction
    | SetPreScrollEnabledAction
    | SetPrClipboardAction
    | SetProjectDrawerVisibleAction
    | SetProjectDrawerOpenAction
    | SetProjectToolbarVisibleAction
    | SetProjectToolSelectionOpenAction
    | SetProjectMenuSelectPatternOpenAction
    | SetProjectModalUnsavedProjectVisibleAction
    | SetHomeModalDeleteProjectVisibleAction
    | SetPianoRollBarRenderWidthAction
    | SetProjectBarRenderWidthAction
    | SetSnapToGridAction
    | StartCopyPatternAction
    | StartClonePatternAction
    | SetProjectSelectedToolAction
    | SetProjectSelectedPatternMappingAction
    | AddPrSelectedNoteAction
    | RemovePrSelectedNoteAction
    | ResetPrSelectedNotesAction
    | SetPrPatternMappingIdAction
    | PrNoteUpdateDeltasAction
    | PianoRollChordToolSetInversionLevelAction
    | UiResetAction
