import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from './button.component'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ActionButtonComponent } from './action-button.component'
import { MatRippleModule } from '@angular/material/core'

@NgModule({
    declarations: [ButtonComponent, ActionButtonComponent],
    exports: [ButtonComponent, ActionButtonComponent],
    imports: [CommonModule, MatIconModule, TranslateModule, MatRippleModule],
})
export class ButtonModule {}
