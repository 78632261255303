import { NgModule } from '@angular/core'
import { NoteNamePipe } from './note-name.pipe'
import { CommonModule } from '@angular/common'

@NgModule({
    declarations: [NoteNamePipe],
    exports: [NoteNamePipe],
    imports: [CommonModule],
})
export class NoteNamePipeModule {}
