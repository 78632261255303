import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    ActionTask,
    DefaultAction,
    UiStateModel,
    initialUIState,
    Logger,
    GlobalPatternMapping,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { listPatternMappings } from '../../../store/reducers/project-state.reducer'
import { ToastService } from '../../../services/toast.service'
import { DeletePatternAction, DeletePatternMappingAction } from '../../../store/actions/project-state.actions'
import { ActionController } from '../../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'TrackPatternAction',
})
export abstract class TrackPatternAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('TrackPatternAction')

    protected uiState: UiStateModel = initialUIState
    protected globalPatterMappings: Record<string, GlobalPatternMapping> = {}

    constructor(protected store: Store<AppState>) {
        super()

        this.store.select('uiState').subscribe({
            next: (value) => {
                this.uiState = value
                this.onUiStateUpdate(this.uiState)
            },
            error: (e) => this.logger.error(e),
        })

        this.store.select(listPatternMappings).subscribe({
            next: (data: Record<string, GlobalPatternMapping>) => {
                // debugger
                this.globalPatterMappings = data
            },
            error: (e) => this.logger.error(e),
        })
    }

    protected onUiStateUpdate(state: UiStateModel) {}

    protected getPatterIdForMapping(mappingId: string): string {
        for (const pattern of Object.values(this.globalPatterMappings)) {
            const match = pattern.mappings.filter((e) => e.mappingId === mappingId)
            if (match.length > 0) {
                return pattern.patternId
            }
        }

        return null
    }

    protected getTrackForMapping(mappingId: string): string {
        for (const pattern of Object.values(this.globalPatterMappings)) {
            const match = pattern.mappings.filter((e) => e.mappingId === mappingId)
            if (match.length > 0) {
                return match[0].trackId
            }
        }

        return null
    }
}
