import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
import { TimelineComponent } from './timeline.component'

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule],
    declarations: [TimelineComponent],
    exports: [TimelineComponent],
})
export class TimelineModule {}
