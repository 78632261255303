<div
    class="track-pattern absolute inline-flex rounded-[10px]"
    [ngClass]="{ '!touch-none': selected, '!touch-auto': !selected }"
    [style]="trackPatternStyle"
    (click)="onClickPattern($event)"
    (click)="onLongPressPattern($event)"
    (tap)="onDoubleTap($event)"
    (pan)="onPan($event)"
    (panend)="onPanEnd($event)"
>
    <div [style]="trackPatternActionWrapperStyle">
        <!--- has copies indicator -->
        <div *ngIf="hasCopies" class="track-pattern__status">
            <mat-icon class="mat-icon-20">content_copy</mat-icon>
        </div>
        <div class="flex h-full items-center justify-center" *ngIf="selected"></div>
    </div>
</div>
