import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ModalActionButton } from './modal.component'

export interface Type<T> extends Function {
    new (...args: any[]): T
}

export interface ModalMessage {
    type: 'default' | 'component-ref'

    // type: default
    headline?: string
    text?: string

    // type: 'component-ref'
    componentRef?: Type<any>

    // common
    actions?: ModalActionButton[]
    allowClose?: boolean
    onClose(): void

    // check message is still valid to show
    isValid?: (message: ModalMessage) => boolean
}

export interface ModalOptionsMessage<T> {
    headline: string
    text: string
    allowClose: boolean
    options: { label: string; value: T }[]
}

/**
 * Service to display dialog messages from code.
 */
@Injectable({
    providedIn: 'root',
})
export class ModalService {
    public modalMessages: BehaviorSubject<ModalMessage> = new BehaviorSubject<ModalMessage>(null)

    /**
     * Display dialog without callback
     * @param message message to display
     */
    public showDialog(message: ModalMessage) {
        this.modalMessages.next(message)
    }

    /**
     * Displays dialog and returns promise that resolves to options value
     * @param message
     */
    public showOptionsDialog<T>(message: ModalOptionsMessage<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.showDialog({
                ...message,
                type: 'default',
                onClose: () => resolve(null),
                actions: message.options.map((option) => {
                    return {
                        label: option.label,
                        callback: () => resolve(option.value),
                    }
                }),
            })
        })
    }
}
