import { Injectable } from '@angular/core'
import { ActionTask } from '@tekbox-coco/midiative-commons'
import { DefaultAction, AppState } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { SetSnapToGridAction } from '../../store/actions/ui-state.actions'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ToggleSnapToGridAction',
})
export class ToggleSnapToGridAction extends DefaultAction implements ActionTask {
    private snapToGrid: boolean = false

    constructor(private store: Store<AppState>) {
        super()

        this.icon.next('border_left')
        this.domID.next('project_snap-to-grid')

        this.store.select('uiState').subscribe({
            next: (value) => {
                this.snapToGrid = value.snapToGrid
                if (this.snapToGrid) {
                    this.buttonClasses.next(['bg-cyan-50'])
                } else {
                    this.buttonClasses.next([])
                }
            },
        })
    }

    onClick() {
        if (this.snapToGrid) {
            this.store.dispatch(new SetSnapToGridAction(false))
        } else {
            this.store.dispatch(new SetSnapToGridAction(true))
        }
    }
}
