import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InfoPanelComponent } from './info-panel.component'
import { BarPipeModule } from '@tekbox-coco/midiative-commons'

@NgModule({
    declarations: [InfoPanelComponent],
    exports: [InfoPanelComponent],
    imports: [CommonModule, BarPipeModule],
})
export class InfoPanelModule {}
