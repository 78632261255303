import { Injectable } from '@angular/core'
import { DefaultAction, PianoRollStateModel, Logger, UiStateModel, AppState } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'
import { Store } from '@ngrx/store'
import { PianoRollChordToolSetInversionLevelAction, SetPrClipboardAction } from '../../store/actions/ui-state.actions'
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollDisplayInversionLevelAction',
})
export class PianoRollDisplayInversionLevelAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollDisplayInversionLevelAction')
    private uiState: UiStateModel

    constructor(private store: Store<AppState>) {
        super()

        this.subscriptions.push(
            this.store.select('uiState').subscribe({
                next: async (value) => {
                    this.uiState = value
                    this.label.next(`${this.uiState.prChordToolInversionLevel}`)
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    onClick() {
        this.store.dispatch(new PianoRollChordToolSetInversionLevelAction(0))
    }
}
