import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToolSelectionMenuComponent } from './tool-selection-menu.component'
import { MatIconModule } from '@angular/material/icon'
import { IonicModule } from '@ionic/angular'
import { MatRippleModule } from '@angular/material/core'

@NgModule({
    imports: [CommonModule, MatIconModule, IonicModule, MatRippleModule],
    declarations: [ToolSelectionMenuComponent],
    exports: [ToolSelectionMenuComponent],
})
export class ToolSelectionMenuModule {}
