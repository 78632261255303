import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { ActionTask, HudActionButtonConfig, Logger } from '@tekbox-coco/midiative-commons'
import { timer } from 'rxjs'

@Component({
    selector: 'app-tile-menu',
    templateUrl: './tile-menu.component.html',
})
export class TileMenuComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('menu') menuDiv: ElementRef<HTMLDivElement>
    @Input() menuItems: (string | HudActionButtonConfig)[]
    private readonly logger = Logger.createLogger('TileMenuComponent')

    renderedMenuItems: HudActionButtonConfig[] = []

    @Output() onClickItem: EventEmitter<string> = new EventEmitter()

    @Input()
    itemsPerRow = 4

    // height 0 will be ignored
    @Input()
    itemHeight = 0

    visibleMenuItems: (string | HudActionButtonConfig)[] = []

    itemStyle: Record<string, string> = {}

    constructor(private injector: Injector) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menuItems) {
            this.updateRenderedMenuItems()
        }
    }

    ngOnInit(): void {
        this.updateRenderedMenuItems()
    }

    onClickActionButton($event: string) {
        this.onClickItem.emit($event)
    }

    async ngAfterViewInit() {
        let width: number = await this.waitForWidth()
        let itemWidth = Math.floor(width / this.itemsPerRow)
        this.itemStyle = {
            width: `${itemWidth}px`,
        }
        if (this.itemHeight != 0) {
            this.itemStyle = {
                ...this.itemStyle,
                height: `${this.itemHeight}px`,
            }
        }
    }

    waitForWidth(): Promise<number> {
        return new Promise((resolve) => {
            const width = this.menuDiv.nativeElement.offsetWidth
            if (width === 0) {
                timer(50).subscribe((subscribe) => resolve(this.waitForWidth()))
            } else {
                resolve(width)
            }
        })
    }

    private updateRenderedMenuItems() {
        this.renderedMenuItems = this.menuItems
            .map((e) => {
                if (typeof e === 'string') {
                    return {
                        action: e,
                    }
                    return e
                }
            })
            .filter((e) => e != undefined)
            .filter((item) => {
                const actionTask = <ActionTask>this.injector.get((item as HudActionButtonConfig).action)
                if (actionTask) {
                    return actionTask.visible.value
                }
                return false
            }) as HudActionButtonConfig[]
    }
}
