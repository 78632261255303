import { Injectable, OnDestroy } from '@angular/core'
import {
    DefaultAction,
    Logger,
    PianoRollStateModel,
    UiStateModel,
    getRandomId,
    calcMissingBars,
    ProjectStateModel,
    MAX_GRID_RESOLUTION,
    calcTimeSignature,
    searchMappings,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { ModalService } from '@tekbox-coco/midiative-components'
import { ActionController } from '../actions.decorator'
import { Store } from '@ngrx/store'
import { AudioService } from '../../services/audio/audio.service'
import { AddNotesAction, SetPatternLengthIRNAction } from '../../store/actions/piano-roll-state.actions'
import { UpdateAllMappingsForPatternAction } from '../../store/actions/project-state.actions'
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollPasteNotesAction',
})
export class PianoRollPasteNotesAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollPasteNotesAction')
    private pianoRollState: PianoRollStateModel
    private uiState: UiStateModel
    private projectState: ProjectStateModel

    constructor(
        private store: Store<AppState>,
        private audioService: AudioService,
        private modalService: ModalService
    ) {
        super()

        this.label.next('COMMON.PASTE')
        this.icon.next('insert_drive_file')

        this.subscriptions.push(
            this.store.select('pianoRollState').subscribe({
                next: async (value) => {
                    this.pianoRollState = value
                },
                error: (e) => this.logger.error(e),
            }),

            this.store.select('uiState').subscribe({
                next: async (value) => {
                    this.uiState = value
                },
                error: (e) => this.logger.error(e),
            }),

            this.store.select('projectState').subscribe({
                next: async (value) => {
                    this.projectState = value
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    private addBars(noBars: number): void {
        const patternLength =
            this.pianoRollState.patternLengthIRN +
            noBars *
                (MAX_GRID_RESOLUTION *
                    calcTimeSignature(
                        this.projectState.timeSignatureNumerator,
                        this.projectState.timeSignatureDenominator
                    ))

        const patternRelatedTrackMapping = searchMappings(this.pianoRollState.patternId, this.projectState)

        this.store.dispatch(new SetPatternLengthIRNAction(patternLength))
        this.store.dispatch(
            new UpdateAllMappingsForPatternAction(patternRelatedTrackMapping.patternId, {
                ...patternRelatedTrackMapping,
                length: patternLength,
            })
        )
    }

    onClick() {
        this.logger.info('PianoRollPasteNotesAction called')
        if (this.uiState.prClipboard.length > 0) {
            const clipboard = [...this.uiState.prClipboard]
            const lowestIRNInClipboard = clipboard.sort((a, b) => a.startIRN - b.startIRN)[0].startIRN
            const endNoteInClipboard = clipboard.sort(
                (a, b) => b.startIRN + b.lengthIRN - (a.startIRN + a.lengthIRN)
            )[0]
            const relativeEndIRN =
                endNoteInClipboard.startIRN +
                endNoteInClipboard.lengthIRN -
                lowestIRNInClipboard +
                this.audioService.cursorPosition.getValue()

            // subtract lowest startIRN from every clipboard entry and add cursorPosition
            const newNotes = clipboard.map((n) => ({
                ...n,
                id: getRandomId(),
                startIRN: n.startIRN - lowestIRNInClipboard + this.audioService.cursorPosition.getValue(),
            }))

            if (this.pianoRollState.patternLengthIRN < relativeEndIRN) {
                const errorCallback = () =>
                    this.logger.debug('enlarge pattern popup was dismissed by user while pasting a too big clipboard')
                const successCallBack = () => {
                    const barsToAdd: number = calcMissingBars(
                        relativeEndIRN,
                        this.pianoRollState.patternLengthIRN,
                        this.projectState.timeSignatureNumerator,
                        this.projectState.timeSignatureDenominator
                    )

                    this.addBars(barsToAdd)
                    this.store.dispatch(new AddNotesAction(newNotes))
                }
                this.modalService.showDialog({
                    type: 'default',
                    headline: 'PIANO_ROLL.MODAL.ENLARGE_PATTERN',
                    text: '',
                    allowClose: true,
                    actions: [
                        { label: 'COMMON.YES', callback: () => successCallBack() },
                        { label: 'COMMON.NO', callback: () => errorCallback() },
                    ],
                    onClose: () => errorCallback(),
                })
            } else {
                this.store.dispatch(new AddNotesAction(newNotes))
            }
        } else {
            this.logger.debug('Could not paste. Piano Roll clipboard is empty.')
        }
    }
}
