import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { MusicTheory } from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-piano',
    templateUrl: './piano.component.html',
    styleUrls: ['./piano.component.scss'],
})
export class PianoComponent implements OnInit, OnDestroy {
    @Input()
    highlightedKeys: BehaviorSubject<number[]>

    @Output()
    keyPressed = new EventEmitter<number>()

    readonly keys = Array.from(Array(96).keys())
        .reverse()
        .map((e) => e + 12) // pitch one octave to fit the app

    private readonly leftOuterWhiteKeys = [0, 5] // C,F
    private readonly middleLeftWhiteKeys = [7] // G
    private readonly middleWhiteKeys = [2] // D
    private readonly middleRightWhiteKeys = [9] // A
    private readonly rightOuterWhiteKeys = [4, 11] // E, B

    private readonly leftOuterBlackKeys = [1, 6] // C#, F#
    private readonly middleBlackKeys = [8] // G#
    private readonly rightOuterBlackKeys = [3, 10] // D#, A#

    private highlightedKeysSubscription: Subscription
    pressedKeysByDevice: number[]

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.highlightedKeysSubscription = this.highlightedKeys.subscribe((value) => {
            this.pressedKeysByDevice = value
            this.cd.detectChanges()
        })
    }

    ngOnDestroy(): void {
        this.highlightedKeysSubscription.unsubscribe()
    }

    getClassForKey(key: number): string {
        // back and white keys
        const keyType = key % 12
        if (this.leftOuterWhiteKeys.includes(keyType)) {
            return 'left-outer-white-key white-key'
        } else if (this.middleLeftWhiteKeys.includes(keyType)) {
            return 'middle-left-white-key white-key'
        } else if (this.middleWhiteKeys.includes(keyType)) {
            return 'middle-white-key white-key'
        } else if (this.middleRightWhiteKeys.includes(keyType)) {
            return 'middle-right-white-key white-key'
        } else if (this.rightOuterWhiteKeys.includes(keyType)) {
            return 'right-outer-white-key white-key'
        } else if (this.leftOuterBlackKeys.includes(keyType)) {
            return 'left-outer-black-key black-key'
        } else if (this.middleBlackKeys.includes(keyType)) {
            return 'middle-black-key black-key'
        } else if (this.rightOuterBlackKeys.includes(keyType)) {
            return 'right-outer-black-key black-key'
        } else {
            return ''
        }
    }

    getNoteName(key: number) {
        const mt = new MusicTheory(0, 88)
        return mt.getCompleteNoteString(key, true)
    }
}
