import { initialSettingsState, SettingsStateModel } from '@tekbox-coco/midiative-commons'
import { SettingsStateActions, SettingsStateActionTypes } from '../actions/settings-state.actions'

export function settingsStateReducer(state: SettingsStateModel = initialSettingsState, action: SettingsStateActions) {
    switch (action.type) {
        case SettingsStateActionTypes.LoadSettings: {
            return {
                ...state,
                ...action.payload,
            }
        }

        case SettingsStateActionTypes.SetShowScales: {
            return { ...state, showScales: action.payload }
        }

        case SettingsStateActionTypes.SetSoundfont: {
            return { ...state, soundFont: action.payload }
        }

        case SettingsStateActionTypes.SetLastMidiDeviceConnection: {
            return { ...state, lastMidiDeviceConnection: action.payload }
        }

        case SettingsStateActionTypes.SetLanguage: {
            return { ...state, language: action.payload }
        }

        case SettingsStateActionTypes.SetTutorialPassed: {
            return { ...state, passedTutorials: [...new Set([...state.passedTutorials, action.payload])] }
        }

        case SettingsStateActionTypes.ResetTutorials: {
            return { ...state, passedTutorials: [] }
        }

        default: {
            return state
        }
    }
}
