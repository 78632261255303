import { Injectable } from '@angular/core'
import { ActionController } from '../actions.decorator'
import { ActionTask, DefaultAction, IconToggleAction, PlatformService } from '@tekbox-coco/midiative-commons'
import { ShortcutOverlayService } from '../../services/shortcut-overlay/shortcut-overlay.service'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'OpenShortcutOverlayAction',
})
export class OpenShortcutOverlayAction extends IconToggleAction implements ActionTask {
    constructor(private shortcutOverlayService: ShortcutOverlayService, private platformService: PlatformService) {
        super('poll', 'poll')
        this.label.next('COMMON.SHORTCUTS')
        if (platformService.isNative()) {
            this.visible.next(false)
        } else {
            this.visible.next(true)
        }
    }

    onClickAction() {
        console.log('log')
        this.shortcutOverlayService.openOverlay()
        this.toggled.next(!this.toggled.getValue())
    }
    onClickActionToggled() {
        this.shortcutOverlayService.closeOverlay()
        this.toggled.next(!this.toggled.getValue())
    }
}
