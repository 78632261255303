import { NgModule } from '@angular/core'
import { TrackHeaderComponent } from './track-header.component'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../button/button.module'

@NgModule({
    imports: [CommonModule, IonicModule, MatIconModule, ButtonModule],
    declarations: [TrackHeaderComponent],
    exports: [TrackHeaderComponent],
})
export class TrackHeaderModule {}
