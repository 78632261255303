import { Component, Input, OnDestroy } from '@angular/core'
import { MixerService } from './mixer.service'
import { Instruments } from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-mixer',
    templateUrl: './mixer.component.html',
})
export class MixerComponent implements OnDestroy {
    @Input()
    isOpen: boolean = false

    constructor(public mixerService: MixerService) {}

    ngOnDestroy(): void {}

    changedVolume(trackID: string, volume: number) {
        this.mixerService.changedVolume(trackID, volume)
    }

    clickedMute(trackID: string) {
        this.mixerService.muteTrack(trackID)
    }

    clickedSolo(trackID: string) {
        this.mixerService.soloTrack(trackID)
    }

    changedInstrument(value: Instruments) {}

    /**
     * Resolves all available instruments from Instruments enum
     */
    get instruments(): string[] {
        return Object.keys(Instruments).map((x) => x.toLowerCase())
    }
}
