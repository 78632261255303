import { Action } from '@ngrx/store'
import {
    Color,
    KeyType,
    Pattern,
    ProjectStateModel,
    ScaleType,
    Track,
    TrackPatternMapping,
    TrackPatternMappingOptions,
} from '@tekbox-coco/midiative-commons'
import { Update } from '@ngrx/entity'

export enum ProjectStateActionTypes {
    LoadProject = '[Project] LoadProject',
    ClearProject = '[Project] ClearProject',
    SetProjectId = '[Project] SetProjectId',
    GenerateNewProjectId = '[Project] GenerateNewProjectId',
    DeactivateDemoMode = '[Project] DeactivateDemoMode',
    SetName = '[Project] SetName',
    SetBPM = '[Project] SetBPM',
    SetTimeSignatureNumerator = '[Project] SetTimeSignatureNumerator',
    SetTimeSignatureDenominator = '[Project] SetTimeSignatureDenominator',
    SetKey = '[Project] SetKey',
    SetScale = '[Project] SetScale',
    // Patterns
    SetPatterns = '[Project] SetPatterns',
    ClearPatterns = '[Project] ClearPatterns',
    AddPattern = '[Project] AddPattern',
    AddPatterns = '[Project] AddPatterns',
    UpsertPattern = '[Project] UpsertPattern',
    UpsertPatterns = '[Project] UpsertPatterns',
    UpdatePattern = '[Project] UpdatePattern',
    UpdatePatterns = '[Project] UpdatePatterns',
    DeletePattern = '[Project] DeletePattern',
    DeletePatternMapping = '[Project] DeletePatternMapping',
    DeletePatterns = '[Project] DeletePatterns',
    SetPatternColor = '[Project] SetPatternColor',
    CutPattern = '[Project] CutPattern',

    // Tracks
    AddTrack = '[Project] AddTrack',
    DeleteTrack = '[Project] DeleteTrack',
    UpdateTrack = '[Project] UpdateTrack',
    UpdateAllMappingsForPattern = '[Project] UpdateAllMappingsForPattern',
    UpsertTrack = '[Project] UpsertTrack',

    SetLoopSelectorStartIRN = '[Project - LoopSelector] SetLoopSelectorStartIRN',
    SetLoopSelectorEndIRN = ' [Project - LoopSelector] SetLoopSelectorEndIRN',
    SetLoopSelectorActive = '[Project - LoopSelector] SetLoopSelectorActive',
    HideLoopSelector = '[Project - LoopSelector] HideLoopSelector',
    ResetLoopSelector = '[Project - LoopSelector] ResetLoopSelector',
}
export class LoadProjectAction implements Action {
    readonly type = ProjectStateActionTypes.LoadProject

    constructor(public payload: ProjectStateModel) {}
}

export class ClearProjectAction implements Action {
    readonly type = ProjectStateActionTypes.ClearProject
}

export class SetProjectIdAction implements Action {
    readonly type = ProjectStateActionTypes.SetProjectId

    constructor(public payload: string) {}
}

export class GenerateNewProjectIdAction implements Action {
    readonly type = ProjectStateActionTypes.GenerateNewProjectId
}

export class DeactivateDemoModeAction implements Action {
    readonly type = ProjectStateActionTypes.DeactivateDemoMode
}

export class SetNameAction implements Action {
    readonly type = ProjectStateActionTypes.SetName

    constructor(public payload: string) {}
}

export class SetBPMAction implements Action {
    readonly type = ProjectStateActionTypes.SetBPM

    constructor(public payload: number) {}
}

export class SetTimeSignatureNumeratorAction implements Action {
    readonly type = ProjectStateActionTypes.SetTimeSignatureNumerator

    constructor(public payload: number) {}
}
export class SetTimeSignatureDenominatorAction implements Action {
    readonly type = ProjectStateActionTypes.SetTimeSignatureDenominator

    constructor(public payload: number) {}
}

export class SetKeyAction implements Action {
    readonly type = ProjectStateActionTypes.SetKey

    constructor(public payload: KeyType) {}
}

export class SetScaleAction implements Action {
    readonly type = ProjectStateActionTypes.SetScale

    constructor(public payload: ScaleType) {}
}

// Patterns

export class SetPatternColorAction implements Action {
    readonly type = ProjectStateActionTypes.SetPatternColor

    constructor(public patternId: string, public color: Color) {}
}

export class SetPatternsAction implements Action {
    readonly type = ProjectStateActionTypes.SetPatterns

    constructor(public payload: Pattern[]) {}
}

export class ClearPatternsAction implements Action {
    readonly type = ProjectStateActionTypes.ClearPatterns
}

export class AddPatternAction implements Action {
    readonly type = ProjectStateActionTypes.AddPattern

    constructor(public payload: Pattern) {}
}

export class AddPatternsAction implements Action {
    readonly type = ProjectStateActionTypes.AddPatterns

    constructor(public payload: Pattern[]) {}
}

export class UpsertPatternAction implements Action {
    readonly type = ProjectStateActionTypes.UpsertPattern

    constructor(public payload: Pattern) {}
}

export class UpsertPatternsAction implements Action {
    readonly type = ProjectStateActionTypes.UpsertPatterns

    constructor(public payload: Pattern[]) {}
}

export class UpdatePatternAction implements Action {
    readonly type = ProjectStateActionTypes.UpdatePattern

    constructor(public payload: Update<Pattern>) {}
}

export class UpdatePatternsAction implements Action {
    readonly type = ProjectStateActionTypes.UpdatePatterns

    constructor(public payload: Update<Pattern>[]) {}
}

export class DeletePatternMappingAction implements Action {
    readonly type = ProjectStateActionTypes.DeletePatternMapping

    constructor(public trackId: string, public mappingId: string) {}
}

export class DeletePatternAction implements Action {
    readonly type = ProjectStateActionTypes.DeletePattern

    constructor(public payload: string) {}
}

export class DeletePatternsAction implements Action {
    readonly type = ProjectStateActionTypes.DeletePatterns

    constructor(public payload: string[]) {}
}

export class CutPatternAction implements Action {
    readonly type = ProjectStateActionTypes.CutPattern
    constructor(
        public payload: {
            track: Track
            trackPatternMapping: TrackPatternMapping
            relativeCutIRN: number
            newPatternColor: Color
        }
    ) {}
}

// Tracks
export class AddTrackAction implements Action {
    readonly type = ProjectStateActionTypes.AddTrack

    constructor(public payload: Track) {}
}

export class DeleteTrackAction implements Action {
    readonly type = ProjectStateActionTypes.DeleteTrack

    constructor(public payload: string) {}
}

export class UpsertTrackAction implements Action {
    readonly type = ProjectStateActionTypes.UpsertTrack

    constructor(public payload: Track) {}
}

export class UpdateTrackAction implements Action {
    readonly type = ProjectStateActionTypes.UpdateTrack

    constructor(public payload: Update<Track>) {}
}

/**
 * Search for pattern id in all track pattern mappings and update options
 */
export class UpdateAllMappingsForPatternAction implements Action {
    readonly type = ProjectStateActionTypes.UpdateAllMappingsForPattern

    constructor(public patternId, public options: TrackPatternMappingOptions) {}
}

export class SetLoopSelectorStartIRNAction implements Action {
    readonly type = ProjectStateActionTypes.SetLoopSelectorStartIRN

    constructor(public payload: number) {}
}

export class SetLoopSelectorEndIRNAction implements Action {
    readonly type = ProjectStateActionTypes.SetLoopSelectorEndIRN

    constructor(public payload: number) {}
}

export class HideLoopSelectorAction implements Action {
    readonly type = ProjectStateActionTypes.HideLoopSelector
}

export class SetLoopSelectorActiveAction implements Action {
    readonly type = ProjectStateActionTypes.SetLoopSelectorActive

    constructor(public payload: boolean) {}
}

export class ResetLoopSelectorAction implements Action {
    readonly type = ProjectStateActionTypes.ResetLoopSelector

    constructor() {}
}

export type ProjectStateActions =
    | LoadProjectAction
    | ClearProjectAction
    | SetProjectIdAction
    | GenerateNewProjectIdAction
    | DeactivateDemoModeAction
    | SetNameAction
    | SetBPMAction
    | SetTimeSignatureNumeratorAction
    | SetTimeSignatureDenominatorAction
    | SetKeyAction
    | SetScaleAction
    | SetPatternsAction
    | ClearPatternsAction
    | AddPatternAction
    | AddPatternsAction
    | UpsertPatternAction
    | UpsertPatternsAction
    | UpdatePatternAction
    | UpdatePatternsAction
    | DeletePatternAction
    | DeletePatternsAction
    | CutPatternAction
    | AddTrackAction
    | DeleteTrackAction
    | UpdateTrackAction
    | UpdateAllMappingsForPatternAction
    | UpsertTrackAction
    | SetLoopSelectorStartIRNAction
    | SetLoopSelectorEndIRNAction
    | HideLoopSelectorAction
    | SetLoopSelectorActiveAction
    | ResetLoopSelectorAction
    | DeletePatternMappingAction
    | SetPatternColorAction
