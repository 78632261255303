import { DefaultAction, PlatformService } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { App } from '@capacitor/app'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'CloseAppAction',
})
export class CloseAppAction extends DefaultAction {
    constructor(private platformService: PlatformService) {
        super()
        this.label.next('Close App')
        this.icon.next('close')
        this.visible.next(false)

        if (this.platformService.isAndroid()) {
            this.visible.next(true)
        }
    }
    onClick() {
        console.log('CLOSE APP')
        App.exitApp()
    }
}
