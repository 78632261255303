import { DefaultAction } from './DefaultAction'
import { BehaviorSubject } from 'rxjs'
import { ActionTask } from '../ActionTask'

export abstract class IconToggleAction extends DefaultAction implements ActionTask {
    protected toggled: BehaviorSubject<boolean> = new BehaviorSubject(false)

    protected constructor(private iconName: string, private iconNameToggled: string) {
        super()

        this.icon.next(iconName)

        this.toggled.subscribe((toggled) => {
            if (toggled) {
                this.icon.next(this.iconNameToggled)
            } else {
                this.icon.next(this.iconName)
            }
        })
    }

    abstract onClickActionToggled()

    abstract onClickAction()

    onClick() {
        if (this.toggled.value) {
            this.onClickActionToggled()
        } else {
            this.onClickAction()
        }
    }
}
