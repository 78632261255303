import { DemoIds } from './demoIds'
import { HipHopDemoProject } from './hip-hop.demo'
import { PopDemoProject } from './pop.demo'
import { BalladDemoProject } from './ballad.demo'
import dreamyDemo from './dreamy.demo.json'

export const Demos = [
    {
        id: DemoIds.HIP_HOP,
        project: HipHopDemoProject,
    },
    {
        id: DemoIds.POP,
        project: PopDemoProject,
    },
    {
        id: DemoIds.BALLAD,
        project: BalladDemoProject,
    },
    {
        id: DemoIds.DREAMY,
        project: dreamyDemo,
    },
]
