export enum EventType {
    BankSelect = 0,
    ModulationWheel = 1,
    DataEntry = 6,
    ChannelVolume = 7,
    NoteOff = 8,
    NoteOn = 9,
    NoteAftertouch = 10,
    Controller = 11,
    ProgramChange = 12,
    ChannelAftertouch = 13,
    PitchBend = 14,

    BankSelect2 = 32,
    Undefined16 = 35,
    FootController = 36,
    DataEntry2 = 38,

    Undefined = 41,
    Pan = 42,
    ExpressionController = 43,
    EffectControl1 = 44,
    EffectControl2 = 45,
    Undefined2 = 46,
    Undefined17 = 47,
    GeneralPurposeController1 = 48,

    GeneralPurposeController2 = 49,
    GeneralPurposeController3 = 50,
    GeneralPurposeController4 = 51,
    Undefined3 = 52,
    Undefined4 = 53,
    Undefined5 = 54,
    Undefined6 = 55,
    Undefined7 = 56,
    Undefined8 = 57,
    Undefined9 = 58,
    Undefined10 = 59,
    Undefined11 = 60,
    Undefined12 = 61,
    Undefined13 = 62,
    Undefined14 = 63,
    Sustain = 64,
    Portamento = 65,
    Sustenuto = 66,
    SoftPedal = 67,
    Legato = 68,
    Hold2 = 69,
    SoundController1 = 70,
    SoundController2 = 71,
    SoundController3 = 72,
    SoundController4 = 73,
    SoundController5 = 74,
    SoundController6 = 75,
    SoundController7 = 76,
    SoundController8 = 77,
    SoundController9 = 78,
    SoundController10 = 79,

    Undefined15 = 90,
    Effects1Depth = 91,
    Effects2Depth = 92,
    Effects3Depth = 93,
    Effects4Depth = 94,
    Effects5Depth = 95,

    RegisteredParameterNumberLSB = 100,
    RegisteredParameterNumberMSB = 101,
    AllSoundOff = 120,
    AllNotesFff = 123,
    PolyModeOnInclMono = 127,

    SystemExclusiveEvents = 240,
    EscapeSequences = 247,

    MetaEvent = 255,
}
