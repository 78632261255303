import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import {
    BeatResolutionEnum,
    initialProjectState,
    initialUIState,
    MAX_GRID_RESOLUTION,
} from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
    public timelineStyle = { width: '0', background: 'red' }
    public beats = []

    @Input() irnOffset: number
    @Input() patternLengthIRN: number
    /**
     * @deprecated
     */
    @Input() cellWidth: number
    /**
     * @deprecated
     */
    @Input() cellHeight: number
    @Input() resolution: BeatResolutionEnum

    @Input() noBeats: number = initialProjectState.timeSignatureNumerator
    @Input() timeSignatureFraction: number =
        initialProjectState.timeSignatureNumerator / initialProjectState.timeSignatureDenominator

    @Input() barRenderWidth: number = initialUIState.prBarRenderWidth
    @Output() cursorPosChanged: EventEmitter<number> = new EventEmitter<number>()

    getTimelineStyle() {
        const noBars = this.patternLengthIRN / MAX_GRID_RESOLUTION
        const width = noBars * this.barRenderWidth
        const timeSignatureRelatedWidth = width * (1 / this.timeSignatureFraction)
        return {
            width: `${timeSignatureRelatedWidth}px`,
        }
    }

    calcIndicatorLeftPadding(index: number): string {
        // main beat indicator offsets
        if (index % this.noBeats === 0) {
            return `${(index / this.noBeats) * this.barRenderWidth}px`
        }
        const parentIndex = Math.ceil(index / this.noBeats) - 1
        const childIndex = index % this.noBeats
        // calc child beat indicator offset
        const parentOffset = parentIndex * this.barRenderWidth
        return `${parentOffset + (this.barRenderWidth / this.noBeats) * childIndex}px`
    }

    buildIndicatorText(index: number): string {
        const isIndex = index % this.noBeats === 0
        if (isIndex) {
            return `${index / this.noBeats + 1}`
        }
        return `${Math.ceil(index / this.noBeats)}.${(index % this.noBeats) + 1}`
    }

    getBeats() {
        return (
            new Array((this.patternLengthIRN / MAX_GRID_RESOLUTION) * this.noBeats * (1 / this.timeSignatureFraction))
                .fill(1)
                .map((v, index: number) => index)
                .map((index) => {
                    return {
                        style: {
                            // set left position of timeline indicator
                            left: this.calcIndicatorLeftPadding(index),
                            'font-size': `${index % this.noBeats === 0 ? 16 : 16 * 0.66}px`,
                            opacity: index % this.noBeats === 0 ? 1 : 0.66,
                        },
                        // set indicator text
                        text: this.buildIndicatorText(index),
                    }
                }) || []
        )
    }

    setPosition($event: MouseEvent): void {
        const bars = $event.offsetX / this.barRenderWidth
        const posIrn = bars * MAX_GRID_RESOLUTION
        const posIrnWithTimeSignature = posIrn * this.timeSignatureFraction
        this.cursorPosChanged.emit(posIrnWithTimeSignature + this.irnOffset)
    }
}
