import { Injectable, OnDestroy } from '@angular/core'
import { Logger, PlatformService } from '@tekbox-coco/midiative-commons'

@Injectable({
    providedIn: 'root',
})
export class ShortcutOverlayService implements OnDestroy {
    public isOpen: boolean = false
    private readonly logger = Logger.createLogger('ShortcutsOverlay')
    constructor(private platformService: PlatformService) {}
    closeOverlay() {
        this.isOpen = false
    }
    openOverlay(): void {
        this.logger.info('Shortcuts isOpen')
        this.isOpen = true
    }
    ngOnDestroy() {}
}
