import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { TrackPatternComponent } from './track-pattern.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
    imports: [CommonModule, IonicModule, MatIconModule],
    declarations: [TrackPatternComponent],
    exports: [TrackPatternComponent],
})
export class TrackPatternModule {}
