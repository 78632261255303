<div class="my-[5px] flex w-max flex-row">
    <!-- TRACK HEADER IS INJECTED HERE -->
    <ng-content select="[header]"></ng-content>

    <div id="track-note-section" class="flex-column flex">
        <div
            class="relative flex min-h-[150px] flex-row self-center"
            [style]="patternWrapperStyle"
            (click)="onClickTrack($event)"
        >
            <!-- TRACK PATTERNS ARE INJECTED HERE -->
            <ng-content></ng-content>
        </div>
    </div>
</div>
