import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'CloseOverlayAction',
})
export class CloseOverlayAction extends DefaultAction {
    constructor() {
        super()
        this.label.next('COMMON.CLOSE')
        this.icon.next('close')
    }

    onClick(options?: any) {
        // this click handler does noting. instead this triggers an action in the hub component handler
    }
}
