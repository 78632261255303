import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { Subscription } from 'rxjs'
import { ActionTask, ActionButtonConfig } from '@tekbox-coco/midiative-commons'
import { BaseButtonComponent } from './base-button.component'

@Component({
    selector: 'app-action-button',
    templateUrl: './base-button.component.html',
})
export class ActionButtonComponent extends BaseButtonComponent implements OnInit, OnDestroy, OnChanges {
    // all service subscriptions
    private subscriptions: Subscription[] = []

    // injected action task service
    private actionTask: ActionTask

    // ##### INPUTS ######
    // name of the action that will be injected
    @Input()
    actionName: string | ActionButtonConfig

    constructor(injector: Injector) {
        super(injector)
    }

    ngOnChanges(changes: SimpleChanges): void {
        // reload service subscription if changed
        if (changes.actionName) {
            this.subscribeService()
        }
        super.ngOnChanges(changes)
    }

    ngOnInit(): void {
        // subscribe to action service
        this.subscribeService()
        // compute button style
        super.ngOnInit()
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((e) => e.unsubscribe())
        super.ngOnDestroy()
    }

    private get action(): string {
        return typeof this.actionName === 'string' ? this.actionName : this.actionName.action
    }

    private subscribeService() {
        // unsubscribe old subscriptions
        this.subscriptions.forEach((e) => e.unsubscribe())
        this.subscriptions = []

        // subscribe if action name valid
        let actionName: string = this.action
        if (actionName && actionName.length > 0) {
            // inject action
            this.actionTask = <ActionTask>this.injector.get(actionName)

            // subscribe to action outputs
            this.subscriptions.push(
                this.actionTask.icon.subscribe((icon) => {
                    this._icon = icon
                })
            )
            this.subscriptions.push(
                this.actionTask.label.subscribe((label) => {
                    this._label = label
                })
            )
            this.subscriptions.push(
                this.actionTask.buttonClasses.subscribe((buttonClasses) => {
                    this._buttonClasses = buttonClasses
                    this.recomputeButtonStyle()
                })
            )
            this.subscriptions.push(
                this.actionTask.iconClasses.subscribe((iconClasses) => {
                    this._iconClasses = iconClasses
                    this.recomputeButtonStyle()
                })
            )
            this.subscriptions.push(
                this.actionTask.labelClasses.subscribe((labelClasses) => {
                    this._labelClasses = labelClasses
                    this.recomputeButtonStyle()
                })
            )
            this.subscriptions.push(
                this.actionTask.visible.subscribe((visible) => {
                    this._isVisible = visible
                })
            )
            this.subscriptions.push(
                this.actionTask.emit.subscribe((emit) => {
                    if (emit != '') {
                        this.onClick.emit(emit)
                    }
                })
            )
            this.subscriptions.push(
                this.actionTask.domID.subscribe((domID) => {
                    this._domID = domID
                })
            )
        }

        this.actionTask.onInitActionConsumer(this.payload)
    }

    onClickHandler($event: MouseEvent) {
        if (this.actionTask) {
            this.actionTask.onClick(this.payload)
        }

        this.onClick.emit(`onClick:${this.action}`)
    }
}
