import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HudComponent } from './hud.component'
import { ButtonModule } from '../button/button.module'
import { InfoPanelModule } from './info-panel/info-panel.module'
import { TileMenuModule } from '../tile-menu/tile-menu.module'
import { ToolbarModule } from './toolbar/toolbar.module'
import { ToolSelectionMenuModule } from './tool-selection-menu/tool-selection-menu.module'

@NgModule({
    declarations: [HudComponent],
    exports: [HudComponent],
    imports: [
        CommonModule,
        ButtonModule,
        InfoPanelModule,
        TileMenuModule,
        ToolbarModule,
        ToolSelectionMenuModule,
    ],
})
export class HudModule {}
