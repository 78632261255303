export enum LogLevel {
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
}

/*
 Default logger config can be overwritten using local storage
 example: enable debug output for VueGtm:
 key: logger-config
 value: {"default":"INFO","logger":{ "SomeLogger": "DEBUG"}}
*/

let LoggerConfigDefault: Record<string, LogLevel> = {}

// @ts-ignore
if (typeof window !== 'undefined') {
    // @ts-ignore
    const w: Window = window
    const configFromLocalStorage = w.localStorage.getItem('logger-config')
    if (configFromLocalStorage) {
        try {
            LoggerConfigDefault = JSON.parse(configFromLocalStorage)
        } catch (e) {
            // @ts-ignore
            console.error('Error loading logger config')
        }
    }
}

export const LoggerConfigFactory: () => {
    default: LogLevel
    logger: Record<string, string | LogLevel>
} = () => {
    // load config from window object
    if ((window as any).loggerConfig) {
        return (window as any).loggerConfig
    }
    return {
        // debug log enabled by default. to reduce log messages set level in `logger`
        default: LogLevel.INFO,
        logger: {
            // default: LogLevel.DEBUG,
            // MidiJS: LogLevel.INFO,
            // // DUMMY: LogLevel.DEBUG
        },
        ...LoggerConfigDefault,
    }
}

/* eslint-disable prefer-rest-params */
// @ts-ignore
const origLog = console.log
export const LoggerWeight: { [key: string]: number } = {}
LoggerWeight[LogLevel.DEBUG] = 0
LoggerWeight[LogLevel.INFO] = 1
LoggerWeight[LogLevel.WARN] = 2
LoggerWeight[LogLevel.ERROR] = 3
LoggerWeight[LogLevel.FATAL] = 4

export class Logger {
    constructor(private name: string) {}

    /*  private static async writeLogFile(tag: string, message0: string, ...messages: string[]) {
      if (Capacitor.getPlatform() === 'android') {
        let _messages = '';

        // log file name is stored in window object
        // a new logfile is created for each app start
        (window as any).__logFile = (window as any).__logFile != null ? (window as any).__logFile : `logfile-${new Date().getTime()}.log`
        const logFileName = (window as any).__logFile

        // silence parsing errors
        try {
          _messages = JSON.stringify(messages);
        } catch (e) {
        }

        const logFile = `Midiative/logs/${logFileName}`;
        // append options
        const options = {
          data: `${tag} ${message0} ${_messages}\r\n`,
          directory: Directory.ExternalStorage,
          path: logFile,
          encoding: Encoding.UTF8
        };

        await Filesystem.appendFile(options)
      }
    }*/

    static createLogger(name: string): Logger {
        return new Logger(name)
    }
    getTime(){
        var t = new Date().toJSON()
        return t;
    }
    debug(...message: any[]): void {
        this.print(LogLevel.DEBUG, ...message)
    }

    info(...message: any[]): void {
        this.print(LogLevel.INFO, ...message)
    }

    warn(...message: any[]): void {
        this.print(LogLevel.WARN, ...message)
    }

    error(...message: any[]): void {
        this.print(LogLevel.ERROR, ...message)
    }

    fatal(...message: any[]): void {
        this.print(LogLevel.FATAL, ...message)
    }

    configuredLevel(): LogLevel {
        const LoggerConfig = LoggerConfigFactory()
        if (LoggerConfig != null) {
            const loggerConfig = LoggerConfig.logger[this.name] as LogLevel
            const configuredLevel = loggerConfig || LoggerConfig.default
            return configuredLevel
        }
        return LogLevel.INFO
    }

    private print(level: string, ...message: any[]): void {
        const LoggerConfig = LoggerConfigFactory()
        const thisLevel = LoggerWeight[level]
        if (LoggerConfig != null) {
            const loggerConfig = LoggerConfig.logger[this.name]
            const configuredLevel =
                LoggerWeight[loggerConfig] !== undefined
                    ? LoggerWeight[loggerConfig]
                    : LoggerWeight[LoggerConfig.default]
            // origLog(this.name, configuredLevel, thisLevel)
            if (thisLevel >= configuredLevel) {
                origLog(`%c[${level}][${this.name}][${this.getTime()}]`, message[0], ...message)

                // write logfile
                // Logger.writeLogFile(`%c[${level}][${this.name}]`, message[0], ...message)
            }
        } else {
            // print only configured loggers
        }
    }
}

// override default console.log fn
const defaultLogger = Logger.createLogger('Default')
console.log = (...args) => {
    defaultLogger.debug(...args)
}
