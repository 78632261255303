import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent {
    @Input()
    heading: string = ''
    @Input()
    leftAction: string = ''
    @Input()
    rightAction: string = ''
    @Input()
    midiativeIcon: boolean = false
}
