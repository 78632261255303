/* takes a simple route like 'home' and returns an router link link ['/home'] */

export const ROUTE_HOME = 'home'
export const ROUTE_SETTINGS = 'settings'
export const ROUTE_NEW_PROJECT = 'new-project'
export const ROUTE_LOAD_PROJECT = 'load-project'
export const ROUTE_PROJECT = 'project'
export const ROUTE_PROJECT_SETTINGS = 'project-settings'
export const ROUTE_PIANO_ROLL = 'piano-roll'
export const ROUTE_LOAD_PATTERN = 'load-pattern'
export const ROUTE_DRUM_SEQUENCER = 'drum-sequencer'
export const ROUTE_LICENSES = 'licenses'
export const ROUTE_DEBUG = 'debug'

export function createRouterLink(route: string): string[] {
    return ['/' + route]
}

export function createProjectRouterLink(projectId: string): string[] {
    return ['/' + ROUTE_PROJECT, projectId]
}

export function createProjectSettingsRouterLink(projectId: string): string[] {
    return ['/' + ROUTE_PROJECT, projectId, ROUTE_SETTINGS]
}

export function createPianoRollRouterLink(projectId: string, patternId: string): string[] {
    return ['/' + ROUTE_PROJECT, projectId, ROUTE_PIANO_ROLL, patternId]
}

export function createSequencerRouterLink(projectId: string, patternId: string): string[] {
    return ['/' + ROUTE_PROJECT, projectId, ROUTE_DRUM_SEQUENCER, patternId]
}

const PROJECT_REGEX = '(^\\/project$|\\/project\\/([a-zA-Z0-9-]*)([\\/]{0,1})$)'
const PIANO_ROLL_REGEX = '^\\/project\\/[\\w|-]*\\/piano-roll\\/[\\w|-]*$'
const SEQUENCER_REGEX = '^\\/project\\/[\\w|-]*\\/drum-sequencer\\/[\\w|-]*$'
const SETTINGS_REGEX = '^\\/project\\/[\\w|-]*\\/settings\\/[\\w|-]*$'

export function getRouteType(value: string): string {
    const projectMatch = value.match(PROJECT_REGEX)
    const isProject = projectMatch !== null ? projectMatch.length >= 1 : false

    const prMatch = value.match(PIANO_ROLL_REGEX)
    const isPianoRoll = prMatch !== null ? prMatch.length === 1 : false

    const sequencerMatch = value.match(SEQUENCER_REGEX)
    const isDrumSequencer = sequencerMatch !== null ? sequencerMatch.length === 1 : false

    const settingsMatch = value.match(SETTINGS_REGEX)
    const isSettings = settingsMatch !== null ? settingsMatch.length === 1 : false

    if (isProject) {
        return ROUTE_PROJECT
    } else if (isPianoRoll) {
        return ROUTE_PIANO_ROLL
    } else if (isDrumSequencer) {
        return ROUTE_DRUM_SEQUENCER
    } else if (isSettings) {
        return ROUTE_SETTINGS
    }
    return ''
}
