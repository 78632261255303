<div
    [attr.id]="_domID !== '' ? _domID : null"
    class="select-none"
    [ngClass]="computedClasses"
    (click)="onClickHandler($event)"
    *ngIf="buttonVisible"
>
    <mat-icon [class]="computedIconClasses" *ngIf="buttonIcon && (mode === 'default' || mode === 'iconOnly')">{{
        buttonIcon
    }}</mat-icon>
    <div
        [class]="computedLabelClasses"
        *ngIf="buttonLabel && buttonLabel.length > 0 && (mode === 'default' || mode === 'labelOnly')"
    >
        {{ buttonLabel | translate }}
    </div>
    <div style="display: none">{{ computedClasses }}</div>
</div>
