import { Injectable } from '@angular/core'
import {
    DefaultAction,
    initialPianoRollState,
    Logger,
    PatternType,
    PianoRollStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { UpdatePatternAction } from '../../store/actions/project-state.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollSaveChangesAction',
})
export class PianoRollSaveChangesAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollSaveChangesAction')
    private pianoRollState: PianoRollStateModel = initialPianoRollState

    constructor(private store: Store<AppState>) {
        super()
        this.label.next('COMMON.SAVE')
        this.icon.next('save')

        this.store.select('pianoRollState').subscribe({
            next: async (value) => {
                this.pianoRollState = value
            },
            error: (e) => this.logger.error(e),
        })
    }

    onClick() {
        if (this.pianoRollState.patternId === '') {
            this.logger.fatal('empty pattern id in PianoRoll')
        } else {
            // update pattern if id is found
            this.store.dispatch(
                new UpdatePatternAction({
                    id: this.pianoRollState.patternId,
                    changes: {
                        lengthIRN: this.pianoRollState.patternLengthIRN,
                        notes: Object.values(this.pianoRollState.notes.entities),
                        lastResolution: this.pianoRollState.resolution,
                        type: PatternType.PianoRollPattern,
                    },
                })
            )
        }
    }
}
