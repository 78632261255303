import { Logger } from '@tekbox-coco/midiative-commons'
import { FileManagerResult } from './PlatformFileManager'
import { Directory } from '@capacitor/filesystem'
import { NativeFileManager } from './NativeFileManager'

const LOGGER = Logger.createLogger('AndroidFileManager')

export class AndroidFileManager extends NativeFileManager {
    async exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        return super.exportFile(`Download/${name}`, blobData, type)
    }

    getStorageRoot(): Directory {
        // Default storage root is data directory on sd card e.g: /sdcard/Android/data/com.midiative/files
        return Directory.External
    }

    getExportStorageRoot(): Directory {
        // Default (external) storage root is data directory on sd card e.g: /sdcard/
        return Directory.ExternalStorage
    }

    getCacheStorageRoot(): Directory {
        return Directory.Cache
    }
}
