import { DefaultAction, AppState, PlatformService } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { StorageService } from '../../services/storage/storage.service'
import { AdService } from '../../services/ad.service'
import { ToastService } from '../../services/toast.service'
import { Router } from '@angular/router'
import { NavigateToHomeAction } from '../navigate/NavigateToHomeAction'
import { Share } from '@capacitor/share'
import { MidiService } from '../../services/midi.service'
import { Logger } from '@tekbox-coco/midiative-commons'
import { FileExporterService } from '../../services/file-manager/file-exporter.service'

import { ActionController } from '../actions.decorator'
import { TranslateService } from '@ngx-translate/core'
import { ModalService } from '@tekbox-coco/midiative-components'
import { DownloadMIDIAction } from './DownloadMIDIAction'
import { DownloadProjectAction } from './DownloadProjectAction'
import { ProjectShareMidiAction } from './ProjectShareMidiAction'
import { ProjectShareAction } from './ProjectShareAction'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ShareAction',
})
export class ShareAction extends DefaultAction {
    private readonly logger = Logger.createLogger('ShareAction')

    constructor(
        protected modalService: ModalService,
        protected shareMidi: ProjectShareMidiAction,
        private shareProject: ProjectShareAction,
        private platformService: PlatformService
    ) {
        super()
        this.icon.next('music_note')
        this.label.next('STORAGE.SHARE')

        this.visible.next(false)
        if (this.platformService.isNative()) {
            this.visible.next(true)
        }
    }

    async onClick() {
        this.modalService.showDialog({
            type: 'default',
            headline: 'PROJECT.MODAL.SHARE_DESCRIPTION',
            text: '',
            allowClose: true,
            isValid: () => {
                return true
            },
            actions: [
                {
                    label: 'PROJECT.MENU.SHARE_MIDI',
                    callback: () => {
                        this.shareMidi.onClick()
                    },
                },
                {
                    label: 'PROJECT.MENU.SHARE_PROJECT',
                    callback: () => {
                        this.shareProject.onClick()
                    },
                },
            ],
            onClose: () => {},
        })
    }
}
