import { Injectable } from '@angular/core'
import {
    ActionTask,
    ProjectToolEnum,
    PrToolEnum,
    ToolSelectionAction,
    UiStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { ToolSelectionMenuService } from '@tekbox-coco/midiative-components'
import { SetProjectSelectedToolAction } from '../../store/actions/ui-state.actions'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ProjectToolSelectionButtonAction',
})
export class ProjectToolSelectionButtonAction extends ToolSelectionAction implements ActionTask {
    public readonly toolSelectionConfig = [
        {
            tool: PrToolEnum.CURSOR,
            label: 'cursor',
            matIconCode: 'near_me',
            domID: 'project_tool-selection-menu_cursor',
        },
        {
            tool: PrToolEnum.CUT,
            label: 'slice',
            matIconCode: 'content_cut',
            domID: 'project_tool-selection-menu_cut',
        },
    ]
    private projectSelectedTool: ProjectToolEnum

    constructor(private store: Store<AppState>, private toolSelectionMenuService: ToolSelectionMenuService) {
        super()

        this.buttonClasses.next(['bg-yellow-50'])
        this.domID.next('project_tool-selection-menu')

        this.store.select('uiState').subscribe({
            next: (value: UiStateModel) => {
                this.projectSelectedTool = value.projectSelectedTool
                let icon
                switch (value.projectSelectedTool) {
                    case ProjectToolEnum.PENCIL:
                        icon = 'create'
                        break
                    case ProjectToolEnum.CURSOR:
                        icon = 'near_me'
                        break
                    case ProjectToolEnum.CUT:
                        icon = 'content_cut'
                        break
                }
                this.icon.next(icon)
            },
        })
    }

    async onClick() {
        // do noting
        const result = await this.toolSelectionMenuService.requestToolSelectionMenu(this.toolSelectionConfig, {
            tool: this.projectSelectedTool,
            label: '?',
            matIconCode: '??',
        })
        if (result) {
            this.store.dispatch(new SetProjectSelectedToolAction(result.tool as ProjectToolEnum))
        }
    }
}
