import { Logger } from '@tekbox-coco/midiative-commons'
import { AbstractPlatformFileManager, FileManagerOpenFileResult, FileManagerResult } from './PlatformFileManager'
import { FileInfo, StatResult } from '@capacitor/filesystem/dist/esm/definitions'

const LOGGER = Logger.createLogger('WebFileManager')

export class WebFileManager extends AbstractPlatformFileManager {
    exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        return this.saveFile(name, blobData, type)
    }

    cacheFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        return this.saveFile(name, blobData, type)
    }

    listDir(path: string): Promise<FileInfo[]> {
        throw new Error('Not implemented')
    }

    async saveFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        try {
            const blob = new Blob(blobData, { type: type })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = name
            link.click()
            return {
                data: URL.createObjectURL(blob),
                successful: true,
            }
        } catch (e) {
            LOGGER.error('Error generating download: ', e)
            throw new e()
        }
    }

    readFile(path: string): Promise<string> {
        throw new Error('Not implemented')
    }

    openFile(): Promise<FileManagerOpenFileResult> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            const input: HTMLInputElement = document.createElement('input')
            input.setAttribute('type', 'file')
            input.click()
            input.addEventListener('change', handleFiles, false)
            input.addEventListener('error', reject, false)

            function handleFiles(event): void {
                const files = this.files
                let file: File
                console.log(event)
                if (files.length === 1) {
                    file = files[0]
                    reader.readAsArrayBuffer(file)
                    reader.onloadend = <ArrayBuffer>(result): void => {
                        resolve({ fileName: file.name, data: result.target.result, canceled: false })
                    }
                }
            }
        })
    }

    async exists(path: string): Promise<boolean> {
        return Promise.resolve(false)
    }

    async mkdir(path: string): Promise<any> {
        return false
    }

    stat(path: string): Promise<StatResult> {
        return null
    }

    rmdir(path: string): Promise<boolean> {
        return Promise.resolve(false)
    }
}
