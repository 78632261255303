import { NgModule } from '@angular/core'
import { PianoComponent } from './piano.component'
import { CommonModule } from '@angular/common'

@NgModule({
    imports: [CommonModule],
    declarations: [PianoComponent],
    exports: [PianoComponent],
})
export class PianoModule {}
