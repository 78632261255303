import { Logger } from '@tekbox-coco/midiative-commons'
import { Directory } from '@capacitor/filesystem'
import { NativeFileManager } from './NativeFileManager'

const LOGGER = Logger.createLogger('IosFileManager')

export class IosFileManager extends NativeFileManager {
    getStorageRoot(): Directory {
        return Directory.Data
    }

    getExportStorageRoot(): Directory {
        return Directory.Documents
    }

    getCacheStorageRoot(): Directory {
        return Directory.Cache
    }
}
