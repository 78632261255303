<div
    id="track_header"
    class="h-full w-fit select-none rounded-r-lg border border border-solid border-black-100 bg-black-80 text-white"
>
    <div class="w-[150px]">
        <div class="flex flex-row place-content-between items-center pt-2">
            <div class="flex place-content-end">
                <div
                    class="font-italic flex h-[25px] w-[25px] items-center justify-center text-nowrap font-bold text-mdv-inactive"
                >
                    {{ track.channel }}
                </div>
            </div>
            <div class="flex-start flex flex-row">
                <app-action-button
                    id="track_delete"
                    actionName="DeleteTrackAction"
                    [innerClasses]="['p-0', 'bg-black-80', 'border-0']"
                    [iconClasses]="['!text-[16px]', '!flex', '!items-center', '!justify-center']"
                    mode="iconOnly"
                    [payload]="track.id"
                ></app-action-button>
            </div>
        </div>
        <div class="flex cursor-pointer flex-row place-content-start content-around px-2" (click)="selectInstrument()">
            <div class="truncate py-2 text-[12px] font-bold">
                {{ computeInstrumentLabel(track.instrument) }}
            </div>
        </div>
        <div class="flex flex-row place-content-evenly">
            <app-action-button
                [actionName]="muteActionName"
                [payload]="track.id"
                id="track_mute"
                [innerClasses]="[
                    'rounded',
                    'border-white',
                    'w-[35px]',
                    'h-[35px]',
                    'flex',
                    'place-content-center',
                    'items-center',
                    track.mute ? '!bg-mdv-active' : 'bg-mdv-inactive'
                ]"
            ></app-action-button>
            <app-action-button
                [actionName]="soloActionName"
                [payload]="track.id"
                id="track_solo"
                [innerClasses]="[
                    'rounded',
                    'border-white',
                    'w-[35px]',
                    'h-[35px]',
                    'flex',
                    'place-content-center',
                    'items-center',
                    track.solo ? '!bg-mdv-active-secondary' : 'bg-mdv-inactive'
                ]"
            ></app-action-button>
        </div>
        <div class="flex max-h-[15px] place-content-evenly content-center items-center px-2 pb-1 pt-5">
            <ion-range
                id="track_volume"
                class="track-volume"
                min="0"
                max="127"
                step="1"
                [value]="track.volume"
                (ionKnobMoveEnd)="emitEvent(changedVolume, $event.detail.value)"
            ></ion-range>
        </div>
    </div>
</div>
