import { NgModule } from '@angular/core'
import { ToolbarComponent } from './toolbar.component'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { IonicModule } from '@ionic/angular'
import { ButtonModule } from '../../button/button.module'

@NgModule({
    declarations: [ToolbarComponent],
    imports: [CommonModule, MatIconModule, IonicModule, ButtonModule],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
