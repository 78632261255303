import {
    drumSequencerNotesAdapter,
    DrumSequencerStateModel,
    initialDrumSequencerState,
    Note,
    TrackNote,
    AppState,
    MAX_GRID_RESOLUTION,
} from '@tekbox-coco/midiative-commons'
import { DrumSequencerActions, DrumSequencerStateActionTypes } from '../actions/drum-sequencer-state.actions'
import { createSelector } from '@ngrx/store'

export function drumSequencerStateReducer(
    state: DrumSequencerStateModel = initialDrumSequencerState,
    action: DrumSequencerActions
) {
    switch (action.type) {
        case DrumSequencerStateActionTypes.LoadDrumSequencerPattern: {
            return {
                ...state,
                patternId: action.payload.id,
                notes: drumSequencerNotesAdapter.setAll(action.payload.notes, state.notes),
                bars: action.payload.lengthIRN / MAX_GRID_RESOLUTION, // TODO: this calculation only works if drum sequencer patterns only consist of one or more whole bars
            }
        }
        case DrumSequencerStateActionTypes.SetDrumSequencerPatternId: {
            return { ...state, patternId: action.payload }
        }
        case DrumSequencerStateActionTypes.SetDrumsSequencerInstrument: {
            return { ...state, instrument: action.payload }
        }
        case DrumSequencerStateActionTypes.SetDrumSequencerChannel: {
            return { ...state, channel: action.payload }
        }
        // Notes
        case DrumSequencerStateActionTypes.SetDrumSequencerNotes: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.setAll(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.ClearDrumSequencerNotes:
            return {
                ...state,
                notes: drumSequencerNotesAdapter.removeAll(state.notes),
            }
        case DrumSequencerStateActionTypes.AddDrumSequencerNote: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.addOne(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.AddDrumSequencerNotes: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.addMany(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.UpsertDrumSequencerNote: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.upsertOne(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.UpsertDrumSequencerNotes: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.upsertMany(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.UpdateDrumSequencerNote: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.updateOne(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.UpdateDrumSequencerNotes: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.updateMany(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.DeleteDrumSequencerNote: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.removeOne(action.payload, state.notes),
            }
        }
        case DrumSequencerStateActionTypes.DeleteDrumSequencerNotes: {
            return {
                ...state,
                notes: drumSequencerNotesAdapter.removeMany(action.payload, state.notes),
            }
        }
        default: {
            return state
        }
    }
}

export const selectDrumSequencerNotes = (state: AppState) => Object.values(state.drumSequencerState.notes.entities)
export const selectDrumSequencerChannel = (state: AppState) => state.drumSequencerState.channel

export const selectDrumSequencerTrackNotes = createSelector(
    selectDrumSequencerNotes,
    selectDrumSequencerChannel,
    (notes: Note[], channel: number): Record<number, TrackNote[]> => {
        return notes
            .map((note) => {
                return {
                    ...note,
                    channel: channel,
                }
            })
            .reduce((a, b) => {
                if (!a[b.startIRN]) {
                    a[b.startIRN] = []
                }
                a[b.startIRN].push(b)
                return a
            }, {})
    }
)
