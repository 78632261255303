import { Chunk, HeaderType } from './Chunk'
import { Hexecutioner } from '../utils/Hexecutioner'

export enum MidiFileType {
    SINGLE = '0000',
    MULTI = '0001',
    INDEPENDENT = '0002',
}

export class HeaderChunk extends Chunk {
    format: MidiFileType
    ntracks: number
    tickdiv: number

    constructor(format: MidiFileType, ntracks: number, tickdiv: number) {
        super(HeaderType.HEADER)
        this.data = format
        this.data += Hexecutioner.numberToHex(ntracks, 4)
        this.data += Hexecutioner.numberToHex(tickdiv, 4)
    }
}
