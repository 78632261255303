import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { TrackComponent } from './track.component'
import { ModalModule } from '../modal/modal.module'
import { TrackHeaderModule } from '../track-header/track-header.module'
import { TrackPatternModule } from '../track-pattern/track-pattern.module'

@NgModule({
    imports: [CommonModule, IonicModule, ModalModule, TrackHeaderModule, TrackPatternModule, MatIconModule],
    declarations: [TrackComponent],
    exports: [TrackComponent],
})
export class TrackModule {}
