import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations'

/**
 * slide effect from the bottom right corner
 * returns an parametrized animation with parameters:
 * - right: the distance to the right screen border
 * - bottom: the distance to the bottom screen border
 * @param name name of the animation which is used in the template
 */
export function createBottomRightCornerAnimation(name: string): AnimationTriggerMetadata {
    return trigger(name, [
        transition(
            ':enter',
            [
                style({
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                }),
                animate(
                    '0.25s',
                    style({
                        right: '{{right}}rem',
                        bottom: '{{bottom}}rem',
                        opacity: 1,
                    })
                ),
            ],
            { params: { right: 0, bottom: 0 } }
        ), // animation params with default values
        transition(':leave', [
            animate(
                '0.25s',
                style({
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    width: '1rem',
                    height: '1rem',
                })
            ),
        ]),
        state(
            '*',
            style({
                right: '{{right}}rem',
                bottom: '{{bottom}}rem',
            }),
            { params: { right: 0, bottom: 0 } }
        ), // animation params with default values
    ])
}

/**
 * fade effect
 * returns an parametrized animation with parameters:
 * - value: on/off toggle value
 * @param name name of the animation which is used in the template
 */
export function generateFadeInOutAnimation(name: string): AnimationTriggerMetadata {
    return trigger(name, [
        state(
            'true',
            style({
                opacity: 0.25,
            })
        ),
        state(
            'false',
            style({
                opacity: 1,
            })
        ),
        transition('true <=> false', [animate('0.25s')]),
    ])
}
