import { MetaEvent } from '../MetaEvent'
import { MetaEventTypes } from '../../enums/MetaEventTypes'
import { Hexecutioner } from '../../../utils/Hexecutioner'

export class TrackName extends MetaEvent {
    constructor(deltaTime: number, protected text: string) {
        super(deltaTime)
        this.metaType = MetaEventTypes.TrackName
        this.data = Hexecutioner.stringToHex(text)
    }

    toString(): string {
        return `[MetaEvent] TrackName: '${this.text}'`
    }
}
