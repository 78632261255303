import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'

@Component({
    templateUrl: './base-button.component.html',
})
export class BaseButtonComponent implements OnInit, OnDestroy, OnChanges {
    // data received from service
    protected _icon: string = ''
    protected _label: string = ''
    protected _buttonClasses: string[] = []
    protected _labelClasses: string[] = []
    protected _iconClasses: string[] = []
    protected _isVisible: boolean = true
    protected _domID: string = ''

    // computed button classes
    public computedClasses = []
    public computedIconClasses = []
    public computedLabelClasses = []

    // ##### OUTPUTS ######
    @Output()
    onClick: EventEmitter<string> = new EventEmitter()

    // ##### INPUTS ######
    @Input()
    iconPosition: 'left' | 'right' = 'left'

    @Input()
    payload: any

    /**
     * force modes
     * default = icon + label
     * iconOnly = icon
     * labelOnly = label
     */
    @Input()
    mode: 'default' | 'iconOnly' | 'labelOnly' = 'default'

    @Input()
    innerClasses: string[] = []

    @Input()
    iconClasses: string[] = []

    @Input()
    labelClasses: string[] = []

    constructor(protected injector: Injector) {}

    public get buttonIcon() {
        return this._icon
    }

    public get buttonLabel() {
        return this._label
    }
    public get buttonVisible() {
        return this._isVisible
    }

    ngOnChanges(changes: SimpleChanges): void {
        // recomputed button style for all input changes
        this.recomputeButtonStyle()
    }

    ngOnInit(): void {
        // compute button style
        this.recomputeButtonStyle()
    }

    ngOnDestroy(): void {}

    onClickHandler($event: MouseEvent) {
        this.onClick.emit(`onClick:${this.payload}`)
    }

    public recomputeButtonStyle() {
        const defaultClasses = ['relative', 'flex', 'cursor-pointer', 'border', 'leading-6']

        const hasBackground =
            this._buttonClasses.find((e) => e.startsWith('bg-')) != null ||
            this.innerClasses.find((e) => e.startsWith('bg-')) != null

        const hasBorder =
            this._buttonClasses.find((e) => e.startsWith('border')) != null ||
            this.innerClasses.find((e) => e.startsWith('border')) != null

        const hasPadding =
            this._buttonClasses.find((e) => e.startsWith('p-')) != null ||
            this.innerClasses.find((e) => e.startsWith('p-')) != null

        const hasTextOptions =
            this._buttonClasses.find((e) => e.startsWith('text-')) != null ||
            this.innerClasses.find((e) => e.startsWith('text-')) != null

        if (!hasBackground) {
            defaultClasses.push('bg-gray-900')
        }

        if (!hasBorder) {
            defaultClasses.push('border-[black]')
        }
        if (!hasPadding) {
            defaultClasses.push('p-2')
        }

        if (!hasTextOptions) {
            defaultClasses.push('text-base')
            defaultClasses.push('text-white')
        }

        this.computedClasses = [
            ...defaultClasses,
            ...this.innerClasses,
            this.iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
            ...this._buttonClasses,
        ].filter((e) => e.length > 0)

        this.computedIconClasses = [...this.iconClasses, ...this._iconClasses]

        this.computedLabelClasses = [...this.labelClasses, ...this._labelClasses]
    }
}
