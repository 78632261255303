export enum Color {
    RED = '#e6194B',
    GREEN = '#3cb44b',
    YELLOW = '#ffe119',
    BLUE = '#4363d8',
    PURPLE = '#911eb4',
    CYAN = '#42d4f4',
    ORANGE = '#f58231',
    MAGENTA = '#f032e6',
    LIME = '#bfef45',
    TEAL = '#469990',
    LAVENDER = '#dcbeff',
    BROWN = '#9A6324',
    MAROON = '#800000',
    MINT = '#aaffc3',
    OLIVE = '#808000',
    APRICOT = '#ffd8b1',
}
