<div
    class="relative flex min-h-[20px] cursor-pointer bg-black-80 text-white"
    [style]="computedStyle"
    (press)="longPressed($event)"
    (click)="clickedTimeLine($event)"
>
    <app-project-timeline-timestamp
        [calculatedBarLength]="projectBarRenderWidth * timeDivision"
        *ngFor="let i of numBeatsArray()"
        [timestamp]="i"
    >
    </app-project-timeline-timestamp>
    <app-project-select-loop
        [loopSelectorConfig]="loopSelectorConfig"
        [calculatedBarLength]="projectBarRenderWidth * timeDivision"
        (clickOverlay)="clickOverlay.emit()"
        (loopSet)="loopSet.emit($event)"
    ></app-project-select-loop>
</div>
