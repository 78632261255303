// Existing enums should not change since they would have to be migrated in the local storage of the app as they are persisted in the settings
export enum TutorialEnum {
    PROJECT = 'PROJECT',
    PROJECT_NEW = 'PROJECT_NEW',
    PIANO_ROLL = 'PIANO_ROLL',
    HOME = 'HOME',
}

export enum Language {
    NONE = '-',
    EN = 'en',
    ES = 'es',
    DE = 'de',
}

export interface SettingsStateModel {
    showScales: boolean
    soundFont: Soundfont
    lastMidiDeviceConnection: LastMidiDeviceConnection
    language: Language
    passedTutorials: TutorialEnum[]
}

export interface LastMidiDeviceConnection {
    port: number
    deviceName: string
}

export interface Soundfont {
    name: string
    url: string
}

export const initialSettingsState: SettingsStateModel = {
    showScales: true,
    soundFont: {
        name: 'none',
        url: '',
    },
    lastMidiDeviceConnection: undefined,
    language: Language.NONE,
    passedTutorials: [],
}
