import { Injectable } from '@angular/core'
import { ActionTask, AppState } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { SetFollowCursorAction } from '../../store/actions/ui-state.actions'
import { IconToggleAction } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'FollowCursorToggleAction',
})
export class FollowCursorToggleAction extends IconToggleAction implements ActionTask {
    constructor(private store: Store<AppState>) {
        super('label_off', 'label')

        this.domID.next('project_follow-cursor')

        this.store.select('uiState').subscribe({
            next: (value) => {
                this.toggled.next(value.followCursor)
                if (this.toggled.getValue()) {
                    this.buttonClasses.next(['bg-cyan-50'])
                } else {
                    this.buttonClasses.next([])
                }
            },
        })
    }

    onClickAction() {
        this.store.dispatch(new SetFollowCursorAction(true))
    }

    onClickActionToggled() {
        this.store.dispatch(new SetFollowCursorAction(false))
    }
}
