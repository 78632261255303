import { Injectable } from '@angular/core'
import { Device } from '@capacitor/device'
import { Language, Logger } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly logger = Logger.createLogger('LanguageService')
    public language: Language = Language.EN

    constructor(private store: Store) {}

    async getSystemLanguage(): Promise<Language> {
        const languageCode = await Device.getLanguageCode()
        return this.getLanguageByLanguageCode(languageCode.value)
    }

    getLanguageByLanguageCode(languageCode: string): Language {
        let systemLanguage: Language
        switch (languageCode) {
            case 'en':
                systemLanguage = Language.EN
                break
            case 'de':
                systemLanguage = Language.DE
                break
            case 'es':
                systemLanguage = Language.ES
                break
            default:
                systemLanguage = Language.EN
                break
        }

        this.logger.info(`Detected system language is ${systemLanguage} from given language code ${languageCode}`)

        return systemLanguage
    }
}
