import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'
import { ModalService } from '@tekbox-coco/midiative-components'
import { OpenMidiFileAction } from '../import/OpenMidiFileAction'
import { OpenProjectFileAction } from '../import/OpenProjectFileAction'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'OpenFileAction',
})
export class OpenFileAction extends DefaultAction {
    constructor(
        private modalService: ModalService,
        private openFileAction: OpenProjectFileAction,
        private openMidiFileAction: OpenMidiFileAction
    ) {
        super()
        this.icon.next('folder_open')
        this.label.next('COMMON.OPEN_FILE')
        this.domID.next('open-file-action')
    }

    async onClick() {
        this.modalService.showDialog({
            type: 'default',
            headline: 'COMMON.OPEN_FILE',
            text: '',
            allowClose: true,
            isValid: () => {
                return true
            },
            actions: [
                {
                    label: 'HOME.OPEN_MIDI_FILE',
                    callback: () => {
                        this.openMidiFileAction.onClick()
                    },
                },
                {
                    label: 'HOME.OPEN_PROJECT_FILE',
                    callback: () => {
                        this.openFileAction.onClick()
                    },
                },
            ],
            onClose: () => {},
        })
    }
}
