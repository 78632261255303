import { Injectable, OnDestroy } from '@angular/core'
import { UpdateTrackAction } from '../../store/actions/project-state.actions'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { Track, Logger, AppState } from '@tekbox-coco/midiative-commons'

@Injectable({
    providedIn: 'root',
})
export class MixerService implements OnDestroy {
    public isOpen: boolean = false
    private readonly logger = Logger.createLogger('MixerService')

    private projectStateSubscription: Subscription
    public tracks: Track[] = []

    constructor(private store: Store<AppState>) {
        this.projectStateSubscription = this.store
            .select('projectState')
            .subscribe((ps) => (this.tracks = Object.values(ps.tracks.entities)))
    }

    ngOnDestroy() {
        this.projectStateSubscription.unsubscribe()
    }

    openMixer(): void {
        this.isOpen = true
    }

    closeMixer(): void {
        this.isOpen = false
    }

    changedVolume(trackID: string, volume: number) {
        this.logger.info('changed volume', trackID, volume)
        this.store.dispatch(
            new UpdateTrackAction({
                id: trackID,
                changes: {
                    volume: volume,
                },
            })
        )
    }

    muteTrack(trackID: string) {
        this.logger.info('clicked mute', trackID)
        this.store.dispatch(
            new UpdateTrackAction({
                id: trackID,
                changes: {
                    mute: !this.tracks.filter((t) => t.id === trackID)[0].mute,
                },
            })
        )
    }

    soloTrack(trackID: string) {
        this.logger.info('clicked solo', trackID)
        this.store.dispatch(
            new UpdateTrackAction({
                id: trackID,
                changes: {
                    solo: !this.tracks.filter((t) => t.id === trackID)[0].solo,
                },
            })
        )
    }
}
