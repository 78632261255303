import { Action, ActionReducer } from '@ngrx/store'
import { Instruments, Logger, MidiHandler, Soundfont, AppState } from '@tekbox-coco/midiative-commons'
import { ProjectStateActionTypes } from '../actions/project-state.actions'
import { PianoRollStateActionTypes, SetInstrumentAction } from '../actions/piano-roll-state.actions'
import {
    DrumSequencerStateActionTypes,
    SetDrumsSequencerInstrumentAction,
} from '../actions/drum-sequencer-state.actions'

const logger = Logger.createLogger('instrumentMetaReducer')

export interface InstrumentMapping {
    instrument: Instruments
    channel: number
}

export function instrumentMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state: AppState, action: Action) => {
        if (state) {
            const settingsState = state.settingsState

            if (settingsState) {
                const soundfont = settingsState.soundFont
                switch (action.type) {
                    case ProjectStateActionTypes.LoadProject:
                        const stateAfterLoadProject: AppState = reducer(state, action)
                        initInstruments(
                            Object.values(stateAfterLoadProject.projectState.tracks.entities).map((t) => ({
                                instrument: t.instrument,
                                channel: t.channel,
                            })),
                            soundfont
                        )
                        return stateAfterLoadProject
                    case ProjectStateActionTypes.UpdateTrack:
                        const calculatedState: AppState = reducer(state, action)
                        const instrs = Object.values(calculatedState.projectState.tracks.entities).map((t) => ({
                            instrument: t.instrument,
                            channel: t.channel,
                        }))
                        initInstruments(instrs, soundfont)
                        return calculatedState
                    case PianoRollStateActionTypes.SetInstrument:
                        initInstrument((action as SetInstrumentAction).payload, state.pianoRollState.channel, soundfont)
                        return reducer(state, action)
                    case DrumSequencerStateActionTypes.SetDrumsSequencerInstrument:
                        initInstrument(
                            (action as SetDrumsSequencerInstrumentAction).payload,
                            state.drumSequencerState.channel,
                            soundfont
                        )
                        return reducer(state, action)
                    default:
                        return reducer(state, action)
                }
            }
        }
        return reducer(state, action)
    }
}

// preload a single instrument
function initInstrument(instrument: Instruments, channel: number, soundfont: Soundfont): void {
    const instrId = MidiHandler.getInstance().getInstrumentId(instrument)
    MidiHandler.getInstance().midi.setInstrument(channel, instrId)
    MidiHandler.getInstance()
        .loadInstruments([instrument], soundfont)
        .then((status) => {
            logger.info('MidiJS initialized', status)
        })
}

function initInstruments(instrumentMappings: InstrumentMapping[], soundfont: Soundfont): void {
    logger.info(' initialized', instrumentMappings)
    instrumentMappings.forEach((instrumentMapping) => {
        logger.info('instrumentMapping', instrumentMapping)
        MidiHandler.getInstance().midi.setInstrument(
            instrumentMapping.channel,
            MidiHandler.getInstance().getInstrumentId(instrumentMapping.instrument)
        )
    })
    MidiHandler.getInstance()
        .loadInstruments(
            instrumentMappings.map((im) => im.instrument),
            soundfont
        )
        .then((status) => {
            logger.info('MidiJS initialized', status)
        })
}
