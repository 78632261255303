<div
    class="fixed inset-0 z-modal flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,0.5)] backdrop-blur-sm"
    (click)="onClickOutside($event)"
    *ngIf="isOpen && currentMessage && currentMessage.type === 'default'"
>
    <div
        class="box-border flex max-h-[80vh] max-w-[80vw] flex-col items-center overflow-y-hidden rounded-lg border bg-black-95 p-5 text-white"
        (click)="onClickInside($event)"
    >
        <div class="modal__content--default" *ngIf="hasDefaultContent">
            <h2>{{ headline | translate }}</h2>
            <p>{{ text | translate }}</p>
        </div>
        <div class="modal__content--slot">
            <ng-content></ng-content>
        </div>
        <div class="mt-auto flex flex-row self-center" *ngIf="buttonActions.length > 0 && renderButtons">
            <app-button
                class="m-5 flex place-content-center items-center"
                *ngFor="let action of buttonActions"
                (onClick)="onClickActionButton(action, $event)"
                [label]="action.label | translate"
            ></app-button>
        </div>
        <div class="modal-list" *ngIf="buttonActions.length > 0 && !renderButtons">
            <app-button
                class="m-5"
                *ngFor="let action of buttonActions"
                (onClick)="onClickActionButton(action, $event)"
                [label]="action.label | translate"
            ></app-button>
        </div>
    </div>
</div>

<app-modal-body
    *ngIf="isOpen && currentMessage && currentMessage.type === 'component-ref'"
    [message]="currentMessage"
    (dialogMessages)="onSubDialogMessage($event)"
></app-modal-body>
