import { ProjectStateModel } from '../project-state.model'

/**
 * Abstract schema migrations
 */
export abstract class SchemaMigrator {
    public constructor(public sourceSchemaVersion: number, public targetSchemaVersion: number) {}

    public abstract migrate(data: ProjectStateModel): ProjectStateModel
    public abstract validate(data: ProjectStateModel): boolean
}
