import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import {
    ActionTask,
    createRouterLink,
    Logger,
    ROUTE_LOAD_PROJECT,
    ROUTE_NEW_PROJECT,
    ROUTE_SETTINGS,
} from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToSettingsAction',
})
export class NavigateToSettingsAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToSettingsAction')

    constructor(private router: Router) {
        super()
        this.label.next('HOME.SETTINGS')
        this.icon.next('settings')
    }

    onClick(): void {
        this.navigate(ROUTE_SETTINGS)
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
