import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TutorialComponent } from './tutorial.component'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ButtonModule } from '../button/button.module'

@NgModule({
    imports: [CommonModule, ButtonModule, MatIconModule, RouterModule, FormsModule, TranslateModule, ButtonModule],
    declarations: [TutorialComponent],
    exports: [TutorialComponent],
})
export class TutorialModule {}
