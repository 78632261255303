import {
    DrumSequencerStateModel,
    initialDrumSequencerState,
    initialPianoRollState,
    initialProjectState,
    initialSettingsState,
    initialUIState,
    PianoRollStateModel,
    ProjectStateModel,
    SettingsStateModel,
    UiStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { pianoRollStateReducer } from './reducers/piano-roll-state.reducer'
import { projectStateReducer } from './reducers/project-state.reducer'
import { uiStateReducer } from './reducers/ui-state.reducer'
import { settingsStateReducer } from './reducers/settings-state.reducer'
import { routerReducer } from '@ngrx/router-store'
import { drumSequencerStateReducer } from './reducers/drum-sequencer-state.reducer'

export const initialAppState = {
    uiState: initialUIState,
    pianoRollState: initialPianoRollState,
    projectState: initialProjectState,
    drumSequencerState: initialDrumSequencerState,
    settingsState: initialSettingsState,
}

export const reducers = {
    router: routerReducer,
    uiState: uiStateReducer,
    pianoRollState: pianoRollStateReducer,
    projectState: projectStateReducer,
    drumSequencerState: drumSequencerStateReducer,
    settingsState: settingsStateReducer,
}
