import { closestPowerOfTwo, isPowerOfTwo } from './calc-util'

const MAX_TIME_SIGNATURE_NUMERATOR = 16
const MAX_TIME_SIGNATURE_DENOMINATOR = 16
export const timeSignatureValidationRegex = '^([1-9]|1[0-6])\\/(2|4|8|16)$'
const timeSignatureRegex = '\\d+\\/\\d+$'

/**
 * Checks if a time signature string is in the form of ${timeSignatureValidationRegex}
 * @param timeSignature
 */
export function hasValidTimeSignature(timeSignature: string): boolean {
    return new RegExp(timeSignatureValidationRegex).test(timeSignature)
}

function hasValidTimeSignatureForm(timeSignature: string): boolean {
    return new RegExp(timeSignatureRegex).test(timeSignature)
}

/**
 * Takes a time signature as string and tries to adjust it to given requirements.
 * E.g. 3/6 would be adjusted to 3/4 since 6 is not a power of 2 and only powers
 * of 2 are possible in the MIDI file standard
 * @param timeSignature e.g. 3/4
 * @param defaultTimeSignature e.g. 4/4
 */
export function readjustTimeSignature(timeSignature: string, defaultTimeSignature: string): string {
    if (hasValidTimeSignatureForm(timeSignature)) {
        let [numerator, denominator] = timeSignature.split('/').map((n) => Number(n))
        // No 1 as time division denominator, because cubase did so as well
        if (!isPowerOfTwo(denominator) || denominator === 1) {
            const closestPossibleDenominator = closestPowerOfTwo(denominator)
            denominator = closestPossibleDenominator === 1 ? 2 : closestPossibleDenominator
        }

        let newTimeSignature

        if (numerator > MAX_TIME_SIGNATURE_NUMERATOR && denominator > MAX_TIME_SIGNATURE_DENOMINATOR) {
            newTimeSignature = `${MAX_TIME_SIGNATURE_NUMERATOR}/${MAX_TIME_SIGNATURE_DENOMINATOR}`
        } else if (numerator > MAX_TIME_SIGNATURE_NUMERATOR && denominator <= MAX_TIME_SIGNATURE_DENOMINATOR) {
            newTimeSignature = `${MAX_TIME_SIGNATURE_NUMERATOR}/${denominator}`
        } else if (numerator <= MAX_TIME_SIGNATURE_NUMERATOR && denominator > MAX_TIME_SIGNATURE_DENOMINATOR) {
            newTimeSignature = `${numerator}/${MAX_TIME_SIGNATURE_DENOMINATOR}`
        } else {
            newTimeSignature = `${numerator}/${denominator}`
        }

        return newTimeSignature
    } else {
        return defaultTimeSignature
    }
}
