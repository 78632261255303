import { Component, OnDestroy } from '@angular/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StorageService } from './services/storage/storage.service'
import {
    createProjectRouterLink,
    Logger,
    MigrationHandler,
    ProjectStateModel,
    SettingsStateModel,
    AppState,
    HotkeyService,
    PlatformService,
    Language,
} from '@tekbox-coco/midiative-commons'
import { Platform } from '@ionic/angular'
import { AdService } from './services/ad.service'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { FileManagerService } from './services/file-manager/file-manager.service'
import { Store } from '@ngrx/store'
import { LoadProjectAction } from './store/actions/project-state.actions'
import { Router } from '@angular/router'
import { ToastService } from './services/toast.service'
import { TranslateService } from '@ngx-translate/core'

import { Subscription } from 'rxjs'
import { Environment } from '@angular/cli/lib/config/workspace-schema'
import { environment } from '../environments/environment'
import { OpenMidiFileAction } from './actions/import/OpenMidiFileAction'
import { LanguageService } from './services/language.service'
import { SetLanguageAction } from './store/actions/settings-state.actions'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnDestroy {
    private readonly logger = Logger.createLogger('AppComponent')
    private settings: SettingsStateModel
    private settingsSubscription: Subscription

    constructor(
        private platformService: PlatformService,
        private languageService: LanguageService,
        private platform: Platform,
        private storage: StorageService,
        private adService: AdService,
        private fileManager: FileManagerService,
        private store: Store<AppState>,
        private router: Router,
        private toastService: ToastService,
        private translateService: TranslateService,
        private hotkeyService: HotkeyService,
        private openMidiFileAction: OpenMidiFileAction
    ) {
        this.settingsSubscription = this.store
            .select('settingsState')
            .subscribe((settingsState: SettingsStateModel) => {
                this.settings = settingsState
            })

        this.initializeApp()
            .then(() => {
                this.logger.debug('App successfully initialized')
            })
            .catch((e) => {
                this.logger.error('Could not initialize app', e)
            })
    }

    ngOnDestroy(): void {
        this.settingsSubscription.unsubscribe()
    }

    private async openAppWithProjectFile(url: string) {
        const fileContent = await this.fileManager.readFile(url)
        const project: ProjectStateModel = JSON.parse(fileContent)

        if (MigrationHandler.validate(project)) {
            this.store.dispatch(new LoadProjectAction(project))
            // TODO: fix ngZone warning here: https://trello.com/c/pFdDc96G/124-fix-ngzone-warning
            this.router.navigate(createProjectRouterLink(project.id)).catch((e) => this.logger.error(e))
        } else {
            this.logger.error('Project file corrupted, could not verify file consistency')
            this.toastService.error('Project file is broken and could not be loaded')
        }
    }

    private async openAppWithMidiFile(url: string) {
        const binaryString = await this.fileManager.readFile(url)
        // extract filename from url
        const fileName = decodeURI(url.split('/').reverse()[0])

        // convert binary string to array buffer
        var bytes = new Uint8Array(binaryString.length)
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        const data = bytes.buffer

        // load
        this.openMidiFileAction.loadMidiFromArrayBuffer(data, fileName)
    }

    async initializeApp() {
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
            this.logger.debug('External "appUrlOpen" event received', event)
            if (event && event.url) {
                if (event.url.endsWith('mdv')) {
                    await this.openAppWithProjectFile(event.url)
                } else if (event.url.endsWith('mid') || event.url.endsWith('midi')) {
                    await this.openAppWithMidiFile(event.url)
                } else {
                    this.logger.error('"appUrlOpen"-File type cannot be opened')
                }
            } else {
                this.logger.error('"appUrlOpen"-Event undefined or it did not contain any file url')
            }
        })

        await SplashScreen.show()
        this.logger.debug('initializing app')

        // load settings from storage
        await this.storage.loadSettings()

        // initialize the hotkey service
        if (environment.production) {
            this.hotkeyService.initialize()
        }

        // init translation service
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('en')
        if (this.settings && this.settings.language !== Language.NONE) {
            this.logger.info(`Fetched language from settings. Language was ${this.settings.language}`)
            this.translateService.use(this.settings.language)
        } else {
            this.logger.info('No language found in settings, will fetch system language.')
            const systemLanguage: Language = await this.languageService.getSystemLanguage()
            this.store.dispatch(new SetLanguageAction(systemLanguage))
            this.translateService.use(systemLanguage)
        }

        if (this.platformService.isNative()) {
            this.logger.debug('platform is:', this.platformService.isIOS() ? 'iOS' : 'Android')
            await this.platform.ready()
            await SplashScreen.hide()
        } else {
            this.logger.debug('platform is web')
            await this.platform.ready()
            await SplashScreen.hide()
        }
        await this.adService.initializeAdMob().catch((e) => this.logger.error('AdMob could not be initialized', e))
    }
}
