import { Component, Input } from '@angular/core'
import { PROJECT_TRACK_HEIGHT, TIMELINE_HEIGHT } from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-cursor',
    templateUrl: './cursor.component.html',
})
export class CursorComponent {
    private readonly TRACK_HEIGHT = PROJECT_TRACK_HEIGHT
    private readonly TIMELINE_HEIGHT = TIMELINE_HEIGHT

    @Input() position: number
    @Input() offset = 0
    @Input() height: number
}
