import { Injectable } from '@angular/core'
import { ActionController } from '../../actions.decorator'
import { TrackPatternAction } from './TrackPatternAction'
import { Store } from '@ngrx/store'
import { AppState, Color } from '@tekbox-coco/midiative-commons'
import {
    ColorPickerColor,
    ColorPickerComponent,
    ColorPickerDialogComponent,
    ModalService,
} from '@tekbox-coco/midiative-components'
import { ToastService } from '../../../services/toast.service'
import { SetPatternColorAction } from '../../../store/actions/project-state.actions'
import { DialogMessage } from 'dist/midiative-components/lib/modal/basic-dialog'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'SelectPatternColorAction',
})
export class SelectPatternColorAction extends TrackPatternAction {
    constructor(store: Store<AppState>, private modalService: ModalService, private toast: ToastService) {
        super(store)

        this.icon.next('palette')
        this.label.next('Color')
    }

    onClick(options?: any) {
        this.modalService.showDialog({
            type: 'component-ref',
            allowClose: true,
            componentRef: ColorPickerDialogComponent,
            actions: [
                {
                    label: 'color',
                    id: 'color',
                    callback: (closeDialog: () => void, color: ColorPickerColor) => {
                        const mappingId = this.uiState.projectSelectedPatternMapping
                        if (mappingId === '') {
                            this.toast.warn('No pattern selected!')
                            return
                        }
                        const patternId = this.getPatterIdForMapping(mappingId)
                        if (!patternId) {
                            this.toast.warn('Pattern mapping could not resolved')
                            return
                        }

                        this.store.dispatch(new SetPatternColorAction(patternId, Color[color.key]))
                        closeDialog()
                    },
                },
            ],
            onClose: () => {},
        })
    }
}
