import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { MAX_GRID_RESOLUTION } from '@tekbox-coco/midiative-commons'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'GoToNextBeatAction',
})
export class GoToNextBeatAction extends DefaultAction {
    constructor(public audioService: AudioService) {
        super()
        this.icon.next('navigate_next')
    }

    onClick() {
        const newPos = this.audioService.getCursorPosition() + MAX_GRID_RESOLUTION
        if (newPos <= this.audioService.getSongEndAsIRN()) {
            this.audioService.setCursorPosition(newPos)
        }
    }
}
