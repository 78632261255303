import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MixerComponent } from './mixer.component'
import { IonicModule } from '@ionic/angular'
import { ButtonModule, HeaderModule } from '@tekbox-coco/midiative-components'

@NgModule({
    imports: [CommonModule, MatIconModule, TranslateModule, IonicModule, ButtonModule, HeaderModule],
    declarations: [MixerComponent],
    exports: [MixerComponent],
})
export class MixerModule {}
