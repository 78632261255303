import { Logger, QUARTER_BEAT_RESOLUTION, TrackNote } from '@tekbox-coco/midiative-commons'
import { NoteBuffer } from './NoteBuffer'

/**
 * NoteBuffer specific helpers
 */

const logger = Logger.createLogger('NoteBufferUtil')

/**
 * Merges two {@link NoteBuffer}s to a single {@link NoteBuffer} instance
 * @param obj0 first buffer
 * @param obj1 second buffer
 * @return instance of {@link NoteBuffer}
 */
export function mergeBuffers(obj0: NoteBuffer, obj1: NoteBuffer): NoteBuffer {
    // merge buffer keys into array
    const joined = [...Object.keys(obj0), ...Object.keys(obj1)]

    // remove duplicates
    const uniqueKeys = [...new Set(joined)]

    // merge obj0 and obj1 into new NoteBuffer
    // obj1 overrides obj0
    const newObject: NoteBuffer = {}
    uniqueKeys.forEach((key) => {
        if (obj0[key] !== undefined && obj1[key] !== undefined) {
            newObject[key] = [...obj0[key], ...obj1[key]]
        } else if (obj0[key] === undefined && obj1[key] !== undefined) {
            newObject[key] = [...obj1[key]]
        } else if (obj0[key] !== undefined && obj1[key] === undefined) {
            newObject[key] = [...obj0[key]]
        } else {
            logger.error('OBJECT MERGING ERROR')
        }
    })

    // remove duplicate notes from different buffers
    // could be unnecessary in future releases
    const uniqueBuffer: NoteBuffer = {}

    Object.keys(newObject).forEach((key) => {
        uniqueBuffer[key] = Array.from(new Set(newObject[key].map((a) => a.id))).map((id) => {
            return newObject[key].find((a) => a.id === id)
        })
    })

    return uniqueBuffer
}

/**
 * Find all notes in the next quarter beat
 *
 * @param buffer {@link NoteBuffer} instance
 * @param currentIRN irn to use as a filter
 * @return returns buffer keys in next quarter note
 */
export function resolveNextQuarterBeatTimestamps(buffer: NoteBuffer, currentIRN: number): number[] {
    return (
        Object.keys(buffer)
            // convert to number
            .map((k) => +k)
            // filter notes in next quarter beat
            .filter((k) => currentIRN <= k && k < currentIRN + QUARTER_BEAT_RESOLUTION)
    )
}

/**
 * Find all notes in the next quarter beat
 *
 * @param buffer {@link NoteBuffer} instance
 * @param currentIRN irn to use as a filter
 * @return returns buffer keys in next quarter note
 */
export function resolveNextQuarterBeatBuffer(buffer: NoteBuffer, currentIRN: number): NoteBuffer {
    return resolveNextQuarterBeatTimestamps(buffer, currentIRN)
        .map((key) => ({
            [key]: buffer[key],
        }))
        .reduce((a: NoteBuffer, b: NoteBuffer) => ({ ...a, ...b }), {})
}

/**
 * Takes a {@link NoteBuffer} and returns the keys as number[] of the buffer
 * at the currentIrn in the buffer with the selected channel
 * @param channel selected channel
 * @param nb {@link NoteBuffer} the buffer to look up for notes
 * @param currentIrn current irn where to filter
 */
export function filterChannelNotes(channel: number, nb: NoteBuffer, currentIrn: number): TrackNote[] {
    return nb[currentIrn].filter((n) => n.channel == channel)
}

/**
 * Get all channels in a {@link NoteBuffer} for a specific irn time
 * @param nb {@link NoteBuffer} the buffer to look up for channels
 * @param currentIrn current irn where to filter
 */
export function getActiveChannels(nb: NoteBuffer, currentIrn: number): number[] {
    return [...new Set(nb[currentIrn].map((n) => n.channel))]
}

/**
 * Filters ignored notes from {@link NoteBuffer}
 * @param buf {@link NoteBuffer} the buffer to filter
 * @param ignoredIds id to be ignored
 * @return filtered {@link NoteBuffer}
 */
export function filterIgnoredNotes(buf: NoteBuffer, ignoredIds: string[]): NoteBuffer {
    const f: NoteBuffer = {}

    Object.keys(buf).forEach((key) => {
        f[key] = buf[key].filter((note: TrackNote) => {
            return ignoredIds.indexOf(note.id) === -1
        })
    })
    return f
}

/**
 * Calculates the note buffer relative to the given offset
 *
 * @param buf {@link NoteBuffer} source buffer
 * @param irnOffset offset in IRN
 * @return shifted source buffer {@link NoteBuffer}
 */
export function addOffsetToNoteBuffer(buf: NoteBuffer, irnOffset: number): NoteBuffer {
    const f: NoteBuffer = {}

    Object.keys(buf).forEach((key) => {
        f[Number(key) + Number(irnOffset)] = buf[key].map((note: TrackNote) => {
            return {
                ...note,
                startIRN: note.startIRN + irnOffset,
            }
        })
    })
    return f
}
