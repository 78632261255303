import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TileMenuComponent } from './tile-menu.component'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../button/button.module'
import { HeaderModule } from '../header/header.module'

@NgModule({
    imports: [CommonModule, MatIconModule, ButtonModule, HeaderModule],
    declarations: [TileMenuComponent],
    exports: [TileMenuComponent],
})
export class TileMenuModule {}
