import { Component, OnInit, ViewChild } from '@angular/core'
import { IonSelect } from '@ionic/angular'
import { SelectDialogService, SelectRequestCallback, SelectRequestItem } from './select-dialog.service'

@Component({
    selector: 'lib-select-dialog',
    templateUrl: './select-dialog.component.html',
})
export class SelectDialogComponent implements OnInit {
    @ViewChild(IonSelect) select: IonSelect
    items: SelectRequestItem[] = []
    request: SelectRequestCallback
    defaultValue!: SelectRequestItem

    constructor(private service: SelectDialogService) {}

    ngOnInit() {
        this.service.selectRequests.subscribe(async (request: SelectRequestCallback) => {
            if (request) {
                this.items = request.items
                this.request = request
                this.defaultValue = request.selected ? request.selected : this.items[0].value
                await this.select.open()
            }
        })
    }

    onChange(event: any) {
        const selectRequestItem: SelectRequestItem = this.items.filter((i) => i.value === event.detail.value)[0]
        this.request.callback(selectRequestItem)
        this.items = []
        this.request = null
    }
}
