<div>
    <div
        (click)="onClickOutside($event)"
        class="fixed inset-0 z-modal flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,0.5)] backdrop-blur-sm"
    >
        <div
            class="box-border flex max-h-[80vh] max-w-[80vw] flex-col items-center overflow-y-hidden rounded-lg border bg-black-95 p-5 text-white"
            (click)="onClickInside($event)"
        >
            <ng-template #dialogTemplateRef></ng-template>
        </div>
    </div>
</div>
