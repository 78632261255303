import { Injectable } from '@angular/core'
import {
    createProjectRouterLink,
    DefaultAction,
    initialPianoRollState,
    initialProjectState,
    Logger,
    PianoRollStateModel,
    ProjectStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { Router } from '@angular/router'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'CloseEditorAction',
})
export class CloseEditorAction extends DefaultAction {
    private readonly logger = Logger.createLogger('CloseEditorAction')
    private projectState: ProjectStateModel = initialProjectState

    constructor(private store: Store<AppState>, private router: Router) {
        super()
        this.label.next('COMMON.CLOSE')
        this.icon.next('close')

        this.subscriptions.push(
            this.store.select('projectState').subscribe({
                next: async (value) => {
                    this.projectState = value
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    async onClick() {
        await this.navigateToProject()
    }

    private async navigateToProject(): Promise<void> {
        await this.router.navigate(createProjectRouterLink(this.projectState.id)).catch((err) => this.logger.error(err))
    }
}
