import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { ModalMessage } from './modal.service'
import { TapTempoComponent } from '../tap-tempo/tap-tempo.component'
import { ColorPickerComponent } from '../color-picker/color-picker.component'
import { BasicDialog, DialogMessage } from './basic-dialog'

/**
 * Wrapper component for dynamic dialog bodies.
 * Handles clickOutside event
 */
@Component({
    selector: 'app-modal-body',
    templateUrl: './modal-body.component.html',
})
export class ModalBodyComponent extends BasicDialog implements OnInit, AfterViewInit {
    @ViewChild('dialogTemplateRef', { read: ViewContainerRef, static: true })
    public dialogTemplateRef: ViewContainerRef

    constructor() {
        super()
    }

    ngOnInit(): void {}

    public async ngAfterViewInit() {
        this.dialogTemplateRef.clear()
        const componentRef = await this.dialogTemplateRef.createComponent(this.message.componentRef)

        ;(componentRef as any).instance.message = this.message
        ;((componentRef as any).instance.dialogMessages as EventEmitter<DialogMessage<any>>).subscribe((e) => {
            this.dialogMessages.emit(e)
        })
    }

    onClickOutside($event: MouseEvent) {
        this.dialogMessages.emit({ message: 'close' })
    }

    onClickInside($event: MouseEvent) {
        $event.stopPropagation()
    }
}
