import { Injectable, OnDestroy } from '@angular/core'
import { TickEmitter } from './TickEmitter'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Subscription, timer } from 'rxjs'
import {
    DrumSequencerStateModel,
    getRouteType,
    Logger,
    MidiHandler,
    PianoRollStateModel,
    ProjectStateModel,
    QUARTER_BEAT_RESOLUTION,
    ROUTE_DRUM_SEQUENCER,
    ROUTE_PIANO_ROLL,
    ROUTE_PROJECT,
    searchMappings,
    TrackNote,
    TrackPatternMapping,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { TickCounter } from './TickCounter'
import { selectProjectNotes, selectSongEnd } from '../../store/reducers/project-state.reducer'
import { selectPianoRollTrackNotes } from '../../store/reducers/piano-roll-state.reducer'
import { CalculationService } from '../calculation.service'
import { selectDrumSequencerTrackNotes } from '../../store/reducers/drum-sequencer-state.reducer'
import { selectUrl } from '../../store/reducers/router.selectors'
import { NoteBuffer } from './NoteBuffer'
import {
    addOffsetToNoteBuffer,
    filterChannelNotes,
    filterIgnoredNotes,
    getActiveChannels,
    mergeBuffers,
    resolveNextQuarterBeatBuffer,
    resolveNextQuarterBeatTimestamps,
} from './NoteBufferUtil'

@Injectable({
    providedIn: 'root',
})
export class AudioService implements OnDestroy {
    private readonly logger = Logger.createLogger('AudioService')

    public cursorPosition: BehaviorSubject<number> = new BehaviorSubject(0)

    private tickEmitter: TickEmitter
    private tickCounter: TickCounter

    private midiHandler: MidiHandler

    private projectStateSubscription: Subscription
    private pianoRollStateSubscription: Subscription
    private drumSequencerStateSubscription: Subscription
    private projectNoteSelectorSubscription: Subscription
    private pianoRollNoteSelectorSubscription: Subscription
    private songEndSelectorSubscription: Subscription
    private sequencerNoteSelectorSubscription: Subscription
    private routerStateSubscription: Subscription
    private projectState: ProjectStateModel
    private pianoRollState: PianoRollStateModel
    private drumSequencerState: DrumSequencerStateModel
    private projectNoteBuffer: Record<number, TrackNote[]>
    private pianoRollNoteBuffer: Record<number, TrackNote[]>
    private drumSequencerNoteBuffer: Record<number, TrackNote[]>
    private songEndIRN: number

    private looped: boolean = false
    private loopStart: number = 0
    private loopEnd: number = 0

    private timeoutSubscriptionBuffer: Subscription[] = []
    private currentRoute: string = ''

    constructor(private store: Store<AppState>, private calcService: CalculationService) {
        this.tickEmitter = TickEmitter.getInstance()
        this.tickCounter = TickCounter.getInstance()

        this.midiHandler = MidiHandler.getInstance()

        this.routerStateSubscription = store.select(selectUrl).subscribe({
            next: (value) => {
                if (value !== undefined) {
                    this.currentRoute = getRouteType(value)
                }
            },
        })

        this.projectStateSubscription = store.select('projectState').subscribe({
            next: (value) => {
                this.projectState = value
                this.tickEmitter.setBPM(this.projectState.bpm)
            },
            error: (e) => this.logger.error(e),
        })

        this.pianoRollStateSubscription = store.select('pianoRollState').subscribe({
            next: (value) => {
                this.pianoRollState = value
            },
            error: (e) => this.logger.error(e),
        })

        this.drumSequencerStateSubscription = store.select('drumSequencerState').subscribe({
            next: (value) => {
                this.drumSequencerState = value
            },
            error: (e) => this.logger.error(e),
        })

        this.projectNoteSelectorSubscription = store.select(selectProjectNotes).subscribe({
            next: (value) => (this.projectNoteBuffer = value),
            error: (e) => this.logger.error(e),
        })

        this.pianoRollNoteSelectorSubscription = store.select(selectPianoRollTrackNotes).subscribe({
            next: (value) => (this.pianoRollNoteBuffer = value),
            error: (e) => this.logger.error(e),
        })

        this.songEndSelectorSubscription = store.select(selectSongEnd).subscribe({
            next: (value) => {
                this.songEndIRN = value
                this.tickCounter.setEnd(value)
            },
            error: (e) => this.logger.error(e),
        })

        this.sequencerNoteSelectorSubscription = store.select(selectDrumSequencerTrackNotes).subscribe({
            next: (value) => (this.drumSequencerNoteBuffer = value),
            error: (e) => this.logger.error(e),
        })

        // go through buffer step by step
        this.tickCounter.getAddressObservable().subscribe((currentIrn) => {
            this.clearBuffers()

            const buffer = this.mergePlayingBuffers(currentIrn)

            // find the time indices that are happening in that particular quarter beat
            const matchingKeys = resolveNextQuarterBeatTimestamps(buffer, currentIrn)

            matchingKeys.forEach((timeIndex) => {
                this.timeoutSubscriptionBuffer.push(
                    // schedule the notes to play
                    timer(this.calcService.getMillisecondsPerQuarterBar() * (timeIndex - currentIrn)).subscribe(
                        (result) => {
                            getActiveChannels(buffer, timeIndex).forEach((channel) => {
                                // collect all notes for channel and timestamp
                                filterChannelNotes(channel, buffer, timeIndex).forEach((note) => {
                                    // play note
                                    this.midiHandler.midi.noteOn(channel, note.key, note.velocity)
                                    // schedule note off
                                    const lengthInSec = this.calcService.getSecondsPerQuarterBar() * note.lengthIRN
                                    this.midiHandler.midi.noteOff(channel, note.key, lengthInSec)
                                })
                            })
                        }
                    )
                )
            })

            // Set smooth cursor scrolling between the ticks
            let nextSteps = [...Array(QUARTER_BEAT_RESOLUTION).keys()]
            // nextSteps contains [0..95]
            nextSteps.forEach((timeToFire) => {
                // add timeout for each index
                let timeToFireAsMilliseconds = timeToFire * this.calcService.getMillisecondsPerQuarterBar()
                this.timeoutSubscriptionBuffer.push(
                    timer(timeToFireAsMilliseconds).subscribe((result) => {
                        this.cursorPosition.next(timeToFire + currentIrn)
                    })
                )
            })
        })
    }

    private mergePlayingBuffers(currentIrn: number): Record<number, TrackNote[]> {
        const projectNoteBuffer = resolveNextQuarterBeatBuffer(this.projectNoteBuffer, currentIrn)
        let ignoredNotes = []
        let filteredProjectNoteBuffer: NoteBuffer = {}
        switch (this.currentRoute) {
            case ROUTE_PROJECT:
                return projectNoteBuffer
            case ROUTE_PIANO_ROLL:
                // get pattern from project
                let pianoRolePatternInProject = this.projectState.patterns.entities[this.pianoRollState.patternId]

                // if pattern mapping is found add notes from mapping to ignored notes list
                if (pianoRolePatternInProject) {
                    ignoredNotes = pianoRolePatternInProject.notes.map((e) => e.id)
                }

                // filters the currently selected piano roll pattern from the project note buffer
                filteredProjectNoteBuffer = filterIgnoredNotes(projectNoteBuffer, ignoredNotes)

                // offset calculation in project. because piano roll starts at 0.
                const patternOffsetInPianoRoll: TrackPatternMapping = searchMappings(
                    this.pianoRollState.patternId,
                    this.projectState
                )

                if (patternOffsetInPianoRoll) {
                    return mergeBuffers(
                        filteredProjectNoteBuffer,
                        addOffsetToNoteBuffer(this.pianoRollNoteBuffer, patternOffsetInPianoRoll.position)
                    )
                }
                this.logger.warn(`PatternMapping for pattern '${this.pianoRollState.patternId}' not found!`)
                return {}
            case ROUTE_DRUM_SEQUENCER:
                // get pattern from project
                let drumSequencerPatternInProject =
                    this.projectState.patterns.entities[this.drumSequencerState.patternId]

                // if pattern mapping is found add notes from mapping to ignored notes list
                if (drumSequencerPatternInProject) {
                    ignoredNotes = drumSequencerPatternInProject.notes.map((e) => e.id)
                }

                // filters the currently selected drum sequencer pattern from the project note buffer
                filteredProjectNoteBuffer = filterIgnoredNotes(projectNoteBuffer, ignoredNotes)

                // resolve pattern mapping from project to get irn offset
                const patternOffsetInDrumSequencer: TrackPatternMapping = searchMappings(
                    this.drumSequencerState.patternId,
                    this.projectState
                )

                if (patternOffsetInDrumSequencer) {
                    // merge buffer with calculated offset
                    return mergeBuffers(
                        filteredProjectNoteBuffer,
                        addOffsetToNoteBuffer(this.drumSequencerNoteBuffer, patternOffsetInDrumSequencer.position)
                    )
                }
                this.logger.warn(`PatternMapping for pattern '${this.drumSequencerState.patternId}' not found!`)
                return {}
            default:
                return {}
        }
    }

    private clearBuffers() {
        this.timeoutSubscriptionBuffer.forEach((t) => {
            t.unsubscribe()
        })

        this.timeoutSubscriptionBuffer = []
    }

    ngOnDestroy(): void {
        this.logger.info('AudioService destroyed')
        this.projectStateSubscription.unsubscribe()
        this.projectNoteSelectorSubscription.unsubscribe()
        this.pianoRollNoteSelectorSubscription.unsubscribe()
        this.songEndSelectorSubscription.unsubscribe()
        this.sequencerNoteSelectorSubscription.unsubscribe()
        this.routerStateSubscription.unsubscribe()
    }

    public playNotes(notes: number[], channel: number, lengthIRN: number) {
        this.midiHandler.midi.chordOn(channel, notes, 127, 0)
        const lengthInSec = this.calcService.getSecondsPerQuarterBar() * lengthIRN
        console.log(lengthInSec)
        this.midiHandler.midi.chordOff(channel, notes, lengthInSec)
    }

    public play() {
        if (!this.looped) {
            this.tickCounter.setStart(0)
            this.tickCounter.setEnd(this.songEndIRN)
        } else {
            this.tickCounter.setStart(this.loopStart)
            this.tickCounter.setEnd(this.loopEnd)
        }

        this.tickEmitter.startTick()
    }

    public pause() {
        this.tickEmitter.stopTick()
        this.clearBuffers()
    }

    public stop() {
        this.tickEmitter.stopTick()
        this.clearBuffers()
        this.tickCounter.reset()
    }

    setLoop(startIRN: number, endIRN: number) {
        this.loopStart = startIRN
        this.loopEnd = endIRN
        this.looped = true
    }

    clearLoop(): void {
        this.looped = false
        this.tickCounter.setStart(0)
        this.tickCounter.setEnd(this.songEndIRN)
    }

    isPlaying() {
        return this.tickEmitter.isTicking()
    }

    public getCursorPosition(): number {
        if (this.songEndIRN === 0) {
            return 0
        }
        return this.cursorPosition.value
    }

    public setCursorPosition(pos: number) {
        this.tickCounter.setAddress(pos)
        this.cursorPosition.next(pos)
        this.clearBuffers()
        if (this.isPlaying().value) {
            this.play()
        }
    }

    getSongEndAsIRN(): number {
        return this.songEndIRN
    }
}
