<div
    class="z-menu w-screen bg-[rgba(0,0,0,0.5)] backdrop-blur-sm"
    (click)="onClickOutside($event)"
    *ngIf="visible && drawer && drawerOpen"
></div>

<div
    *ngIf="(!drawer && visible) || (drawer && visible && drawerOpen)"
    [ngClass]="{
        menu: !drawer,
        'left-0 z-menu w-[60vw] border-r bg-black-95': drawer
    }"
>
    <div *ngIf="!drawer && visible">
        <app-button
            *ngFor="let item of items"
            [label]="item.label"
            [icon]="item.icon"
            iconPosition="right"
            (onClick)="item.callback !== undefined && item.callback(); drawerOpen = false"
            [innerClasses]="['flex', 'justify-center']"
        >
        </app-button>
        <div class="bg p-4 text-center text-lg text-white" *ngIf="items == undefined || items.length === 0">
            {{ emptyMessage | translate }}
        </div>
    </div>

    <div *ngIf="drawer && visible" class="scroll-container">
        <app-tile-menu buttonSize="16vw" itemHeight="150" *ngIf="drawerOpen" [menuItems]="items"></app-tile-menu>
    </div>
</div>
<app-button
    class="border-box fixed right-0 top-0 z-menu"
    *ngIf="visible && drawer"
    (document:keydown.escape)="clickedMenuButton()"
    (onClick)="clickedMenuButton()"
    [icon]="drawerOpen ? 'undo' : 'build'"
    [innerClasses]="[
        'items-center',
        'justify-center',
        'bg-black-60',
        'border-black-60',
        'pr-0',
        'pt-0',
        'rounded-bl-full',
        'min-w-[7vw]',
        'min-h-[7vw]'
    ]"
>
</app-button>
