import { NgModule } from '@angular/core'
import { BarPipe } from './bar.pipe'
import { CommonModule } from '@angular/common'

@NgModule({
    declarations: [BarPipe],
    exports: [BarPipe],
    imports: [CommonModule],
})
export class BarPipeModule {}
