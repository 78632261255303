import { MetaEvent } from '../MetaEvent'
import { MetaEventTypes } from '../../enums/MetaEventTypes'

export class EndOfTrack extends MetaEvent {
    constructor(deltaTime: number) {
        super(deltaTime)
        this.metaType = MetaEventTypes.EndOfTrack
        this.data = ''
    }

    toString(): string {
        return '[MetaEvent] EndOfTrack'
    }
}
