import { ChordType, getChordDef, getScaleDef, KeyType, ScaleType } from './definitions'

export class MusicTheory {
    public MIN: number
    public MAX: number

    constructor(minNoteId: number, maxNoteId: number) {
        this.MIN = minNoteId
        this.MAX = maxNoteId
    }

    // enter note in any octave and get the note type as noteId
    // e.g. getRootNote(24)=0 => C
    private static getRootNote(note: number): number {
        return note % 12
    }

    /* -------------------------------------------------------------
                                    SCALE
     -------------------------------------------------------------*/

    public getScale(note: number, scaleType: ScaleType): number[] {
        let scale = []
        const rootNote = MusicTheory.getRootNote(note)

        scale = getScaleDef(scaleType).map((v) => rootNote + v)

        return scale
    }

    public getFromPosition(note: number, scaleType: ScaleType): number[] {
        let scale = []
        scale = getScaleDef(scaleType).map((v) => note + v)
        return scale
    }

    public getScaleFull(note: number, scaleType: ScaleType): number[] {
        const scale: number[] = []
        const rootNote = MusicTheory.getRootNote(note)

        const maxOct = Math.floor(this.MAX / 12)
        const minOct = Math.floor(this.MIN / 12) - 1

        for (let i = 0; i <= maxOct; i++) {
            getScaleDef(scaleType)
                .map((v) => v + (rootNote + 12 * i))
                .forEach((n) => {
                    if (n <= this.MAX) {
                        scale.push(n)
                    }
                })
        }

        for (let i = -1; i >= minOct; i--) {
            const partScale: number[] = []
            getScaleDef(scaleType)
                .map((v) => rootNote + 12 * i + v)
                .forEach((n) => {
                    if (n >= this.MIN) {
                        partScale.push(n)
                    }
                })

            scale.unshift(...partScale)
        }

        return scale
    }

    /* -------------------------------------------------------------
                                    CHORD
     -------------------------------------------------------------*/
    public getChord(baseNote: number, type: ChordType): number[] {
        return getChordDef(type).map((e) => e + baseNote)
    }

    // MAJOR
    public getMajorChord(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR).map((e) => e + baseNote)
    }

    public getMajorChordFull(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR_FULL).map((e) => e + baseNote)
    }

    public getMajor7Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR_7).map((e) => e + baseNote)
    }

    public getMajor9Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR_9).map((e) => e + baseNote)
    }

    public getMajor11Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR_11).map((e) => e + baseNote)
    }

    public getMajor13Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MAJOR_13).map((e) => e + baseNote)
    }

    // MINOR
    public getMinorChord(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR).map((e) => e + baseNote)
    }

    public getMinorChordFull(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR_FULL).map((e) => e + baseNote)
    }

    public getMinor7Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR_7).map((e) => e + baseNote)
    }

    public getMinor9Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR_9).map((e) => e + baseNote)
    }

    public getMinor11Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR_11).map((e) => e + baseNote)
    }

    public getMinor13Chord(baseNote: number): number[] {
        return getChordDef(ChordType.MINOR_13).map((e) => e + baseNote)
    }

    // OTHER
    public get7Chord(baseNote: number): number[] {
        return getChordDef(ChordType.SEVEN).map((e) => e + baseNote)
    }

    public getDimChord(baseNote: number): number[] {
        return getChordDef(ChordType.DIM).map((e) => e + baseNote)
    }

    public invert(chord: number[], inversions: number): number[] {
        const invChord = chord
        if (inversions > 0) {
            for (let i = 0; i < inversions; i++) {
                const first = invChord.shift()
                // Move last element one octave(12 notes) up to invert upward
                const firstNextOct = first + 12
                if (firstNextOct <= this.MAX) {
                    invChord.push(firstNextOct)
                } else {
                    invChord.unshift(first)
                }
            }
        } else {
            for (let i = 0; i < Math.abs(inversions); i++) {
                const last = invChord.pop()
                // Move last element one octave(12 notes) down to invert downward
                const lastPrevOct = last - 12
                if (lastPrevOct >= this.MIN) {
                    invChord.unshift(lastPrevOct)
                } else {
                    invChord.push(last)
                }
            }
        }

        return invChord
    }

    private isChordInScale(chord: number[], scale: number[]): boolean {
        return chord.every((note) => scale.includes(note))
    }

    public getMatchingChordsForNoteInScale(note: number, scale: number[]): number[][] {
        const matchingChords = []

        for (const chordTypeKey in ChordType) {
            const chord = this.getChord(note, ChordType[chordTypeKey])
            if (this.isChordInScale(chord, scale)) {
                matchingChords.push(chord)
            }
        }

        return [...new Set(matchingChords)]
    }

    public getMatchingChordsForNoteInScaleSimple(note: number, scale: number[]): number[][] {
        const matchingChords = []

        const minorChord = this.getChord(note, ChordType.MINOR_FULL)
        if (this.isChordInScale(minorChord, scale)) {
            matchingChords.push(minorChord)
        }

        const majorChord = this.getChord(note, ChordType.MAJOR_FULL)
        if (this.isChordInScale(majorChord, scale)) {
            matchingChords.push(majorChord)
        }

        return [...new Set(matchingChords)]
    }

    public getRandomMatchingChordForNoteInScale(note: number, scale: number[]): number[] {
        const matchingChords = this.getMatchingChordsForNoteInScale(note, scale)
        return matchingChords[Math.floor(Math.random() * matchingChords.length)]
    }

    public getRandomMatchingChordForNoteInScaleSimple(note: number, scale: number[]): number[] {
        const matchingChords = this.getMatchingChordsForNoteInScaleSimple(note, scale)
        return matchingChords[Math.floor(Math.random() * matchingChords.length)]
    }

    public getCompleteNoteString(note: number, addOctave: boolean): string {
        const baseNote = this.getBaseNoteString(note)
        if (addOctave) {
            return baseNote + String(this.getOctave(note))
        } else {
            return baseNote
        }
    }

    private getBaseNoteString(note: number) {
        switch (MusicTheory.getRootNote(note)) {
            case 0:
                return 'C'
            case 1:
                return 'C#'
            case 2:
                return 'D'
            case 3:
                return 'D#'
            case 4:
                return 'E'
            case 5:
                return 'F'
            case 6:
                return 'F#'
            case 7:
                return 'G'
            case 8:
                return 'G#'
            case 9:
                return 'A'
            case 10:
                return 'A#'
            case 11:
                return 'B'
            default:
                return 'nAn' // Means not a note
        }
    }

    public getKeyTypeAsNumber(key: KeyType): number {
        switch (key) {
            case KeyType.A:
                return 9
            case KeyType.Ais:
                return 10
            case KeyType.B:
                return 11
            case KeyType.C:
                return 0
            case KeyType.Cis:
                return 1
            case KeyType.D:
                return 2
            case KeyType.Dis:
                return 3
            case KeyType.E:
                return 4
            case KeyType.F:
                return 5
            case KeyType.Fis:
                return 6
            case KeyType.G:
                return 7
            case KeyType.Gis:
                return 8
            default:
                return -1
        }
    }

    public getOctave(note: number): number {
        return Math.floor(note / 12) - 1 // -1 octave mapping for MidiApp
    }

    public noteToKeyType(note: number): KeyType {
        let noteKeyID = note % 12
        if (noteKeyID < 0) {
            noteKeyID = 12 + noteKeyID
        }
        let noteString

        switch (noteKeyID) {
            case 0: {
                return KeyType.C
            }
            case 1: {
                return KeyType.Cis
            }
            case 2: {
                return KeyType.D
            }
            case 3: {
                return KeyType.Dis
            }
            case 4: {
                return KeyType.E
            }
            case 5: {
                return KeyType.F
            }
            case 6: {
                return KeyType.Fis
            }
            case 7: {
                return KeyType.G
            }
            case 8: {
                return KeyType.Gis
            }
            case 9: {
                return KeyType.A
            }
            case 10: {
                return KeyType.Ais
            }
            case 11: {
                return KeyType.B
            }
        }
    }
}
