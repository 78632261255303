<div class="top-0 flex grid h-[3rem] w-full grid-cols-3 gap-0 bg-gray-900">
    <div class="flex justify-start">
        <app-action-button
            *ngIf="leftAction.length > 0"
            [actionName]="leftAction"
            mode="iconOnly"
            [innerClasses]="['h-full', 'items-center', 'border-0', 'ml-2']"
        >
        </app-action-button>
    </div>
    <div class="flex justify-center">
        <div class="pointer-events-none justify-self-center p-2 text-lg font-bold text-white">
            <div class="flex select-none items-center">
                <img *ngIf="midiativeIcon" src="assets/icon/icon.svg" class="h-[30px] pr-2" alt="MIDIative" />
                <div>{{ heading | translate }}</div>
            </div>
        </div>
    </div>
    <div class="flex justify-end">
        <app-action-button
            *ngIf="rightAction.length > 0"
            [actionName]="rightAction"
            mode="iconOnly"
            [innerClasses]="['h-full', 'items-center', 'border-0', 'mr-2']"
        >
        </app-action-button>
    </div>
</div>
