import { SchemaMigrator } from '../SchemaMigrator'
import { ProjectStateModel } from '../../project-state.model'
import { Logger } from '../../../logger/Logger'

/**
 * Migrates schemas without a version number to version 1
 */
export class InitialSchemaMigration extends SchemaMigrator {
    private readonly logger = Logger.createLogger('MidiHandler')

    constructor() {
        super(0, 1)
    }

    public migrate(data: ProjectStateModel): ProjectStateModel {
        this.logger.info(`Migrate ${data.id} to version 1`)
        return {
            ...data,
            schemaVersion: 1,
        }
    }

    validate(data: ProjectStateModel): boolean {
        if (
            data === undefined ||
            data.id === undefined ||
            data.demo === undefined ||
            data.key === undefined ||
            data.scale === undefined ||
            data.patterns === undefined ||
            data.tracks === undefined ||
            data.loopSelector === undefined
        ) {
            return false
        } else {
            return true
        }
    }
}
