/**
 * GridConfig
 *
 * Json representation of grid configuration
 */
export interface GridConfig {
    // width of cell in pixel
    cellWidth: number
    // height of cell in pixel
    cellHeight: number
    // (optional) default border config applied to all cells
    border?: BorderConfig
    // set image transparency
    opacity?: number
    // adds borders to the top and bottom of the grid
    startAndEndDividers?: boolean
    // cell config factory, return cell configuration for cell index
    cellConfigFactory(x: number, y: number, gridConfig: GridConfig): CellConfig
}

/**
 * Border Config
 */
export interface BorderConfig {
    // border thickness
    thickness: number
    // border color
    color: string
}

/**
 * Cell configuration
 */
export interface CellConfig {
    // cell background color
    backgroundColor: string
    // border configs
    borderLeft?: BorderConfig
    borderRight?: BorderConfig
    borderTop?: BorderConfig
    borderBottom?: BorderConfig

    // set cell transparency
    opacity?: number
}

/**
 * CanvasGridGenerator
 *
 * Generates a grid on a canvas
 */
export class GridGenerator {
    constructor(protected gridConfig: GridConfig) {}

    /**
     * Generates grid on canvas
     *
     * @param cols number of cols
     * @param rows number of rows
     * @returns HTMLCanvasElement containing the grid
     */
    public async generateCanvas(cols: number, rows: number): Promise<HTMLCanvasElement> {
        const canvasElement = this.createCanvas(cols, rows)
        const ctx = canvasElement.getContext('2d')

        // set alpha level
        ctx.globalAlpha = this.gridConfig.opacity ? this.gridConfig.opacity : 1

        for (let row = 0; row <= rows; row++) {
            for (let col = 0; col <= cols; col++) {
                const cellConfig = this.gridConfig.cellConfigFactory(col, row, this.gridConfig)
                // skip cell if no config is supplied
                if (cellConfig) {
                    this.drawCell(col, row, ctx, cellConfig)
                }
            }
        }

        return canvasElement
    }

    /**
     * Generates grid as data url and wraps it in background css property
     *
     * @param cols number of cols
     * @param rows number of rows
     * @param withSize when true css width and height attributes are added
     * @returns object containing css properties
     */
    public async generateBackgroundCss(
        cols: number,
        rows: number,
        withSize: boolean = false
    ): Promise<Record<string, any>> {
        const canvas = await this.generateCanvas(cols, rows)

        let size = {}

        if (withSize) {
            size = {
                width: `${canvas.width}px`,
                height: `${canvas.height}px`,
            }
        }

        return {
            ...size,
            background: `url(${canvas.toDataURL()})`,
        }
    }

    /**
     * Set fill style color
     * @param ctx context in which color should be applied
     * @param color the color
     */
    protected setDrawingColor(ctx: CanvasRenderingContext2D, color: string): void {
        ctx.fillStyle = color
    }

    /**
     * Draws cell to canvas
     *
     * @param cellIndex cell (column) index
     * @param rowIndex row index
     * @param ctx canvas context
     * @param cell cell config
     */
    protected drawCell(cellIndex: number, rowIndex: number, ctx: CanvasRenderingContext2D, cell: CellConfig): void {
        // resolve cell position
        const cellPosition = this.cellPosition(cellIndex, rowIndex)

        let offsetLeft = 0
        let offsetRight = 0
        let offsetTop = 0
        let offsetBottom = 0

        ctx.globalAlpha = cell.opacity ? cell.opacity : 1

        // add left border if specified
        const borderLeft = this.borderConfig(cell.borderLeft)
        if (borderLeft) {
            this.setDrawingColor(ctx, borderLeft.color)
            ctx.fillRect(cellPosition.x, cellPosition.y, borderLeft.thickness, this.gridConfig.cellHeight)
            offsetLeft = borderLeft.thickness
        }

        // add right border if specified
        const borderRight = this.borderConfig(cell.borderRight)
        if (borderRight) {
            this.setDrawingColor(ctx, borderRight.color)
            ctx.fillRect(
                cellPosition.x + this.gridConfig.cellWidth - borderRight.thickness,
                cellPosition.y,
                borderRight.thickness,
                this.gridConfig.cellHeight
            )
            offsetRight = borderRight.thickness
        }

        // add top border if specified
        const borderTop = this.borderConfig(cell.borderTop)
        if (borderTop) {
            this.setDrawingColor(ctx, borderTop.color)
            ctx.fillRect(
                cellPosition.x + offsetLeft,
                cellPosition.y,
                this.gridConfig.cellWidth - (offsetLeft + offsetRight),
                borderTop.thickness
            )
            offsetTop = borderTop.thickness
        }

        // add bottom border if specified
        const borderBottom = this.borderConfig(cell.borderBottom)
        if (borderBottom) {
            this.setDrawingColor(ctx, borderBottom.color)
            ctx.fillRect(
                cellPosition.x + offsetLeft,
                cellPosition.y + this.gridConfig.cellHeight - borderBottom.thickness,
                this.gridConfig.cellWidth - (offsetLeft + offsetRight),
                borderBottom.thickness
            )
            offsetBottom = borderBottom.thickness
        }

        // set color
        this.setDrawingColor(ctx, cell.backgroundColor)
        // draw full cell in solid color
        ctx.fillRect(
            cellPosition.x + offsetLeft,
            cellPosition.y + offsetTop,
            this.gridConfig.cellWidth - (offsetLeft + offsetRight),
            this.gridConfig.cellHeight - (offsetTop + offsetBottom)
        )

        ctx.globalAlpha = 1
    }

    /**
     * Calculates absolute cell position
     *
     * @param x cell (column) index
     * @param y row index
     * @returns absolute cell position
     */
    protected cellPosition(x: number, y: number): { x: number; y: number } {
        return {
            x: this.gridConfig.cellWidth * x,
            y: this.gridConfig.cellHeight * y,
        }
    }

    protected canvasWidth(cols: number): number {
        return this.gridConfig.cellWidth * cols
    }

    protected canvasHeight(rows: number): number {
        return this.gridConfig.cellHeight * rows
    }

    /**
     * Returns first specified BorderConfig
     * parameter => girdConfig.border => 0
     * @param config
     * @returns
     */
    private borderConfig(config: BorderConfig): BorderConfig {
        if (config) {
            return config
        } else if (this.gridConfig.border) {
            return this.gridConfig.border
        }
        return undefined
    }

    /**
     * Generate new canvas element fitting the full image
     * @private
     */
    private createCanvas(cols: number, rows: number): HTMLCanvasElement {
        const canvasElement = document.createElement('canvas')
        canvasElement.width = this.canvasWidth(cols)
        canvasElement.height = this.canvasHeight(rows)
        return canvasElement
    }
}
