import { Note, Pattern, PatternType, TrackPatternMapping } from '../models/project-state.model'
import { getRandomId } from './id-generators'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'
import { MAX_GRID_RESOLUTION } from './piano-roll-util'
import { Color } from '../enums/Color'

export function lightenDarkenColor(col, amt): string {
    let usePound = false

    if (col[0] === '#') {
        col = col.slice(1)
        usePound = true
    }

    const num = parseInt(col, 16)

    let r = (num >> 16) + amt

    if (r > 255) {
        r = 255
    } else if (r < 0) {
        r = 0
    }

    let b = ((num >> 8) & 0x00ff) + amt

    if (b > 255) {
        b = 255
    } else if (b < 0) {
        b = 0
    }

    let g = (num & 0x0000ff) + amt

    if (g > 255) {
        g = 255
    } else if (g < 0) {
        g = 0
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function getUnixTimeStampByDate(date: Date): number {
    return Math.floor(date.getTime() / 1000)
}

export function cutTrackIntoPatterns(
    pattern: Pattern,
    patternPartLengthIRN: number
): { patterns: Pattern[]; trackPatternMappings: TrackPatternMapping[] } {
    const buckets = pattern.notes.reduce((a, b) => {
        const index = Math.floor(b.startIRN / patternPartLengthIRN)

        if (!a[index]) {
            // todo: ![{}]=false everytime?
            const pattern: Pattern = {
                id: getRandomId(),
                notes: [],
                lastResolution: BeatResolutionEnum.r1_4, // todo: read global default
                type: PatternType.PianoRollPattern,
                lengthIRN: patternPartLengthIRN,
                color: Color.GREEN, // todo: add random color?
            }
            a[index] = pattern
        }

        a[index].notes.push({
            ...b,
            startIRN: b.startIRN - index * patternPartLengthIRN,
        } as Note)

        return a
    }, {})

    // {0: []. 1: []. 2: []}
    const trackPatternMappings = Object.values(buckets).map((pattern: Pattern, index: number) => {
        const trackPatternMapping: TrackPatternMapping = {
            id: getRandomId(),
            patternId: pattern.id,
            length: patternPartLengthIRN,
            position: index * patternPartLengthIRN,
            startAt: 0,
        }
        return trackPatternMapping
    })

    return {
        patterns: Object.values(buckets),
        trackPatternMappings: trackPatternMappings,
    }
}

export function calculateBPMFromMicroseconds(microsecondsPerBar: number): number {
    const microsecondsInOneMinute = 60000000 // 60 seconds * 1,000,000 microseconds per second
    const BPM = microsecondsInOneMinute / microsecondsPerBar
    return Math.round(BPM * 100) / 100 // round to last two digits after comma
}
