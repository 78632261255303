import { Pipe, PipeTransform } from '@angular/core'
import { MAX_GRID_RESOLUTION } from '../util/piano-roll-util'

/**
 * Transforms an IRN into a readable string
 * e.g. with 4/4 and irn is 96 you would get 0:2
 */
@Pipe({ name: 'bar' })
export class BarPipe implements PipeTransform {
    transform(value: number, timeDivisionNumerator: number): string {
        const barLength = MAX_GRID_RESOLUTION
        const currentBar = 1 + Math.floor(value / barLength)
        const currentBeat = 1 + Math.floor((value % barLength) / (barLength / timeDivisionNumerator))
        return currentBar + ':' + currentBeat
    }
}
