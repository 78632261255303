import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ActionController } from '../actions.decorator'
import {
    ChordType,
    DefaultAction,
    getChordAbbreviation,
    Logger,
    PrToolEnum,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { SelectDialogService } from '@tekbox-coco/midiative-components'
import { SetPrSelectedChordTypeAction, SetPrSelectedToolAction } from '../../store/actions/ui-state.actions'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollSetChordAction',
})
export class PianoRollSetChordAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollSetChordAction')

    private prSelectedChordType: ChordType

    constructor(private store: Store<AppState>, private selectDialogService: SelectDialogService) {
        super()

        this.icon.next('playlist_add')
        this.subscriptions.push(
            this.store.select('uiState').subscribe({
                next: async (value) => {
                    if (value.prSelectedChordType) {
                        this.prSelectedChordType = value.prSelectedChordType
                        this.icon.next('')
                        this.label.next(getChordAbbreviation(value.prSelectedChordType))
                    }
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    async onClick() {
        const chordTypes = Object.keys(ChordType)
        const chordItems = Object.values(ChordType).map((cT, index) => {
            return {
                label: cT,
                value: chordTypes[index],
            }
        })
        const chordItem = await this.selectDialogService.showSelect({
            items: chordItems,
        })

        if (chordItem) {
            this.store.dispatch(new SetPrSelectedToolAction(PrToolEnum.CHORD))
            this.store.dispatch(new SetPrSelectedChordTypeAction(chordItem.label as ChordType))
        }
    }
}
