import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CursorComponent } from './cursor.component'

@NgModule({
    imports: [CommonModule],
    declarations: [CursorComponent],
    exports: [CursorComponent],
})
export class CursorModule {}
