import { DefaultAction, AppState } from '@tekbox-coco/midiative-commons'
import { ModalService } from '@tekbox-coco/midiative-components'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { StorageService } from '../../services/storage/storage.service'
import { ToastService } from '../../services/toast.service'
import { NavigateToHomeAction } from '../navigate/NavigateToHomeAction'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ProjectDeleteAction',
})
export class ProjectDeleteAction extends DefaultAction {
    private projectId: string = ''

    constructor(
        private storageService: StorageService,
        private store: Store<AppState>,
        private modalService: ModalService,
        private toastService: ToastService,
        private navigateToHomeAction: NavigateToHomeAction
    ) {
        super()
        this.icon.next('delete')
        this.label.next('PROJECT.MENU.DELETE')

        this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectId = value.id
                this.visible.next(!value.demo)
            },
            error: (e) => {
                console.error(e)
            },
        })
    }

    async onClick() {
        const result = await this.modalService.showOptionsDialog({
            options: [
                {
                    label: 'COMMON.NO',
                    value: false,
                },
                {
                    label: 'COMMON.YES',
                    value: true,
                },
            ],
            allowClose: true,
            headline: 'PROJECT.MODAL.DELETE_PROJECT',
            text: '',
        })
        if (result) {
            await this.storageService.deleteProject(this.projectId)
            this.toastService.info('PROJECT.DELETE_PROJECT_TOAST')
            this.navigateToHomeAction.onClick()
        }
    }
}
