import { Injectable, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { Color, Logger, ProjectStateModel, AppState } from '@tekbox-coco/midiative-commons'

@Injectable({
    providedIn: 'root',
})
export class ColorService implements OnDestroy {
    private readonly logger = Logger.createLogger('ColorService')

    private projectStateSubscription: Subscription

    constructor(private store: Store<AppState>) {
        this.usedPatternColors = []
        this.projectStateSubscription = store.select('projectState').subscribe({
            next: (projectState) => (this.projectState = projectState),
            error: (e) => this.logger.error(e),
        })
    }

    private usedPatternColors: Color[]

    private projectState: ProjectStateModel

    private getRandomColor(): Color {
        return this.getColorEnumAsArray()[0]
    }

    ngOnDestroy(): void {
        this.projectStateSubscription.unsubscribe()
    }

    getUniquePatternColor(): Color {
        Object.values(this.projectState.patterns.entities).forEach((p) => this.usedPatternColors.push(p.color))

        let uniqueColor = ''

        const colors = this.getColorEnumAsArray()
        colors.forEach((color, i) => {
            const currElem = colors[i]
            if (this.usedPatternColors.indexOf(currElem) === -1) {
                uniqueColor = currElem
            }
        })

        if (uniqueColor === '') {
            return this.getRandomColor()
        } else {
            return uniqueColor as Color
        }
    }

    public getColorEnumAsArray(): Color[] {
        return this.shuffle(Object.values(Color))
    }

    private shuffle(array) {
        let currentIndex = array.length
        let temporaryValue
        let randomIndex

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex -= 1

            // And swap it with the current element.
            temporaryValue = array[currentIndex]
            array[currentIndex] = array[randomIndex]
            array[randomIndex] = temporaryValue
        }

        return array
    }

    public getTrackColorForImportedTrackPatterns(channelNo: number): Color {
        switch (channelNo) {
            case 0:
                return Color.RED
            case 1:
                return Color.TEAL
            case 2:
                return Color.GREEN
            case 3:
                return Color.YELLOW
            case 4:
                return Color.ORANGE
            case 5:
                return Color.PURPLE
            case 6:
                return Color.CYAN
            case 7:
                return Color.MAGENTA
            case 8:
                return Color.LIME
            case 9:
                return Color.BLUE
            case 10:
                return Color.LAVENDER
            case 11:
                return Color.BROWN
            case 12:
                return Color.MAROON
            case 13:
                return Color.MINT
            case 14:
                return Color.OLIVE
            case 15:
                return Color.APRICOT
            default:
                return this.getUniquePatternColor()
        }
    }
}
