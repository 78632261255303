import {
    Color,
    initialLoopSelectorState,
    Instruments,
    KeyType,
    PatternType,
    ProjectStateModel,
    ScaleType,
} from '@tekbox-coco/midiative-commons'
import { DemoIds } from './demoIds'

export const HipHopDemoProject: ProjectStateModel = {
    id: DemoIds.HIP_HOP,
    name: 'HipHop-Demo',
    demo: true,
    bpm: 106,
    timeSignatureNumerator: 4,
    timeSignatureDenominator: 4,
    key: KeyType.A,
    schemaVersion: 1,
    scale: ScaleType.PENT_MIN,
    loopSelector: initialLoopSelectorState,
    created: 0,
    lastEdit: 0,
    patterns: {
        ids: ['patternId1', 'patternId2', 'patternId3'],
        entities: {
            patternId1: {
                id: 'patternId1',
                lastResolution: 1,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '32509536-280d-4c33-93e2-50662d4b8155',
                        startIRN: 0,
                        key: 57,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: 'f1311128-3264-4215-a51f-e1eb8e780733',
                        startIRN: 0,
                        key: 60,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '2eef0c10-829c-43bf-bec6-81c7534467ba',
                        startIRN: 0,
                        key: 64,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '2c3c2848-1467-456d-a8c6-965236021fbf',
                        startIRN: 384,
                        key: 60,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '60e8791c-0012-4081-ae7f-dd22c2c8fde0',
                        startIRN: 384,
                        key: 64,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: 'd1346d40-0839-4824-96c6-43471ed73de7',
                        startIRN: 384,
                        key: 67,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: 'd6cb1b0f-b62f-4bd5-9f11-3b93fba2d238',
                        startIRN: 768,
                        key: 55,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '3ebefdf7-367f-4164-bd1b-c908e12e0d40',
                        startIRN: 768,
                        key: 59,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: 'ba8ec472-772e-4f41-ad04-61b0bb1e091a',
                        startIRN: 768,
                        key: 62,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '3b90ff1d-9fd0-41c4-8229-addce92e29d9',
                        startIRN: 1152,
                        key: 53,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: 'e4adae0f-fa28-462c-b703-c8f8d2694650',
                        startIRN: 1152,
                        key: 57,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                    {
                        id: '25fe2673-830a-4aad-ac92-9b21635a52fc',
                        startIRN: 1152,
                        key: 60,
                        lengthIRN: 384,
                        velocity: 127,
                    },
                ],
                color: Color.RED,
                type: PatternType.PianoRollPattern,
            },
            patternId2: {
                id: 'patternId2',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '9657267a-3b92-4d7b-81f7-8ef5274c6192',
                        startIRN: 192,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '87c93ec1-0085-459f-8be7-779ddd0fc0f9',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5cb47200-952f-40b1-aa91-0b717b06ff10',
                        startIRN: 864,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fe1e4e58-4e9d-420b-8cdd-02cce544dd36',
                        startIRN: 912,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '24f8faeb-1710-4c14-a936-77811510e529',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c8ecee57-329a-4cec-89a2-7e22ec5f443e',
                        startIRN: 432,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b23de32c-9e33-4eff-986a-0bc98486f06f',
                        startIRN: 1296,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f9162671-2ced-4090-92aa-4a1db2c8c352',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '95285cac-b3d4-4a17-9533-47cd8c44f61a',
                        startIRN: 1248,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.CYAN,
                type: PatternType.PianoRollPattern,
            },
            patternId3: {
                id: 'patternId3',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: 'a3293aee-a79c-493d-b1db-838d1a7a1aa1',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'f527c1bb-02cd-4c51-a0cb-0488fe2f5ddd',
                        key: 42,
                        startIRN: 96,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'b30a9dad-2118-4406-b9a6-d82818f0a41f',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '1bfd32ce-73af-411c-8359-a95577025f9a',
                        key: 42,
                        startIRN: 288,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '226cb32f-a90c-4a58-b501-e6f5f02fbbc3',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '7aa95400-da0f-4f18-9138-137d9add6a58',
                        key: 42,
                        startIRN: 480,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '913f8ab0-7f18-4229-b657-4544af796477',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '9524832f-0a92-45b4-89a4-a5035c389fe3',
                        key: 42,
                        startIRN: 672,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'efeea602-afc7-43d5-8f4c-9918e2bfc014',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'f3498b7c-ba74-448a-98e8-5b490aa625a8',
                        key: 42,
                        startIRN: 864,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'b4b3bd67-9040-47d4-985b-355eeb26cd2a',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '5ba38804-1970-487f-884d-27882ae3cf2d',
                        key: 42,
                        startIRN: 1056,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '612400a0-ec4a-4152-aa0f-9c9be4f8335d',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'e9b5911d-276a-4b88-b50c-ed7cee49e7e3',
                        key: 42,
                        startIRN: 1248,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'e9e21d64-68f9-458f-943d-344470b7a261',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '619a91e4-884d-4aa3-a032-def5c7d8debb',
                        key: 42,
                        startIRN: 1440,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.YELLOW,
                type: PatternType.SequencerPattern,
            },
        },
    },
    tracks: {
        ids: ['trackId1', 'trackId2', 'trackID3'],
        entities: {
            trackId1: {
                id: 'trackId1',
                channel: 0,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    'de83fd70-9dd8-4d99-81ba-986bdd2eff6a': {
                        id: 'de83fd70-9dd8-4d99-81ba-986bdd2eff6a',
                        patternId: 'patternId1',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            trackId2: {
                id: 'trackId2',
                channel: 1,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    '84fd6d72-776b-4f44-af31-1df0011aae53': {
                        id: '84fd6d72-776b-4f44-af31-1df0011aae53',
                        patternId: 'patternId2',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            trackID3: {
                id: 'trackID3',
                channel: 9,
                instrument: Instruments.STEEL_DRUMS,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    '53421257-a1c9-4aca-aae0-18ec5c586980': {
                        id: '53421257-a1c9-4aca-aae0-18ec5c586980',
                        patternId: 'patternId3',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
        },
    },
}
