<ion-select
    class="hidden"
    (ionChange)="onChange($event)"
    interface="action-sheet"
    [value]="defaultValue ? defaultValue : {}"
>
    <ion-select-option *ngFor="let entry of items" [value]="entry.value">
        {{ entry.label }}
    </ion-select-option>
</ion-select>
