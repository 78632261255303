import { Logger } from '../logger/Logger'
import { ProjectStateModel, TrackPatternMapping } from '../models/project-state.model'

const logger = Logger.createLogger('ProjectStoreUtil')

/**
 * Resolves { @link  TrackPatternMapping } from { @link ProjectStateModel} for given {@param patternId }
 * @param state { @link  }  instance to search pattern mapping in
 * @param patternId pattern id to search
 * @return the track pattern mapping if found or undefined otherwise
 */
export function searchMappings(patternId: string, state: ProjectStateModel): TrackPatternMapping {
    const tracks = Object.values(state.tracks.entities)
    const trackPatternsMappingsList = tracks.map((e) => Object.values(e.patterns))
    const trackMappings = trackPatternsMappingsList.reduce((a, b) => a.concat(b), [])
    const filtered = trackMappings.filter((e) => e !== undefined).filter((e) => e.patternId === patternId)

    if (filtered.length > 0) {
        return filtered[0]
    }

    return undefined
}

/**
 * Get channel for { @link  patternId }
 * @param patternId pattern id to search
 * @param state current { @link  ProjectStateModel }
 * @return channel of current state{ @link  ProjectStateModel }  or -1 if the mapping for the pattern id is not found
 */
export function getChannelForPattern(patternId: string, state: ProjectStateModel): number {
    const mapping = searchMappings(patternId, state)
    if (mapping) {
        const tracks = Object.values(state.tracks.entities)
        const track = tracks.filter((t) => {
            return !!t.patterns[mapping.id]
        })

        if (track.length > 0) {
            return track[0].channel
        }
    }
    return -1
}

/**
 * Takes an array of { @link  TrackPatternMapping[] }  and returns the
 * Record representation for the track pattern mapping.
 * @param mappings
 */
export function trackPatternMappingArrayToRecord(mappings: TrackPatternMapping[]): Record<string, TrackPatternMapping> {
    return mappings.reduce((acc, item) => ((acc[item.id] = item), acc), {} as Record<string, TrackPatternMapping>)
}
