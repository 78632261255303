import { Pipe, PipeTransform } from '@angular/core'
import { MusicTheory } from '../music-theory/music-theory'

@Pipe({ name: 'notename' })
export class NoteNamePipe implements PipeTransform {
    transform(value: number): string {
        const mt = new MusicTheory(0, 88)
        return mt.getCompleteNoteString(value, false)
    }
}
