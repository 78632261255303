import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ProjectStateActionTypes } from '../actions/project-state.actions'
import { tap } from 'rxjs'
import { StorageService } from '../../services/storage/storage.service'
import { DrumSequencerStateActionTypes } from '../actions/drum-sequencer-state.actions'

@Injectable()
export class AutoSaveEffect {
    public autoSave

    constructor(private actions: Actions, private storageService: StorageService) {
        this.autoSave = createEffect(
            () => {
                return this.actions.pipe(
                    ofType(
                        ProjectStateActionTypes.AddPattern,
                        ProjectStateActionTypes.AddPatterns,
                        ProjectStateActionTypes.UpsertPattern,
                        ProjectStateActionTypes.UpsertPatterns,
                        ProjectStateActionTypes.UpdatePattern,
                        ProjectStateActionTypes.UpdatePatterns,
                        ProjectStateActionTypes.DeletePattern,
                        ProjectStateActionTypes.DeletePatterns,
                        ProjectStateActionTypes.ClearPatterns,
                        ProjectStateActionTypes.CutPattern,

                        ProjectStateActionTypes.UpdateAllMappingsForPattern,

                        // TODO: We cannot save on add track since we add a initial track every time we load a project, e.g. the demo projects
                        // ProjectStateActionTypes.AddTrack,
                        ProjectStateActionTypes.DeleteTrack,
                        ProjectStateActionTypes.UpdateTrack,
                        ProjectStateActionTypes.UpsertTrack,

                        ProjectStateActionTypes.SetName,
                        ProjectStateActionTypes.SetBPM,
                        ProjectStateActionTypes.SetKey,
                        ProjectStateActionTypes.SetScale
                    ),
                    tap(() => {
                        void this.storageService.saveCurrentProject()
                    })
                )
            },
            { dispatch: false }
        )
    }
}
