import { Component, Input, OnInit } from '@angular/core'
import {
    BottomRightCornerPosition,
    createBottomRightCornerAnimation,
    generateFadeInOutAnimation,
    getCircularButtonCoordinates,
    ToolSelectionMenuButton,
} from '@tekbox-coco/midiative-commons'
import { ToolSelectionMenuService } from './tool-selection-menu.service'

@Component({
    selector: 'app-tool-selection-menu',
    templateUrl: './tool-selection-menu.html',
    animations: [
        generateFadeInOutAnimation('toolbarButtonAnimation'),
        createBottomRightCornerAnimation('cornerAnimation'),
    ],
})
export class ToolSelectionMenuComponent implements OnInit {
    buttons: ToolSelectionMenuButton[]
    open: boolean
    selectedTool: ToolSelectionMenuButton

    @Input() numberOfButtonsPerCircle: number[]

    @Input() toolSelectionMenuService: ToolSelectionMenuService

    ngOnInit() {
        this.toolSelectionMenuService.open.subscribe({
            next: (value: boolean) => {
                this.open = value
            },
        })
        this.toolSelectionMenuService.buttons.subscribe({
            next: (value: ToolSelectionMenuButton[]) => {
                this.buttons = value
            },
        })
        this.toolSelectionMenuService.selectedTool.subscribe({
            next: (value: ToolSelectionMenuButton) => {
                this.selectedTool = value
            },
        })
    }

    getButtonCoordinates(index: number, radius: number): BottomRightCornerPosition {
        return getCircularButtonCoordinates(this.numberOfButtonsPerCircle, index, radius)
    }

    onToolSelected(button: ToolSelectionMenuButton) {
        this.toolSelectionMenuService.selectTool.next(button)
    }

    onClickOutside() {
        this.toolSelectionMenuService.selectTool.next(null)
    }
}
