import { DefaultAction, AppState } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { ModalService } from '@tekbox-coco/midiative-components'
import { ActionController } from '../../actions.decorator'
import { Store } from '@ngrx/store'
import { DeleteTrackAction as StoreDeleteTrackAction } from '../../../store/actions/project-state.actions'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'DeleteTrackAction',
})
export class DeleteTrackAction extends DefaultAction<string> {
    constructor(private store: Store<AppState>, protected modalService: ModalService) {
        super()
        this.label.next('Delete Track')
        this.icon.next('delete_outline')
    }
    async onClick(id: string) {
        const result = await this.modalService.showOptionsDialog({
            options: [
                {
                    label: 'COMMON.NO',
                    value: false,
                },
                {
                    label: 'COMMON.YES',
                    value: true,
                },
            ],
            allowClose: true,
            headline: 'PROJECT.MODAL.DELETE_TRACK',
            text: '',
        })
        if (result) {
            this.store.dispatch(new StoreDeleteTrackAction(id))
        }
    }
}
