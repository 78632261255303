import { defaultEnvironment } from './environment.default'
import { MIDIativeEnvironment } from './midiative-environment'
import { LogLevel } from '@tekbox-coco/midiative-commons'

export const environment: MIDIativeEnvironment = {
    ...defaultEnvironment,
    production: true,
    showAds: true,
    runtime: 'web',
    sentryEnv: 'production',
    logger: {
        default: LogLevel.INFO,
        logger: {
            SentryEnabled: LogLevel.DEBUG,
        },
    },
    features: {},
}
