import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ActionTask, HudActionButtonConfig, HudConfig, HudInfoPanelConfig } from '@tekbox-coco/midiative-commons'
import { ToolSelectionMenuService } from './tool-selection-menu/tool-selection-menu.service'

@Component({
    selector: 'lib-hud',
    templateUrl: './hud.component.html',
})
export class HudComponent implements OnInit, OnChanges {
    // if true the tile component for additional actions is rendered
    public additionalActionsOpen = false
    // these are the rendered action including close action
    public additionalActions: (string | HudActionButtonConfig)[] = []
    // if true the drawer menu is rendered
    public drawerMenuOpen = false

    public renderedToolbarItems: (string | HudActionButtonConfig)[] = []
    public renderedSidebarItems: HudActionButtonConfig[] = []

    public selectedTool: string = ''

    @Input()
    config: HudConfig = {}

    @Input()
    inputPanelConfig: HudInfoPanelConfig

    constructor(private injector: Injector, public toolSelectionMenuService: ToolSelectionMenuService) {}

    ngOnInit() {
        this.updateRenderedAdditionalActions()
        this.updateRenderedToolbarItems()
        this.updateRenderedSidebarItems()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateRenderedAdditionalActions()
        this.updateRenderedToolbarItems()
        this.updateRenderedSidebarItems()
    }

    onClickAdditionalActionsButton() {
        this.additionalActionsOpen = !this.additionalActionsOpen
    }

    onClickDrawerMenuButton() {
        this.drawerMenuOpen = !this.drawerMenuOpen
    }

    onClickOutsideDrawerMenu() {
        this.drawerMenuOpen = false
    }

    /**
     * Updates the additionalActions variable.
     * Combine actions supplied via config input with default actions (close overlay)
     * Also remaps all actions to trigger close overlay before calling original callback
     * @private
     */
    private updateRenderedAdditionalActions() {
        this.additionalActions = [
            ...(this.config.additionalActions ? this.config.additionalActions.actions : []),
            'CloseOverlayAction',
        ]
    }

    updateRenderedToolbarItems() {
        this.renderedToolbarItems = []

        // render additionalActions button as toolbar item
        if (this.additionalActions.length > 0) {
            this.renderedToolbarItems.push('AdditionalActionsButtonAction')
        }

        // add default actions
        this.renderedToolbarItems = [...this.renderedToolbarItems, ...(this.config.toolbar.buttons || [])]
    }

    updateRenderedSidebarItems() {
        if (this.config.sidePanel) {
            this.renderedSidebarItems = this.config.sidePanel.actions
                .map((e) => {
                    if (typeof e === 'string') {
                        return {
                            action: e,
                        }
                    }
                    return e
                })
                .filter((e) => e != undefined)
                .filter((item) => {
                    const actionTask = <ActionTask>this.injector.get((item as HudActionButtonConfig).action)
                    if (actionTask) {
                        return actionTask.visible.value
                    }
                    return false
                }) as HudActionButtonConfig[]
        }
    }

    /**
     * run additional actions after action runner
     * @param action
     */
    onClickToolbarItem(action: string) {
        if (action === 'onClick:AdditionalActionsButtonAction') {
            this.onClickAdditionalActionsButton()
        }
    }

    /**
     * Close overlay when additional action triggered
     * @param $event
     */
    onClickAdditionalActionItem($event: string) {
        this.additionalActionsOpen = !this.additionalActionsOpen
    }

    onClickTileMenuItem($event: any) {
        this.drawerMenuOpen = !this.drawerMenuOpen
    }
}
