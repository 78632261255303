import { Action } from '@ngrx/store'
import {
    Language,
    LastMidiDeviceConnection,
    SettingsStateModel,
    Soundfont,
    TutorialEnum,
} from '@tekbox-coco/midiative-commons'

export enum SettingsStateActionTypes {
    LoadSettings = '[Settings] LoadSettings',
    SetShowScales = '[Settings] SetShowScales',
    SetSoundfont = '[Settings] SetSoundfont',
    SetLastMidiDeviceConnection = '[Settings] SetLastMidiDeviceConnection',
    SetLanguage = '[Settings] SetLanguage',
    SetTutorialPassed = '[Settings] SetTutorialPassed',
    ResetTutorials = '[Settings] ResetTutorials',
}

export class LoadSettingsAction implements Action {
    readonly type = SettingsStateActionTypes.LoadSettings

    constructor(public payload: SettingsStateModel) {}
}

export class SetShowScalesAction implements Action {
    readonly type = SettingsStateActionTypes.SetShowScales

    constructor(public payload: boolean) {}
}
export class SetSoundfontAction implements Action {
    readonly type = SettingsStateActionTypes.SetSoundfont

    constructor(public payload: Soundfont) {}
}

export class SetLastMidiDeviceConnectionAction implements Action {
    readonly type = SettingsStateActionTypes.SetLastMidiDeviceConnection

    constructor(public payload: LastMidiDeviceConnection) {}
}

export class SetLanguageAction implements Action {
    readonly type = SettingsStateActionTypes.SetLanguage

    constructor(public payload: Language) {}
}

export class SetTutorialPassedAction implements Action {
    readonly type = SettingsStateActionTypes.SetTutorialPassed

    constructor(public payload: TutorialEnum) {}
}
export class ResetTutorialsAction implements Action {
    readonly type = SettingsStateActionTypes.ResetTutorials
}

export type SettingsStateActions =
    | LoadSettingsAction
    | SetShowScalesAction
    | SetSoundfontAction
    | SetLastMidiDeviceConnectionAction
    | SetLanguageAction
    | SetTutorialPassedAction
    | ResetTutorialsAction
