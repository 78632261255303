import { ExistingProvider } from '@angular/core'
import { ActionDecoratorOptions, annotatedActionControllers, Class } from './actions.decorator'

function createActionControllerConfigProvider(
    target: Class<unknown>,
    options: ActionDecoratorOptions
): ExistingProvider {
    return {
        provide: options.name,
        useExisting: target,
    }
}

export function createActionProvidersProviders(): ExistingProvider[] {
    return annotatedActionControllers.map((definition) => {
        return createActionControllerConfigProvider(definition.target, definition.options)
    })
}
