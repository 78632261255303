import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionTask } from '@tekbox-coco/midiative-commons'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'AdditionalActionsButtonAction',
})
export class AdditionalActionsButtonAction extends DefaultAction implements ActionTask {
    constructor() {
        super()
        this.icon.next('menu')
        this.buttonClasses.next(['bg-yellow-50'])
        this.domID.next('project_additional-actions')
    }

    onClick() {
        // do noting
    }
}
