import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SelectDialogComponent } from './select-dialog.component'
import { IonicModule } from '@ionic/angular'

@NgModule({
    declarations: [SelectDialogComponent],
    imports: [CommonModule, IonicModule],
    exports: [SelectDialogComponent],
})
export class SelectDialogModule {}
