<div
    class="flex w-full flex-row"
    [ngStyle]="{
        'margin-left': offsetLeft ? offsetLeft + 'vw' : '',
        'margin-right': offsetRight ? offsetRight + 'vw' : ''
    }"
>
    <div class="flex grow text-white" *ngFor="let item of renderedToolbarItems; let i = index">
        <app-action-button
            class="grow"
            (onClick)="onClickActionButton($event)"
            [mode]="item.options && item.options.mode ? item.options.mode : 'iconOnly'"
            [actionName]="item"
            [iconClasses]="[
                '!text-[14px]',
                'md:!text-[20px]',
                '!w-[14px]',
                '!h-[14px]',
                'md:!w-[20px]',
                'md:!h-[20px]'
            ]"
            [labelClasses]="['!text-[14px]', 'md:!text-[20px]', '!leading-[14px]', 'md:!leading-[20px]']"
            [innerClasses]="[
                i === 0 ? 'border-l-0' : '',
                i === toolbarItems.length - 1 ? 'border-r-0' : '',
                'border-1',
                'border-b-0',
                'animation-1',
                'border-black-45',
                'justify-center',
                'items-center'
            ]"
        ></app-action-button>
    </div>
</div>
