import { Action } from '@ngrx/store'

export enum HistoryActionTypes {
    ClearHistory = '[History] ClearHistory',
    Undo = '[History] Undo',
    Redo = '[History] Redo',
}

export class ClearHistoryAction implements Action {
    readonly type = HistoryActionTypes.ClearHistory
}

export class UndoAction implements Action {
    readonly type = HistoryActionTypes.Undo
}

export class RedoAction implements Action {
    readonly type = HistoryActionTypes.Redo
}

export type HistoryStateActions = ClearHistoryAction | UndoAction | RedoAction
