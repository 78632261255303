import { Action } from '@ngrx/store'
import { BeatResolutionEnum, DisplayNote, Instruments, Pattern } from '@tekbox-coco/midiative-commons'
import { Update } from '@ngrx/entity'

export enum PianoRollStateActionTypes {
    // General
    LoadPattern = '[PianoRoll] LoadPattern',
    // Background
    SetRows = '[PianoRoll] SetRows',
    SetInstrument = '[PianoRoll] SetInstrument',
    SetChannel = '[PianoRoll] SetChannel',
    SetName = '[PianoRoll] SetName',
    SetPatternId = '[PianoRoll] SetPatternId',
    SetPatternLengthIRN = '[PianoRoll] SetPatternLengthIRN',
    SetResolution = '[PianoRoll] SetResolution',
    // Cursor
    SetCursorPosition = '[PianoRoll] SetCursorPosition',
    IterateCursorPosition = '[PianoRoll] IterateCursorPosition',
    Play = '[PianoRoll] Play',
    Pause = '[PianoRoll] Pause',
    JumpToStart = '[PianoRoll] Jump to start',
    JumpToPreviousBeat = '[PianoRoll] Jump to previous beat',
    JumpToNextBeat = '[PianoRoll] Jump to next beat',
    JumpToEnd = '[PianoRoll] Jump to end',
    Loop = '[PianoRoll] Loop',

    // Notes
    SetNotes = '[PianoRoll] SetNotes',
    ClearNotes = '[PianoRoll] ClearNotes',
    AddNote = '[PianoRoll] AddNote',
    AddNotes = '[PianoRoll] AddNotes',
    UpsertNote = '[PianoRoll] UpsertNote',
    UpsertNotes = '[PianoRoll] UpsertNotes',
    UpdateNote = '[PianoRoll] UpdateNote',
    UpdateNotes = '[PianoRoll] UpdateNotes',
    DeleteNote = '[PianoRoll] DeleteNote',
    DeleteNotes = '[PianoRoll] DeleteNotes',
    OctaveUpNotes = '[PianoRoll] OctaveUpNotes',
    OctaveDownNotes = '[PianoRoll] OctaveDownNotes',
}

// General
export class LoadPatternAction implements Action {
    readonly type = PianoRollStateActionTypes.LoadPattern

    constructor(public payload: Pattern) {}
}

// Background
export class SetRowsAction implements Action {
    readonly type = PianoRollStateActionTypes.SetRows

    constructor(public payload: number) {}
}

export class SetInstrumentAction implements Action {
    readonly type = PianoRollStateActionTypes.SetInstrument

    constructor(public payload: Instruments) {}
}

export class SetChannelAction implements Action {
    readonly type = PianoRollStateActionTypes.SetChannel

    constructor(public payload: number) {}
}

export class SetNameAction implements Action {
    readonly type = PianoRollStateActionTypes.SetName

    constructor(public payload: string) {}
}

export class SetPatternIdAction implements Action {
    readonly type = PianoRollStateActionTypes.SetPatternId

    constructor(public payload: string) {}
}

export class SetPatternLengthIRNAction implements Action {
    readonly type = PianoRollStateActionTypes.SetPatternLengthIRN

    constructor(public payload: number) {}
}

export class SetResolutionAction implements Action {
    readonly type = PianoRollStateActionTypes.SetResolution

    constructor(public payload: BeatResolutionEnum) {}
}

// NOTES

export class SetNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.SetNotes

    constructor(public payload: DisplayNote[]) {}
}

export class ClearNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.ClearNotes
}

export class AddNoteAction implements Action {
    readonly type = PianoRollStateActionTypes.AddNote

    constructor(public payload: DisplayNote) {}
}

export class AddNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.AddNotes

    constructor(public payload: DisplayNote[]) {}
}

export class UpsertNoteAction implements Action {
    readonly type = PianoRollStateActionTypes.UpsertNote

    constructor(public payload: DisplayNote) {}
}

export class UpsertNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.UpsertNotes

    constructor(public payload: DisplayNote[]) {}
}

export class UpdateNoteAction implements Action {
    readonly type = PianoRollStateActionTypes.UpdateNote

    constructor(public payload: Update<DisplayNote>) {}
}

export class UpdateNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.UpdateNotes

    constructor(public payload: Update<DisplayNote>[]) {}
}

export class DeleteNoteAction implements Action {
    readonly type = PianoRollStateActionTypes.DeleteNote

    constructor(public payload: string) {}
}

export class DeleteNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.DeleteNotes

    constructor(public payload: string[]) {}
}

export class OctaveUpNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.OctaveUpNotes
}

export class OctaveDownNotesAction implements Action {
    readonly type = PianoRollStateActionTypes.OctaveDownNotes
}

export type PianoRollStateActions =
    | LoadPatternAction
    | SetRowsAction
    | SetInstrumentAction
    | SetChannelAction
    | SetNameAction
    | SetPatternIdAction
    | SetPatternLengthIRNAction
    | SetResolutionAction
    | SetNotesAction
    | ClearNotesAction
    | AddNoteAction
    | AddNotesAction
    | UpsertNoteAction
    | UpsertNotesAction
    | UpdateNoteAction
    | UpdateNotesAction
    | DeleteNoteAction
    | DeleteNotesAction
    | OctaveUpNotesAction
    | OctaveDownNotesAction
