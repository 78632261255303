import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ShortcutOverlayService } from './shortcut-overlay.service'
import { SHORTCUTS, Shortcut, SubscriptionManager, getRouteType } from '@tekbox-coco/midiative-commons'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'

@Component({
    selector: 'app-shortcut-overlay',
    templateUrl: './shortcut-overlay.component.html',
})
export class ShortcutsOverlayComponent extends SubscriptionManager {
    @Input()
    isOpen: boolean = false
    shortcuts: Partial<Shortcut>[]

    private shortcutHandler: Array<Shortcut> = []
    private currentRoute: string

    constructor(public shortcutOverlayService: ShortcutOverlayService, private router: Router) {
        super()
        this.addSubscription(
            this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: any) => {
                this.currentRoute = event.url
                this.getShortcuts()
            })
        )
    }

    getShortcuts(): void {
        this.shortcuts = SHORTCUTS[getRouteType(this.currentRoute)] || []
    }

    getButtonLabel(buttonLabel: string): string[] {
        return buttonLabel.split('+').map((b) => this.getButtonLabelText(b))
    }

    private getButtonLabelText(button: string) {
        const keyLabels: { [key: string]: string } = {
            Enter: '⏎',
            Tab: '↹',
            Backspace: '⌫',
            Shift: '⇧',
            Ctrl: 'Ctrl',
            Alt: 'Alt',
            CapsLock: '⇪',
            space: 'Space',
            ArrowUp: '↑',
            ArrowRight: '→',
            ArrowDown: '↓',
            ArrowLeft: '←',
            // Numbers
            '0': '0',
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
            '7': '7',
            '8': '8',
            '9': '9',
            // Alphabets
            a: 'A',
            b: 'B',
            c: 'C',
            d: 'D',
            e: 'E',
            f: 'F',
            g: 'G',
            h: 'H',
            i: 'I',
            j: 'J',
            k: 'K',
            l: 'L',
            m: 'M',
            n: 'N',
            o: 'O',
            p: 'P',
            q: 'Q',
            r: 'R',
            s: 'S',
            t: 'T',
            u: 'U',
            v: 'V',
            w: 'W',
            x: 'X',
            y: 'Y',
            z: 'Z',
            // Special characters
            '`': '`',
            '-': '-',
            '=': '=',
            '[': '[',
            ']': ']',
            '\\': '\\',
            ';': ';',
            "'": "'",
            ',': ',',
            '.': '.',
            '/': '/',
            // Add more keys as needed
        }

        return keyLabels[button] || button
    }
}
