import { TICK_EVENT_IDENTIFIER } from './TickEmitter'
import { Observable } from 'rxjs'
import { QUARTER_BEAT_RESOLUTION } from '@tekbox-coco/midiative-commons'

export class TickCounter {
    private static instance: TickCounter

    private readonly addressObservable: Observable<number>

    private start: number
    private end: number
    private address: number

    private constructor() {
        this.address = 0
        this.addressObservable = new Observable<number>((subscriber) => {
            document.addEventListener(TICK_EVENT_IDENTIFIER, () => {
                subscriber.next(this.address)
                this.handleTick()
            })
        })
    }

    public static getInstance(): TickCounter {
        if (!TickCounter.instance) {
            TickCounter.instance = new TickCounter()
        }

        return TickCounter.instance
    }

    public getAddressObservable(): Observable<number> {
        return this.addressObservable
    }

    public setAddress(addressIRN: number) {
        this.address = addressIRN
    }

    public setStart(startIRN: number) {
        this.start = startIRN
    }

    public setEnd(endIRN: number) {
        this.end = endIRN
    }

    public reset() {
        this.address = this.start
    }

    private handleTick() {
        if (this.address < this.end - QUARTER_BEAT_RESOLUTION) {
            this.address += QUARTER_BEAT_RESOLUTION
        } else {
            this.address = this.start
        }
    }
}
