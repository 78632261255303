import { MIDIativeEnvironment } from './midiative-environment'
import { LogLevel } from '@tekbox-coco/midiative-commons'

export const defaultEnvironment: MIDIativeEnvironment = {
    production: false,
    showAds: false,
    runtime: 'web',
    sentryEnv: 'local',
    logger: {
        default: LogLevel.DEBUG,
        logger: {
            default: LogLevel.INFO,
            MidiJS: LogLevel.INFO,
        },
    },
    features: {
        'settings.debug': true,
    },
}
