import { DefaultAction, AppState, ProjectStateModel } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { ModalService } from '@tekbox-coco/midiative-components'
import { ActionController } from '../../actions.decorator'
import { Store } from '@ngrx/store'
import {
    DeleteTrackAction as StoreDeleteTrackAction,
    UpdateTrackAction,
} from '../../../store/actions/project-state.actions'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'MuteTrackAction',
})
export class MuteTrackAction extends DefaultAction<string> {
    projectState: ProjectStateModel
    constructor(private store: Store<AppState>, protected modalService: ModalService) {
        super()
        this.label.next('M')
        this.buttonClasses.next(['bg-mdv-inactive'])

        this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectState = value
            },
            error: (e) => {
                console.error(e)
            },
        })
    }
    async onClick(id: string) {
        this.store.dispatch(
            new UpdateTrackAction({
                id: id,
                changes: {
                    mute: !this.projectState.tracks.entities[id].mute,
                },
            })
        )
    }
}
