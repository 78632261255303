import { IMidiJS, MidiJSWrapper } from './MidiJS'
import { Instruments } from './Instruments'
import { DrumsEnum } from './Drums'
import { Logger } from '../logger/Logger'
import { Soundfont } from '../models/settings-state.model'

/**
 * Handles midi stuff
 * !!! USE AS SINGLETON !!!
 */
export class MidiHandler {
    private readonly logger = Logger.createLogger('MidiHandler')

    /**
     * Returns { @link IMidiJS } instance
     */
    public get midi(): IMidiJS {
        return this.midiJS
    }

    private static instance: MidiHandler = new MidiHandler()
    protected midiJS: IMidiJS = new MidiJSWrapper()

    public static getInstance(): MidiHandler {
        if (!MidiHandler.instance) {
            MidiHandler.instance = new MidiHandler()
        }
        return MidiHandler.instance
    }

    /**
     * Load the instruments provided in { @link Instruments[] }.
     * @param instruments an Array of { @link Instruments[] }
     */
    public loadInstruments(instruments: Instruments[], soundfont: Soundfont) {
        return new Promise<boolean>((resolve, reject) => {
            MidiHandler.instance.midiJS.loadPlugin({
                soundfontUrl: soundfont.url,
                _soundfont: soundfont,
                instruments: instruments,
                onsuccess: () => {
                    this.logger.debug('Loaded instruments', instruments.join(','))
                },
            })
        })
    }

    /**
     * Resolves instrument id for instrument name
     * @param instrument instrument name
     * @return instrument id
     */
    public getInstrumentId(instrument: Instruments): number {
        return (
            Object.keys(Instruments)
                // map to lower case
                .map((instrumentName) => instrumentName.toLowerCase())
                // instrument id == index in instrument list
                .indexOf(instrument)
        )
    }

    /**
     * Resolves drum id for drum name
     * @param drum drum name
     * @return drum id
     */
    public getDrumId(drum: DrumsEnum): number {
        return (
            Object.keys(DrumsEnum)
                .map((drumName) => drumName.toLowerCase())
                // instrument id == index in instrument list
                .indexOf(drum)
        )
    }
}
