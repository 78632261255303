export enum KeyType {
    C = 'C',
    Cis = 'C#',
    D = 'D',
    Dis = 'D#',
    E = 'E',
    F = 'F',
    Fis = 'F#',
    G = 'G',
    Gis = 'G#',
    A = 'A',
    Ais = 'A#',
    B = 'B',
}

export enum ScaleType {
    // pentatonics
    PENT_MAJ = 'Pentatonic Major',
    PENT_MIN = 'Pentatonic Minor',
    // standard modes
    IONIAN = 'Ionian (Major)',
    DORIAN = 'Dorian',
    PHRYGIAN = 'Phrygian',
    LYDIAN = 'Lydian',
    MIXOLYDIAN = 'Mixolydian',
    AEOLIAN = 'Aeolian (Minor)',
    LOCRIAN = 'Locrian',
    // more special modes
    HARMONIC_MINOR = 'Harmonic Minor',
    IONIAN_AUGMENTED = 'Ionian Augmented',
    PHRYGIAN_DOMINANT = 'Phrygian Dominant',
    MELODIC_MINOR = 'Melodic Minor',
    LYDIAN_AUGMENTED = 'Lydian Augmented',
    OVERTONE = 'Overtone',
    AEOLIAN_DOMINANT = 'Aeolian Dominant',
    ALTERED = 'Altered',
    AUGMENTED = 'Augmented',
    BYZANTINE = 'Byzantine',
    CHROMATIC = 'Chromatic',
    HUNGARIAN_MAJOR = 'Hungarian Major',
    HUNGARIAN_MINOR = 'Hungarian Minor',
    NEAPOLITAN_MAJOR = 'Neapolitan Major',
    NEAPOLITAN_MINOR = 'Neapolitan Minor',
    PROMETHEUS = 'Prometheus',
    PROMETHEUS_NEAPOLITAN = 'Prometheus Neapolitan',
    SPANISH_EIGHT_TONE = 'Spanish Eight Tone',
}

export enum ChordType {
    // MAJOR
    MAJOR = 'Major',
    MAJOR_FULL = 'Major w. Octave',
    MAJOR_7 = 'Major 7',
    MAJOR_9 = 'Major 9',
    MAJOR_11 = 'Major 11',
    MAJOR_13 = 'Major 13',
    // MINOR
    MINOR = 'Minor',
    MINOR_FULL = 'Minor w. Octave',
    MINOR_7 = 'Minor 7',
    MINOR_9 = 'Minor 9',
    MINOR_11 = 'Minor 11',
    MINOR_13 = 'Minor 13',
    // OTHER
    FIVE = 'Fifth',
    SEVEN = 'Seventh',
    AUG = 'Augmented',
    DIM = 'Diminished',
    SUS_2 = 'Sus 2',
    SUS_4 = 'Sus 4',
}

export function getScaleDef(scaleType: ScaleType): number[] {
    switch (scaleType) {
        // pentatonics
        case ScaleType.PENT_MAJ:
            return [0, 2, 4, 7, 9]
        case ScaleType.PENT_MIN:
            return [0, 3, 5, 7, 10]
        // standard modes
        case ScaleType.IONIAN:
            return [0, 2, 4, 5, 7, 9, 11]
        case ScaleType.DORIAN:
            return [0, 2, 3, 5, 7, 9, 10]
        case ScaleType.PHRYGIAN:
            return [0, 1, 3, 5, 7, 8, 10]
        case ScaleType.LYDIAN:
            return [0, 2, 4, 6, 7, 9, 11]
        case ScaleType.MIXOLYDIAN:
            return [0, 2, 4, 5, 7, 9, 10]
        case ScaleType.AEOLIAN:
            return [0, 2, 3, 5, 7, 8, 10]
        case ScaleType.LOCRIAN:
            return [0, 1, 3, 5, 6, 8, 10]
        // more special modes
        case ScaleType.HARMONIC_MINOR:
            return [0, 2, 3, 5, 7, 8, 11]
        case ScaleType.IONIAN_AUGMENTED:
            return [0, 2, 4, 5, 8, 9, 11]
        case ScaleType.PHRYGIAN_DOMINANT:
            return [0, 1, 4, 5, 7, 8, 10]
        case ScaleType.MELODIC_MINOR:
            return [0, 2, 3, 5, 7, 9, 11]
        case ScaleType.LYDIAN_AUGMENTED:
            return [0, 2, 4, 6, 8, 9, 11]
        case ScaleType.OVERTONE:
            return [0, 2, 4, 6, 7, 9, 10]
        case ScaleType.AEOLIAN_DOMINANT:
            return [0, 2, 4, 5, 7, 8, 10]
        case ScaleType.ALTERED:
            return [0, 1, 3, 4, 6, 8, 10]
        case ScaleType.AUGMENTED:
            return [0, 3, 4, 7, 8, 11]
        case ScaleType.BYZANTINE:
            return [0, 1, 4, 5, 7, 8, 11]
        case ScaleType.CHROMATIC:
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        case ScaleType.HUNGARIAN_MAJOR:
            return [0, 3, 4, 6, 7, 9, 10]
        case ScaleType.HUNGARIAN_MINOR:
            return [0, 2, 3, 6, 7, 8, 11]
        case ScaleType.NEAPOLITAN_MAJOR:
            return [0, 1, 3, 5, 7, 9, 11]
        case ScaleType.NEAPOLITAN_MINOR:
            return [0, 1, 3, 5, 7, 8, 11]
        case ScaleType.PROMETHEUS:
            return [0, 2, 4, 6, 9, 10]
        case ScaleType.PROMETHEUS_NEAPOLITAN:
            return [0, 1, 4, 6, 9, 10]
        case ScaleType.SPANISH_EIGHT_TONE:
            return [0, 1, 3, 4, 5, 6, 8, 10]
        default:
            return []
    }
}

export function getChordDef(chordType: ChordType): number[] {
    switch (chordType) {
        case ChordType.MAJOR:
            return [0, 4, 7]
        case ChordType.MAJOR_7:
            return [0, 4, 7, 11]
        case ChordType.MAJOR_9:
            return [0, 4, 7, 11, 14]
        case ChordType.MAJOR_11:
            return [0, 4, 7, 11, 14, 17]
        case ChordType.MAJOR_13:
            return [0, 4, 7, 11, 14, 17, 21]
        case ChordType.MAJOR_FULL:
            return [0, 4, 7, 12]
        case ChordType.MINOR:
            return [0, 3, 7]
        case ChordType.MINOR_7:
            return [0, 3, 7, 10]
        case ChordType.MINOR_9:
            return [0, 3, 7, 10, 14]
        case ChordType.MINOR_11:
            return [0, 3, 7, 10, 14, 17]
        case ChordType.MINOR_13:
            return [0, 3, 7, 10, 14, 17, 21]
        case ChordType.MINOR_FULL:
            return [0, 3, 7, 12]
        case ChordType.AUG:
            return [0, 4, 8]
        case ChordType.DIM:
            return [0, 3, 6]
        case ChordType.SUS_2:
            return [0, 2, 7]
        case ChordType.SUS_4:
            return [0, 5, 7]
        case ChordType.SEVEN:
            return [0, 4, 7, 10]
        case ChordType.FIVE:
            return [0, 7, 12]
        default:
            return []
    }
}
