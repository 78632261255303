import { HudActionButtonConfig, HudConfig } from '@tekbox-coco/midiative-commons'

/**
 * Converts (string | HudActionButtonConfig)[] => HudActionButtonConfig[]
 * @param config action config
 * @returns
 */
export function toActionButtonConfig(config: (string | HudActionButtonConfig)[]): HudActionButtonConfig[] {
    return config.map((e) => {
        if (typeof e === 'string') {
            return {
                action: e,
            }
        }
        return e
    })
}

/**
 * Checks if a list of (string | HudActionButtonConfig)[] contains string | HudActionButtonConfig
 * @param _action action to check
 * @param _allActions all actions
 * @returns true if all actions contains action
 */
export function containsAction(
    _action: string | HudActionButtonConfig,
    _allActions: (string | HudActionButtonConfig)[]
): boolean {
    const allActions = toActionButtonConfig(_allActions)
    const action = toActionButtonConfig([_action])[0]

    return allActions.filter((a) => a.action === action.action).length > 0
}

/**
 * Returns a filter function to remove actions from a list
 * @param items items to remove
 * @returns array filter function
 */
export function filterActions(items: (string | HudActionButtonConfig)[]): (item: HudActionButtonConfig) => boolean {
    const actionsToRemove = toActionButtonConfig(items)
    return (item: HudActionButtonConfig) => {
        for (const action of actionsToRemove) {
            if (action.action === item.action) {
                return false
            }
        }
        return true
    }
}

/**
 * Add action to sidebar config, add again if already exists
 * @param hud hud config
 * @param _action action to add
 * @returns modified hud config
 */
export function addSidebarAction(hud: HudConfig, _action: string | HudActionButtonConfig) {
    hud = {
        ...hud,
        sidePanel: {
            visible: true,
            actions: toActionButtonConfig(hud.sidePanel.actions || []),
        },
    }
    const action = toActionButtonConfig([_action])[0]

    hud.sidePanel.actions.push(action)

    return hud
}
/**
 * Add action to sidebar config if not already exists
 * @param hud hud config
 * @param _action action to add
 * @returns modified hud config
 */
export function addSidebarActionOnce(hud: HudConfig, _action: string | HudActionButtonConfig) {
    hud = {
        ...hud,
        sidePanel: {
            visible: true,
            actions: toActionButtonConfig(hud.sidePanel.actions || []),
        },
    }
    const action = toActionButtonConfig([_action])[0]

    if (!containsAction(action, hud.sidePanel.actions)) {
        hud.sidePanel.actions.push(action)
    }

    return hud
}

/**
 * Remove action form sidebar config
 * @param hud hud config
 * @param _action action to remove
 * @returns modified hud config
 */
export function removeSidebarAction(hud: HudConfig, _action: string | HudActionButtonConfig) {
    const action = toActionButtonConfig([_action])[0]

    hud = {
        ...hud,
        sidePanel: {
            visible: true,
            actions: hud.sidePanel.actions.filter(filterActions([action])),
        },
    }

    return hud
}
