import { lightenDarkenColor } from '../util/global-utils'
import { CellConfig, GridConfig, GridGenerator } from './GridGenerator'
import { getScaleDef, KeyType, ScaleType } from '../music-theory/definitions'
import { BackgroundCss, CanvasBackgroundGenerator } from './BackgroundLayerGenerator'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'
import { Colors } from '../global-constants'
import { getCellsPerBar } from '../util/rendering.util'

export const defaultRowColors = [
    Colors.PR_DIVIDER_A_COLOR,
    Colors.PR_DIVIDER_B_COLOR,
    lightenDarkenColor(Colors.PR_DIVIDER_A_COLOR, 20),
    lightenDarkenColor(Colors.PR_DIVIDER_B_COLOR, 20),
]

/**
 * Generates default piano roll background (alternating colors matching piano keys)
 */
export class PianoRollBackgroundGenerator implements CanvasBackgroundGenerator {
    protected gridGeneratorConfig: GridConfig
    protected rows = 12
    protected cells = 0
    protected cellWidth: number

    /**
     *
     * @param resolution - selected Resolution in Piano Roll
     * @param timeSignatureNumerator
     * @param timeSignatureDenominator
     * @param keyType - Highlight key type
     * @param barWidth - width of a bar in pixel
     * @param cellHeight - height of a cell
     * @param colors - Row colors [<color1, color2, color1b, color2b>]
     */
    constructor(
        protected resolution: BeatResolutionEnum,
        protected timeSignatureNumerator: number,
        protected timeSignatureDenominator: number,
        protected keyType: KeyType,
        protected barWidth: number,
        protected cellHeight,
        protected colors: string[] = defaultRowColors
    ) {
        // render two beats to alternate background color
        this.cells = 2 * getCellsPerBar(resolution, timeSignatureNumerator, timeSignatureDenominator)

        this.cellWidth = (this.barWidth * 2) / this.cells
    }

    async build(): Promise<HTMLCanvasElement> {
        const config: GridConfig = {
            cellWidth: this.cellWidth,
            cellHeight: this.cellHeight,
            opacity: 1,
            startAndEndDividers: true,
            cellConfigFactory: (x: number, y: number, gridConfig: GridConfig) =>
                this.cellConfigFactory(x, y, gridConfig),
        }

        const gridGenerator = new GridGenerator(config)
        const gridBackground = await gridGenerator.generateCanvas(this.cells, this.rows)
        return gridBackground
    }

    /**
     * Generates background css containing images as data url
     * @returns background css
     */
    async generateBackground(): Promise<BackgroundCss> {
        const bg = await this.build()
        return {
            backgroundImage: `url(${bg.toDataURL()})`,
            backgroundRepeat: 'repeat-x repeat-y',
            backgroundBlendMode: 'normal',
        }
    }

    cellConfigFactory(column: number, row: number, gridConfig: GridConfig): CellConfig {
        let color: string

        // get IONIAN scale and use it as index lookup table to get all white keys
        const scale = getScaleDef(ScaleType.IONIAN)

        let reversedIndex = this.rows - row - 1

        // check if (reversed) index exists in scale
        if (scale.indexOf(reversedIndex) != -1) {
            color = this.colors[0]
            // lighten color in second bar
            if (column >= this.cells / 2) {
                color = this.colors[2]
            }
        } else {
            color = this.colors[1]
            // lighten color in second bar
            if (column >= this.cells / 2) {
                color = this.colors[3]
            }
        }

        return {
            backgroundColor: color,
            borderBottom:
                gridConfig.startAndEndDividers && row === this.rows - 1
                    ? {
                          thickness: 1,
                          color: '#8d8a8a',
                      }
                    : undefined,
            borderLeft: {
                thickness: 1,
                color: '#8d8a8a',
            },
            borderRight: {
                thickness: 1,
                color: '#8d8a8a',
            },
        }
    }
}
