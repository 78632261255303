<div class="ml-[150px] bg-black-80 cursor-pointer" (click)="setPosition($event)">
    <div class="relative h-[20px]" [style]="getTimelineStyle()">
        <div
            class="pointer-events-none absolute bottom-0 -ml-[1px] border-l-[2px] border-black-45 pl-2 text-white"
            *ngFor="let beat of getBeats()"
            [style]="beat.style"
        >
            {{ beat.text }}
        </div>
    </div>
</div>
