import { BeatResolutionEnum } from './enums/BeatResolutionEnum'

/**
 * Defines the max number of Tracks, as MIDI is limited to 16 tracks this won't change.
 */
export const MAX_NO_TRACKS = 16
/**
 * Defines minimal rendering width (px) for one single bar in the piano roll.
 */
export const PR_MIN_BAR_RENDER_WIDTH = 100
/**
 * Defines maximal rendering width (px) for one single bar in the piano roll.
 */
export const PR_MAX_BAR_RENDER_WIDTH = 4000
/**
 * Default zoom step size (px) in piano roll
 */
export const PR_DEFAULT_ZOOM_STEP = 100

export const PR_GRID_HEIGHT = 2900

export const PROJECT_MIN_BAR_RENDER_WIDTH = 50
export const PROJECT_MAX_BAR_RENDER_WIDTH = 500
export const PROJECT_DEFAULT_ZOOM_STEP = 25

export const PROJECT_TRACK_HEIGHT = 155 // 150px height + 5px margin

export const TIMELINE_HEIGHT = 20
export const TRACK_HEADER_WIDTH = 150
/**
 * Number of keys in the piano roll
 */
export const PIANO_ROLL_KEY_COUNT = 96

/**
 * How many bars are added to the project after the last pattern to get some space to the project end
 */
export const BARS_AFTER_LAST_PATTERN = 16

export enum Colors {
    PR_DIVIDER_A_COLOR = '#4E4D50',
    PR_DIVIDER_B_COLOR = '#323133',
    PR_SCALE_HIGHLIGHT_COLOR = '#00ff00',
}

/**
 * Max +/- inversion level
 */
export const MAX_INVERSION_LEVEL = 5

/**
 * Prefixes for Hotkey/Shortcut Overlays
 */
export const HOTKEY_OVERLAY_GENERAL = 'SHORTCUTS.GENERAL'
export const HOTKEY_OVERLAY_PROJECT = 'SHORTCUTS.PROJECT'
export const HOTKEY_OVERLAY_PIANOROLL = 'SHORTCUTS.PIANOROLL'

export const RESOLUTIONS = [
    { label: '1/1', value: BeatResolutionEnum.r1_1 },
    { label: '1/2', value: BeatResolutionEnum.r1_2 },
    { label: '1/4', value: BeatResolutionEnum.r1_4 },
    { label: '1/8', value: BeatResolutionEnum.r1_8 },
    { label: '1/16', value: BeatResolutionEnum.r1_16 },
    { label: '1/32', value: BeatResolutionEnum.r1_32 },
    { label: '1/64', value: BeatResolutionEnum.r1_64 },
    { label: '1/128', value: BeatResolutionEnum.r1_128 },
    { label: '1/2 Triplet', value: BeatResolutionEnum.rt1_2 },
    { label: '1/4 Triplet', value: BeatResolutionEnum.rt1_4 },
    { label: '1/8 Triplet', value: BeatResolutionEnum.rt1_8 },
    { label: '1/16 Triplet', value: BeatResolutionEnum.rt1_16 },
    { label: '1/32 Triplet', value: BeatResolutionEnum.rt1_32 },
    { label: '1/64 Triplet', value: BeatResolutionEnum.rt1_64 },
    { label: '1/128 Triplet', value: BeatResolutionEnum.rt1_128 },
]
