import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { Loop } from '../project-select-loop/project-select-loop.component'
import {
    calcBarLengthPX,
    calcTimeSignature,
    calcTransformFactor,
    calcZoomFactor,
    initialProjectState,
    initialUIState,
    Logger,
    LoopSelector,
    QUARTER_BEAT_RESOLUTION,
} from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-project-timeline',
    templateUrl: './project-timeline.component.html',
})
export class ProjectTimelineComponent implements OnInit, OnChanges {
    private readonly logger = Logger.createLogger('ProjectTimelineComponent')

    public computedStyle = {
        minWidth: '0px',
        marginLeft: `0px`,
    }

    @Output()
    clickTimeline: EventEmitter<number> = new EventEmitter()
    @Output()
    loopSelectionStarted: EventEmitter<number> = new EventEmitter()
    @Output()
    loopSelectionFinished: EventEmitter<number> = new EventEmitter()
    @Output()
    loopSelectionRestarted: EventEmitter<number> = new EventEmitter()
    @Output()
    clickOverlay: EventEmitter<void> = new EventEmitter()
    @Output()
    loopSet: EventEmitter<Loop> = new EventEmitter<Loop>()

    @Input()
    projectBarRenderWidth: number = initialUIState.projectBarRenderWidth

    @Input()
    timeDivision: number = initialProjectState.timeSignatureNumerator / initialProjectState.timeSignatureDenominator

    // offset to the left in px
    @Input()
    offset: number

    @Input()
    loopSelectorConfig: LoopSelector = {
        active: false,
        endIRN: 0,
        excluded: false,
        startIRN: 0,
        visible: false,
    }
    @Input()
    timeSignatureNumerator: number = initialProjectState.timeSignatureNumerator
    @Input()
    timeSignatureDenominator: number = initialProjectState.timeSignatureDenominator
    @Input()
    noProjectBars: number

    private getBarLength() {
        return calcBarLengthPX(
            calcTimeSignature(this.timeSignatureNumerator, this.timeSignatureDenominator),
            this.projectBarRenderWidth
        )
    }

    private getZoomFactor() {
        return calcZoomFactor(this.getBarLength())
    }

    ngOnInit(): void {
        this.recomputeStyle()
    }

    /**
     * Watch changes for certain properties
     */
    ngOnChanges(changes: SimpleChanges): void {
        // recompute style when zoom factor changes
        if (changes.projectBarRenderWidth || changes.timeDivision || changes.offset || changes.noProjectBars) {
            this.recomputeStyle()
        }
    }

    numBeatsArray(): number[] {
        return new Array(this.noProjectBars).fill(0).map((_, index) => index) || []
    }

    clickedTimeLine($event: MouseEvent): void {
        const irnPos = Math.floor($event.offsetX / calcTransformFactor(this.getBarLength()))
        this.clickTimeline.emit(irnPos)
        $event.stopPropagation()
    }

    longPressed(ev: any): void {
        ev.preventDefault()
        const posIRN = ev.srcEvent.offsetX / this.getZoomFactor()
        const quarterQuantizedPosIRN = Math.round(posIRN / QUARTER_BEAT_RESOLUTION) * QUARTER_BEAT_RESOLUTION
        if (this.loopSelectorConfig.visible && !this.loopSelectorConfig.active) {
            this.loopSelectionFinished.emit(quarterQuantizedPosIRN)
        } else if (this.loopSelectorConfig.visible && this.loopSelectorConfig.active) {
            this.loopSelectionRestarted.emit(quarterQuantizedPosIRN)
        } else {
            this.loopSelectionStarted.emit(quarterQuantizedPosIRN)
        }
    }

    recomputeStyle(): void {
        this.computedStyle = {
            minWidth: `${this.noProjectBars * this.getBarLength()}px`,
            marginLeft: `${this.offset + 1}px`,
        }
    }
}
