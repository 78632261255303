import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    ActionTask,
    DefaultAction,
    UiStateModel,
    initialUIState,
    Logger,
    GlobalPatternMapping,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { listPatternMappings } from '../../../store/reducers/project-state.reducer'
import { ToastService } from '../../../services/toast.service'
import { DeletePatternAction, DeletePatternMappingAction } from '../../../store/actions/project-state.actions'
import { TrackPatternAction } from './TrackPatternAction'
import { SetProjectSelectedPatternMappingAction } from '../../../store/actions/ui-state.actions'
import { ActionController } from '../../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'DeleteTrackPatternAction',
})
export class DeleteTrackPatternAction extends TrackPatternAction {
    constructor(store: Store<AppState>, private toast: ToastService) {
        super(store)

        this.icon.next('delete')
    }

    async onClick() {
        const mappingId = this.uiState.projectSelectedPatternMapping
        if (mappingId === '') {
            this.toast.warn('No pattern selected!')
            return
        }
        const patternId = this.getPatterIdForMapping(mappingId)
        const track = this.getTrackForMapping(mappingId)
        if (!patternId || !track) {
            this.toast.warn('Pattern mapping could not resolved')
            return
        }
        if (this.globalPatterMappings[patternId].mappings.length === 1) {
            this.store.dispatch(new DeletePatternMappingAction(track, mappingId))
            this.store.dispatch(new DeletePatternAction(patternId))
        } else {
            this.store.dispatch(new DeletePatternMappingAction(track, mappingId))
        }
        this.store.dispatch(new SetProjectSelectedPatternMappingAction(''))
    }
}
