import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { OctaveDownNotesAction } from '../../store/actions/piano-roll-state.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollOctaveDownAction',
})
export class PianoRollOctaveDownAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.label.next('PIANO_ROLL.MENU.OCTAVE_DOWN')
        this.icon.next('keyboard_arrow_down')
    }

    onClick() {
        this.store.dispatch(new OctaveDownNotesAction())
    }
}
