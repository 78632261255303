import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    ActionTask,
    DefaultAction,
    UiStateModel,
    initialUIState,
    Logger,
    GlobalPatternMapping,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { listPatternMappings } from '../../../store/reducers/project-state.reducer'
import { ToastService } from '../../../services/toast.service'
import { DeletePatternAction, DeletePatternMappingAction } from '../../../store/actions/project-state.actions'
import { TrackPatternAction } from './TrackPatternAction'
import {
    SetProjectSelectedPatternMappingAction,
    StartClonePatternAction,
    StartCopyPatternAction,
} from '../../../store/actions/ui-state.actions'
import { ActionController } from '../../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'TrackPatternDeselectAction',
})
export class TrackPatternDeselectAction extends TrackPatternAction {
    constructor(store: Store<AppState>, private toast: ToastService) {
        super(store)

        this.icon.next('cancel')
        this.label.next('Deselect')
    }

    async onClick() {
        this.store.dispatch(new SetProjectSelectedPatternMappingAction(''))
    }
}
