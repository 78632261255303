export interface BottomRightCornerPosition {
    right: number
    bottom: number
}

/**
 * returns circular button coordinates for the bottom right corner.
 * @param noButtonsPerCircle an array with the numbers of buttons for each circle
 * @param index the current index you want to have the coordinates for. starting in the bottom right corner.
 * @param radius the radius of the circle that increases per circle
 */
export function getCircularButtonCoordinates(
    noButtonsPerCircle: number[],
    index: number,
    radius: number
): BottomRightCornerPosition {
    const spacing = 1.5
    // dont show incalculable buttons
    const errorValue = {
        bottom: -99,
        right: -99,
    }
    if (noButtonsPerCircle && index >= 0 && radius > 0) {
        for (let i = 0; i <= noButtonsPerCircle.length - 1; i++) {
            const noButtonsInCurrentCircle = noButtonsPerCircle.slice(0, i + 1).reduce((a, b) => a + b)
            const noButtonsInPreviousCircle = i > 0 ? noButtonsPerCircle.slice(0, i).reduce((a, b) => a + b) : 0
            // find correct circle
            if (noButtonsInCurrentCircle > index) {
                const splitAngle = 90 / (noButtonsPerCircle[i] - 1)
                const indexInCurrentCircle = index - noButtonsInPreviousCircle
                // calculate angle
                const angleStepSize = splitAngle * indexInCurrentCircle
                const angleForIndex = 180 + angleStepSize
                const currRadius = radius * (i + 1)
                // create position
                const right = -currRadius * Math.cos((angleForIndex * Math.PI) / 180) + spacing
                const bottom = -currRadius * Math.sin((angleForIndex * Math.PI) / 180) + spacing
                return {
                    right,
                    bottom,
                }
            }
        }
        return errorValue
    } else {
        return errorValue
    }
}
