import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { ClearNotesAction, OctaveDownNotesAction } from '../../store/actions/piano-roll-state.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollClearNotesAction',
})
export class PianoRollClearNotesAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.label.next('PIANO_ROLL.MENU.CLEAR_NOTES')
        this.icon.next('clear_all')
    }

    onClick() {
        this.store.dispatch(new ClearNotesAction())
    }
}
