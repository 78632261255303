import { EntityAdapter, EntityState } from '@ngrx/entity/src/models'
import { createEntityAdapter } from '@ngrx/entity'
import { getRandomId } from '../util/id-generators'
import { Instruments } from '../midi/Instruments'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'
import { KeyType, ScaleType } from '../music-theory/definitions'
import { Color } from '../enums/Color'
import { getUnixTimeStampByDate } from '../util/global-utils'

export interface LoopSelector {
    excluded: boolean
    visible: boolean
    startIRN: number
    endIRN: number
    active: boolean
}

export interface ProjectStateModel {
    id: string
    name: string
    demo: boolean
    bpm: number
    timeSignatureNumerator: number
    timeSignatureDenominator: number
    key: KeyType
    scale: ScaleType
    patterns: PatternState
    tracks: TrackState

    loopSelector: LoopSelector
    schemaVersion: number

    created: number
    lastEdit: number
}

export enum PatternType {
    PianoRollPattern = 'PianoRollPattern',
    SequencerPattern = 'SequencerPattern',
}

export interface Pattern {
    id: string
    lengthIRN: number // number based on internal MAX_RESOLUTION
    notes: Note[]
    color: Color
    lastResolution: BeatResolutionEnum
    type: PatternType
}

export interface TrackPatternMappingOptions {
    position: number
    startAt: number
    length: number
}

export interface TrackPatternMapping extends TrackPatternMappingOptions {
    id: string
    patternId: string
}

export interface Track {
    id?: string
    channel: number
    instrument: Instruments
    volume: number
    solo: boolean
    mute: boolean
    patterns: Record<string, TrackPatternMapping>
    // TODO: implement set color
    color?: string
}

export interface Note {
    id: string
    key: number
    startIRN: number // number based on internal MAX_RESOLUTION
    velocity: number // 0 - 255
    lengthIRN: number // number based on internal MAX_RESOLUTION
}

export interface TrackNote extends Note {
    channel: number
}

export interface TrackState extends EntityState<Track> {}

export interface PatternState extends EntityState<Pattern> {}

export const patternAdapter: EntityAdapter<Pattern> = createEntityAdapter<Pattern>({})

export const trackAdapter: EntityAdapter<Track> = createEntityAdapter<Track>({})

export const initialLoopSelectorState: LoopSelector = {
    excluded: false,
    visible: false,
    startIRN: 0,
    endIRN: 0,
    active: false,
}

export const initialProjectState: ProjectStateModel = {
    id: getRandomId(),
    name: 'new-project',
    demo: false,
    bpm: 120,
    timeSignatureNumerator: 4,
    timeSignatureDenominator: 4,
    key: KeyType.C,
    scale: ScaleType.IONIAN,
    patterns: patternAdapter.getInitialState(),
    tracks: trackAdapter.getInitialState(),

    loopSelector: initialLoopSelectorState,
    schemaVersion: 1,

    created: getUnixTimeStampByDate(new Date('1970-01-01')),
    lastEdit: getUnixTimeStampByDate(new Date('1970-01-01')),
}

/**
 * Describes all mappings of pattern
 */
export interface GlobalPatternMappingDescription {
    trackId: string
    mappingId: string
    start: number
    length: number
}

export interface GlobalPatternMapping {
    patternId: string
    mappings: Array<GlobalPatternMappingDescription>
}

const { selectIds, selectEntities, selectAll, selectTotal } = patternAdapter.getSelectors()
