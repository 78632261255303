import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { SetProjectBarRenderWidthAction } from '../../store/actions/ui-state.actions'
import { PROJECT_DEFAULT_ZOOM_STEP } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { UndoAction as StoreUndoAction } from '../../store/meta/history/history.actions'

import { ActionController } from '../actions.decorator'

/**
 * Spacer component, only useful in sidebar
 */
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'SpacerAction',
})
export class SpacerAction extends DefaultAction {
    constructor() {
        super()
        this.label.next('')
        this.icon.next('close')
        this.buttonClasses.next(['!rounded-none', '!h-[0px]', '!my-0', 'p-0', 'border-l-0', 'border-r-0', 'border-b-0'])
        this.iconClasses.next(['!hidden'])
    }

    onClick() {
        // do nothing
    }
}
