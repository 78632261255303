import { Logger } from '@tekbox-coco/midiative-commons'
import { AbstractPlatformFileManager, FileManagerOpenFileResult, FileManagerResult } from './PlatformFileManager'
import { Directory, Filesystem, ReadFileResult } from '@capacitor/filesystem'
import { FileInfo, StatResult } from '@capacitor/filesystem/dist/esm/definitions'
import { FilePicker, PickFilesOptions, PickFilesResult } from '@capawesome/capacitor-file-picker'

const LOGGER = Logger.createLogger('NativeFileManager')

export abstract class NativeFileManager extends AbstractPlatformFileManager {
    abstract getStorageRoot(): Directory

    abstract getExportStorageRoot(): Directory

    abstract getCacheStorageRoot(): Directory

    constructor() {
        super()
    }

    get fileChooser() {
        return FilePicker
    }

    async listDir(path: string): Promise<FileInfo[]> {
        return Filesystem.readdir({ path, directory: this.getStorageRoot() }).then((e) => {
            return e.files
        })
    }

    async exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        return this.saveFileToStorage(name, blobData, type, this.getExportStorageRoot())
    }

    async cacheFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        // the file is saved to cache in order to share the file
        return this.saveFileToStorage(name, blobData, type, this.getCacheStorageRoot())
    }

    async saveFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        return this.saveFileToStorage(name, blobData, type, this.getStorageRoot())
    }

    private async saveFileToStorage(
        name: string,
        blobData: any[],
        type: string,
        storageRoot: Directory
    ): Promise<FileManagerResult> {
        let base64Data = ''

        if (typeof blobData[0] === 'string') {
            base64Data = btoa(blobData[0])
        } else {
            base64Data = btoa(String.fromCharCode.apply(null, blobData[0]))
        }

        const permissions = await Filesystem.checkPermissions()
        LOGGER.info('Current Filesystem permissions:', permissions)
        if (permissions.publicStorage !== 'granted') {
            const reqPermissions = await Filesystem.requestPermissions()
            if (reqPermissions.publicStorage !== 'granted') {
                LOGGER.error("Filesystem permissions couldn't be granted, permission state was", reqPermissions)
                return {
                    data: undefined,
                    successful: false,
                }
            }
        }

        await Filesystem.writeFile({
            path: `${name}`,
            data: base64Data,
            directory: storageRoot,
        }).catch((e) => {
            LOGGER.error('Could not write file:', name, e)
            return {
                data: undefined,
                successful: false,
            }
        })

        const uri = await Filesystem.getUri({
            path: `${name}`,
            directory: storageRoot,
        })
        return {
            data: uri.uri,
            successful: true,
        }
    }

    private str2ab(str: string): ArrayBuffer {
        const buf = new ArrayBuffer(str.length) // 2 bytes for each char
        const bufView = new Uint8Array(buf)
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i)
        }
        return buf
    }

    async readFile(path: string): Promise<string> {
        const data: ReadFileResult = await Filesystem.readFile({ path: path })
        // force value is used as sting
        return atob(data.data as string)
    }

    async openFile(_filePickerOptions?: PickFilesOptions): Promise<FileManagerOpenFileResult> {
        const filePickerOptions: PickFilesOptions = {
            limit: 1,
            readData: true,
            ..._filePickerOptions,
        }
        // pick files
        const result = await FilePicker.pickFiles(filePickerOptions)
        const file = result.files[0]
        const fileName: string = file.name

        // convert to binary (array buffer)
        var binaryString = atob(file.data)
        var bytes = new Uint8Array(binaryString.length)
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        const data = bytes.buffer

        return { fileName: fileName, data, canceled: false }
    }

    async exists(path: string): Promise<boolean> {
        try {
            const result = await Filesystem.stat({
                path,
                directory: this.getStorageRoot(),
            })
            return true
        } catch (e) {}
        return false
    }

    async stat(path: string): Promise<StatResult> {
        const result = await Filesystem.stat({
            path,
            directory: this.getStorageRoot(),
        })
        return result
    }

    async mkdir(path: string): Promise<boolean> {
        try {
            const result = await Filesystem.mkdir({
                path,
                directory: this.getStorageRoot(),
            })
            return true
        } catch (e) {
            LOGGER.error('Error while creating folder', this.getStorageRoot(), path, e)
        }
        return false
    }

    async rmdir(path: string): Promise<boolean> {
        try {
            const result = await Filesystem.rmdir({
                path,
                recursive: true,
                directory: this.getStorageRoot(),
            })
            return true
        } catch (e) {
            LOGGER.error('Error while removing folder', this.getStorageRoot(), path, e)
        }
        return false
    }
}
