import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { FileManagerOpenFileResult, FileManagerResult, PlatformFileManager } from './platforms/PlatformFileManager'
import { WebFileManager } from './platforms/WebFileManager'
import { Logger } from '@tekbox-coco/midiative-commons'
import { Capacitor } from '@capacitor/core'
import { AndroidFileManager } from './platforms/AndroidFileManager'
import { IosFileManager } from './platforms/IosFileManager'
import { FileInfo, StatResult } from '@capacitor/filesystem/dist/esm/definitions'
import { PickFilesOptions } from '@capawesome/capacitor-file-picker'

export interface FileManger {
    saveFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>

    exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>
    cacheFile(name: string, blobData: any[], type: string): Promise<FileManagerResult>

    readFile(path: string): Promise<string>

    openFile(_filePickerOptions?: PickFilesOptions): Promise<FileManagerOpenFileResult>

    exists(path: string): Promise<boolean>

    mkdir(path: string): Promise<boolean>

    stat(path: string): Promise<any>

    rmdir(path: string): Promise<boolean>

    listDir(path: string): Promise<FileInfo[]>
}

@Injectable({
    providedIn: 'root',
})
export class FileManagerService implements FileManger {
    private readonly logger = Logger.createLogger('FileManagerService')

    private platformFileManagers: Map<string, PlatformFileManager>

    constructor(private platform: Platform) {
        this.platformFileManagers = new Map<string, PlatformFileManager>()

        this.platformFileManagers.set('web', new WebFileManager())
        this.platformFileManagers.set('android', new AndroidFileManager())
        this.platformFileManagers.set('ios', new IosFileManager())
    }

    listDir(path: string): Promise<FileInfo[]> {
        const fileHandler = this.getPlatformManager()
        return fileHandler.listDir(path)
    }

    exists(path: string): Promise<boolean> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`exists\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.exists(path)
    }

    saveFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`saveFile\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.saveFile(name, blobData, type)
    }

    exportFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`exportFile\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.exportFile(name, blobData, type)
    }

    cacheFile(name: string, blobData: any[], type: string): Promise<FileManagerResult> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`exportFile\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.cacheFile(name, blobData, type)
    }

    readFile(path: string): Promise<string> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`readFile\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.readFile(path)
    }

    openFile(_filePickerOptions?: PickFilesOptions): Promise<FileManagerOpenFileResult> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`openFile\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.openFile(_filePickerOptions)
    }

    stat(path: string): Promise<StatResult> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`stat\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.stat(path)
    }

    mkdir(path: string): Promise<any> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`mkdir\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.mkdir(path)
    }

    rmdir(path: string): Promise<boolean> {
        const fileHandler = this.getPlatformManager()
        if (!fileHandler) {
            throw new Error(`\`rmdir\` supported on platform ${this.platform.platforms().join(',')}`)
        }
        return fileHandler.rmdir(path)
    }

    private getPlatformManager(): PlatformFileManager | null {
        this.logger.debug('Resolve manager for platform:', Capacitor.getPlatform())
        const handler = this.platformFileManagers.get(Capacitor.getPlatform())
        if (handler) {
            this.logger.debug('Manager: ', handler)
            return handler
        }
        return null
    }
}
