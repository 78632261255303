import {
    initialUIState,
    PR_MAX_BAR_RENDER_WIDTH,
    PR_MIN_BAR_RENDER_WIDTH,
    PROJECT_MAX_BAR_RENDER_WIDTH,
    PROJECT_MIN_BAR_RENDER_WIDTH,
    UiStateModel,
} from '@tekbox-coco/midiative-commons'
import { UiStateActions, UIStateActionTypes } from '../actions/ui-state.actions'

export function uiStateReducer(state: UiStateModel = initialUIState, action: UiStateActions): UiStateModel {
    switch (action.type) {
        case UIStateActionTypes.SetFollowCursor: {
            return { ...state, followCursor: action.payload }
        }
        case UIStateActionTypes.SetPrDrawerVisible: {
            return { ...state, prDrawerVisible: action.payload }
        }
        case UIStateActionTypes.SetPrDrawerOpen: {
            return { ...state, prDrawerOpen: action.payload }
        }
        case UIStateActionTypes.SetPrToolbarVisible: {
            return { ...state, prToolbarVisible: action.payload }
        }
        case UIStateActionTypes.SetPrToolSelectionOpen: {
            return { ...state, prToolSelectionOpen: action.payload }
        }
        case UIStateActionTypes.SetPrSelectedChordType: {
            return { ...state, prSelectedChordType: action.payload }
        }
        case UIStateActionTypes.SetPrMenuLoadPatternVisible: {
            return { ...state, prMenuLoadPatternVisible: action.payload }
        }
        case UIStateActionTypes.SetPrModalSavePatternVisible: {
            return { ...state, prModalSavePatternVisible: action.payload }
        }
        case UIStateActionTypes.SetPrModalUnsavedPatternVisible: {
            return { ...state, prModalUnsavedPatternVisible: action.payload }
        }
        case UIStateActionTypes.SetPrSelectedTool: {
            return { ...state, prSelectedTool: action.payload }
        }
        case UIStateActionTypes.AddPrSelectedNote: {
            return { ...state, prSelectedNotes: [...state.prSelectedNotes, action.payload] }
        }
        case UIStateActionTypes.RemovePrSelectedNote: {
            return {
                ...state,
                prSelectedNotes: state.prSelectedNotes.filter((e) => e != action.payload),
            }
        }
        case UIStateActionTypes.PrNoteUpdateDeltas: {
            return {
                ...state,
                prNoteDeltaResizeX: action.delta.resizeX,
                prNoteDeltaX: action.delta.x,
                prNoteDeltaY: action.delta.y,
            }
        }
        case UIStateActionTypes.SetPreScrollEnabled: {
            return {
                ...state,
                prScrollEnabled: action.enabled,
            }
        }
        case UIStateActionTypes.ResetPrSelectedNotes: {
            return { ...state, prSelectedNotes: [] }
        }
        case UIStateActionTypes.SetPrClipboard: {
            return { ...state, prClipboard: action.payload }
        }
        case UIStateActionTypes.SetPrPatternMappingId: {
            return { ...state, prPatternMappingId: action.payload }
        }
        case UIStateActionTypes.PianoRollChordToolSetInversionLevel: {
            return { ...state, prChordToolInversionLevel: action.level }
        }
        case UIStateActionTypes.SetProjectDrawerVisible: {
            return { ...state, projectDrawerVisible: action.payload }
        }
        case UIStateActionTypes.SetProjectDrawerOpen: {
            return { ...state, projectDrawerOpen: action.payload }
        }
        case UIStateActionTypes.SetProjectToolbarVisible: {
            return { ...state, projectToolbarVisible: action.payload }
        }
        case UIStateActionTypes.SetProjectToolSelectionOpen: {
            return { ...state, projectToolSelectionOpen: action.payload }
        }
        case UIStateActionTypes.SetProjectMenuSelectPatternOpen: {
            return { ...state, projectMenuSelectPatternOpen: action.payload }
        }
        case UIStateActionTypes.SetProjectModalUnsavedProjectVisible: {
            return {
                ...state,
                projectModalUnsavedProjectVisible: action.payload,
            }
        }
        case UIStateActionTypes.SetProjectSelectedPatternMapping: {
            return {
                ...state,
                projectSelectedPatternMapping:
                    state.projectSelectedPatternMapping === action.payload ? '' : action.payload, // deselect if selected again
            }
        }
        case UIStateActionTypes.SetProjectBarRenderWidth: {
            if (action.payload < PROJECT_MIN_BAR_RENDER_WIDTH) {
                return { ...state, projectBarRenderWidth: PROJECT_MIN_BAR_RENDER_WIDTH }
            } else if (action.payload > PROJECT_MAX_BAR_RENDER_WIDTH) {
                return { ...state, projectBarRenderWidth: PROJECT_MAX_BAR_RENDER_WIDTH }
            }
            return { ...state, projectBarRenderWidth: action.payload }
        }
        case UIStateActionTypes.SetHomeModalDeleteProjectVisible: {
            return { ...state, homeModalDeleteProjectVisible: action.payload }
        }
        case UIStateActionTypes.SetPianoRollBarRenderWidth: {
            if (action.payload < PR_MIN_BAR_RENDER_WIDTH) {
                return { ...state, prBarRenderWidth: PR_MIN_BAR_RENDER_WIDTH }
            } else if (action.payload > PR_MAX_BAR_RENDER_WIDTH) {
                return { ...state, prBarRenderWidth: PR_MAX_BAR_RENDER_WIDTH }
            }
            return { ...state, prBarRenderWidth: action.payload }
        }
        case UIStateActionTypes.SetSnapToGrid: {
            return { ...state, snapToGrid: action.payload }
        }
        case UIStateActionTypes.StartCopyPattern: {
            return {
                ...state,
                copyPatternId: action.payload,
                clonePatternId: '',
            }
        }
        case UIStateActionTypes.StartClonePattern: {
            return {
                ...state,
                copyPatternId: '',
                clonePatternId: action.payload,
            }
        }
        case UIStateActionTypes.SetProjectSelectedTool: {
            return {
                ...state,
                projectSelectedTool: action.payload,
            }
        }
        case UIStateActionTypes.Reset: {
            return initialUIState
        }
        default: {
            return state
        }
    }
}
