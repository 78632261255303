import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { Gesture, GestureController } from '@ionic/angular'
import {
    calcBarLengthPX,
    calcTimeSignature,
    calcTransformFactor,
    calcZoomFactor,
    EventBusEvent,
    getPianoRollKeyMap,
    GlobalPatternMapping,
    initialProjectState,
    initialUIState,
    Logger,
    MAX_GRID_RESOLUTION,
    Pattern,
    quantizeIRNToBeat,
    Track,
    TrackPatternMapping,
    ProjectInfoService,
    calcScalingFactor,
} from '@tekbox-coco/midiative-commons'
import Hammer from 'hammerjs'
import { timer } from 'rxjs'
/**
 * Payload structure of TrackPattern related events
 */
export interface PatternAction<T> {
    id: string
    track: string
    mappingId: string
    payload?: T
    clickEvent?: Hammer.Event | MouseEvent
}

/**
 * TrackPatternComponents renders a preview of a pattern.
 * When clicked several pattern actions can be triggered.
 */
@Component({
    selector: 'app-track-pattern',
    templateUrl: './track-pattern.component.html',
})
export class TrackPatternComponent implements OnInit, OnChanges {
    private readonly logger = Logger.createLogger('TrackPatternComponent')

    pianoRollKeys = getPianoRollKeyMap()
    dragOffsetX = 0
    dragOffsetY = 0
    resizeOffset = 0

    cutLeftOffset = 0

    // if true there are exist other mappings with the same pattern
    hasCopies: boolean

    // actions emitters
    @Output() clickPattern: EventEmitter<PatternAction<void>> = new EventEmitter()
    @Output() editPattern: EventEmitter<PatternAction<void>> = new EventEmitter()
    @Output() updatePatternMapping: EventEmitter<
        PatternAction<{ newXPosition: number; newLength: number; newTrack: number }>
    > = new EventEmitter()

    @Input()
    track: Track

    @Input()
    pattern: Pattern

    @Input()
    mapping: TrackPatternMapping

    @Input()
    offset: number

    @Input()
    projectBarRenderWidth = initialUIState.projectBarRenderWidth

    @Input()
    timeSignatureNumerator = initialProjectState.timeSignatureNumerator

    @Input()
    timeSignatureDenominator = initialProjectState.timeSignatureDenominator

    @Input()
    selected: boolean

    @Input()
    globalMappings: Record<string, GlobalPatternMapping>
    trackPatternStyle = {}
    trackPatternActionWrapperStyle = {}

    @Input()
    pressGesture: any

    @Input()
    snapToGridActive: boolean

    constructor(private projectInfoService: ProjectInfoService) {}

    ngOnInit() {
        this.recomputeStyle()
        this.recomputeFlags()
        this.setupDragHandlers()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.projectBarRenderWidth ||
            changes.timeSignatureNumerator ||
            changes.timeSignatureDenominator ||
            changes.offset ||
            changes.pattern ||
            changes.selected
        ) {
            this.recomputeStyle()
            this.recomputeFlags()
        }
    }

    recomputeFlags() {
        // check for other mappings
        if (this.globalMappings) {
            const mappings = this.globalMappings[this.mapping.patternId]
            if (mappings && mappings.mappings) {
                this.hasCopies = mappings.mappings.length > 1
            }
        }
    }

    emitEditPattern() {
        this.editPattern.emit({
            id: this.pattern.id,
            mappingId: this.mapping.id,
            track: this.track.id,
        })
    }

    // emitCopyPattern($event: any) {
    //     this.copyPattern.emit({
    //         id: this.pattern.id,
    //         mappingId: this.mapping.id,
    //         track: this.track.id,
    //     })
    //     $event.preventDefault()
    // }

    // emitClonePattern($event: any) {
    //     this.clonePattern.emit({
    //         id: this.pattern.id,
    //         mappingId: this.mapping.id,
    //         track: this.track.id,
    //     })
    //     $event.preventDefault()
    // }

    emitUpdatePattern() {
        let newXPosition = this.offset
        let newLength = this.patternWidth
        let newTrack
        if (this.dragOffsetX !== 0 || this.dragOffsetY !== 0) {
            const offsetX = this.getLeftOffset() + this.dragOffsetX
            const exactPos = (offsetX / this.projectBarRenderWidth) * MAX_GRID_RESOLUTION

            const timeSignature = calcTimeSignature(this.timeSignatureNumerator, this.timeSignatureDenominator)
            const scalingFactor = calcScalingFactor(timeSignature)
            const exactPosScaled = exactPos * scalingFactor
            newXPosition = this.snapToGridActive
                ? quantizeIRNToBeat(exactPosScaled, this.timeSignatureNumerator, scalingFactor)
                : exactPosScaled

            // update channel if pattern is dragged more than half pattern height
            if (Math.abs(this.dragOffsetY) > this.patternHeight / 2) {
                const relativeYOffset = Math.round(this.dragOffsetY / this.patternHeight)
                newTrack = this.projectInfoService.getTrackIndexFromChannel(this.track.channel) + relativeYOffset
            }
        } else if (this.cutLeftOffset !== 0) {
            newXPosition = this.offset + this.cutLeftOffset
            newLength = this.patternWidth - this.cutLeftOffset
        }

        // don't move patterns below 0
        // TODO: check for song end, too
        if (newXPosition < 0) {
            newXPosition = 0
        }
        this.dragOffsetX = 0
        this.dragOffsetY = 0
        this.recomputeStyle()
        this.updatePatternMapping.emit({
            id: this.mapping.id,
            track: this.track.id,
            mappingId: this.mapping.id,
            payload: {
                newXPosition,
                newTrack,
                newLength,
            },
        })
    }

    protected setupDragHandlers() {
        // ################# resizeDragHandler #################
        // this.resizeDragHandler.onDragEnd(() => {
        //     this.emitUpdatePattern()
        // })
        // this.resizeDragHandler.onDrag((start, posX) => {
        //     this.resizeOffset = posX - start
        //     this.recomputeStyle()
        //     return false
        // })
        // // ################# cutLeftDragHandler #################
        // this.cutLeftDragHandler.onDragEnd(() => {
        //     this.emitUpdatePattern()
        // })
        // this.cutLeftDragHandler.onDrag((start, posX) => {
        //     this.cutLeftOffset = posX - start
        //     this.recomputeStyle()
        //     return false
        // })
    }

    protected recomputeStyle() {
        this.trackPatternActionWrapperStyle = {
            width: '100%',
        }
        if (this.pattern) {
            this.trackPatternStyle = {
                left: `${this.getLeftOffset() + this.dragOffsetX + this.cutLeftOffset}px`,
                width: `${this.getTransformFactor() * (this.patternWidth - this.cutLeftOffset)}px`,
                maxWidth: `${this.getTransformFactor() * (this.patternWidth - this.cutLeftOffset)}px`,
                minWidth: `${this.getTransformFactor() * (this.patternWidth - this.cutLeftOffset)}px`,
                minHeight: `${this.patternHeight}px`,
                maxHeight: `${this.patternHeight}px`,
                backgroundSize: `${this.getTransformFactor() * (this.patternWidth - this.cutLeftOffset)}px ${
                    this.patternHeight
                }px`,
                background: `url(${this.getBackground()}) no-repeat`,
                transformOrigin: 'left',
                boxShadow: `inset 0px 0px 15px 0px ${this.pattern.color}`,
                border: this.selected ? `2px solid white` : `2px solid ${this.pattern.color}`,
                top: `${this.dragOffsetY}px`,
            }
        }
    }

    private getTimeSignature(): number {
        return calcTimeSignature(this.timeSignatureNumerator, this.timeSignatureDenominator)
    }

    private getBarLength(): number {
        return this.projectBarRenderWidth
    }

    private getZoomFactor(): number {
        return calcZoomFactor(this.getBarLength())
    }

    private getTransformFactor(): number {
        return calcTransformFactor(this.getBarLength())
    }

    private getLeftOffset(): number {
        return this.getTransformFactor() * Number(this.offset)
    }

    // onResizeStart(event: TouchEvent | MouseEvent): boolean {
    //     this.resizeDragHandler.onDragStart(event)
    //     return false
    // }

    // onCutLeft(event: TouchEvent | MouseEvent): boolean {
    //     this.cutLeftDragHandler.onDragStart(event)
    //     return false
    // }

    // deletePatternMapping(event: TouchEvent | MouseEvent): void {
    //     this.logger.info('remove pattern mapping', this.mapping.id)
    //     this.removePattern.emit({
    //         id: this.pattern.id,
    //         mappingId: this.mapping.id,
    //         track: this.track.id,
    //     })
    //     event.preventDefault()
    // }

    get patternWidth(): number {
        return this.mapping.length + this.resizeOffset
    }

    get patternHeight(): number {
        return 150
    }

    onDoubleTap($event) {
        timer(50).subscribe((subscribe) => this.emitEditPattern())
    }

    onPan(e: Hammer.Event) {
        if (this.selected) {
            this.dragOffsetX = e.deltaX
            this.dragOffsetY = e.deltaY
            this.recomputeStyle()
        }
    }

    onPanEnd($event: Hammer.Event) {
        this.emitUpdatePattern()
    }

    onLongPressPattern($event: Hammer.Event) {
        this.clickPattern.emit({
            id: this.mapping.id,
            mappingId: this.mapping.id,
            track: this.track.id,
            clickEvent: $event,
        })
    }

    /**
     * Stop propagation of event. This is necessary to prevent creation of a new pattern
     * @param $event
     */
    onClickPattern($event) {
        $event.stopPropagation()
        $event.preventDefault()
    }

    getBackground() {
        const canvasElement = document.createElement('canvas')
        canvasElement.width = this.patternWidth
        canvasElement.height = this.patternHeight
        const ctx = canvasElement.getContext('2d')
        // transparent background
        if (this.pattern) {
            ctx.globalAlpha = 0.05
            ctx.fillStyle = this.pattern.color || ''
            ctx.fillRect(0, 0, this.patternWidth, this.patternWidth)
            // back to opacity: 1
            ctx.globalAlpha = 1

            const mapRange = (num: number, inMin: number, inMax: number, outMin: number, outMax: number) => {
                return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
            }
            const mapNote = (note: number) => mapRange(note, 0, 107, 0, this.patternHeight)

            for (let i = 0; i < Math.ceil(this.patternWidth / this.pattern.lengthIRN); i++) {
                this.pattern.notes.forEach((note) => {
                    ctx.fillStyle = '#19FFE6' // turtle-cyan-100

                    const start = i * this.pattern.lengthIRN + note.startIRN
                    // remove a bit of the end to make the gap visible between two following notes
                    const length = note.lengthIRN - 2
                    const x = mapNote(this.pianoRollKeys.map((k) => k.note).indexOf(note.key))
                    ctx.fillRect(start, x, length, this.patternHeight / this.pianoRollKeys.length)
                })
            }
            return canvasElement.toDataURL()
        }
        return ''
    }
}
