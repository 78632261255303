import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import {
    calcBarLengthPX,
    calcTimeSignature,
    pxToIrn,
    initialProjectState,
    initialUIState,
    Logger,
    quantizeIRNToLowerBeat,
    Track,
    TrackBackgroundGenerator,
    calcScalingFactor,
} from '@tekbox-coco/midiative-commons'

/**
 * Payload structure of Track related events
 */
export interface TrackAction<T> {
    id: string
    payload?: T
}

/**
 * TrackComponent acts as wrapper for {@link TrackHeaderComponent} and {@link TrackPatternComponent}
 */
@Component({
    selector: 'app-track',
    templateUrl: './track.component.html',
})
export class TrackComponent implements OnInit {
    private readonly logger = Logger.createLogger('TrackComponent')

    patternWrapperStyle = {}

    // emitted when track is clicked/touched
    @Output() clickTrack: EventEmitter<TrackAction<{ irnPosition: number }>> = new EventEmitter<
        TrackAction<{ irnPosition: number }>
    >()

    // the track
    @Input()
    track: Track

    @Input()
    projectBarRenderWidth: number = initialUIState.projectBarRenderWidth

    @Input()
    timeSignatureNumerator: number = initialProjectState.timeSignatureNumerator
    @Input()
    timeSignatureDenominator: number = initialProjectState.timeSignatureDenominator

    @Input()
    noProjectBars: number

    @Input()
    snapToGridActive: boolean

    ngOnInit() {
        // background style is calculated once on component init
        // width = num in pixel to cover one beat
        // background is generated with resolution 4
        this.patternWrapperStyle = {
            ...this.patternWrapperStyle,
            width: `${this.noProjectBars * this.calcBarLength()}px`,
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.projectBarRenderWidth ||
            changes.timeSignatureNumerator ||
            changes.timeSignatureDenominator ||
            changes.noProjectBars
        ) {
            void this.recomputeStyle()
        }
    }

    private async recomputeStyle() {
        let bg = await new TrackBackgroundGenerator(
            this.calcBarLength(),
            this.timeSignatureNumerator
        ).generateBackground()
        this.patternWrapperStyle = {
            ...this.patternWrapperStyle,
            ...bg,
            minWidth: `${this.noProjectBars * this.calcBarLength()}px`,
            width: `${this.noProjectBars * this.calcBarLength()}px`,
        }
    }

    private calcBarLength(): number {
        return calcBarLengthPX(
            calcTimeSignature(this.timeSignatureNumerator, this.timeSignatureDenominator),
            this.projectBarRenderWidth
        )
    }

    /**
     * Calculates irn position from Mouse/TouchEvent and emits event with calculated irn position
     * @param $event
     */
    onClickTrack($event: MouseEvent): void {
        const timeSignature = calcTimeSignature(this.timeSignatureNumerator, this.timeSignatureDenominator)
        const scalingFactor = calcScalingFactor(timeSignature)
        const irnPos = pxToIrn($event.offsetX, scalingFactor, this.projectBarRenderWidth)
        const pos = this.snapToGridActive ? quantizeIRNToLowerBeat(irnPos, this.timeSignatureNumerator, 1) : irnPos
        this.clickTrack.emit({
            id: this.track.id,
            payload: {
                irnPosition: pos,
            },
        })
        $event.preventDefault()
    }
}
