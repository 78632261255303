export enum HeaderType {
    HEADER = '4D546864',
    TRACK = '4D54726B',
}

export class Chunk {
    type: HeaderType
    data: string = ''

    constructor(type: HeaderType) {
        this.type = type
    }

    get chunklen() {
        const len = this.data.length / 2
        if (len >= parseInt('FFFFFFFF', 2)) {
            throw new Error('Exceeded maximum TrackChunkLength. Max is FFFFFFFF.')
        }
        return len
    }

    toHex(): string {
        const length = this.chunklen.toString(16).padStart(8, '0')
        return this.type + length + this.data
    }

    setData(data: string): Chunk {
        this.data = data
        return this
    }
}
