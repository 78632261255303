import { MetaEvent } from '../MetaEvent'
import { MetaEventTypes } from '../../enums/MetaEventTypes'
import { Hexecutioner } from '../../../utils/Hexecutioner'

export class TimeSignature extends MetaEvent {
    constructor(
        deltaTime: number,
        protected num: number,
        protected denom: number,
        protected metro: number,
        protected n32nds: number
    ) {
        super(deltaTime)
        this.metaType = MetaEventTypes.TimeSignature
        const render = (x) => Hexecutioner.numberToHex(x, 2)
        this.data = `${render(this.num)}${render(this.denom)}${render(this.metro)}${render(this.n32nds)}`
    }

    toString(): string {
        return `[MetaEvent] TimeSignature num: '${this.num}', denom: ${this.denom}, metro: ${this.metro}, n32nds: ${this.n32nds}`
    }
}
