import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionTask } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { SetFollowCursorAction } from '../../store/actions/ui-state.actions'
import { IconToggleAction } from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'TogglePlayPauseAction',
})
export class TogglePlayPauseAction extends IconToggleAction implements ActionTask {
    constructor(public audioService: AudioService) {
        super('play_arrow', 'pause')

        this.label.next('Play')
        this.domID.next('project_play-pause')

        this.audioService.isPlaying().subscribe((isPlaying) => {
            this.toggled.next(isPlaying)
            if (isPlaying) {
                this.buttonClasses.next(['bg-cyan-50'])
            } else {
                this.buttonClasses.next([])
            }
        })
    }

    onClickAction() {
        this.audioService.play()
        this.label.next('Pause')
    }

    onClickActionToggled() {
        this.audioService.pause()
        this.label.next('Play')
    }
}
