export type GeneratorType = () => Promise<HTMLCanvasElement>

/**
 * css representation for background image
 */
export interface BackgroundCss {
    backgroundImage?: string
    backgroundBlendMode?: string
    [key: string]: string
}

/**
 * Defines canvas image generator
 */
export interface CanvasBackgroundGenerator {
    generateBackground(): Promise<BackgroundCss>
}

/**
 * Helper for layering canvas images using css background-blend-mode
 */
export class BackgroundLayerGenerator {
    protected generators: CanvasBackgroundGenerator[] = []

    /**
     * Add layer to background image
     * @param generator layer generator
     */
    public async addLayer(generator: CanvasBackgroundGenerator): Promise<void> {
        this.generators.push(generator)
    }

    /**
     * Generates background css containing images as data url
     * @returns background css
     */
    async build(): Promise<BackgroundCss> {
        let bgCSS: BackgroundCss = {
            backgroundImage: '',
        }
        for (const layer of this.generators) {
            const layerData = await layer.generateBackground()
            bgCSS = this.mergeBackgroundCss(bgCSS, layerData)
        }

        return bgCSS
    }

    /**
     * Merge css props by property concatenation
     * @param a
     * @param b
     * @returns
     */
    private mergeBackgroundCss(a: BackgroundCss, b: BackgroundCss): BackgroundCss {
        const keysA = Object.keys(a)
        const keysB = Object.keys(b)

        for (const key of keysB) {
            if (keysA.indexOf(key) != -1 && a[key].length > 0) {
                a[key] = `${a[key]}, ${b[key]}`
            } else {
                a[key] = b[key]
            }
        }

        return a
    }
}
