import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalMessage } from '../modal/modal.service'
import { BasicDialog } from '../modal/basic-dialog'
import { ColorPickerColor } from './color-picker.component'

@Component({
    selector: 'app-color-picker-dialog',
    templateUrl: './color-picker.dialog.html',
})
export class ColorPickerDialogComponent extends BasicDialog {
    onColorSelect(item: ColorPickerColor) {
        this.emit('color', item)
    }
}
