import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionTask, createRouterLink, Logger, ROUTE_HOME } from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToHomeAction',
})
export class NavigateToHomeAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToHomeAction')

    constructor(private router: Router) {
        super()
        this.label.next('COMMON.CLOSE')
        this.icon.next('close')
    }

    onClick() {
        this.navigate(ROUTE_HOME)
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
