export enum BeatResolutionEnum {
    r1_1 = 1,
    r1_2 = 2,
    r1_4 = 4,
    r1_8 = 8,
    r1_16 = 16,
    r1_32 = 32,
    r1_64 = 64,
    r1_128 = 128,
    rt1_2 = 3,
    rt1_4 = 6,
    rt1_8 = 12,
    rt1_16 = 24,
    rt1_32 = 48,
    rt1_64 = 96,
    rt1_128 = 192,
}
