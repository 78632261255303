import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { ModalService } from '@tekbox-coco/midiative-components'
import { NavigateToHomeAction } from '../navigate/NavigateToHomeAction'
import { StorageService } from '../../services/storage/storage.service'
import { AdService } from '../../services/ad.service'

import { ActionController } from '../actions.decorator'
import { AudioService } from '../../services/audio/audio.service'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ProjectCloseAction',
})
export class ProjectCloseAction extends DefaultAction {
    constructor(
        protected modalService: ModalService,
        private navigateToHomeAction: NavigateToHomeAction,
        private storageService: StorageService,
        private adService: AdService,
        private audioService: AudioService
    ) {
        super()
        this.icon.next('close')
        this.label.next('PROJECT.MENU.CLOSE')
    }

    async onClick() {
        this.audioService.stop()
        this.navigateToHomeAction.onClick()
    }
}
