import { getScaleDef, KeyType, ScaleType } from '../music-theory/definitions'
import { CellConfig, GridConfig, GridGenerator } from './GridGenerator'
import { PianoRollBackgroundGenerator } from './PianoRollBackgroundGenerator'
import { BackgroundCss } from './BackgroundLayerGenerator'
import { lightenDarkenColor } from '../util/global-utils'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'
import { Colors } from '../global-constants'

export const defaultHighlightColor = Colors.PR_SCALE_HIGHLIGHT_COLOR
export const defaultScaleColor = lightenDarkenColor(Colors.PR_SCALE_HIGHLIGHT_COLOR, 100)

export class PianoRollScaleGenerator extends PianoRollBackgroundGenerator {
    constructor(
        resolution: BeatResolutionEnum,
        keyType: KeyType,
        beatWidth: number,
        cellHeight,
        protected scale: ScaleType,
        protected highlightColor = defaultHighlightColor,
        protected scaleColor = defaultScaleColor
    ) {
        super(resolution, 4, 4, keyType, beatWidth, cellHeight)
    }

    async build() {
        const config: GridConfig = {
            cellWidth: this.cellWidth,
            cellHeight: this.cellHeight,
            opacity: 0,
            cellConfigFactory: (x: number, y: number, gridConfig: GridConfig) =>
                this.cellConfigFactory(x, y, gridConfig),
        }

        const gridGenerator = new GridGenerator(config)
        const gridBackground = await gridGenerator.generateCanvas(this.cells, this.rows)
        return gridBackground
    }

    async generateBackground(): Promise<BackgroundCss> {
        const bg = await this.build()
        return {
            backgroundImage: `url(${bg.toDataURL()})`,
            backgroundRepeat: 'repeat-x repeat-y',
            backgroundBlendMode: 'overlay',
        }
    }

    cellConfigFactory(column: number, row: number, gridConfig: GridConfig): CellConfig {
        let color = 'transparent'

        // check reversed index is matching selected key type
        const isKeyType = this.rows - 1 - Object.values(KeyType).indexOf(this.keyType) === row
        if (isKeyType) {
            // TODO: color handling
            color = this.highlightColor

            return {
                backgroundColor: color,
            }
        }

        // draw colored scale
        const rootNoteIndex = Object.values(KeyType).indexOf(this.keyType)
        const scale = getScaleDef(this.scale)
            .map((item) => item + rootNoteIndex)
            .map((item) => item % 12)
        if (scale.indexOf(this.rows - 1 - row) != -1) {
            color = this.scaleColor
            return {
                backgroundColor: color,
            }
        }

        return undefined
    }
}
