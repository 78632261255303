import { NgModule } from '@angular/core'
import { ShortcutsOverlayComponent } from './shortcut-overlay.component'
import { CommonModule } from '@angular/common'
import { ButtonModule } from '@tekbox-coco/midiative-components'
import { IonicModule } from '@ionic/angular'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    imports: [CommonModule, TranslateModule, ButtonModule, IonicModule, MatIconModule],
    declarations: [ShortcutsOverlayComponent],
    exports: [ShortcutsOverlayComponent],
})
export class ShortcutsOverlayModule {}
