<div
    [style]="noteStyle"
    class="note cursor-pointer touch-none select-none rounded-[7px] border-[1px] border-[black]"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseUp($event)"
    [ngClass]="{
        'cursor-pointer': !viewOnlyMode,
        'scrollable-note': !viewOnlyMode,
        'opacity-50': viewOnlyMode,
        'pointer-events-none': viewOnlyMode
    }"
>
    <div class="flex h-full justify-between" *ngIf="!viewOnlyMode">
        <div class="flex-1">
            <div class="flex h-full items-center pl-1" (pan)="onPan($event)" (panend)="onPanEnd($event)">
                <div [hidden]="showNoteInResizeArea">
                    {{ note.key | notename }}
                </div>
            </div>
        </div>
        <div
            *ngIf="activeTool == prToolEnumEnum.PENCIL"
            class="border-l-rounded-[7px] flex w-[20px] items-center bg-white opacity-50 hover:bg-[#ff00ff]"
            (pan)="onResize($event)"
            (panend)="onResizeEnd($event)"
        >
            <div class="w-full text-center" [hidden]="!showNoteInResizeArea">
                {{ note.key | notename }}
            </div>
        </div>
    </div>
</div>
