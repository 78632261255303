import { SoundfontManagerService } from './services/file-manager/soundfont-manager.service'

const _window = window as any

/**
 * Overrides window.MIDI.loadResource with custom implementation to use local files
 * @param soundfontManagerService
 */
export function initCustomMidiLoader(soundfontManagerService: SoundfontManagerService) {
    const originalLoadPlugin = _window.MIDI.loadResource

    _window.MIDI.loadResource = function (args: any) {
        const instruments = args.instruments || [args.instrument] || ['acoustic_grand_piano']
        const soundfont = args._soundfont
        soundfontManagerService.loadInstruments(instruments, soundfont, originalLoadPlugin, args).then(() => {
            // silent
        })
    }
    return
}
