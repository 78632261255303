export enum MetaEventTypes {
    SequenceNumber = 0, // hex: 00,
    TextEvent = 1, // hex: 01,
    CopyrightNotice = 2, // hex: 02,
    TrackName = 3, // hex: 03,
    InstrumentName = 4, // hex: 04,
    Lyrics = 5, // hex: 05,
    Marker = 6, // hex: 06,
    CuePoint = 7, // hex: 07,
    MIDIChannelPrefix = 32, // hex: 20,
    MIDIPort = 33, // hex = 21
    SetTempo = 81, // hex: 51,
    SMPTEOffset = 84, // hex: 54,
    TimeSignature = 88, // hex: 58,
    KeySignature = 89, // hex: 59,
    EndOfTrack = 47, // hex: 2F,
    SequencerSpecific = 127, // hex: 7F
}
