import { Injectable, OnDestroy } from '@angular/core'
import { first, Observable, Observer, Subscription, tap } from 'rxjs'
import { Selector } from '@ngrx/store'

@Injectable()
export abstract class SubscriptionManager implements OnDestroy {
    private subscriptions: Subscription[] = []

    protected unsubscribeAll() {
        this.subscriptions.forEach((e) => e.unsubscribe())
        this.subscriptions = []
    }

    protected addSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription)
    }

    /**
     * Unsubscribe on component destroy
     */
    ngOnDestroy() {
        this.unsubscribeAll()
    }

    /**
     * Unsubscribe on page leave
     */
    ionViewDidLeave(): void {
        this.unsubscribeAll()
    }
}
