<div
    *ngFor="let key of keys"
    [ngStyle]="{
        background: pressedKeysByDevice.includes(key)
            ? 'linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(139,219,97,1) 50%, rgba(253,187,45,1) 100%)'
            : ''
    }"
    [ngClass]="getClassForKey(key)"
    (click)="keyPressed.emit(key)"
>
    <div *ngIf="key % 12 === 0" class="flex h-full select-none place-content-end items-end p-1">
        {{ getNoteName(key) }}
    </div>
</div>
