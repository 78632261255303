import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionTask, createRouterLink, Logger, ROUTE_HOME } from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'
import { Location } from '@angular/common'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateBackAction',
})
export class NavigateBackAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateBackAction')

    constructor(private router: Router, private _location: Location) {
        super()
        this.label.next('BACK')
        this.icon.next('arrow_back')
    }

    onClick() {
        this._location.back()
    }
}
