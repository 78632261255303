import { EntityAdapter, EntityState } from '@ngrx/entity/src/models'
import { createEntityAdapter } from '@ngrx/entity'
import { Instruments } from '../midi/Instruments'
import { BeatResolutionEnum } from '../enums/BeatResolutionEnum'
import { Note } from './project-state.model'
import { MAX_GRID_RESOLUTION } from '../util/piano-roll-util'

export interface DisplayNote extends Note {}

/**
 * Note from other tracks. Has id and color as from parent track
 */
export interface OtherTrackNote extends Note {
    trackId: string
    trackColor: string
}

export interface NotesState extends EntityState<DisplayNote> {}

export const canvasNotesAdapter: EntityAdapter<DisplayNote> = createEntityAdapter<DisplayNote>({})

export interface PianoRollStateModel {
    rows: number

    channel: number

    instrument: Instruments
    patternId: string
    patternLengthIRN: number
    resolution: BeatResolutionEnum

    notes: NotesState
}

export const initialPianoRollState: PianoRollStateModel = {
    rows: 88,

    channel: 0,

    instrument: Instruments.ELECTRIC_GRAND_PIANO,
    patternId: '',
    patternLengthIRN: MAX_GRID_RESOLUTION * 4,
    resolution: BeatResolutionEnum.r1_8,

    notes: canvasNotesAdapter.getInitialState(),
}

const { selectIds, selectEntities, selectAll, selectTotal } = canvasNotesAdapter.getSelectors()
