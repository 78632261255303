import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { ActionTask, HudActionButtonConfig } from '@tekbox-coco/midiative-commons'

export interface TutorialActionButton {
    name: string
    callback?: (close: () => void) => any
    routerLink?: string
}

export enum LabelPosition {
    TOP = 'top',
    LEFT = 'left',
    RIGHT = 'right',
    BOTTOM = 'bottom',
}
export enum LabelAlignment {
    LEFT = 'start',
    RIGHT = 'end',
    CENTER = 'center',
}

export interface TutorialStep {
    text: string
    active?: boolean

    selector?: string

    xPos?: number
    yPos?: number
    height?: number
    width?: number
    bottom?: number
    left?: number
    right?: number
    top?: number

    // delay the opening of the this step ms
    delay?: number

    label?: {
        height?: number
        width?: number
        position?: LabelPosition
        alignment?: LabelAlignment
        offsetTop?: number
        offsetLeft?: number
    }

    preActions?: string[]
    postActions?: string[]
}

export type PositionArea = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.components.scss'],
})
export class TutorialComponent implements OnInit {
    hasDefaultContent = false
    stepCounter: number

    @Output() requestClose = new EventEmitter<boolean>()

    @Input() tutorialSteps: TutorialStep[] = []

    @Input() isOpen = false
    @Input() buttonActions: TutorialActionButton[] = []

    @Input() renderButtons = true

    constructor(private injector: Injector) {}

    get lastStep(): boolean {
        return this.stepCounter > this.tutorialSteps.length - 1
    }

    get firstStep(): boolean {
        return this.stepCounter === 0
    }

    ngOnInit(): void {
        this.hasDefaultContent = true
        this.stepCounter = 0
    }

    calculateOverlayStyle(step: TutorialStep): Record<string, string> {
        const style = {
            position: 'fixed',
            boxShadow: '0 0 0 99999px rgba(0, 0, 0, .8)',
        }
        const styleExtra: Record<string, any> = {}

        if (step.selector) {
            const appSelector = this.extractSelector(step)
            const element = document.querySelector(appSelector)
            if (!element) {
                return {}
            }
            const box = element.getBoundingClientRect()
            const elementStyle = window.getComputedStyle(element)

            if (elementStyle.borderRadius) {
                styleExtra.borderRadius = elementStyle.borderRadius
            }
            return {
                ...style,
                ...styleExtra,
                left: box.left + 'px',
                top: box.top + 'px',
                width: box.width + 'px',
                height: box.height + 'px',
                bottom: box.bottom + 'px',
                right: box.right + 'px',
            }
        }
        return {
            ...style,
            left: step.left + 'px',
            top: step.top + 'px',
            width: step.width + 'px',
            height: step.height + 'px',
            bottom: step.bottom + 'px',
            right: step.right + 'px',
        }
    }

    calculateTextAreaStyle(step: TutorialStep) {
        if (step.selector) {
            const appSelector = this.extractSelector(step)
            const element = document.querySelector(appSelector)
            if (!element) {
                return {}
            }
            const box = element.getBoundingClientRect()

            let elementLeft = box.left
            const elementRight = box.right

            const label = step.label || {}
            let elementTop = box.top
            const elementHeight = label.height || box.height
            let elementBottom = box.bottom
            const elementWidth = label.width || box.width

            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight

            const distanceRight = windowWidth - elementRight
            const distanceLeft = elementLeft
            const distanceTop = elementTop
            const distanceBottom = windowHeight - elementBottom

            if (label.position) {
                if (label.position === LabelPosition.TOP) {
                    elementTop = box.top - elementHeight
                } else if (label.position === LabelPosition.BOTTOM) {
                    elementTop = box.bottom + 10
                } else if (label.position === LabelPosition.LEFT) {
                    elementLeft = box.left - elementWidth
                } else {
                    elementLeft = box.right + 10
                }
            } else {
                if (distanceLeft > distanceRight && distanceRight > 0) {
                    elementLeft = distanceLeft - elementWidth
                } else if (distanceLeft < distanceRight) {
                    elementLeft = box.right + 20
                } else {
                    if (distanceTop > distanceBottom) {
                        elementTop = box.top
                    } else {
                        elementTop = box.top + elementHeight
                    }
                }
            }

            if (label.offsetTop) {
                elementTop += label.offsetTop
            }
            if (label.offsetLeft) {
                elementLeft += label.offsetLeft
            }

            return {
                left: `${elementLeft}px`,
                top: `${elementTop}px`,
                width: `${elementWidth}px`,
                height: `${elementHeight}px`,
                display: 'flex',
                justifyContent: label.alignment || LabelAlignment.LEFT,
            }
        }

        return {
            left: step.left + 'px',
            top: step.top + 'px',
            width: step.width + 'px',
            height: step.height + 'px',
            bottom: step.bottom + 'px',
        }
    }

    calculateButtonStyle() {
        const freeCorner: PositionArea = this.getFreeScreenCorner()
        switch (freeCorner) {
            case 'top-left':
                return {
                    top: '0',
                    left: '0',
                }
            case 'top-right':
                return {
                    top: '0',
                    right: '0',
                }
            case 'bottom-left':
                return {
                    bottom: '0',
                    left: '0',
                }
            case 'bottom-right':
                return {
                    bottom: '0',
                    right: '0',
                }
        }
    }

    getFreeScreenCorner(): PositionArea {
        const step: TutorialStep = this.tutorialSteps[this.stepCounter]
        if (!step) {
            return 'bottom-left'
        }

        const highlightedArea = this.calculateOverlayStyle(step)
        const tutorialTextArea = this.calculateTextAreaStyle(step)
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight

        if (highlightedArea.top) {
            const hATop = Number(`${highlightedArea.top.replace('px', '')}`)
            const hALeft = Number(`${highlightedArea.left.replace('px', '')}`)
            const hAWidth = Number(`${highlightedArea.width.replace('px', '')}`)
            const hAHeight = Number(`${highlightedArea.height.replace('px', '')}`)

            const hABottom = hATop + hAHeight
            const hARight = hALeft + hAWidth

            const tATop = Number(`${tutorialTextArea.top.replace('px', '')}`)
            const tALeft = Number(`${tutorialTextArea.left.replace('px', '')}`)
            const tAWidth = Number(`${tutorialTextArea.width.replace('px', '')}`)
            const tAHeight = Number(`${tutorialTextArea.height.replace('px', '')}`)
            const tABottom = tATop + tAHeight
            const tARight = tALeft + tAWidth
            const posHAStart = this.getPointPosition(hALeft, hATop)
            const posHAEnd = this.getPointPosition(hARight, hABottom)

            const posTAStart = this.getPointPosition(tATop, tALeft)
            const posTAEnd = this.getPointPosition(tABottom, tARight)

            const positions: string[] = [...new Set([posHAStart, posHAEnd, posTAStart, posTAEnd])]
            const all = ['top-left', 'top-right', 'bottom-left', 'bottom-right']

            const finalPos = all.filter((x) => !positions.includes(x))
            if (finalPos.length > 0) {
                return finalPos[0] as PositionArea
            }
        }
        return 'bottom-left'
    }

    getPointPosition(x: number, y: number): PositionArea {
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight

        const halfScreenWidth = screenWidth / 2
        const halfScreenHeight = screenHeight / 2
        let position = ''
        if (y <= halfScreenHeight) {
            position += 'top'
        } else {
            position += 'bottom'
        }

        if (x <= halfScreenWidth) {
            position += '-left'
        } else {
            position += '-right'
        }
        return position as PositionArea
    }

    closeDialog() {
        this.requestClose.emit(true)
    }

    skipTutorial() {
        this.requestClose.emit(true)
    }

    onClickEvent($event: MouseEvent) {
        if (this.stepCounter > 0) {
            this.runPostActions(this.stepCounter)
        }
        if (this.stepCounter <= this.tutorialSteps.length && !this.lastStep) {
            const currentStep = this.tutorialSteps[this.stepCounter]
            const nextStep = this.tutorialSteps[this.stepCounter + 1]

            if (nextStep && nextStep.delay) {
                const stepCounterTemp = this.stepCounter
                this.stepCounter = -1
                setTimeout(() => {
                    this.stepCounter = stepCounterTemp + 1
                    this.runPreActions(this.stepCounter)
                }, nextStep.delay)
            } else {
                this.stepCounter = this.stepCounter + 1
                this.runPreActions(this.stepCounter)
            }
        }
        if (this.lastStep) {
            this.stepCounter = 0
            this.requestClose.emit(true)
        }
        $event.stopPropagation()
    }

    onClickInside($event: MouseEvent) {
        this.onClickEvent($event)
    }

    onClickOutside($event: MouseEvent) {
        this.onClickEvent($event)
    }

    private runPreActions(index: number) {
        const step = this.tutorialSteps[index]
        if (step && step.preActions) {
            step.preActions.forEach((e) => this.runAction(e))
        }
    }
    private runPostActions(index: number) {
        const step = this.tutorialSteps[index]
        if (step && step.postActions) {
            step.postActions.forEach((e) => this.runAction(e))
        }
    }

    private async runAction(actionName: string) {
        const actionTask = <ActionTask>this.injector.get(actionName)
        if (actionTask) {
            await actionTask.onClick()
        }
    }

    private getTextWidth(textData: string): number {
        const text = document.createElement('span')
        document.body.appendChild(text)

        text.style.font = 'times new roman'
        text.style.fontSize = 16 + 'px'
        text.style.height = 'auto'
        text.style.width = 'auto'
        text.style.position = 'absolute'
        text.style.whiteSpace = 'no-wrap'
        text.innerHTML = textData

        const width = Math.ceil(text.clientWidth)

        document.body.removeChild(text)
        return width
    }

    private extractSelector(tutorialStep: TutorialStep): string {
        const appSelectorTmp = tutorialStep.selector
        return appSelectorTmp.replace('$app', 'body > app-root > ion-app > ion-router-outlet >')
    }
}
