import { initialAppState } from '../../AppState'
import { Action } from '@ngrx/store'
import { AppState } from '@tekbox-coco/midiative-commons'
export interface HistoryStateModel {
    past: HistoryItem[]
    present: HistoryItem
    future: HistoryItem[]
}

export interface HistoryItem {
    currentState: AppState
    relatedAction: Action
}

export const initialHistoryState: HistoryStateModel = {
    past: [],
    present: { currentState: initialAppState, relatedAction: null },
    future: [],
}
