import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface SelectRequestItem {
    label: string
    value: any
}

export interface SelectRequest {
    items: SelectRequestItem[]
    selected?: any
}

export interface SelectRequestCallback extends SelectRequest {
    callback: (item: SelectRequestItem) => void
}

@Injectable({
    providedIn: 'root',
})
export class SelectDialogService {
    public selectRequests: BehaviorSubject<SelectRequestCallback> = new BehaviorSubject<SelectRequestCallback>(null)

    showSelect(request: SelectRequest): Promise<SelectRequestItem | null> {
        return new Promise((resolve, reject) => {
            this.selectRequests.next({
                ...request,
                callback: (item: SelectRequestItem) => {
                    resolve(item)
                },
            })
        })
    }
}
