import { Injectable, OnDestroy } from '@angular/core'
import { Location } from '@angular/common'
import { Store } from '@ngrx/store'
import { Storage } from '@ionic/storage-angular'
import {
    getUnixTimeStampByDate,
    Logger,
    ProjectStateModel,
    SettingsStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import {
    DeactivateDemoModeAction,
    GenerateNewProjectIdAction,
    LoadProjectAction,
    SetNameAction,
} from '../../store/actions/project-state.actions'
import { skipUntil, skipWhile, Subscription, timer } from 'rxjs'
import { LoadSettingsAction } from '../../store/actions/settings-state.actions'
import { DemoIds } from './demo-projects/demoIds'
import { Demos } from './demo-projects/demos'
import { ToastService } from '../toast.service'
import { SpinnerService } from '@tekbox-coco/midiative-components'

const enum StorageKey {
    PROJECTS = 'PROJECTS',
    SETTINGS = 'SETTINGS',
}

export interface SimpleProjectObject {
    id: string
    name: string
    demo: boolean
}

@Injectable({
    providedIn: 'root',
})
export class StorageService implements OnDestroy {
    private readonly logger = Logger.createLogger('StorageService')

    private projectStateSubscription: Subscription
    private settingsStateSubscription: Subscription

    projectState: ProjectStateModel
    settingsState: SettingsStateModel

    constructor(
        private store: Store<AppState>,
        private storage: Storage,
        private toastService: ToastService,
        private spinner: SpinnerService,
        private location: Location
    ) {
        this.storage
            .create()
            .then((value) => this.logger.info('Storage created: ', value))
            .catch((e) => this.logger.error('Could not create storage: ', e))

        this.projectStateSubscription = this.store.select('projectState').subscribe({
            next: (value) => (this.projectState = value),
            error: (e) => this.logger.error(e),
        })
        this.settingsStateSubscription = this.store.select('settingsState').subscribe({
            next: (value) => (this.settingsState = value),
            error: (e) => this.logger.error(e),
        })
    }

    ngOnDestroy(): void {
        this.projectStateSubscription.unsubscribe()
        this.settingsStateSubscription.unsubscribe()
    }

    public async loadSettings(): Promise<void> {
        const settings = await this.storage
            .get(StorageKey.SETTINGS)
            .catch((e) => this.logger.info('No settings in storage found', e))

        // if settings are found on the device load them to the store
        if (settings) {
            this.store.dispatch(new LoadSettingsAction(settings))
        }

        this.logger.info('Loaded settings from storage')
    }

    public async saveSettings(): Promise<void> {
        await this.storage
            .set(StorageKey.SETTINGS, this.settingsState)
            .catch((e) => this.logger.error('Could not persist settings)', e))
        this.logger.info('Saved settings to storage')
    }

    public async saveCurrentProject(): Promise<void> {
        // project is demo project
        if (
            Object.values(DemoIds)
                .map((v) => v.toString())
                .includes(this.projectState.id)
        ) {
            // TODO: improve naming
            const name = this.projectState.name + ' (D)'
            this.store.dispatch(new GenerateNewProjectIdAction())
            this.store.dispatch(new SetNameAction(name))
            this.store.dispatch(new DeactivateDemoModeAction())

            // TODO: Remove timeout workaround to replace url.
            // The workaround here was necessary since the id
            // was not written in the store yet, but we needed
            // the new one
            timer(100).subscribe(() => {
                this.logger.info('Replaced url for editable clone of demo id')
                this.location.replaceState('/project/' + this.projectState.id)
            })
        }

        const projects = await this.storage
            .get(StorageKey.PROJECTS)
            .catch((e) => this.logger.error('Storage Key PROJECTS does not exist', e))
        if (projects && projects.length > 0 && projects.filter((p) => p.id === this.projectState.id).length > 0) {
            await this.storage
                .set(StorageKey.PROJECTS, [
                    ...projects.filter((p) => p.id !== this.projectState.id),
                    { ...this.projectState, lastEdit: getUnixTimeStampByDate(new Date()) },
                ])
                .catch((e) => {
                    this.logger.error('Error while updating project', e)
                    this.toastService.error('STORAGE.SAVE_PROJECT_ERROR')
                })
            this.logger.debug('Saved project successfully')
            this.spinner.showing = false
            return
        } else {
            // if there are no projects in the storage, projects won't be iterable
            // and therefore the destruction operator will crash if not handled
            const projectsToSave = projects && projects.length > 0 ? projects : []
            await this.storage
                .set(StorageKey.PROJECTS, [
                    ...projectsToSave,
                    {
                        ...this.projectState,
                        created: getUnixTimeStampByDate(new Date()),
                        lastEdit: getUnixTimeStampByDate(new Date()),
                    },
                ])
                .catch((e) => {
                    this.logger.error('Error while saving project', e)
                    this.toastService.error('STORAGE.SAVE_PROJECT_ERROR')
                })
            this.logger.info('created new project', this.projectState.name, 'successfully')
            return
        }
    }

    public async logStorageProjects(): Promise<void> {
        const projects = await this.storage.get(StorageKey.PROJECTS)
        this.logger.info('current projects in storage: ', projects)
    }

    public async getProjectsInStorage(): Promise<SimpleProjectObject[]> {
        this.spinner.showing = true
        const res = await this.storage.get(StorageKey.PROJECTS)
        const demos = Demos.map((demo) => ({
            id: demo.id,
            name: demo.project.name,
            demo: true,
        }))
        const projectsAndDemos = res !== null ? res.concat(demos) : demos
        this.spinner.showing = false
        return projectsAndDemos !== null
            ? projectsAndDemos.map((p) => {
                  return { id: p.id, name: p.name, demo: p.demo }
              })
            : []
    }

    public async loadProject(projectId: string): Promise<void> {
        this.spinner.showing = true
        const projects = await this.storage
            .get(StorageKey.PROJECTS)
            .catch((e) => this.logger.error('Storage Key PROJECTS does not exist', e))
        const demoData = Demos.map((d) => d.project)
        if (projects === null) {
            this.logger.info('No Projects in storage found')
        }
        const projectsAndDemos = projects !== null ? projects.concat(demoData) : demoData
        const projectData = projectsAndDemos.filter((p) => p.id === projectId)[0]
        if (projectData !== undefined) {
            this.store.dispatch(new LoadProjectAction(projectData))
            if (projectData.demo) {
                this.logger.info('Loading demo project...')
            }
            this.logger.info('Project loaded successfully')
        } else {
            this.logger.error('Project not found, id was: ', projectId)
        }
        this.spinner.showing = false
    }

    public async deleteProject(projectId: string): Promise<void> {
        if (
            Object.values(DemoIds)
                .map((v) => v.toString())
                .includes(projectId)
        ) {
            this.logger.error('Cannot delete Demo Project')
            return
        }

        this.spinner.showing = true
        const projects = await this.storage
            .get(StorageKey.PROJECTS)
            .catch((e) => this.logger.error('Storage Key PROJECTS does not exist', e))
        if (projects === null) {
            this.logger.error('no projects found')
        } else {
            await this.storage
                .set(
                    StorageKey.PROJECTS,
                    projects.filter((p) => p.id !== projectId)
                )
                .catch((e) => this.logger.error('Storage Key PROJECTS does not exist', e))
            this.logger.info('Deleted project successfully')
        }
        this.spinner.showing = false
    }
}
