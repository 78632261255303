import { Output, Input, EventEmitter, Component } from '@angular/core'
import { ModalMessage } from './modal.service'

/**
 * Message definition for dialog messaging
 */
export interface DialogMessage<T = string> {
    message: string
    payload?: T
}

/**
 * base class for dialog components used with modal.module
 */
@Component({
    template: '',
})
export class BasicDialog<T = any> {
    // event emitter for outgoing events/messages
    @Output()
    dialogMessages = new EventEmitter<DialogMessage<T>>()

    // message properties
    @Input()
    public message: ModalMessage

    protected emit(id: string, payload: any) {
        this.dialogMessages.emit({ message: id, payload })
    }
}
