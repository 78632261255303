import { Injectable, Injector } from '@angular/core'
import { EventManager } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { getRouteType, ROUTE_PIANO_ROLL, ROUTE_PROJECT } from '../../util/route-utils'
import { Logger } from '../../logger/Logger'
import { ActionTask } from '../../action-task/ActionTask'
import { PlatformService } from '../platform.service'

export class Shortcut {
    keys: string
    description?: string
    route: string
    action: string
    isNativelyAvailable: boolean = true
}

type Options = {
    element: any
    keys: string
}

/**
 * Extract key codes with this tool https://developer.mozilla.org/en-US/play
 */
const generalShortcuts: Partial<Shortcut>[] = [
    {
        keys: 'Control+e',
        action: 'DownloadProjectAction',
    },
    {
        keys: 'Control+m',
        action: 'DownloadMIDIAction',
    },
    {
        keys: 'Control+z',
        action: 'UndoAction',
        description: 'SHORTCUTS.GENERAL.CTRLZ',
    },
    {
        keys: 'Control+y',
        action: 'RedoAction',
        description: 'SHORTCUTS.GENERAL.CTRLY',
    },
    {
        keys: 'space',
        action: 'TogglePlayPauseAction',
        description: 'SHORTCUTS.GENERAL.SPACE',
    },
    {
        keys: 's',
        action: 'ToggleSnapToGridAction',
        description: 'SHORTCUTS.GENERAL.S',
    },
    {
        keys: 'f',
        action: 'FollowCursorToggleAction',
        description: 'SHORTCUTS.GENERAL.F',
    },
    {
        keys: 'p',
        action: 'NavigateToProjectSettingsAction',
        description: 'SHORTCUTS.GENERAL.P',
    },
    {
        keys: 'ArrowRight',
        action: 'GoToNextBeatAction',
        description: 'SHORTCUTS.GENERAL.ARROWRIGHT',
    },
    {
        keys: 'ArrowLeft',
        action: 'GoToPreviousBeatAction',
        description: 'SHORTCUTS.GENERAL.ARROWLEFT',
    },
    {
        keys: 'h',
        action: 'OpenShortcutOverlayAction',
        description: 'SHORTCUTS.GENERAL.OPENSHORTCUT',
        isNativelyAvailable: false,
    },
]

// add shortcuts here
export const SHORTCUTS: Record<string, Partial<Shortcut>[]> = {
    [ROUTE_PROJECT]: [
        ...generalShortcuts,
        {
            keys: 'Control+s',
            action: 'ProjectSaveChangesAction',
            description: 'SHORTCUTS.PROJECT.CTRLSPROJECT',
        },
        {
            keys: 'Control+plus',
            action: 'ZoomProjectInAction',
            description: 'SHORTCUTS.PROJECT.CTRLPLUSPROJECT',
        },
        {
            keys: 'Control+-',
            action: 'ZoomProjectOutAction',
            description: 'SHORTCUTS.PROJECT.CTRLMINUSPROJECT',
        },
        {
            keys: 'Control+e',
            action: 'ProjectExportAction',
            description: 'SHORTCUTS.PROJECT.CTRLE',
        },
    ],
    [ROUTE_PIANO_ROLL]: [
        ...generalShortcuts,
        {
            keys: 'Control+s',
            action: 'PianoRollSaveChangesAction',
            description: 'SHORTCUTS.PIANOROLL.CTRLSPIANOROLL',
        },
        {
            keys: 'Control+plus',
            action: 'ZoomPianoRollInAction',
            description: 'SHORTCUTS.PIANOROLL.CTRLPLUSPIANOROLL',
        },
        {
            keys: 'Control+-',
            action: 'ZoomPianoRollOutAction',
            description: 'SHORTCUTS.PIANOROLL.CTRLMINUSPIANOROLL',
        },
    ],
}

@Injectable({ providedIn: 'root' })
export class HotkeyService {
    private readonly logger = Logger.createLogger('HotkeyService')

    private modifierKeys: string[] = []
    private activeModifierKeys: string[] = []

    defaults: Partial<Options> = {
        element: document,
    }
    private currentRoute: string

    private shortcutHandler: Array<Shortcut> = []

    constructor(
        private eventManager: EventManager,
        private router: Router,
        private injector: Injector,
        private platformService: PlatformService
    ) {
        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: any) => {
            this.currentRoute = event.url
        })
    }

    initialize() {
        this.modifierKeys.push('Control')
        this.modifierKeys.push('Alt')

        const doc = document as any
        this.eventManager.addEventListener(doc, 'keydown', (e: KeyboardEvent) => {
            e.preventDefault()

            if (this.modifierKeys.indexOf(e.key) !== -1) {
                this.activeModifierKeys.push(e.key)
            } else {
                let key = e.key
                // overwrite space key with identifier 'space'
                if (e.key === ' ') {
                    key = 'space'
                }
                if (e.key === '+') {
                    key = 'plus'
                }

                const isNative = this.platformService.isNative()
                const shortcutPath = this.buildShortcutPath(key, this.activeModifierKeys)
                const shortcut = this.shortcutHandler
                    .filter((shortcut) => getRouteType(this.currentRoute) === shortcut.route)
                    .filter((s) => {
                        return this.normalizeShortcutPath(s.keys) === shortcutPath
                    })
                    // filter all non mobile shortcuts
                    .filter((s) => {
                        if (isNative && s.isNativelyAvailable === true) {
                            return false
                        } else {
                            return true
                        }
                    })

                if (shortcut.length > 0) {
                    // resolve actiond
                    console.log()
                    const actionTask = <ActionTask>this.injector.get(shortcut[0].action)
                    actionTask.onClick()
                }
            }
        })
        this.eventManager.addEventListener(doc, 'keyup', (e: KeyboardEvent) => {
            e.preventDefault()

            if (this.modifierKeys.indexOf(e.key) !== -1) {
                this.activeModifierKeys = this.activeModifierKeys.filter((key) => !(key === e.key))
            }
        })

        // "parse" shortcut config and add shortcuts
        for (const route of Object.keys(SHORTCUTS)) {
            for (const shortcut of SHORTCUTS[route]) {
                this.addShortcut({
                    ...shortcut,
                    route: route,
                } as Shortcut)
            }
        }
    }

    /* will only work for project, piano roll and sequencer due to limitations of the getRouteType function */
    addShortcut(shortcut: Shortcut): void {
        // add shortcut handler
        this.shortcutHandler.push(shortcut)
    }

    private normalizeShortcutPath(path: string) {
        const normalizedPath = path.split('+').map((e) => e.trim())

        const top = normalizedPath.pop()
        return this.buildShortcutPath(top, normalizedPath)
    }

    /**
     * Takes key and list of mod keys and builds common path
     * @param key shortcut key
     * @param modifierKeys modifier keys
     * @returns Shortcut path: Mod1+Mod2+Key
     */
    private buildShortcutPath(key: string, modifierKeys: string[]) {
        return [...[...new Set(modifierKeys.map((e) => e.toLowerCase()))].sort(), key].join('+')
    }
}
