import { DemoIds } from './demoIds'
import {
    Color,
    initialLoopSelectorState,
    Instruments,
    KeyType,
    PatternType,
    ProjectStateModel,
    ScaleType,
} from '@tekbox-coco/midiative-commons'

export const PopDemoProject: ProjectStateModel = {
    id: DemoIds.POP,
    name: 'Pop-Demo',
    demo: true,
    bpm: 120,
    timeSignatureNumerator: 4,
    timeSignatureDenominator: 4,
    key: KeyType.A,
    schemaVersion: 1,
    scale: ScaleType.PENT_MIN,
    loopSelector: initialLoopSelectorState,
    created: 0,
    lastEdit: 0,
    patterns: {
        ids: ['patternId1', 'patternId2', 'patternId3'],
        entities: {
            patternId1: {
                id: 'patternId1',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '2fbbc404-ee3f-48ef-b426-74cbe2496a58',
                        startIRN: 0,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9fa22b7d-b933-40d8-8af8-94649d3fd005',
                        startIRN: 0,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c767beb2-9fbb-4dd2-be95-7bda75248566',
                        startIRN: 0,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '49668911-0041-4e34-9288-4e2471b787d1',
                        startIRN: 48,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd924f733-9222-4caa-b2de-8ed048d92ea2',
                        startIRN: 48,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '83a3b23d-574b-41dc-ac51-ecabc426eebe',
                        startIRN: 48,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6160445d-0c2a-476f-bd01-8d83abf727c6',
                        startIRN: 96,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '7bb6c60d-70b1-4962-9ded-4d060eb1fdbc',
                        startIRN: 96,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '806ce979-d85a-4205-b852-02f8d87f737f',
                        startIRN: 96,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '889ff41c-d857-470b-a4a7-0ba527503b59',
                        startIRN: 144,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4fcd4cf9-b59b-4329-b109-7a0d106f52c3',
                        startIRN: 144,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '58e257df-e4d7-4f3d-bbc1-4ff9a6a1112b',
                        startIRN: 144,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c86d4acc-d14c-4580-800c-ef64ce64dcae',
                        startIRN: 192,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '03db906b-fc49-4aca-85b6-c82e418896b6',
                        startIRN: 192,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5a335a5d-b84e-4539-8d51-78b29fb00176',
                        startIRN: 192,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9c33e9ae-8356-43c4-adf7-ccc8c8c1e30a',
                        startIRN: 240,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fdb795ad-5e69-40d8-b31c-8cbe7523c5df',
                        startIRN: 240,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '51e37f2f-1423-46bd-ae82-3802ed767126',
                        startIRN: 240,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0e06ad9d-821f-4a60-bc2e-bc4a981bb688',
                        startIRN: 288,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '459a6e6a-9034-44fe-93ab-c60ee1d91ab2',
                        startIRN: 288,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '8a988184-b30e-451d-a448-1d919d4dcbe5',
                        startIRN: 288,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1f40562c-ea9d-41ef-8ec0-75304b1ba63f',
                        startIRN: 336,
                        key: 57,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa92faab-7640-48c7-9931-2fb5939a318d',
                        startIRN: 336,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0e4ad7d3-d96d-409e-81b3-009cfd098659',
                        startIRN: 336,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9c34c154-6871-44c2-8646-1c86a59678f2',
                        startIRN: 384,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a7085d08-77ea-43f1-9b20-11013f50a8bf',
                        startIRN: 384,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '938f8c88-00e8-4248-b50f-8627de6b08ca',
                        startIRN: 384,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b9798982-7190-46d1-a33d-000c47c3bbc9',
                        startIRN: 432,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f46ac7f2-a6b7-4dfc-8b31-015342039fdc',
                        startIRN: 432,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd5c1c8dc-f1b4-49de-bbf5-290dcd4b6919',
                        startIRN: 432,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a163de5-60d7-4fd4-85b0-cbadcb4cccae',
                        startIRN: 480,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c0af1f32-6eda-41c0-b594-2fb74a79dfda',
                        startIRN: 480,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9bcf9d5f-2c04-43a5-820d-e6e25d523d98',
                        startIRN: 480,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1f0c9d9f-c28f-41b9-8676-4ee327479c3b',
                        startIRN: 528,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '915d286a-053b-49c1-8f4a-30a86e359e5e',
                        startIRN: 528,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d845065-9194-46bd-b27b-81aea55e9db6',
                        startIRN: 528,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6323cbcc-2dc8-498f-b4c0-551719251a93',
                        startIRN: 576,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '58327427-7535-4d25-a4a4-c0084ed28bde',
                        startIRN: 576,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c01bbc51-0322-4a41-b288-bc66f3abab03',
                        startIRN: 576,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a205827d-37a2-4f25-9954-98183137a221',
                        startIRN: 624,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ec7ae5f-6672-423b-9a7c-6f5ec4957404',
                        startIRN: 624,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd6443ad9-2cbd-4e4d-a570-5a6100b16aae',
                        startIRN: 624,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '558140be-c49b-4cd4-928b-14b20ef696f5',
                        startIRN: 672,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '02b68593-5442-49f8-9a5b-b813def1b010',
                        startIRN: 672,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a35fd436-9b99-48f9-83cd-7909d999e35b',
                        startIRN: 672,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4c1a9619-8f50-48dd-bb3b-5995b73c8523',
                        startIRN: 720,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f27543e-990b-4b59-92c2-cb1cec1d5e2d',
                        startIRN: 720,
                        key: 65,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4def38d7-32f0-463e-bc01-76ca96f1c164',
                        startIRN: 720,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '49dfc2bd-2bb6-47e4-a6ee-7252f2bec3d0',
                        startIRN: 768,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'dd7b4a75-8316-465f-9e43-4cef677800ca',
                        startIRN: 768,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '312507f3-cf33-4c6f-9891-0961c7a16c20',
                        startIRN: 768,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6dfdc653-c781-4116-860e-cf5f4b3890ca',
                        startIRN: 816,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a102b741-dec3-4f64-820e-ee24ce904ae2',
                        startIRN: 816,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd390058a-ea0a-4eb9-b272-8ff211f7ddcf',
                        startIRN: 816,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9300fe67-e89c-4ee9-a28a-c1bdab8ecc3f',
                        startIRN: 864,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '274baed0-817b-4719-ad9d-c6035fec9bf1',
                        startIRN: 864,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6a00158e-d731-40f7-80ed-c9bd4ab1cc96',
                        startIRN: 864,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3de469a-0d08-4f42-82de-657ca8d20633',
                        startIRN: 912,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6644564c-1016-40f7-9331-40530b2283b5',
                        startIRN: 912,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '658eeb51-f0bf-4106-a16e-d51d50eeef5e',
                        startIRN: 912,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c91f44cf-ac21-480d-831b-e621a507e652',
                        startIRN: 960,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '13c94b82-9062-42df-9183-15d59b12d6b7',
                        startIRN: 960,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b9efe73d-b4c0-4c9b-9f2e-074b4123345d',
                        startIRN: 960,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4790ff42-ee1a-4c64-95ae-88dc35c20533',
                        startIRN: 1008,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4f6a9c98-b6c9-48da-a7b7-7c711d5f03e7',
                        startIRN: 1008,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0e0a25b9-7cb3-4e50-8061-e2c4fb96113d',
                        startIRN: 1008,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ad213ab6-a69d-4f4e-948f-c577e828a0c9',
                        startIRN: 1056,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'bdc77aef-d778-4452-8f27-2f5a5f2f8933',
                        startIRN: 1056,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f641f41f-a046-4d36-8372-d7ba9545c8e1',
                        startIRN: 1056,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c0359276-cdc8-4f85-9373-e5a11ad28297',
                        startIRN: 1104,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0d4ccc34-7d28-420e-8840-629736f5ee97',
                        startIRN: 1104,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d3964e2-ce6c-41ba-b0f7-28986a0ba155',
                        startIRN: 1104,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb74d479-80f9-41d0-a600-1c3058e1fdd1',
                        startIRN: 1152,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '52ecdbcc-8274-423a-94cd-5c79d9969e4d',
                        startIRN: 1152,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e9797d51-8ccf-4b9d-910f-fbdd72c2df0e',
                        startIRN: 1200,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'bec4e63e-5a19-4ee2-895a-44788a992be5',
                        startIRN: 1200,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1d05339a-3724-441b-a488-71e96247f3a8',
                        startIRN: 1248,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'efb12447-7190-44cb-b804-0432f9ef90db',
                        startIRN: 1248,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '336a3529-cf82-460d-8949-a40331657693',
                        startIRN: 1296,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9ebd31c8-8a90-40db-aee8-35de025f4552',
                        startIRN: 1296,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '29f99f61-6b64-4673-9340-89a1de45828a',
                        startIRN: 1344,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '7eec377d-d30c-405a-9dd2-d0fc20e56e3e',
                        startIRN: 1344,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e954c029-b850-4885-ba0f-8da2a6219b6c',
                        startIRN: 1344,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9e112f58-602b-473a-aebb-2b63b40c0a66',
                        startIRN: 1392,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c5bdbf5a-0aaf-4dd8-9bb5-23db3df4589d',
                        startIRN: 1392,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f5f7eb4f-b835-40c8-a7df-fadbe7d7a3c7',
                        startIRN: 1392,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b0dd0d5e-5837-45a4-af7f-7eeb6189189d',
                        startIRN: 1440,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '40b3b1a7-577e-4a46-996d-4748758bae61',
                        startIRN: 1440,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '63acf611-446e-45ad-9aec-74c86484a032',
                        startIRN: 1440,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '8d62873b-7b0f-49ae-a129-3d2066a8e1a6',
                        startIRN: 1488,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '72465b3c-1d80-43aa-88c3-92a3014e28b9',
                        startIRN: 1488,
                        key: 59,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5b8bfd10-698f-4241-a3a3-603c75be7482',
                        startIRN: 1488,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '703a5b12-d5fd-43d8-98ed-7cc2ac77aa4e',
                        startIRN: 1152,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e69fdaec-bcec-4974-a092-03db40789532',
                        startIRN: 1200,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '738ad3bd-c1de-4a13-92ca-12e78ce3fbb9',
                        startIRN: 1248,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '599dda8c-2d34-458a-80fd-670a2addf502',
                        startIRN: 1296,
                        key: 55,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.RED,
                type: PatternType.PianoRollPattern,
            },
            patternId2: {
                id: 'patternId2',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: 'cb87fe87-c46e-4c81-87f9-a39408e74c51',
                        startIRN: 48,
                        key: 86,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3d956bc-cd22-4518-b38f-753e36fab458',
                        startIRN: 96,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e9c4fc7b-68e1-4700-8d4b-f82509245f87',
                        startIRN: 144,
                        key: 91,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '38783596-51fe-4a03-941a-b95f032b39eb',
                        startIRN: 432,
                        key: 91,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6291598f-4f6c-4a26-a01b-20ad2513c973',
                        startIRN: 480,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f8838eb-8adb-413f-a4b1-3b525a9925f3',
                        startIRN: 528,
                        key: 86,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '67cf25fd-b8b7-4358-b340-9a3dcf45765e',
                        startIRN: 816,
                        key: 84,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b454743d-9fe3-4927-a15d-59c54a2deff1',
                        startIRN: 864,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9cf763a3-bc02-46f6-9715-02223093a537',
                        startIRN: 912,
                        key: 86,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '05f0c2ce-11a2-43b7-a5d8-23a683a7f716',
                        startIRN: 1200,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a720361-ad92-4dd7-a99a-9a11ae6eae86',
                        startIRN: 1248,
                        key: 91,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6dc8ae4b-4329-46ce-ad5a-9c3003221fc9',
                        startIRN: 1296,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0369a959-d29a-48fd-8cf0-22888f2d9e28',
                        startIRN: 1392,
                        key: 91,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '582d1603-a7da-475c-b830-f9e1850cfb15',
                        startIRN: 1440,
                        key: 88,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '78a2865f-ff77-4b6e-9e40-023a67fa6780',
                        startIRN: 1488,
                        key: 86,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.GREEN,
                type: PatternType.PianoRollPattern,
            },
            patternId3: {
                id: 'patternId3',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '588035b4-bca0-403e-b9e7-ce2fe831a042',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '8345c6ac-af2e-48f3-8bbc-50a5b8f5217c',
                        key: 42,
                        startIRN: 96,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'ff05f187-e836-4cfb-b49e-75f9501fa99a',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '551eccd9-c83f-4e0e-9dd7-5ae7f8ab0d8d',
                        key: 42,
                        startIRN: 288,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'dca68a29-0473-47d5-8905-07c553b2c5ea',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '7c971281-ed68-4a2c-9155-4a9a2bea3d3e',
                        key: 42,
                        startIRN: 480,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'cefac4cf-19e7-444e-b394-f2ae92865216',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'c35dca92-6a48-4072-8f7c-6a16f2dc0254',
                        key: 42,
                        startIRN: 672,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '67b0bef0-0768-4fb9-b06f-4c307f7f1c45',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '321f22c2-6b1a-4d5f-86d9-5d2f9bebcc68',
                        key: 42,
                        startIRN: 864,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '610478e6-98a0-4847-8db2-a1d587df39bc',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '2487f88d-a06c-44f2-bce9-0e7a506b1ebe',
                        key: 42,
                        startIRN: 1056,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '91ff4034-3cb6-4af6-85f3-7e3a422801ed',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '3c32751d-6201-43e9-a6b6-31c81ed40154',
                        key: 42,
                        startIRN: 1248,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '043e97ac-e821-4d41-a39a-ae9da0bd12a5',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '574e3e1b-c341-4ac8-9779-b17679aab0f6',
                        key: 42,
                        startIRN: 1440,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '9ba561e2-1889-496f-903e-9b4eaf1910cc',
                        key: 36,
                        startIRN: 96,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '397137c5-568b-497b-834e-f49ae71cab0b',
                        key: 36,
                        startIRN: 864,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.YELLOW,
                type: PatternType.SequencerPattern,
            },
        },
    },
    tracks: {
        ids: [
            '8742031d-6a4e-47df-bd41-268879ae80ca',
            'd1b45bc8-ecaa-41c9-8e0a-67ae56541669',
            'c06f5c2e-8a9e-44cb-b967-2379877fcd89',
        ],
        entities: {
            '8742031d-6a4e-47df-bd41-268879ae80ca': {
                id: '8742031d-6a4e-47df-bd41-268879ae80ca',
                channel: 0,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    '4ff89dcd-2c06-415d-b43d-1097f65e4c7b': {
                        id: '4ff89dcd-2c06-415d-b43d-1097f65e4c7b',
                        patternId: 'patternId1',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            'd1b45bc8-ecaa-41c9-8e0a-67ae56541669': {
                id: 'd1b45bc8-ecaa-41c9-8e0a-67ae56541669',
                channel: 1,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    'bcd308f8-49c9-4c7c-b736-cfe35c608462': {
                        id: 'bcd308f8-49c9-4c7c-b736-cfe35c608462',
                        patternId: 'patternId2',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            'c06f5c2e-8a9e-44cb-b967-2379877fcd89': {
                id: 'c06f5c2e-8a9e-44cb-b967-2379877fcd89',
                channel: 9,
                instrument: Instruments.STEEL_DRUMS,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    'fb8ff4e4-7918-4f7d-b59c-a5a09fe34caa': {
                        id: 'fb8ff4e4-7918-4f7d-b59c-a5a09fe34caa',
                        patternId: 'patternId3',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
        },
    },
}
