import { Component, Input } from '@angular/core'
import { SpinnerService } from './spinner.service'

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    animations: [],
})
export class SpinnerComponent {
    @Input() icon: string
    constructor(public spinnerService: SpinnerService) {}
}
