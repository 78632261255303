export enum NotesEnum {
    G9 = 127,
    Fis9 = 126,
    F9 = 125,
    E9 = 124,
    Dis9 = 123,
    D9 = 122,
    Cis9 = 121,
    C9 = 120,
    B8 = 119,
    Ais8 = 118,
    A8 = 117,
    Gis8 = 116,
    G8 = 115,
    Fis8 = 114,
    F8 = 113,
    E8 = 112,
    Dis8 = 111,
    D8 = 110,
    Cis8 = 109,
    C8 = 108,
    B7 = 107,
    Ais7 = 106,
    A7 = 105,
    Gis7 = 104,
    G7 = 103,
    Fis7 = 102,
    F7 = 101,
    E7 = 100,
    Dis7 = 99,
    D7 = 98,
    Cis7 = 97,
    C7 = 96,
    B6 = 95,
    Ais6 = 94,
    A6 = 93,
    Gis6 = 92,
    G6 = 91,
    Fis6 = 90,
    F6 = 89,
    E6 = 88,
    Dis6 = 87,
    D6 = 86,
    Cis6 = 85,
    C6 = 84,
    B5 = 83,
    Ais5 = 82,
    A5 = 81,
    Gis5 = 80,
    G5 = 79,
    Fis5 = 78,
    F5 = 77,
    E5 = 76,
    Dis5 = 75,
    D5 = 74,
    Cis5 = 73,
    C5 = 72,
    B4 = 71,
    Ais4 = 70,
    A4 = 69,
    Gis4 = 68,
    G4 = 67,
    Fis4 = 66,
    F4 = 65,
    E4 = 64,
    Dis4 = 63,
    D4 = 62,
    Cis4 = 61,
    C4 = 60,
    B3 = 59,
    Ais3 = 58,
    A3 = 57,
    Gis3 = 56,
    G3 = 55,
    Fis3 = 54,
    F3 = 53,
    E3 = 52,
    Dis3 = 51,
    D3 = 50,
    Cis3 = 49,
    C3 = 48,
    B2 = 47,
    Ais2 = 46,
    A2 = 45,
    Gis2 = 44,
    G2 = 43,
    Fis2 = 42,
    F2 = 41,
    E2 = 40,
    Dis2 = 39,
    D2 = 38,
    Cis2 = 37,
    C2 = 36,
    B1 = 35,
    Ais1 = 34,
    A1 = 33,
    Gis1 = 32,
    G1 = 31,
    Fis1 = 30,
    F1 = 29,
    E1 = 28,
    Dis1 = 27,
    D1 = 26,
    Cis1 = 25,
    C1 = 24,
    B0 = 23,
    Ais0 = 22,
    A0 = 21,
    Gis0 = 20,
    G0 = 19,
    Fis0 = 18,
    F0 = 17,
    E0 = 16,
    Dis0 = 15,
    D0 = 14,
    Cis0 = 13,
    C0 = 12,
}
