import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProjectTimelineComponent } from './timeline/project-timeline.component'
import { TimestampComponent } from './timestamp/timestamp.component'
import { ProjectSelectLoopComponent } from './project-select-loop/project-select-loop.component'

@NgModule({
    declarations: [ProjectTimelineComponent, TimestampComponent, ProjectSelectLoopComponent],
    imports: [CommonModule],
    exports: [ProjectTimelineComponent, TimestampComponent, ProjectSelectLoopComponent],
})
export class ProjectTimelineModule {}
