import { MidiEvent } from './MidiEvent'
import { EventType } from '../enums/EventType'
import { Hexecutioner } from '../../utils/Hexecutioner'
import { MetaEventTypes } from '../enums/MetaEventTypes'

export class MetaEvent extends MidiEvent {
    metaType: MetaEventTypes
    data: string

    constructor(deltaTime: number) {
        super(deltaTime, EventType.MetaEvent)
        this.data = ''
    }

    toHex(): string {
        return (
            Hexecutioner.numberToVariableMidiHex(this.deltaTime, 2) +
            Hexecutioner.numberToHex(EventType.MetaEvent, 2) +
            Hexecutioner.numberToHex(this.metaType, 2) +
            Hexecutioner.numberToHex(this.data.length / 2, 2) +
            this.data
        )
    }

    toString(): string {
        return `[MetaEvent] type: ${this.metaType} data: ${this.data}`
    }
}
