import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { environment } from './environments/environment'
import { AppModule } from './app/app.module'

import 'hammerjs' // HAMMER TIME

import { featureFlagsController, Logger } from '@tekbox-coco/midiative-commons'
// update logger config
// this needs to be here!
if (environment.logger && !(window as any).loggerConfig) {
    ;(window as any).loggerConfig = JSON.parse(JSON.stringify(environment.logger))
}

const logger = Logger.createLogger('main.ts')

if (environment.production) {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => logger.error(err))

// set feature flags
featureFlagsController.setFlags(environment.features)
