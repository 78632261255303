import { Injectable } from '@angular/core'
import { ProjectToolEnum, PrToolEnum, ToolSelectionMenuButton } from '@tekbox-coco/midiative-commons'
import { BehaviorSubject } from 'rxjs'

export interface ToolSelectionRequest {
    tools: ProjectToolEnum[] | PrToolEnum[]
    selectedTool: ProjectToolEnum | PrToolEnum
}

@Injectable({
    providedIn: 'root',
})
export class ToolSelectionMenuService {
    open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    // input: called from button component to select tool
    selectTool: BehaviorSubject<ToolSelectionMenuButton> = new BehaviorSubject<ToolSelectionMenuButton>(null)

    // output: currently selected tool, subscribed by button component
    selectedTool: BehaviorSubject<ToolSelectionMenuButton> = new BehaviorSubject<ToolSelectionMenuButton>(null)

    buttons: BehaviorSubject<ToolSelectionMenuButton[]> = new BehaviorSubject<ToolSelectionMenuButton[]>([])

    requestResolve: (value: ToolSelectionMenuButton | PromiseLike<ToolSelectionMenuButton>) => void
    requestReject: (reason?: any) => void

    constructor() {
        this.selectTool.subscribe((e) => {
            this.selectedTool.next(e)
            if (this.requestResolve) {
                this.requestResolve(e)
                this.requestResolve = null
                this.requestReject = null
                this.open.next(false)
            }
        })
    }

    requestToolSelectionMenu(
        items: ToolSelectionMenuButton[],
        preSelected: ToolSelectionMenuButton
    ): Promise<ToolSelectionMenuButton> {
        return new Promise<ToolSelectionMenuButton>((resolve, reject) => {
            this.selectedTool.next(preSelected)
            this.buttons.next(items)
            this.open.next(true)
            this.requestResolve = resolve
            this.requestReject = reject
        })
    }
}
