import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'GoToTrackEndAction',
})
export class GoToTrackEndAction extends DefaultAction {
    constructor(public audioService: AudioService) {
        super()
        this.icon.next('skip_next')
    }

    onClick() {
        this.audioService.setCursorPosition(this.audioService.getSongEndAsIRN())
    }
}
