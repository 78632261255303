import { Injectable } from '@angular/core'
import {
    ActionTask,
    DefaultAction,
    getRandomId,
    Instruments,
    MAX_NO_TRACKS,
    Track,
    AppState,
    Logger,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { selectHasDrumTrack, selectNextInstrumentChannel } from '../../store/reducers/project-state.reducer'
import { ModalService } from '@tekbox-coco/midiative-components'
import { AddTrackAction } from '../../store/actions/project-state.actions'

import { ActionController } from '../actions.decorator'
import { ToastService } from '../../services/toast.service'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'AddTrackToProjectAction',
})
export class AddTrackToProjectAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('AddTrackToProjectAction')
    private projectContainsDrumTrack: boolean = false
    private nextInstrumentChannel: number = 0
    private trackEntities: Track[] = []

    constructor(
        private store: Store<AppState>,
        private modalService: ModalService,
        private toastService: ToastService
    ) {
        super()

        this.icon.next('playlist_add')
        this.domID.next('track_add')

        this.store.select('projectState').subscribe({
            next: (value) => {
                this.trackEntities = Object.values(value.tracks.entities)
            },
            error: (e) => {
                console.error(e)
            },
        })

        this.store
            .select(selectHasDrumTrack)
            .subscribe((hasDrumTrack: boolean) => (this.projectContainsDrumTrack = hasDrumTrack))

        this.store.select(selectNextInstrumentChannel).subscribe((channel: number | undefined) => {
            this.nextInstrumentChannel = channel
        })
    }

    async onClick() {
        const noTracks = Object.values(this.trackEntities).length

        if (noTracks === MAX_NO_TRACKS) {
            this.toastService.warn('PROJECT.MAX_TRACKS_REACHED')
            this.logger.info('MAX number of tracks reached')
            return
        }

        if (!this.projectContainsDrumTrack && this.nextInstrumentChannel) {
            const result = await this.modalService.showOptionsDialog({
                headline: '',
                text: '',
                allowClose: true,
                options: [
                    {
                        label: 'PROJECT.MODAL.ADD_DRUM_TRACK',
                        value: 'drum_sequencer',
                    },
                    {
                        label: 'PROJECT.MODAL.ADD_INSTRUMENT_TRACK',
                        value: 'piano_roll',
                    },
                ],
            })

            if (result === 'piano_roll') {
                this.addPianoRollTrack()
            } else if (result === 'drum_sequencer') {
                this.addSequencerTrack()
            }
        } else if (!this.projectContainsDrumTrack && !this.nextInstrumentChannel) {
            this.addSequencerTrack()
        } else {
            this.addPianoRollTrack()
        }
    }

    private addSequencerTrack(): void {
        this.store.dispatch(
            new AddTrackAction({
                id: getRandomId(),
                channel: 9,
                instrument: Instruments.STEEL_DRUMS,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {},
            })
        )
    }

    private addPianoRollTrack(): void {
        this.store.dispatch(
            new AddTrackAction({
                id: getRandomId(),
                channel: this.nextInstrumentChannel,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {},
            })
        )
    }
}
