import { Injectable, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    Logger,
    MAX_GRID_RESOLUTION,
    PianoRollStateModel,
    ProjectStateModel,
    UiStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Subscription } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class CalculationService implements OnDestroy {
    private readonly logger = Logger.createLogger('CalculationService')
    private pianoRollStateSubscription: Subscription
    private projectStateSubscription: Subscription
    private uiStateSubscription: Subscription

    private pianoRollState: PianoRollStateModel
    private projectState: ProjectStateModel
    private uiState: UiStateModel

    constructor(private store: Store<AppState>) {
        this.pianoRollStateSubscription = this.store.select('pianoRollState').subscribe({
            next: (value) => {
                this.pianoRollState = value
            },
            error: (e) => this.logger.error(e),
        })
        this.projectStateSubscription = this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectState = value
            },
            error: (e) => this.logger.error(e),
        })
        this.uiStateSubscription = this.store.select('uiState').subscribe({
            next: (value) => {
                this.uiState = value
            },
            error: (e) => this.logger.error(e),
        })
    }

    // TODO: check this for 3/4 and 5/4 time signatures
    getMillisecondsPerQuarterBar() {
        return (1000 / ((this.projectState.bpm / 60) * MAX_GRID_RESOLUTION)) * 4
    }

    getMicrosecondsPerBar() {
        // 60000000 = Microseconds in one Minute
        // 120 bpm => 2 bps => 0.5 sec per beat = 500 ms = 500.000 microseconds
        //  (((i / 60 ) / 4) * 1000) * 1000

        return Math.round(60000000 / this.projectState.bpm)
    }

    getSecondsPerQuarterBar() {
        return this.getMillisecondsPerQuarterBar() / 1000
    }

    ngOnDestroy(): void {
        this.pianoRollStateSubscription.unsubscribe()
        this.projectStateSubscription.unsubscribe()
    }
}
