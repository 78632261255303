import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { Logger } from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-project-timeline-timestamp',
    templateUrl: './timestamp.component.html',
    styleUrls: ['./timestamp.component.scss'],
})
export class TimestampComponent implements OnInit {
    private readonly logger = Logger.createLogger('TimestampComponent')

    @ViewChild('timestampElement') timestampElement: ElementRef

    public computedStyle: { left: string }

    @Input()
    timestamp: number

    @Input()
    calculatedBarLength: number

    ngOnInit() {
        this.recomputeStyle()
    }

    /**
     * Watch changes for certain properties
     */
    ngOnChanges(changes: SimpleChanges) {
        // recompute style when zoom factor changes
        if (changes.calculatedBarLength) {
            this.recomputeStyle()
        }
    }

    private recomputeStyle() {
        this.computedStyle = {
            left: `${this.timestamp * this.calculatedBarLength}px`,
        }
    }
}
