import {
    AddPatternAction,
    DeletePatternAction,
    DeletePatternMappingAction,
    ProjectStateActions,
    ProjectStateActionTypes,
    UpdateTrackAction,
} from '../actions/project-state.actions'
import {
    AppState,
    BARS_AFTER_LAST_PATTERN,
    Color,
    getRandomId,
    GlobalPatternMapping,
    initialProjectState,
    Logger,
    MAX_GRID_RESOLUTION,
    MigrationHandler,
    Note,
    Pattern,
    patternAdapter,
    PatternType,
    ProjectStateModel,
    QUARTER_BEAT_RESOLUTION,
    Track,
    trackAdapter,
    TrackNote,
    TrackPatternMapping,
    trackPatternMappingArrayToRecord,
    quantizeIRNToBar,
} from '@tekbox-coco/midiative-commons'
import { createSelector } from '@ngrx/store'
import { Update } from '@ngrx/entity'

const logger = Logger.createLogger('projectStateReducer')

export function projectStateReducer(
    state: ProjectStateModel = initialProjectState,
    action: ProjectStateActions
): ProjectStateModel {
    switch (action.type) {
        case ProjectStateActionTypes.LoadProject: {
            let project = action.payload

            // add version number 0 to projects without schemaVersion
            if (!project.schemaVersion) {
                project = {
                    ...project,
                    schemaVersion: 0,
                }
            }

            // run migrations
            project = MigrationHandler.migrate(project)

            logger.debug('LoadProject', 'after migration', project)

            return { ...project }
        }
        case ProjectStateActionTypes.ClearProject: {
            return { ...initialProjectState }
        }
        case ProjectStateActionTypes.GenerateNewProjectId: {
            return { ...state, id: getRandomId() }
        }
        case ProjectStateActionTypes.DeactivateDemoMode: {
            return { ...state, demo: false }
        }
        case ProjectStateActionTypes.SetName: {
            return { ...state, name: action.payload }
        }
        case ProjectStateActionTypes.SetBPM: {
            return { ...state, bpm: action.payload }
        }
        case ProjectStateActionTypes.SetTimeSignatureNumerator:
            return { ...state, timeSignatureNumerator: action.payload }
        case ProjectStateActionTypes.SetTimeSignatureDenominator:
            return { ...state, timeSignatureDenominator: action.payload }
        case ProjectStateActionTypes.SetKey: {
            return { ...state, key: action.payload }
        }
        case ProjectStateActionTypes.SetScale: {
            return { ...state, scale: action.payload }
        }
        // Patterns
        case ProjectStateActionTypes.SetPatternColor: {
            const pattern = JSON.parse(JSON.stringify(state.patterns.entities[action.patternId]))
            pattern.color = action.color
            return {
                ...state,
                patterns: patternAdapter.upsertOne(pattern, state.patterns),
            }
        }
        case ProjectStateActionTypes.SetPatterns: {
            return {
                ...state,
                patterns: patternAdapter.setAll(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.ClearPatterns:
            return {
                ...state,
                patterns: patternAdapter.removeAll(state.patterns),
            }
        case ProjectStateActionTypes.AddPattern: {
            return {
                ...state,
                patterns: patternAdapter.addOne(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.AddPatterns: {
            return {
                ...state,
                patterns: patternAdapter.addMany(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.UpsertPattern: {
            return {
                ...state,
                patterns: patternAdapter.upsertOne(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.UpsertPatterns: {
            return {
                ...state,
                patterns: patternAdapter.upsertMany(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.UpdatePattern: {
            return {
                ...state,
                patterns: patternAdapter.updateOne(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.UpdatePatterns: {
            return {
                ...state,
                patterns: patternAdapter.updateMany(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.DeletePatternMapping: {
            const track = state.tracks.entities[action.trackId]
            return {
                ...state,
                tracks: trackAdapter.updateOne(
                    {
                        id: action.trackId,
                        changes: {
                            patterns: {
                                ...track.patterns,
                                [action.mappingId]: undefined,
                            },
                        },
                    },
                    state.tracks
                ),
            }
        }
        case ProjectStateActionTypes.DeletePattern: {
            // remove pattern from project
            const projectStateWithoutPattern = {
                ...state,
                patterns: patternAdapter.removeOne(action.payload, state.patterns),
            }

            // remove pattern from all track mappings
            const tracksWithoutPatternMapping = Object.values(projectStateWithoutPattern.tracks.entities).map(
                (t: Track) => {
                    try {
                        const patternMappingsWithoutPattern = Object.values(t.patterns)
                            .filter((e) => e != undefined)
                            .filter((p) => {
                                return p.patternId !== action.payload
                            })
                        return {
                            ...t,
                            patterns: trackPatternMappingArrayToRecord(patternMappingsWithoutPattern),
                        }
                    } catch (e) {
                        logger.error('Error filtering pattern without mapping.', e)
                        return {
                            ...t,
                        }
                    }
                }
            )

            return {
                ...projectStateWithoutPattern,
                // old track state is not of interest. it is faster to use initial state.
                tracks: trackAdapter.addMany(tracksWithoutPatternMapping, trackAdapter.getInitialState()),
            }
        }
        case ProjectStateActionTypes.CutPattern: {
            const originalPattern: Pattern = state.patterns.entities[action.payload.trackPatternMapping.patternId]
            const timeSignature = state.timeSignatureNumerator / state.timeSignatureDenominator
            const quantizedCutIRN = quantizeIRNToBar(action.payload.relativeCutIRN, timeSignature)
            const leftSideLengthIRN = quantizedCutIRN
            const rightSideLengthIRN = originalPattern.lengthIRN - quantizedCutIRN
            const barLength = timeSignature * MAX_GRID_RESOLUTION
            const isCutable =
                (rightSideLengthIRN > 0 && leftSideLengthIRN >= barLength) ||
                (leftSideLengthIRN > 0 && rightSideLengthIRN >= barLength)

            if (isCutable) {
                let rightPattern: Pattern = {
                    id: getRandomId(),
                    type: PatternType.PianoRollPattern,
                    lengthIRN: rightSideLengthIRN,
                    notes: [],
                    lastResolution: originalPattern.lastResolution,
                    color: action.payload.newPatternColor,
                }
                const rightPatternMapping: TrackPatternMapping = {
                    id: getRandomId(),
                    patternId: rightPattern.id,
                    length: rightPattern.lengthIRN,
                    startAt: 0,
                    position: action.payload.trackPatternMapping.position + leftSideLengthIRN,
                }
                let leftPattern: Pattern = {
                    id: getRandomId(),
                    type: PatternType.PianoRollPattern,
                    lengthIRN: leftSideLengthIRN,
                    notes: [],
                    lastResolution: originalPattern.lastResolution,
                    color: originalPattern.color,
                }
                const leftPatternMapping: TrackPatternMapping = {
                    id: getRandomId(),
                    patternId: leftPattern.id,
                    length: leftPattern.lengthIRN,
                    startAt: 0,
                    position: action.payload.trackPatternMapping.position,
                }

                originalPattern.notes.forEach((note) => {
                    // notes for right pattern
                    if (leftSideLengthIRN <= note.startIRN) {
                        rightPattern.notes.push({
                            ...note,
                            startIRN: note.startIRN - leftSideLengthIRN,
                            id: getRandomId(),
                        })
                    }
                    // notes for left pattern
                    if (leftSideLengthIRN > note.startIRN) {
                        leftPattern.notes.push(note)
                        // slice note and split into two patterns/notes
                        if (note.startIRN + note.lengthIRN > leftSideLengthIRN) {
                            rightPattern.notes.push({
                                ...note,
                                startIRN: note.startIRN - leftSideLengthIRN,
                                id: getRandomId(),
                            })
                        }
                    }
                })
                // add notes to rightPattern
                rightPattern = {
                    ...rightPattern,
                    notes: rightPattern.notes,
                }
                // this.store.dispatch(new AddPatternAction(rightPattern))
                let newPatternState = patternAdapter.addOne(rightPattern, state.patterns)
                // add notes to leftPattern
                leftPattern = {
                    ...leftPattern,
                    notes: leftPattern.notes,
                }
                // this.store.dispatch(new AddPatternAction(leftPattern))
                newPatternState = patternAdapter.addOne(leftPattern, newPatternState)

                // prepare new Patterns
                const newPatterns: Record<string, TrackPatternMapping> = {
                    [rightPatternMapping.id]: rightPatternMapping,
                    [leftPatternMapping.id]: leftPatternMapping,
                    ...action.payload.track.patterns,
                }

                let newTrackState = trackAdapter.updateOne(
                    {
                        id: action.payload.track.id,
                        changes: {
                            patterns: newPatterns,
                        },
                    },
                    state.tracks
                )

                // delete old pattern AFTER updating otherwise it will be displayed again.
                // this.store.dispatch(new DeletePatternAction(action.payload.trackPatternMapping.patternId))
                newPatternState = patternAdapter.removeOne(
                    action.payload.trackPatternMapping.patternId,
                    newPatternState
                )

                // delete original pattern mapping
                newTrackState = trackAdapter.updateOne(
                    {
                        id: action.payload.track.id,
                        changes: {
                            patterns: {
                                ...newTrackState.entities[action.payload.track.id].patterns,
                                [action.payload.trackPatternMapping.id]: undefined,
                            },
                        },
                    },
                    newTrackState
                )
                return {
                    ...state,
                    tracks: newTrackState,
                    patterns: newPatternState,
                }
            } else {
                console.error('cannot cut pattern, returning original state')
                return {
                    ...state,
                }
            }
        }
        case ProjectStateActionTypes.DeletePatterns: {
            return {
                ...state,
                patterns: patternAdapter.removeMany(action.payload, state.patterns),
            }
        }
        case ProjectStateActionTypes.AddTrack: {
            return {
                ...state,
                tracks: trackAdapter.addOne(action.payload, state.tracks),
            }
        }
        case ProjectStateActionTypes.DeleteTrack: {
            // find patternid in tracks and remove it from patterns
            const tracks: Track[] = Object.values(state.tracks.entities)
            let filteredTracks = tracks.filter((t) => t.id === action.payload)
            if (filteredTracks.length > 0) {
                const patternsToDeleteObjects: Record<string, TrackPatternMapping> = filteredTracks[0].patterns
                const patternsToDelete: string[] = Object.values(patternsToDeleteObjects).map(
                    (p: TrackPatternMapping) => p.patternId
                )
                // remove track from project
                return {
                    ...state,
                    patterns: patternAdapter.removeMany(patternsToDelete, state.patterns),
                    tracks: trackAdapter.removeOne(action.payload, state.tracks),
                }
            } else {
                logger.error('Track not found!')
                return state
            }
        }
        case ProjectStateActionTypes.UpdateTrack: {
            return {
                ...state,
                tracks: trackAdapter.updateOne(action.payload, state.tracks),
            }
        }
        case ProjectStateActionTypes.UpdateAllMappingsForPattern: {
            try {
                const tracks = state.tracks.entities
                const trackUpdates: Update<Track>[] = []

                for (const track of Object.values(tracks)) {
                    const updateMappings = Object.values(track.patterns)
                        .filter((e) => e.patternId === action.patternId)
                        .map((e) => {
                            return {
                                [e.id]: {
                                    ...e,
                                    ...action.options,
                                },
                            }
                        })
                    if (updateMappings.length > 0) {
                        let items = {
                            id: track.id,
                            changes: {
                                patterns: {
                                    ...track.patterns,
                                    ...updateMappings.reduce((a, b) => {
                                        return { ...a, ...b }
                                    }, {}),
                                },
                            },
                        }
                        trackUpdates.push(items)
                    }
                }
                if (trackUpdates.length > 0) {
                    return {
                        ...state,
                        tracks: trackAdapter.updateMany(trackUpdates, state.tracks),
                    }
                }
                return state
            } catch (e) {
                logger.error('Something went wrong: ', e)
                return {
                    ...state,
                }
            }
        }
        case ProjectStateActionTypes.UpsertTrack: {
            return {
                ...state,
                tracks: trackAdapter.upsertOne(action.payload, state.tracks),
            }
        }
        case ProjectStateActionTypes.SetLoopSelectorStartIRN: {
            if (state.loopSelector.endIRN > action.payload) {
                return {
                    ...state,
                    loopSelector: {
                        ...state.loopSelector,
                        startIRN: action.payload,
                        excluded: false,
                        visible: true,
                    },
                }
            } else {
                return {
                    ...state,
                    loopSelector: {
                        ...state.loopSelector,
                        startIRN: action.payload,
                        excluded: true,
                        visible: true,
                    },
                }
            }
        }
        case ProjectStateActionTypes.SetLoopSelectorEndIRN: {
            if (state.loopSelector.startIRN > action.payload) {
                return {
                    ...state,
                    loopSelector: {
                        ...state.loopSelector,
                        endIRN: action.payload,
                        excluded: true,
                    },
                }
            } else {
                return {
                    ...state,
                    loopSelector: {
                        ...state.loopSelector,
                        endIRN: action.payload,
                        excluded: false,
                    },
                }
            }
        }
        case ProjectStateActionTypes.HideLoopSelector: {
            return {
                ...state,
                loopSelector: {
                    ...state.loopSelector,
                    visible: false,
                    endIRN: 0,
                    active: false,
                },
            }
        }
        case ProjectStateActionTypes.SetLoopSelectorActive: {
            return {
                ...state,
                loopSelector: { ...state.loopSelector, active: action.payload },
            }
        }
        case ProjectStateActionTypes.ResetLoopSelector: {
            return {
                ...state,
                loopSelector: initialProjectState.loopSelector,
            }
        }
        default: {
            return state
        }
    }
}

export const selectTracks = (state: AppState) => Object.values(state.projectState.tracks.entities)
export const selectPatterns = (state: AppState) => state.projectState.patterns.entities
export const selectProject = (state: AppState) => state.projectState

export const selectHasDrumTrack = createSelector(selectTracks, (tracks: Track[]): boolean => {
    return tracks.filter((t: Track) => t.channel === 9).length === 1
})

export const selectNextInstrumentChannel = createSelector(selectTracks, (tracks: Track[]): number | undefined => {
    const channels = tracks.map((t: Track) => t.channel)
    let nextChannel = 0
    for (let i = 0; i <= 16; i++) {
        if (channels.indexOf(i) === -1 && i !== 9) {
            nextChannel = i
            break
        }
    }

    return nextChannel < 16 ? nextChannel : undefined
})

// TODO: Make this failsafe, unhandled errors will break the selector state subscriptions
export const selectProjectNotes = createSelector(
    selectTracks,
    selectPatterns,
    (tracks: Track[], patterns: Record<string, Pattern>): Record<number, TrackNote[]> => {
        const soloTracks = tracks.filter((t: Track) => t.solo).map((t) => t.id)
        return (
            tracks
                // resolve pattern notes
                .map((track: Track) => {
                    return {
                        ...track,
                        patterns: Object.values(track.patterns).map((patternMapping) => {
                            if (patternMapping && patterns[patternMapping.patternId]) {
                                return {
                                    ...patternMapping,
                                    // add notes to mapping object
                                    notes: patterns[patternMapping.patternId].notes
                                        // map notes to correct startIRN
                                        .map((note) => {
                                            return {
                                                ...note,
                                                startIRN: note.startIRN + patternMapping.position,
                                                channel: track.channel,
                                                velocity: track.volume !== undefined ? track.volume : 255,
                                            }
                                        }) as TrackNote[],
                                }
                            } else {
                                return {
                                    ...patternMapping,
                                    // add notes to mapping object
                                    notes: [] as TrackNote[],
                                }
                            }
                        }),
                    }
                })
                // remove muted channels
                .filter((track) => track.solo || !track.mute)
                // check for solo channel
                .filter((track) => {
                    // no solo exists => true for all tracks
                    if (soloTracks.length === 0) {
                        return true
                    }
                    // return only solo channel
                    return soloTracks.indexOf(track.id) !== -1
                })
                // map tracks to notes
                .map((track) => {
                    return track.patterns.reduce((a, b) => {
                        return a.concat(b.notes)
                    }, [])
                })
                // reduce tracks to big notes array
                .reduce((a, b) => a.concat(b), [])
                // sort array
                .sort((a: Note, b: Note) => a.startIRN - b.startIRN)
                // reduce notes by start
                .reduce((a, b) => {
                    if (!a[b.startIRN]) {
                        a[b.startIRN] = []
                    }
                    a[b.startIRN].push(b)
                    return a
                }, {})
        )
    }
)

export const selectSongEnd = createSelector(selectTracks, (tracks: Track[]): number => {
    const trackEnds: number[] = tracks.map((track: Track): number => {
        // filter undefined references
        const trackPatternMappings: TrackPatternMapping[] = Object.values(track.patterns).filter(
            (o: TrackPatternMapping) => !!o
        )
        // find element with the highest position number
        const maxPos = Math.max.apply(
            Math,
            trackPatternMappings.map((o: TrackPatternMapping) => o.position)
        )
        const lastTrackPattern: TrackPatternMapping = trackPatternMappings.filter(
            (p: TrackPatternMapping) => p.position === maxPos
        )[0]
        if (lastTrackPattern !== undefined) {
            return lastTrackPattern.position + lastTrackPattern.length
        } else {
            return 0
        }
    })

    if (trackEnds.length === 0) {
        return 0
    } else {
        return Math.max(...trackEnds)
    }
})

export const selectNoProjectBars = createSelector(
    selectProject,
    selectSongEnd,
    (project: ProjectStateModel, songEnd: number): number => {
        const extraBars = BARS_AFTER_LAST_PATTERN // some extra bars to get more space to the end of the project
        const barsNeededForTracks = Math.ceil(songEnd / MAX_GRID_RESOLUTION)
        return barsNeededForTracks + extraBars
    }
)

export const listPatternMappings = createSelector(
    selectTracks,
    selectPatterns,

    (tracks: Track[], patterns: Record<string, Pattern>): Record<string, GlobalPatternMapping> => {
        if (tracks.length > 0) {
            const mappings = tracks
                .map((track) => {
                    const mappings = Object.values(track.patterns)
                    return mappings
                        .map((mapping) => {
                            if (mapping) {
                                const result: GlobalPatternMapping = {
                                    patternId: mapping.patternId,
                                    mappings: [
                                        {
                                            mappingId: mapping.id,
                                            trackId: track.id,
                                            start: mapping.position,
                                            length: mapping.length,
                                        },
                                    ],
                                }
                                return result
                            }
                            return undefined
                        })
                        .filter((e) => e !== undefined)
                    // debugger
                })
                .reduce((a, b) => {
                    return a.concat(b)
                })
                .reduce((a: any, b: GlobalPatternMapping) => {
                    if (!a[b.patternId]) {
                        a[b.patternId] = b
                    } else {
                        a[b.patternId].mappings = [...a[b.patternId].mappings, ...b.mappings]
                    }
                    return a
                }, {})
            return mappings
        }
        return {}
    }
)
