import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { DEFAULT_MAX_HEADER_WIDTH, Instruments, Track } from '@tekbox-coco/midiative-commons'
import { SelectDialogService } from '../select-dialog/select-dialog.service'
import { AppState } from '@capacitor/app'
import { Store } from '@ngrx/store'
import { Action } from 'rxjs/internal/scheduler/Action'

/**
 * Payload structure of TrackHeader related events
 */
export interface HeaderAction<T> {
    id: string
    payload: T
}

/**
 * TrackHeaderComponent is located at the beginning of an track.
 * It's usual used in conjunction with TrackComponent
 */
@Component({
    selector: 'app-track-header',
    templateUrl: './track-header.component.html',
})
export class TrackHeaderComponent {
    // if true header is rendered as overlay (position absolute)
    @Input() track: Track
    @Input() drumStyle: boolean
    @Input() muteActionName: string = 'MuteTrackAction'
    @Input() soloActionName: string = 'MuteTrackAction'
    @Output()
    changedInstrument: EventEmitter<HeaderAction<Instruments>> = new EventEmitter<HeaderAction<Instruments>>()
    @Output() changedVolume: EventEmitter<HeaderAction<number>> = new EventEmitter<HeaderAction<number>>()

    constructor(private selectDialogService: SelectDialogService) {}

    /**
     * Resolves all available instruments from Instruments enum
     */
    get instruments(): string[] {
        return Object.keys(Instruments).map((x) => x.toLowerCase())
    }

    /**
     * Emits HeaderAction event
     * @param eventEmitter Emitter to be used for firing
     * @param payload Payload to be emitted with event
     */
    emitEvent(eventEmitter: EventEmitter<HeaderAction<any>>, $event: Event) {
        eventEmitter.emit({
            id: this.track.id,
            payload: $event,
        })
    }

    computeInstrumentLabel(key: string) {
        if (key) {
            return key
                .split('_')
                .map((e) => {
                    return `${e.substring(0, 1).toUpperCase()}${e.substring(1)}`
                })
                .join(' ')
        }
    }

    async selectInstrument() {
        const selectedInstrument = await this.selectDialogService.showSelect({
            items: this.instruments.map((e) => ({ value: e, label: this.computeInstrumentLabel(e) })),
            selected: this.track.instrument,
        })
        console.log(selectedInstrument)
        this.changedInstrument.emit({ id: this.track.id, payload: selectedInstrument.value })
    }
}
