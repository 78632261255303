import { NgModule } from '@angular/core'
import { PianoNoteComponent } from './piano-note.component'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { NoteNamePipeModule } from '@tekbox-coco/midiative-commons'

@NgModule({
    imports: [CommonModule, MatIconModule, NoteNamePipeModule],
    declarations: [PianoNoteComponent],
    exports: [PianoNoteComponent],
})
export class PianoNoteModule {}
