import { DefaultAction, AppState } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { StorageService } from '../../services/storage/storage.service'
import { AdService } from '../../services/ad.service'
import { ToastService } from '../../services/toast.service'
import { Router } from '@angular/router'
import { NavigateToHomeAction } from '../navigate/NavigateToHomeAction'
import { Share } from '@capacitor/share'
import { MidiService } from '../../services/midi.service'
import { Logger } from '@tekbox-coco/midiative-commons'
import { FileExporterService } from '../../services/file-manager/file-exporter.service'

import { ActionController } from '../actions.decorator'
import { TranslateService } from '@ngx-translate/core'
import { ModalService } from '@tekbox-coco/midiative-components'
import { DownloadMIDIAction } from './DownloadMIDIAction'
import { DownloadProjectAction } from './DownloadProjectAction'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'DownloadAction',
})
export class DownloadAction extends DefaultAction {
    private readonly logger = Logger.createLogger('DownloadAction')

    constructor(
        protected modalService: ModalService,
        protected dlMidi: DownloadMIDIAction,
        private dlProject: DownloadProjectAction
    ) {
        super()
        this.icon.next('music_note')
        this.label.next('STORAGE.DOWNLOAD')
    }

    async onClick() {
        this.modalService.showDialog({
            type: 'default',
            headline: 'PROJECT.MODAL.DOWNLOAD_DESCRIPTION',
            text: '',
            allowClose: true,
            isValid: () => {
                return true
            },
            actions: [
                {
                    label: 'PROJECT.MENU.DOWNLOAD_MIDI',
                    callback: () => {
                        this.dlMidi.onClick()
                    },
                },
                {
                    label: 'PROJECT.MENU.DOWNLOAD_PROJECT',
                    callback: () => {
                        this.dlProject.onClick()
                    },
                },
            ],
            onClose: () => {},
        })
    }
}
