import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { BaseButtonComponent } from './base-button.component'

@Component({
    selector: 'app-button',
    templateUrl: './base-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseButtonComponent implements OnInit, OnChanges {
    // ##### INPUTS ######
    @Input()
    label: string

    @Input()
    icon: string

    @Input()
    visible: boolean = true

    ngOnInit(): void {
        this.update()
        super.ngOnInit()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.update()
        super.ngOnChanges(changes)
    }

    update() {
        this._icon = this.icon
        this._label = this.label
        this._isVisible = this.visible

        this.recomputeButtonStyle()
    }
}
