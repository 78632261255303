import { Injectable } from '@angular/core'
import { ActionController } from '../actions.decorator'
import { Router } from '@angular/router'
import { ActionTask, createRouterLink, Logger, ROUTE_LICENSES, DefaultAction } from '@tekbox-coco/midiative-commons'
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToLicensesAction',
})
export class NavigateToLicensesAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToLicensesAction')

    constructor(private router: Router) {
        super()
        this.label.next('COMMON.LICENSES')
        this.icon.next('warning')
        this.domID.next('licenses-action')
    }

    onClick(): void {
        this.navigate(ROUTE_LICENSES)
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
