import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'RedoAction',
})
export class RedoAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.icon.next('redo')
        this.domID.next('project_redo')
    }

    onClick() {
        this.store.dispatch(new StoreRedoAction())
    }
}
