import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { calcZoomFactor, Logger, LoopSelector } from '@tekbox-coco/midiative-commons'

export interface Loop {
    start: number
    end: number
}

@Component({
    selector: 'app-project-select-loop',
    templateUrl: './project-select-loop.component.html',
})
export class ProjectSelectLoopComponent implements OnInit {
    private readonly logger = Logger.createLogger('ProjectSelectLoopComponent')

    public startPos = 0
    public width = 0
    public background = ''
    public visible: boolean

    selectedLoopStartIRN = 0
    selectedLoopEndIRN = 0

    @Output()
    clickOverlay: EventEmitter<void> = new EventEmitter()
    @Output()
    loopSet: EventEmitter<Loop> = new EventEmitter<Loop>()

    @Input()
    loopSelectorConfig: LoopSelector = {
        active: false,
        endIRN: 0,
        excluded: false,
        startIRN: 0,
        visible: false,
    }

    @Input()
    calculatedBarLength: number

    getZoomFactor(): number {
        return calcZoomFactor(this.calculatedBarLength)
    }

    onClickOverlay(e: Event) {
        e.preventDefault()
        e.stopPropagation()

        this.clickOverlay.emit()
    }

    ngOnInit() {
        this.recalculateLoopSelector()
    }

    recalculateLoopSelector() {
        this.selectedLoopStartIRN = this.loopSelectorConfig.startIRN
        this.selectedLoopEndIRN = this.loopSelectorConfig.endIRN
        this.visible = this.loopSelectorConfig.visible
        if (this.visible) {
            if (this.selectedLoopStartIRN < this.selectedLoopEndIRN) {
                this.startPos = this.loopSelectorConfig.startIRN
                this.width = this.loopSelectorConfig.endIRN - this.loopSelectorConfig.startIRN
                // turtle-cyan-30
                this.background = '#084d45'
            } else {
                this.startPos = this.loopSelectorConfig.endIRN
                this.width = this.loopSelectorConfig.startIRN - this.loopSelectorConfig.endIRN
                this.background = 'red'
            }
            if (!this.loopSelectorConfig.excluded) {
                const loop: Loop = {
                    start: this.selectedLoopStartIRN,
                    end: this.selectedLoopEndIRN,
                }
                this.loopSet.emit(loop)
            }
        }
    }

    /**
     * Watch changes for certain properties
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.loopSelectorConfig) {
            this.recalculateLoopSelector()
        }
    }
}
