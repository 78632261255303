<div class="pointer-events-none fixed left-0 top-0 z-hud h-full w-full text-[#ff00f0]">
    <div
        *ngIf="config.sidePanel && config.sidePanel.visible"
        class="pointer-events-none absolute right-0 flex h-full justify-between"
    >
        <div class="flex flex-col justify-center text-black-90">
            <div class="pointer-events-auto m-2" id="hud-sidebar">
                <app-action-button
                    *ngFor="let item of renderedSidebarItems; let i = index"
                    [innerClasses]="[
                        'rounded-full',
                        'my-[10px]',
                        'h-[48px]',
                        'w-[48px]',
                        'flex',
                        'place-content-center',
                        'items-center',
                        'p-0',
                        'border-white'
                    ]"
                    [iconClasses]="['m-2']"
                    [mode]="item.options && item.options.mode ? item.options.mode : 'iconOnly'"
                    [actionName]="item"
                    [payload]="item.payload || {}"
                >
                </app-action-button>
            </div>
        </div>
    </div>

    <div class="top absolute flex w-full justify-between">
        <div class="grow">
            <div *ngIf="inputPanelConfig && inputPanelConfig.visible" class="grow">
                <div
                    class="absolute mt-[20px] flex h-[20px] w-[150px] select-none place-content-center"
                    [class.bg-cyan-50]="config.logoInvisible === undefined || config.logoInvisible === false"
                    [class.invisible]="config.logoInvisible === true"
                >
                    <img src="assets/icon/icon.svg" alt="MIDIative" />
                </div>
                <app-info-panel [config]="inputPanelConfig"></app-info-panel>
            </div>
        </div>
        <div class="pointer-events-auto min-w-[40px]">
            <app-button
                [icon]="drawerMenuOpen ? 'undo' : 'menu'"
                iconClasses="text-white"
                [innerClasses]="['justify-center', 'rounded-bl-[20px]', 'p-[7.5px]', 'border-r-0', 'border-t-0']"
                (onClick)="onClickDrawerMenuButton()"
            ></app-button>
        </div>
    </div>

    <div class="absolute bottom-0 flex w-full justify-between">
        <div *ngIf="config.toolbar && config.toolbar.visible" class="grow">
            <app-toolbar
                class="pointer-events-auto"
                (onClickItem)="onClickToolbarItem($event)"
                [toolbarItems]="renderedToolbarItems"
            ></app-toolbar>
        </div>
    </div>
</div>

<!--- ########################## --->
<!--- Tool Selection Menu --->
<!--- ########################## --->
<app-tool-selection-menu
    class="fixed z-toolSelection"
    [numberOfButtonsPerCircle]="[3, 5, 7]"
    [toolSelectionMenuService]="toolSelectionMenuService"
></app-tool-selection-menu>

<!--- ########################## --->
<!--- Additional Actions Overlay --->
<!--- ########################## --->
<div
    class="fixed top-0 z-menu h-full w-full bg-black-100"
    *ngIf="config.additionalActions && config.additionalActions.actions && additionalActionsOpen"
>
    <app-tile-menu
        (onClickItem)="onClickAdditionalActionItem($event)"
        [menuItems]="additionalActions || []"
    ></app-tile-menu>
</div>

<!--- ########################## --->
<!--- Menu Overlay --->
<!--- ########################## --->
<div
    class="absolute z-menuBackground h-screen w-screen bg-[rgba(0,0,0,0.5)] backdrop-blur-sm"
    (click)="onClickOutsideDrawerMenu($event)"
    *ngIf="config.menu && drawerMenuOpen"
></div>

<div *ngIf="config.menu && drawerMenuOpen" class="fixed fixed left-0 z-menu h-screen w-[60vw] border-r bg-black-95">
    <div class="scroll-container">
        <app-tile-menu
            buttonSize="16vw"
            itemHeight="150"
            (onClickItem)="onClickTileMenuItem($event)"
            [menuItems]="config.menu.actions"
        ></app-tile-menu>
    </div>
</div>
