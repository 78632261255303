<div *ngIf="open" class="bg-black-100/50 fixed inset-0 h-full w-full backdrop-blur-sm" (click)="onClickOutside()"></div>
<div class="fixed bottom-0 right-0">
    <div *ngFor="let button of buttons;let index=index;">
        <div
            matRipple
            class="absolute flex h-[5rem] w-[5rem] cursor-pointer select-none flex-col place-content-center items-center rounded-[4rem] bg-[gray] text-white"
            *ngIf="open"
            [id]="button.domID"
            [ngClass]="{'bg-[cyan]': selectedTool.tool==button.tool}"
            (document:keydown.{{button.hotkey}})="button.tool?changedTool.emit(button):button.callback()"
            (click)="onToolSelected(button)"
            [@cornerAnimation]="{value:'', params: {right: getButtonCoordinates(index, 7).right, bottom: getButtonCoordinates(index, 7).bottom}}"
        >
            <mat-icon>{{button.matIconCode}}</mat-icon>
            <label>{{button.label}}</label>
        </div>
    </div>
</div>
