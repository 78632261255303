import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RedoAction as StoreRedoAction } from '../../store/meta/history/history.actions'
import { StorageService } from '../../services/storage/storage.service'
import { AdService } from '../../services/ad.service'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'ProjectSaveChangesAction',
})
export class ProjectSaveChangesAction extends DefaultAction {
    constructor(private storageService: StorageService, private adService: AdService) {
        super()
        this.icon.next('save')
        this.label.next('PROJECT.MENU.SAVE')
    }

    async onClick() {
        await this.storageService.saveCurrentProject()

        // Show ads on save
        await this.adService.showInterstitial()
    }
}
