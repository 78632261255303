import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser'
import { RouteReuseStrategy, Router } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { MetaReducer, StoreModule } from '@ngrx/store'
import { environment } from '../environments/environment'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatIconModule } from '@angular/material/icon'
import { IonicStorageModule } from '@ionic/storage-angular'
import { reducers } from './store/AppState'
import { historyMetaReducer } from './store/meta/history/history.reducer'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { instrumentMetaReducer } from './store/meta/instrument.reducer'
import { MyHammerGestureConfig } from './gestures/MyHammerGestureConfig'
import {
    ColorPickerModule,
    HudModule,
    MenuModule,
    ModalModule,
    ProjectTimelineModule,
    SelectDialogModule,
    SpinnerModule,
    TapTempoModule,
    TimelineModule,
    TrackHeaderModule,
    TrackModule,
    TrackPatternModule,
    TutorialModule,
    HeaderModule,
} from '@tekbox-coco/midiative-components'
import { FeatureFlagModule, Logger, PlatformService } from '@tekbox-coco/midiative-commons'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { initCustomMidiLoader } from './customMidiLoader'
import { SoundfontManagerService } from './services/file-manager/soundfont-manager.service'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { EffectsModule } from '@ngrx/effects'
import { AutoSaveEffect } from './store/effects/auto-save.effect'
import { ActionsModule } from './actions/actions.module'
import { MixerModule } from './services/mixer/mixer.module'
import { ShortcutsOverlayModule } from './services/shortcut-overlay/shortcut-overlay.module'
import * as Sentry from '@sentry/capacitor'
// Use @sentry/angular-ivy for Angular 12+ or `@sentry/angular` from Angular 10 and 11
import * as SentryAngular from '@sentry/angular'
import { versions } from '../environments/versions'

export const metaReducers: MetaReducer<any>[] = [historyMetaReducer, instrumentMetaReducer]
const logger = Logger.createLogger('SentryEnabled')

if (environment.production) {
    logger.info('SENTRY ENABLED')
    Sentry.init(
        {
            dsn: 'https://9d053b67ae4d33f0508dc1283ed288ed@o4504956396568576.ingest.us.sentry.io/4508100116414464',

            // Set your release version, such as "getsentry@1.0.0"
            release: 'midiative@' + versions.version,
            // Set your dist version, such as "1"
            dist: versions.revision,
            integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                SentryAngular.browserTracingIntegration(),
                // Registers the Replay integration,
                // which automatically captures Session Replays
                Sentry.replayIntegration(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost'],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        },
        // Forward the init method from @sentry/angular
        SentryAngular.init
    )
} else {
    logger.info('SENTRY DISABLED')
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        IonicModule.forRoot(),
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
            },
        }),
        EffectsModule.forRoot([AutoSaveEffect]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production, // Restrict extension to log-only mode
            connectInZone: true,
        }),
        // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
        StoreRouterConnectingModule.forRoot(),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        MatIconModule,
        ActionsModule.forRoot(),
        FeatureFlagModule,
        ModalModule,
        TimelineModule,
        TrackHeaderModule,
        TrackPatternModule,
        TrackModule,
        TutorialModule,
        HammerModule,
        MenuModule,
        SpinnerModule,
        ProjectTimelineModule,
        SelectDialogModule,
        TapTempoModule,
        HudModule,
        MixerModule,
        ColorPickerModule,
        ShortcutsOverlayModule,
        HeaderModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            // Attach the Sentry ErrorHandler
            useValue: SentryAngular.createErrorHandler(),
        },
        {
            provide: SentryAngular.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [SentryAngular.TraceService],
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerGestureConfig,
        },
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private pl: PlatformService, private fileManagerService: SoundfontManagerService) {
        // use custom load method
        initCustomMidiLoader(fileManagerService)
    }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http)
}
