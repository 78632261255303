import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { ActionTask, createRouterLink, Logger, ROUTE_NEW_PROJECT } from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'
import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToNewProjectAction',
})
export class NavigateToNewProjectAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToNewProjectAction')

    constructor(private router: Router) {
        super()
        this.label.next('HOME.NEW_PROJECT')
        this.icon.next('create')
        this.domID.next('new-project-action')
    }

    onClick() {
        this.navigate(ROUTE_NEW_PROJECT)
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
