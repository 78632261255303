import { Injectable } from '@angular/core'
import {
    DefaultAction,
    PianoRollStateModel,
    Logger,
    UiStateModel,
    DisplayNote,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { ActionController } from '../actions.decorator'
import { Store } from '@ngrx/store'
import { SetPrClipboardAction } from '../../store/actions/ui-state.actions'
@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollCopyNotesAction',
})
export class PianoRollCopyNotesAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollCopyNotesAction')
    private pianoRollState: PianoRollStateModel
    private uiState: UiStateModel
    constructor(private store: Store<AppState>) {
        super()

        this.label.next('COMMON.COPY')
        this.icon.next('file_copy')

        this.subscriptions.push(
            this.store.select('pianoRollState').subscribe({
                next: async (value) => {
                    this.pianoRollState = value
                },
                error: (e) => this.logger.error(e),
            }),

            this.store.select('uiState').subscribe({
                next: async (value) => {
                    this.uiState = value
                },
                error: (e) => this.logger.error(e),
            })
        )
    }

    onClick() {
        this.logger.debug('PianoRollCopyNotesAction called')
        const notes: DisplayNote[] = Object.values(this.pianoRollState.notes.entities)
        const selectedNotes: DisplayNote[] = notes.filter((n) => this.uiState.prSelectedNotes.includes(n.id))
        this.store.dispatch(new SetPrClipboardAction(selectedNotes))
    }
}
