import { NgModule } from '@angular/core'
import { ModalComponent } from './modal.component'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ButtonModule } from '../button/button.module'
import { TranslateModule } from '@ngx-translate/core'
import { ModalBodyComponent } from './modal-body.component'

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, ButtonModule, TranslateModule],
    declarations: [ModalComponent, ModalBodyComponent],
    exports: [ModalComponent, ModalBodyComponent],
})
export class ModalModule {}
