import {
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { Router } from '@angular/router'
import { ModalMessage, ModalService } from './modal.service'
import { SpinnerComponent } from '../spinner/spinner.component'
import { TapTempoComponent } from '../tap-tempo/tap-tempo.component'
import { DialogMessage } from './basic-dialog'

export interface ModalActionButton {
    // label of the action
    label: string

    // id of message (type: 'component-ref' only)
    id?: string

    /**
     * This function is called when the actions is triggered
     * @param close call this to close the dialog
     * @param value (type: 'component-ref' only) set to callback value emitted by a sub component
     * @returns void
     */
    callback?: (close: () => void, value?: any) => any

    // router target
    routerLink?: string
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
    hasDefaultContent = false
    currentMessage: ModalMessage
    @Output() requestClose = new EventEmitter<boolean>()

    // if true input properties will be overwritten by service
    @Input() useService: boolean = false

    // dialog shown if true
    @Input() isOpen = false
    // action button to display at the bottom of the dialog
    @Input() buttonActions: ModalActionButton[] = []
    // headline of the dialog
    @Input() headline = ''
    // text of the dialog
    @Input() text = ''
    // if false render buttons as list
    @Input()
    renderButtons: boolean = true

    private messages: ModalMessage[] = []

    constructor(private router: Router, private modalService: ModalService) {}

    ngOnInit() {
        // check if default content is available, this is necessary for default template rendering
        this.hasDefaultContent = this.headline !== '' || this.text !== ''

        if (this.useService) {
            // subscribe to service messages
            // todo: enable batching of dialog messages
            this.modalService.modalMessages.subscribe((message) => {
                if (message) {
                    this.messages.push(message)
                    this.handlePendingMessages()
                }
            })
        }
    }

    async handlePendingMessages() {
        if (!this.isOpen && this.messages.length > 0) {
            const message = this.messages.shift()
            const isValid = (message.isValid && message.isValid(message)) || !message.isValid
            if (isValid) {
                // select handler by type
                if (message.type === 'component-ref') {
                    this.showComponentRefMessage(message)
                } else {
                    this.showDefaultMessage(message)
                }
            }
        }
    }

    /**
     * Handle component ref message
     * @param message
     */
    private async showComponentRefMessage(message: ModalMessage) {
        // set message and open dialog
        this.currentMessage = message
        this.isOpen = true
    }

    /**
     * Handle dialog messaging events form sub component
     * @param message DialogMessage emitted from sub component
     */
    async onSubDialogMessage(message: DialogMessage<any>) {
        // handle close event
        if (message.message === 'close') {
            this.closeDialog()
        } else {
            if (this.currentMessage.actions) {
                // select message handler by id
                const handlers = this.currentMessage.actions.filter((e) => e.id === message.message)
                if (handlers.length > 0) {
                    const handler = handlers[0]
                    handler.callback(async () => {
                        this.closeDialog()
                    }, message.payload)
                }
            }
        }
    }

    private async showDefaultMessage(message: ModalMessage) {
        this.currentMessage = message
        this.isOpen = true
        this.headline = message.headline
        this.text = message.text
        this.hasDefaultContent = this.headline !== '' || this.text !== ''

        // wrap actions callback to close modal dialog
        this.buttonActions = message.actions.map((action) => ({
            ...action,
            callback: () => {
                this.isOpen = false
                this.currentMessage = undefined
                action.callback(null, null)
                this.handlePendingMessages()
            },
        }))
    }

    async onClickActionButton(action: ModalActionButton, $event: MouseEvent) {
        if (typeof action.callback === 'function') {
            action.callback(async () => {
                this.closeDialog()
            })
        } else if (typeof action.routerLink === 'string') {
            await this.router.navigateByUrl(action.routerLink)
            this.closeDialog()
        }
    }

    closeDialog(): boolean {
        if (this.currentMessage && this.currentMessage.allowClose) {
            if (this.useService) {
                this.isOpen = false
                this.currentMessage.onClose()
                this.currentMessage = null

                return true
            } else {
                this.requestClose.emit(true)
                this.currentMessage.onClose()
                this.currentMessage = null

                return true
            }
        }
        return false
    }

    onClickInside($event: MouseEvent) {
        $event.stopPropagation()
    }

    onClickOutside($event: MouseEvent) {
        this.closeDialog()
    }
}
