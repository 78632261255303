import { Component, EventEmitter, Output } from '@angular/core'
import { Color } from '@tekbox-coco/midiative-commons'

export interface ColorPickerColor {
    key: string
    color: string
}

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
})
export class ColorPickerComponent {
    colors: ColorPickerColor[] = []

    @Output()
    selectColor = new EventEmitter<ColorPickerColor>()

    ngOnInit() {
        this.colors = Object.keys(Color).map((e) => ({
            key: e,
            color: Color[e],
        }))
    }

    onSelectColor(item: ColorPickerColor) {
        this.selectColor.emit(item)
    }
}
