import { DefaultAction } from '@tekbox-coco/midiative-commons'
import { Injectable } from '@angular/core'
import { AudioService } from '../../services/audio/audio.service'
import { SetProjectBarRenderWidthAction } from '../../store/actions/ui-state.actions'
import { PROJECT_DEFAULT_ZOOM_STEP } from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { UndoAction as StoreUndoAction } from '../../store/meta/history/history.actions'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'UndoAction',
})
export class UndoAction extends DefaultAction {
    constructor(private store: Store) {
        super()
        this.icon.next('undo')
        this.domID.next('project_undo')
    }

    onClick() {
        this.store.dispatch(new StoreUndoAction())
    }
}
