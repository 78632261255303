// TODO: MOVE TO CALCULATION SERVICE
import { NotesEnum } from '../enums/NotesEnum'
import { PIANO_ROLL_KEY_COUNT } from '../global-constants'

export const MAX_GRID_RESOLUTION = 128 * 3 // 384
export const QUARTER_BEAT_RESOLUTION = MAX_GRID_RESOLUTION / 4 // 96
export const SIXTEENTH_BEAT_RESOLUTION = MAX_GRID_RESOLUTION / 16 // 24

export const SMALLEST_DISPLAYABLE_IRN = 2

// TODO: Move to calculation service and use rows from state as max key number
export function getPianoRollKeyMap() {
    return Array.from(Array(PIANO_ROLL_KEY_COUNT).keys())
        .map((index) => index + 12)
        .map((note) => {
            return {
                note,
                name: NotesEnum[note],
            }
        })
        .reverse()
}
