import { InitialSchemaMigration } from './migrations/InitialSchemaMigration'
import { SchemaMigrator } from './SchemaMigrator'
import { ProjectStateModel } from '../project-state.model'

/**
 * Handles schema migrations
 */
export class SchemaMigrationHandler {
    private migrators: SchemaMigrator[]

    constructor() {
        // list of all migrations.
        // migrations are executed in the array order from bottom to top
        this.migrators = [new InitialSchemaMigration()]
    }

    migrate(data: ProjectStateModel): ProjectStateModel {
        return (
            this.migrators
                // filter all migrators with lower version number
                .filter((migrator) => migrator.sourceSchemaVersion >= data.schemaVersion)
                // run all migrations in sequence
                .reduce((a, b) => {
                    return b.migrate(a)
                }, data)
        )
    }

    validate(data: ProjectStateModel): boolean {
        const matchingMigrators = this.migrators.filter(
            (migrator) => migrator.targetSchemaVersion === data.schemaVersion
        )
        if (matchingMigrators.length > 0) {
            return matchingMigrators[0].validate(data)
        } else {
            return false
        }
    }
}

/**
 * Singleton to use in app
 */
export const MigrationHandler = new SchemaMigrationHandler()
