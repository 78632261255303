import { Injectable } from '@angular/core'
import {
    createProjectRouterLink,
    DefaultAction,
    initialPianoRollState,
    initialProjectState,
    Logger,
    PatternType,
    PianoRollStateModel,
    ProjectStateModel,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Store } from '@ngrx/store'
import { UpdatePatternAction } from '../../store/actions/project-state.actions'
import { ModalService } from '@tekbox-coco/midiative-components'
import { Router } from '@angular/router'

import { ActionController } from '../actions.decorator'
import { SetPreScrollEnabledAction } from '../../store/actions/ui-state.actions'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'PianoRollToggleScrollAction',
})
export class PianoRollToggleScrollAction extends DefaultAction {
    private readonly logger = Logger.createLogger('PianoRollToggleScrollAction')
    prScrollEnabled: boolean = true

    constructor(private store: Store<AppState>) {
        super()
        this.label.next('PianoRollToggleScrollAction')
        this.icon.next('close')

        this.subscriptions.push(
            this.store.select('uiState').subscribe({
                next: (value) => {
                    this.prScrollEnabled = value.prScrollEnabled

                    if (this.prScrollEnabled) {
                        this.icon.next('dynamic_feed')
                    } else {
                        this.icon.next('auto_fix_normal')
                    }
                },
            })
        )
    }

    async onClick() {
        this.store.dispatch(new SetPreScrollEnabledAction(!this.prScrollEnabled))
    }
}
