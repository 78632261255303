import { Injectable } from '@angular/core'
import { ActionController } from '../actions.decorator'
import { ActionTask, DefaultAction } from '@tekbox-coco/midiative-commons'
import { MixerService } from '../../services/mixer/mixer.service'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'CloseMixerAction',
})
export class CloseMixerAction extends DefaultAction implements ActionTask {
    constructor(private mixerService: MixerService) {
        super()
        this.icon.next('arrow_back')
    }

    onClick(options?: any) {
        this.mixerService.closeMixer()
    }
}
