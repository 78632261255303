import { Injectable } from '@angular/core'
import { AdMob, AdmobConsentStatus, BannerAdOptions, BannerAdPosition, BannerAdSize } from '@capacitor-community/admob'
import { Logger, PlatformService } from '@tekbox-coco/midiative-commons'
import { environment } from '../../environments/environment'

const BANNER_AD_ID_ANDROID = 'ca-app-pub-7785831696975067/5214662763'
const BANNER_AD_ID_IOS = 'ca-app-pub-7785831696975067/9879911791'
const INTERSTITIAL_AD_ID_ANDROID = 'ca-app-pub-7785831696975067/6357978497'
const INTERSTITIAL_AD_ID_IOS = 'ca-app-pub-7785831696975067/9688340102'
const REWARD_AD_ID_ANDROID = 'ca-app-pub-7785831696975067/8083614392'
const REWARD_AD_ID_IOS = 'ca-app-pub-7785831696975067/9496768415'

@Injectable({
    providedIn: 'root',
})
export class AdService {
    private readonly logger = Logger.createLogger('AdService')

    constructor(private platform: PlatformService) {}

    public async initializeAdMob() {
        if (!environment.showAds || !this.platform.isNative()) {
            return
        }
        await AdMob.initialize()

        const [trackingInfo, consentInfo] = await Promise.all([
            AdMob.trackingAuthorizationStatus(),
            AdMob.requestConsentInfo(),
        ])

        if (trackingInfo.status === 'notDetermined') {
            /**
             * If you want to explain TrackingAuthorization before showing the iOS dialog,
             * you can show the modal here.
             * ex)
             * const modal = await this.modalCtrl.create({
             *   component: RequestTrackingPage,
             * });
             * await modal.present();
             * await modal.onDidDismiss();  // Wait for close modal
             **/

            await AdMob.requestTrackingAuthorization()
        }

        const authorizationStatus = await AdMob.trackingAuthorizationStatus()
        if (
            authorizationStatus.status === 'authorized' &&
            consentInfo.isConsentFormAvailable &&
            consentInfo.status === AdmobConsentStatus.REQUIRED
        ) {
            await AdMob.showConsentForm()
        }
    }

    public async showBanner(): Promise<void> {
        if (!environment.showAds) {
            return
        }
        // AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
        //     // Subscribe Banner Event Listener
        // });

        // AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
        //     // Subscribe Change Banner Size
        // });

        let options: BannerAdOptions
        if (this.platform.isAndroid()) {
            options = {
                adId: BANNER_AD_ID_ANDROID,
                adSize: BannerAdSize.FULL_BANNER,
                position: BannerAdPosition.BOTTOM_CENTER,
                margin: 0,
                // isTesting: true
                // npa: true
            }
        } else if (this.platform.isIOS()) {
            // TODO insert iOS id
            options = {
                adId: BANNER_AD_ID_IOS,
                adSize: BannerAdSize.FULL_BANNER,
                position: BannerAdPosition.BOTTOM_CENTER,
                margin: 0,
                // isTesting: true
                // npa: true
            }
        } else {
            // no ads on web
            return
        }

        await AdMob.showBanner(options)
    }

    public async showInterstitial(): Promise<void> {
        if (!environment.showAds) {
            return
        }
        // AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
        //     // Subscribe prepared interstitial
        // });

        let options
        if (this.platform.isAndroid()) {
            options = {
                adId: INTERSTITIAL_AD_ID_ANDROID,
                // isTesting: true
                // npa: true
            }
        } else if (this.platform.isIOS()) {
            options = {
                adId: INTERSTITIAL_AD_ID_IOS,
                // isTesting: true
                // npa: true
            }
        } else {
            // no ads on web
            return
        }

        await AdMob.prepareInterstitial(options)
        await AdMob.showInterstitial()
    }

    public async showRewardVideo(): Promise<void> {
        if (!environment.showAds) {
            return
        }
        // AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
        //     // Subscribe prepared rewardVideo
        // });

        // AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) => {
        //     // Subscribe user rewarded
        //     console.log(rewardItem);
        // });

        let options
        if (this.platform.isAndroid()) {
            options = {
                adId: REWARD_AD_ID_ANDROID,
                // isTesting: true
                // npa: true
                // ssv: {
                //   userId: "A user ID to send to your SSV"
                //   customData: JSON.stringify({ ...MyCustomData })
                //}
            }
        } else if (this.platform.isIOS()) {
            options = {
                adId: REWARD_AD_ID_IOS,
                // isTesting: true
                // npa: true
                // ssv: {
                //   userId: "A user ID to send to your SSV"
                //   customData: JSON.stringify({ ...MyCustomData })
                //}
            }
        } else {
            // no ads on web
            return
        }

        await AdMob.prepareRewardVideoAd(options)
        const rewardItem = await AdMob.showRewardVideoAd()
    }

    public async removeBanner() {
        if (!environment.showAds) {
            return
        }
        await AdMob.removeBanner().catch((e) => this.logger.error('Could not remove Banner', e))
    }
}
