import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { ActionTask, Logger, HudActionButtonConfig } from '@tekbox-coco/midiative-commons'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit, OnChanges, OnDestroy {
    private readonly logger = Logger.createLogger('ToolbarComponent')

    @Output() onClickItem: EventEmitter<string> = new EventEmitter()
    @Input() toolbarItems: (string | HudActionButtonConfig)[]
    @Input() offsetLeft = 0
    @Input() offsetRight = 0

    renderedToolbarItems: HudActionButtonConfig[] = []

    subscriptions: Subscription[] = []

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        this.updateRenderedToolbarItems()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateRenderedToolbarItems()
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    onClickActionButton(action: string) {
        this.onClickItem.emit(action)
    }

    private updateRenderedToolbarItems() {
        this.renderedToolbarItems = this.toolbarItems
            .map((e) => {
                if (typeof e === 'string') {
                    return {
                        action: e,
                    }
                }
                return e
            })
            .filter((e) => e != undefined)
            .filter((item) => {
                const actionTask = <ActionTask>this.injector.get((item as HudActionButtonConfig).action)
                if (actionTask) {
                    return actionTask.visible.value
                }
                return false
            }) as HudActionButtonConfig[]
    }
}
