import { Injectable } from '@angular/core'
import { DefaultAction } from '@tekbox-coco/midiative-commons'
import {
    ActionTask,
    createRouterLink,
    Logger,
    createProjectSettingsRouterLink,
    AppState,
} from '@tekbox-coco/midiative-commons'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'

import { ActionController } from '../actions.decorator'

@Injectable({
    providedIn: 'root',
})
@ActionController({
    name: 'NavigateToProjectSettingsAction',
})
export class NavigateToProjectSettingsAction extends DefaultAction implements ActionTask {
    private readonly logger = Logger.createLogger('NavigateToProjectSettingsAction')
    private projectId: string = ''

    constructor(private router: Router, private store: Store<AppState>) {
        super()
        this.label.next('PROJECT.MENU.SETTINGS')
        this.icon.next('settings')

        this.store.select('projectState').subscribe({
            next: (value) => {
                this.projectId = value.id
            },
            error: (e) => {
                console.error(e)
            },
        })
    }

    onClick() {
        let route = createProjectSettingsRouterLink(this.projectId)
        this.navigate(route.join('/'))
    }

    private async navigate(route: string): Promise<void> {
        await this.router.navigate(createRouterLink(route)).catch((e) => this.logger.error(e))
    }
}
