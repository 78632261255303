import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import {
    DisplayNote,
    getPianoRollKeyMap,
    irnToPx,
    Logger,
    MAX_GRID_RESOLUTION,
    OtherTrackNote,
    PIANO_ROLL_KEY_COUNT,
    PrToolEnum,
    pxToIrn,
    quantizeIRNLength,
    quantizeIRNToBeat,
    SMALLEST_DISPLAYABLE_IRN,
} from '@tekbox-coco/midiative-commons'

@Component({
    selector: 'app-piano-note',
    templateUrl: './piano-note.component.html',
})
export class PianoNoteComponent implements OnInit, OnChanges {
    private readonly logger = Logger.createLogger('PianoNoteComponent')

    @Output()
    noteUpdate: EventEmitter<DisplayNote> = new EventEmitter<DisplayNote>()

    @Output()
    noteAction: EventEmitter<{ note: DisplayNote; action: string; srcEvent: MouseEvent }> = new EventEmitter<{
        note: DisplayNote
        action: string
        srcEvent: MouseEvent
    }>()

    @Output()
    noteUpdateDeltas: EventEmitter<{ x: number; y: number; resizeX: number }> = new EventEmitter<{
        x: number
        y: number
        resizeX: number
    }>()

    public readonly prToolEnumEnum = PrToolEnum

    @Input()
    prBarRenderWidth: number = 0
    @Input()
    prCellRenderHeight: number = 0
    @Input()
    timeSignatureNumerator: number = 0
    @Input()
    timeSignatureDenominator: number = 0
    @Input()
    activeTool: PrToolEnum = PrToolEnum.NONE

    @Input()
    note!: DisplayNote | OtherTrackNote

    @Input()
    selected: string[] = []

    /**
     * If true note is displayed in view only mode
     */
    @Input()
    viewOnlyMode = false

    @Input()
    snapToGridActive: boolean
    @Input()
    selectedResolution: number

    @Input()
    delta: { x: number; y: number; resizeX: number } = { x: 0, y: 0, resizeX: 0 }

    @Input()
    patternLengthIRN: number

    pianoRollKeys = getPianoRollKeyMap()
    public noteStyle: Record<string, any> = {}

    public readonly PrToolEnum = PrToolEnum

    // local delta values on item drag
    _deltaX: number = 0
    _deltaY: number = 0
    _deltaResizeX: number = 0

    // track panned stated, used to ensure delete note event is only emitted when not panned
    private panStarted = false
    private panned = false

    showNoteInResizeArea: boolean = false

    ngOnInit(): void {
        this.onStateChange()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.prBarRenderWidth || changes.selected || changes.delta) {
            if (changes.delta) {
                const currentValueNull =
                    changes.delta.currentValue.x === 0 &&
                    changes.delta.currentValue.y === 0 &&
                    changes.delta.currentValue.resizeX === 0
                const hasPreviousValue = changes.delta.previousValue != undefined

                // if note is selected and new delta is all 0 this indicates that the value was committed in "main" note.
                // trigger save changes with previous value
                if (this.isSelected && currentValueNull && hasPreviousValue) {
                    const value = changes.delta.previousValue
                    this.noteStyle = this.calcNoteStyle(value.resizeX, value.x, value.y)
                    this.emitUpdateEvent(value.resizeX)
                    return
                }
            }

            this.onStateChange()
        }
    }

    private get isSelected() {
        return this.selected.indexOf(this.note.id) != -1
    }

    /**
     * Return global only if selected
     */
    private get deltaResizeX(): number {
        if (this.selected.length > 0 && this.selected.indexOf(this.note.id) != -1) {
            return this.delta.resizeX
        }
        return this._deltaResizeX
    }

    /**
     * Return global only if selected
     */
    private get deltaX(): number {
        if (this.selected.length > 0 && this.selected.indexOf(this.note.id) != -1) {
            return this.delta.x
        }
        return this._deltaX
    }

    /**
     * Return global only if selected
     */
    private get deltaY(): number {
        if (this.selected.length > 0 && this.selected.indexOf(this.note.id) != -1) {
            return this.delta.y
        }
        return this._deltaY
    }

    /**
     * Item can pan in x and y direction
     * @param $event pan event data
     */
    onPan($event) {
        $event.srcEvent.preventDefault()
        $event.srcEvent.stopPropagation()
        if (this.activeTool === PrToolEnum.PENCIL) {
            this._deltaX = $event.deltaX
            this._deltaY = $event.deltaY

            if (this.selected.length > 0) {
                this.noteUpdateDeltas.emit({ x: $event.deltaX, y: $event.deltaY, resizeX: 0 })
            }

            this.onStateChange()
        }
    }

    /**
     * Called when pan is done. Triggers emitting of updated irn values
     * @param $event pan event data
     */
    onPanEnd($event) {
        $event.srcEvent.preventDefault()
        $event.srcEvent.stopPropagation()
        if (this.selected.length > 0) {
            this.noteUpdateDeltas.emit({ x: 0, y: 0, resizeX: 0 })
        }
        this.emitUpdateEvent()
    }

    /**
     * Item can pan in x and y direction
     * @param $event pan event data
     */
    onResize($event) {
        this._deltaResizeX = $event.deltaX
        if (this.selected.length > 0) {
            this.noteUpdateDeltas.emit({ x: 0, y: 0, resizeX: $event.deltaX })
        }
        this.onStateChange()
    }

    /**
     * Called when pan is done. Triggers emitting of updated irn values
     * @param $event pan event data
     */
    onResizeEnd($event) {
        if (this.selected.length > 0) {
            this.noteUpdateDeltas.emit({ x: 0, y: 0, resizeX: 0 })
        }
        this.emitUpdateEvent(this.deltaResizeX)
    }

    /**
     * Handle mouse down
     * Prevents default and stops propagation
     * @param event MouseEvent
     */
    onMouseDown(event: MouseEvent) {
        event.preventDefault()
        event.stopPropagation()
        this.panStarted = true
        this.panned = false
    }

    /**
     * Handle mouse move
     * Prevents default and stops propagation
     * @param event MouseEvent
     */
    onMouseMove(event) {
        event.preventDefault()
        event.stopPropagation()
        if (this.panStarted) {
            this.panned = true
        } else {
            this.panned = false
        }
    }

    /**
     * Handle mouse up
     * Prevents default and stops propagation
     * @param event MouseEvent
     */
    onMouseUp(event) {
        event.preventDefault()
        event.stopPropagation()
        if (!this.panned && this.panStarted) {
            this.noteAction.emit({ note: this.note, action: this.activeTool, srcEvent: event })
        }

        this.panStarted = false
        this.panned = false
    }

    protected onStateChange() {
        this.noteStyle = this.calcNoteStyle(this.deltaResizeX, this.deltaX, this.deltaY)
    }

    /**
     * Updates the note in the store after it was edited.
     * @param resize defines if it is a resize or a moving action
     * @protected
     */
    protected emitUpdateEvent(deltaResizeX: number = 0, ignoreSnapToGrid: boolean = false) {
        let timeSignature = this.timeSignatureNumerator / this.timeSignatureDenominator
        const widthPx = irnToPx(this.note.lengthIRN, timeSignature, this.prBarRenderWidth) + deltaResizeX
        const displaySize = pxToIrn(widthPx, timeSignature, this.prBarRenderWidth)

        let left = this.note.startIRN
        // search for px value in css style.left property (100px => 100)
        const match = this.noteStyle.left.match(/(-?[0-9]+)/gm)
        if (match.length > 0 && match[0].length > 0) {
            left = pxToIrn(match[0], timeSignature, this.prBarRenderWidth)
            // set pattern start to 0 if it is out of bounds
            if (left < 0) {
                left = 0
            }
        }

        // prent to move the note out of the top or bottom of the piano roll. Sets to highest or lowest note if the user tries to do that.
        let noteIndex = Math.round(Number(this.noteStyle.top.replace('px', '')) / this.prCellRenderHeight)
        if (noteIndex < 0) {
            noteIndex = 0
        } else if (noteIndex > PIANO_ROLL_KEY_COUNT) {
            noteIndex = PIANO_ROLL_KEY_COUNT - 1
        }
        let pianoRollKey = this.pianoRollKeys[noteIndex]

        // if displaySize is negative return the lowest displayable value.
        let correctedDisplaySize = displaySize
        if (displaySize <= 0) {
            correctedDisplaySize = SMALLEST_DISPLAYABLE_IRN
        }

        let emitPayload
        const resize = deltaResizeX != 0
        if (resize) {
            let length: number
            if (this.snapToGridActive) {
                length = quantizeIRNLength(left, displaySize, this.selectedResolution, timeSignature)
            } else {
                length = correctedDisplaySize
            }

            emitPayload = {
                ...this.note,
                key: pianoRollKey.note,
                lengthIRN: this.snapToGridActive
                    ? // quantizeIRNLength can handle negative lengths therefore we should use the uncorrected display size
                      length
                    : correctedDisplaySize,
            }
        } else {
            let start: number
            if (this.snapToGridActive) {
                start = quantizeIRNToBeat(left, this.selectedResolution, timeSignature)
            } else {
                start = left
            }
            emitPayload = {
                ...this.note,
                key: pianoRollKey.note,
                startIRN: start,
            }
        }

        this.noteUpdate.emit(emitPayload)
    }

    private calcNoteStyle(deltaResizeX: number, deltaX: number, deltaY: number): Record<string, any> {
        const beatWidth = this.prBarRenderWidth

        let timeSignature = this.timeSignatureNumerator / this.timeSignatureDenominator

        let noteWidth = irnToPx(this.note.lengthIRN, timeSignature, this.prBarRenderWidth) + deltaResizeX

        // Show Note name in resize area
        if (noteWidth < 30) {
            this.showNoteInResizeArea = true
        } else {
            this.showNoteInResizeArea = false
        }

        let noteLeftPadding = beatWidth * (this.note.startIRN / MAX_GRID_RESOLUTION) * (1 / timeSignature)

        if (deltaX != 0) {
            noteLeftPadding += deltaX
        }

        let top = this.pianoRollKeys.map((k) => k.note).indexOf(this.note.key) * this.prCellRenderHeight

        if (deltaY != 0) {
            top += deltaY
        }
        const trackColor = (this.note as OtherTrackNote).trackColor
        return {
            position: 'absolute',
            width: `${noteWidth}px`,
            left: `${noteLeftPadding}px`,
            height: `${this.prCellRenderHeight}px`,
            top: `${top}px`,
            background: this.selected.indexOf(this.note.id) != -1 ? 'red' : trackColor ? trackColor : '#19FFE6', // turtle-cyan-100
        }
    }
}
