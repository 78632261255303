import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { ColorPickerComponent } from './color-picker.component'
import { ColorPickerDialogComponent } from './color-picker.dialog'

@NgModule({
    declarations: [ColorPickerComponent, ColorPickerDialogComponent],
    exports: [ColorPickerComponent, ColorPickerDialogComponent],
    imports: [CommonModule, MatIconModule],
})
export class ColorPickerModule {}
